<template>
  <div>     
    <!-- // -->
    <v-card tile flat v-if="!(show===false)">  
        <v-card-title class="primary white--text" v-if="top_label!=false" >
            <div class="not-f7 font-italic ">
                {{MBS.actions.SIZE(DATA_ITEMS)}} {{MBS.actions.TEXT_UP1(NAME_ITEMS)}}
            </div>
        </v-card-title>  
        <!-- <v-card v-if="!PAGE_LOAD"  -->
        <v-card v-if="false" 
            :height="vsh/2"
            tile="" flat>
            <mbs-page-spinner />  
        </v-card>
        <div v-else class="primary lighten-5" 
            :class="'pa-'+padding"> 
            <v-card tile flat>
                <!-- //HEADER -->
                <v-layout v-if="!table_no_header" class="primary white--text ma-0 py-4 px-2" dark align-center>  
                    <v-icon dark v-if="table_title_icon" class="mr-2" >{{table_title_icon}}</v-icon> 
                    <v-toolbar-title v-if="table_title" class="">{{table_title}}</v-toolbar-title> 
                    <v-spacer></v-spacer>  
                    <span v-if="!table_actions">
                        <v-btn  @click="ADD_ITEM()" 
                            color="white" outlined="" rounded="" 
                            class="mr-2">
                            <v-icon size="18" class="pr-1">mdi-plus-thick</v-icon>
                            Add {{MBS.actions.TEXT_UP1(NAME_ITEM)}}
                        </v-btn>
                    </span >
                    <v-layout row v-else justify-center align-center >  
                        <v-spacer></v-spacer>
                        <span v-for="(item, index) in TABLE_ACTION('btn')" :key="index"> 
                            <v-menu offset-y  v-if="item.actions" rounded="xl" >
                                <template v-slot:activator="{ on }"> 
                                    <v-btn 
                                        v-on="on" 
                                        @click="SELECT_TABLE_ACTION(item)" 
                                        :color="item.color?item.color:'white'" 
                                        :outlined="item.outlined" 
                                        :rounded="item.rounded" 
                                        :width="item.width" 
                                        :min-width="item.min_width" 
                                        :max-width="item.max_width" 
                                        :height="item.height" 
                                        :min-height="item.min_height" 
                                        :max-height="item.max_height" 
                                        :small="item.small" :tile="item.tile"  
                                        class="mr-2">
                                        <v-icon size="18" class="pr-1" v-if="item.icon">
                                            {{item.icon}}
                                        </v-icon> 
                                        {{item.text}}
                                        <v-icon class="ml-2">arrow_drop_down</v-icon>
                                    </v-btn>
                                </template> 
                                <v-card>
                                    <v-card-title class="secondary white--text" dark>
                                        {{item.text}}
                                    </v-card-title> 
                                    <v-list >   
                                        <v-list  > 
                                            <div v-for="(action,index) in item.actions" :key="index">
                                                <v-list-item  @click="SELECT_TABLE_ACTION(action)">
                                                    <v-list-item-icon v-if="action.icon">
                                                        <v-icon>{{action.icon}}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>{{action.text}}</v-list-item-content>
                                                </v-list-item> 
                                            </div>
                                        </v-list> 
                                    </v-list>
                                </v-card>
                            </v-menu> 
                            <v-btn v-else-if="!item.buttons" 
                                @click="SELECT_TABLE_ACTION(item)" 
                                :color="item.color?item.color:'white'" 
                                :outlined="item.outlined" 
                                :rounded="item.rounded" 
                                :icon="item.btn_icon" 
                                :width="item.width" 
                                :min-width="item.min_width" 
                                :max-width="item.max_width" 
                                :height="item.height" 
                                :min-height="item.min_height" 
                                :max-height="item.max_height" 
                                :small="item.small" :tile="item.tile"   
                                :class="item.class?item.class:'mr-2'">
                                <v-layout class="ma-0" justify-center align-center >
                                    <v-icon size="18" class="pr-1" v-if="item.icon">
                                        {{item.icon}}
                                    </v-icon> 
                                    
                                    <span>
                                        {{MBS.actions.TEXT_UP1(item.text)}} 
                                    </span> 
                                    <!-- //-----------indicator -->
                                    <span v-if="item.indicator_r">
                                        <v-avatar  
                                            size="12"
                                            :color="'white'" 
                                            :class="MBS.data.animate.zoom.in"
                                            class="ml-2"  >  
                                        </v-avatar> 
                                    </span> 
                                </v-layout>
                            </v-btn>
                            <v-btn-toggle @click="SELECT_TABLE_ACTION(item)" v-else 
                                color="primary darken-4"
                                dense dark v-model="SELECT_TABLE_ACTION_MODEL"
                                group class="mr-5"
                                mandatory  >
                                <v-btn v-for="(item2,index2) in item.buttons" :key="'k'+index2"
                                    :value="item2.value"  
                                    >
                                    <v-icon>{{item2.icon}}</v-icon>
                                </v-btn> 
                            </v-btn-toggle>
                        </span> 
                    </v-layout> 
                    <v-menu  bottom left rounded="xl"
                        v-model="menuFilters" 
                        :close-on-content-click="false"
                        :nudge-width="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-if="filters" class="ml-5"
                                dark outlined="" rounded=""
                                icon
                                v-on="on" >
                                <v-icon>mdi-filter-cog-outline</v-icon>
                            </v-btn>
                        </template> 
                        <v-card>
                            <v-card-title class="secondary white--text">
                                <v-layout justify-center align-center >
                                    <v-icon dark  class="mr-1">mdi-filter-cog-outline</v-icon>
                                    <div class="font-weight-bold not-f5">FILTER</div> 
                                    <v-spacer></v-spacer>
                                    <v-btn dark icon="" @click="menuFilters=false"><v-icon>close</v-icon></v-btn>
                                </v-layout>
                            </v-card-title>
                            <v-card-text>
                                <br/>
                                <v-card width="600" outlined class="pa-4">
                                    <mbs-input-filters 
                                        v-model="input_filters"
                                        :field_items="filters"
                                        :select_items="SELECT_ITEMS"
                                        :data_items="data_items"
                                        :filter_date="filter_date"
                                        :input_filter_Date="input_filter_Date"
                                        :filter_date_value="filter_date_value"
                                        :filter_date_only_display="filter_date_only_display"
                                        :dense="true"
                                        :px="1"
                                        />
                                </v-card>
                                
                            </v-card-text> 
                            <v-divider></v-divider>
                            <v-card-actions class="pa-0">
                                <v-btn @click="input_filters={}" tile height="45" color="primary" width="50%" class="mx-0">Clear</v-btn>
                                <v-btn @click="menuFilters=false" tile height="45" color="secondary" width="50%"  class="mx-0">Apply</v-btn>
                            </v-card-actions>
                            <v-layout class="pt-2 pr-2 pb-2" v-if="false">
                                <v-spacer></v-spacer>
                                <v-btn @click="menuFilters = false" color="primary">OK</v-btn>
                            </v-layout>
                        </v-card> 
                    </v-menu>
                    <v-menu  bottom left 
                        v-model="menuTableSet" 
                        :close-on-content-click="false"
                        :nudge-width="250">
                        <template v-slot:activator="{ on }">
                            <v-btn class="ml-3"
                                dark outlined="" rounded=""
                                icon
                                v-on="on" >
                                <v-icon>mdi-tune</v-icon>
                            </v-btn>
                        </template> 
                        <v-card>
                            <v-card-title primary-title>
                                <v-layout >
                                    <div class="font-weight-bold grey--text text--darken-2 not-f5">CUSTOMIZE TABLE</div> 
                                    <v-spacer></v-spacer>
                                    <v-btn icon="" @click="menuTableSet=false"><v-icon>close</v-icon></v-btn>
                                </v-layout>
                            </v-card-title>
                            <v-card-text>
                                <v-virtual-scroll 
                                    height="400"
                                    item-height="50"
                                    :items="HEADER_ITEMS">
                                    <template v-slot ="{item, index}" >
                                        <v-subheader v-if="item.title">{{item.name}}</v-subheader>
                                        <v-list-item v-else  @click="MBS.actions.TABLE_HEADER_SELECTOR(HEADER_ITEMS,index)" >
                                            <v-list-item-icon><v-icon :color="item.show?'primary':''">{{item.show?"mdi-checkbox-intermediate ":"mdi-checkbox-blank-outline"}}</v-icon></v-list-item-icon>
                                            <v-list-item-title>{{item.name}}</v-list-item-title>
                                        </v-list-item> 
                                    </template> 
                                </v-virtual-scroll>  
                            </v-card-text> 
                            <v-divider></v-divider>
                            <v-layout class="pt-2 pr-2 pb-2" >
                                <v-spacer></v-spacer>
                                <v-btn @click="menuTableSet = false" color="primary">OK</v-btn>
                            </v-layout>
                        </v-card> 
                    </v-menu>
                    <v-menu bottom left  rounded="xl">
                        <template v-slot:activator="{ on }">
                        <v-btn 
                            dark
                            icon
                            v-on="on" >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                        </template> 
                        <v-card>
                            <v-card-title class="secondary white--text" >
                                TABLE ACTIONS
                            </v-card-title> 
                            <v-list> 
                                 
                                <div v-if="!table_actions">
                                    <v-list-item  @click="ADD_ITEM()" >
                                        <v-list-item-title>Add {{MBS.actions.TEXT_UP1(NAME_ITEM)}} Manually</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item  @click="SELECT_EXCEL()" >
                                        <v-list-item-title>Import {{MBS.actions.TEXT_UP1(NAME_ITEMS)}} from Excel</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item   >
                                        <v-list-item-title>Export {{MBS.actions.TEXT_UP1(NAME_ITEMS)}}</v-list-item-title>
                                    </v-list-item> 
                                    <v-list-item  @click="DELETE_ALL_ITEMS()" >
                                        <v-list-item-title>Delete All {{MBS.actions.TEXT_UP1(NAME_ITEMS)}}</v-list-item-title>
                                    </v-list-item> 
                                </div>
                                <div v-else>
                                    <div v-for="(item, index) in TABLE_ACTION('action')" :key="index">
                                        <v-list-item  @click="SELECT_TABLE_ACTION(item)" >
                                            <v-list-item-action class="mr-3" ><v-icon>{{item.icon}}</v-icon></v-list-item-action>
                                            <v-list-item-title class="">{{item.text}}</v-list-item-title>
                                        </v-list-item> 
                                    </div> 
                                </div>
                            </v-list> 
                        </v-card>
                    </v-menu>
                </v-layout>

                <!--//TABS-->
                <div v-if="showTableTab">
                    <div v-if="CALCULATED_TABLE_TABS">
                        <mbs-page-tab   
                            v-model="currentTableTab"
                            color="b2"
                            text_color="black"
                            dark
                            :items="CALCULATED_TABLE_TABS"
                            />     
                    </div> 
                </div>

                <!-- //TABLE -->
                <v-card-text >
                    <v-layout row wrap class="ma-0 pr-5"> 
                        <v-spacer></v-spacer>
                        <div v-if="filter_date">
                            <div v-if="input_filter_Date">
                                <mbs-date-select 
                                    v-model="input_filter_Date"
                                    color="text--darken-2 grey"
                                    title_color="secondary"
                                    :max_from_date="MBS.date.moment().format('yyyy-MM-DD')" 
                                    :max_to_date="MBS.date.moment().format('yyyy-MM-DD')"
                                    :default_days="filterDateDifference?filterDateDifference:filter_default_days?filter_default_days:'7'" 
                                    />    
                            </div>
                            <hr>
                        </div>
                    </v-layout>
 

                    <v-alert v-if="input_filters.input_value" class="mt-4"
                        :dismissible="false" @click="menuFilters=true"
                        :outlined="false" 
                        :prominent="true"
                        color="primary"
                        border="top"
                        elevation="2"
                        colored-border
                        icon="mdi-filter-cog-outline" 
                        >
                        <v-layout class="ma-0" align-center>
                            <div>
                                <div>
                                    <strong class="">
                                        Data Filters: 
                                    </strong> 
                                </div>
                                <v-layout  class="ma-0 not-f3">
                                    <span v-for="(item,index) in input_filters.input_value">
                                        <span v-if="index"> ,</span>
                                        {{item.label}} 
                                    </span>
                                </v-layout>
                                <div  class="ma-0 not-f3">{{ MBS.actions.SIZE(input_filters.data_filtered) }} Data row(s) found.</div>
                            </div>
                            <v-spacer/>
                            <v-btn @click="input_filters={}" color="primary" icon> 
                                <v-icon>cancel</v-icon>
                            </v-btn> 
                        </v-layout>
                    </v-alert>
                    
                    <div  v-if="MBS.actions.SIZE(JOINED_DATA_ITEMS())>0">
                        <v-card-title>  
                            {{MBS.actions.TEXT_UP(TABLE_TAB?TABLE_TAB.name:NAME_ITEMS)}} 
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line 
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title> 
                        
                        <!-- @click:row="onRowClick" -->
                        <v-data-table 
                            @current-items="CURRENT_TABLE_ITEMS"
                            v-model="input_selected_items"
                            :options="tableOptions"
                            @update:options="TABLE_UPDATED"
                            :headers="HEADERS(VALUE_ITEMS)"
                            :items="JOINED_DATA_ITEMS()"
                            :search="search"
                            :height="height"
                            :fixed-header="fixed_header"

                            :show-select="show_select"
                            :single-select="single_select"  

                            :show-expand="show_expand?show_expand:input.show_expand"
                            :single-expand="single_expand?single_expand:input.single_expand"
                            :item-key="item_key?item_key:'key'"
                            >

                            <template v-slot:top>  
                            </template> 

                            <template v-slot:no-data>
                                <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                    <v-icon>warning</v-icon>
                                    Empty
                                </v-layout>
                            </template> 

                            <!-- //-------------------- IF ON CLICK ROW---------------------- -->
                            <template v-if="row_click&&!show_select" v-slot:item="{ item }" 
                                @click="onRowClick(item)">
                                <v-menu  right min-width="200">
                                    <template v-slot:activator="{ on }">
                                        <tr class="pointer" @click="onRowClick(item)" v-on="on"> 
                                            <td :class="HEADER_CLASS(header)" 
                                                :style="'text-align: '+header.align+';'"
                                                v-for="header in HEADERS(VALUE_ITEMS)" :key="header.value">

                                                <span v-if="header.value == 'action'">
                                                    <v-menu bottom left min-width="200">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn  
                                                                icon
                                                                v-on="on" >
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-toolbar   color="primary" dark>
                                                            ACTION
                                                            <v-spacer></v-spacer>
                                                            <v-btn icon=""><v-icon>close</v-icon></v-btn>
                                                        </v-toolbar>
                                                        <div class="white" v-if="HEADER_ACTION_ITEM.no_action">
        
                                                            <v-subheader>No action</v-subheader> 
                                                        </div> 
                                                        <v-list v-else>  
                                                            <v-subheader>Item number {{item.no}}</v-subheader> 
                                                            <div v-if="MBS.actions.SIZE(HEADER_ACTION_ITEM.item)<1">
                                                                <v-list-item  @click="EDIT_ITEM(item)" >
                                                                    <v-list-item-action><v-icon>mdi-pencil</v-icon></v-list-item-action>
                                                                    <v-list-item-title>Edit</v-list-item-title>
                                                                </v-list-item> 
                                                                <v-list-item  @click="DELETE_ITEM(item)" >
                                                                    <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action>
                                                                    <v-list-item-title>Delete</v-list-item-title>
                                                                </v-list-item>  
                                                            </div>
                                                            <div v-else>
                                                                <div v-for="(header, index) in HEADER_ACTION_ITEM?HEADER_ACTION_ITEM.item:null" 
                                                                        :key="index" >
                                                                    <v-list-item v-if="header.show_if?header.show_if(item):true"
                                                                        @click="SELECT_ITEM_ACTION(header.action, item)" >
                                                                        <v-list-item-action><v-icon>{{header.icon}}</v-icon></v-list-item-action>
                                                                        <v-list-item-title>{{header.name}}</v-list-item-title> 
                                                                    </v-list-item>    
                                                                </div>
                                                            </div>
                                                        </v-list>
                                                    </v-menu> 
                                                </span>
                                                <span v-if="header.value == 'icon'">
                                                    <v-icon 
                                                        :size="HEADER('icon')?HEADER('icon').size:''"
                                                        :color="HEADER('icon')?HEADER('icon').color:''">
                                                        {{item.icon}}
                                                    </v-icon>
                                                </span>
                                                <span v-if="header.value == 'image'">  
                                                    <span v-if="ItemActionFunction(item,header.progress)">
                                                        <v-progress-circular
                                                            :rotate="360" 
                                                            :indeterminate="false"
                                                            :size="40"
                                                            :width="5"
                                                            :value="40"
                                                            color="grey darken-"
                                                            >
                                                            <!-- {{ value }} -->
                                                            <v-avatar v-if="item.photoURL"
                                                                size="30"  >
                                                                <v-img :src="item.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                                    <template v-slot:placeholder>
                                                                        <v-row
                                                                            class="fill-height ma-0"
                                                                            align="center"
                                                                            justify="center">
                                                                            <v-progress-circular
                                                                                indeterminate
                                                                                color="grey lighten-5"
                                                                                /> 
                                                                        </v-row>
                                                                    </template>
                                                                </v-img>
                                                            </v-avatar> 
                                                            <v-avatar v-else
                                                                size="30" 
                                                                color="secondary" class="white--text" >
                                                                <div v-if="item">
                                                                    {{MBS.actions.TEXT_UP(item.surname?item.surname.slice(0,1):'..')}} 
                                                                </div>
                                                            </v-avatar>   
                                                        </v-progress-circular> 
                                                    </span>
                                                    <span v-else>
                                                        <v-avatar v-if="item.photoURL"
                                                            size="40"  >
                                                            <v-img :src="item.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                                <template v-slot:placeholder>
                                                                    <v-row
                                                                        class="fill-height ma-0"
                                                                        align="center"
                                                                        justify="center">
                                                                        <v-progress-circular
                                                                            indeterminate
                                                                            color="grey lighten-5"
                                                                            /> 
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </v-avatar> 
                                                        <v-avatar v-else
                                                            size="40" 
                                                            color="secondary" class="white--text" >
                                                            <div v-if="item">
                                                                {{MBS.actions.TEXT_UP(item.surname?item.surname.slice(0,1):'..')}} 
                                                            </div>
                                                        </v-avatar> 
                                                    </span> 
                                                </span>
                                                <span v-else-if="header.value == 'icon_image'">
                                                    <v-avatar
                                                        :size="HEADER('icon_image')?HEADER('icon_image').size?HEADER('icon_image').size:30:30"
                                                        :tile="HEADER('icon_image')?HEADER('icon_image').size?HEADER('icon_image').tile:false:false"
                                                        :color="HEADER('icon_image')?HEADER('icon_image').background_color?HEADER('icon_image').background_color:'b2':'b2'" > 
                                                        <v-icon v-if="item.icon_image || (HEADER('icon_image')?HEADER('icon_image').icon?true:false:false)" 
                                                            :size="HEADER('icon_image')?HEADER('icon_image').size?HEADER('icon_image').icon_size:null:null"
                                                            :color="HEADER('icon_image')?HEADER('icon_image').color:''">
                                                            {{ ICON_FUNCTION(
                                                                HEADER('icon_image')?(HEADER('icon_image').icon?HEADER('icon_image').icon:
                                                                item.icon_image.icon):item.icon_image.icon,item
                                                            )}}
                                                        </v-icon>  
                                                    </v-avatar>
                                                </span>
                                                <span v-else> 
                                                    {{ item[header.value] }} 
                                                </span>
                                            </td>
                                        </tr> 
                                    </template>
                                    <div v-if="row_click_action!=false">
                                        <v-toolbar   color="primary" dark>
                                            ACTION
                                            <v-spacer></v-spacer>
                                            <v-btn icon=""><v-icon>close</v-icon></v-btn>
                                        </v-toolbar>
                                        <div class="white" v-if="HEADER_ACTION_ITEM.no_action">
                                            <v-subheader>No action</v-subheader> 
                                        </div> 
                                        <v-list v-else>  
                                            <v-subheader>Item number {{item.no}}</v-subheader> 
                                            <div v-if="MBS.actions.SIZE(HEADER_ACTION_ITEM.item)<1">
                                                <v-list-item  @click="EDIT_ITEM(item)" >
                                                    <v-list-item-action><v-icon>mdi-pencil</v-icon></v-list-item-action>
                                                    <v-list-item-title>Edit</v-list-item-title>
                                                </v-list-item> 
                                                <v-list-item  @click="DELETE_ITEM(item)" >
                                                    <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action>
                                                    <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>  
                                            </div>
                                            <div v-else>
                                                <div v-for="(header, index) in HEADER_ACTION_ITEM?HEADER_ACTION_ITEM.item:null" 
                                                        :key="index" >
                                                    <v-list-item v-if="header.show_if?header.show_if(item):true"
                                                        @click="SELECT_ITEM_ACTION(header.action, item)" >
                                                        <v-list-item-action><v-icon>{{header.icon}}</v-icon></v-list-item-action>
                                                        <v-list-item-title>{{header.name}}</v-list-item-title> 
                                                    </v-list-item>    
                                                </div>
                                            </div>
                                        </v-list> 
                                    </div>
                                </v-menu> 
                            </template>

                            <!-- //-------------------- IF NOT ON CLICK ROW---------------------- --> 
                            <template v-slot:item.device_icon="{ item }" >  
                                <v-icon>{{item.device_icon}}</v-icon>
                            </template>
                            <template v-slot:item.icon="{ item }" >  
                                <v-icon 
                                    :size="HEADER('icon')?HEADER('icon').size:''"
                                    :color="HEADER('icon')?HEADER('icon').color:''">
                                    {{item.icon}}
                                </v-icon>
                            </template>
                            <template v-slot:item.icon_image="{ item }" >
                                <v-avatar
                                    :size="HEADER('icon_image')?HEADER('icon_image').size?HEADER('icon_image').size:30:30"
                                    :tile="HEADER('icon_image')?HEADER('icon_image').size?HEADER('icon_image').tile:false:false"
                                    :color="HEADER('icon_image')?HEADER('icon_image').background_color?HEADER('icon_image').background_color:'b2':'b2'" > 
                                    <v-icon v-if="item.icon_image || (HEADER('icon_image')?HEADER('icon_image').icon?true:false:false)" 
                                        :size="HEADER('icon_image')?HEADER('icon_image').size?HEADER('icon_image').icon_size:null:null"
                                        :color="HEADER('icon_image')?HEADER('icon_image').color:''">
                                        {{ ICON_FUNCTION(
                                            HEADER('icon_image')?(HEADER('icon_image').icon?HEADER('icon_image').icon:
                                            item.icon_image.icon):item.icon_image.icon,item
                                        )}}
                                    </v-icon>  
                                </v-avatar>
                            </template>
                            <!-- <template v-slot:item.image="{ item }" > 
                                <v-card flat>
                                    <v-img
                                        aspect-ratio="1"
                                        :src="item.image?item.image.link:MBS.data.noImg"> 
                                    </v-img>
                                </v-card>
                            </template> -->
                            
                            <template v-slot:item.action="{item}" class="with-divider" >  
                                <v-menu bottom left min-width="200">
                                    <template v-slot:activator="{ on }">
                                    <v-btn  
                                        icon
                                        v-on="on" >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                    </template>
                                    <v-toolbar   color="primary" dark>
                                        ACTION
                                        <v-spacer></v-spacer>
                                        <v-btn icon=""><v-icon>close</v-icon></v-btn>
                                    </v-toolbar>
                                    <div class="white" v-if="HEADER_ACTION_ITEM.no_action">

                                        <v-subheader>No action</v-subheader> 
                                    </div> 
                                    <v-list v-else>  
                                        <v-subheader>Item number {{item.no}}</v-subheader> 
                                        <div v-if="MBS.actions.SIZE(HEADER_ACTION_ITEM.item)<1">
                                            <v-list-item  @click="EDIT_ITEM(item)" >
                                                <v-list-item-action><v-icon>mdi-pencil</v-icon></v-list-item-action>
                                                <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item> 
                                            <v-list-item  @click="DELETE_ITEM(item)" >
                                                <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action>
                                                <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>  
                                        </div>
                                        <div v-else>
                                            <div v-for="(header, index) in HEADER_ACTION_ITEM?HEADER_ACTION_ITEM.item:null" 
                                                    :key="index" >
                                                <v-list-item v-if="header.show_if?header.show_if(item):true"
                                                    @click="SELECT_ITEM_ACTION(header.action, item)" >
                                                    <v-list-item-action><v-icon>{{header.icon}}</v-icon></v-list-item-action>
                                                    <v-list-item-title>{{header.name}}</v-list-item-title> 
                                                </v-list-item>    
                                            </div>
                                        </div>
                                    </v-list>
                                </v-menu> 
                            </template>
                            
                             <!-- //-------------------- IF EXPAND---------------------- -->
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="pa-1">
                                     
                                    <mbs-item-manager
                                        :add_vertical_lines="true"
                                        :top_label="false"
                                        :table_no_header="false"
                                        :filter_date="false"
                                        :filter_date_value="'local_created_at'"
                                        :filter_default_days="'90'"
                                        :name_items="NAME_CHILD_ITEMS"
                                        :name_item="NAME_CHILD_ITEM"
                                        :value_items="VALUE_CHILD_ITEMS"
                                        :value_item="VALUE_CHILD_ITEM" 
                                        :name_child_items="NAME_CHILD_ITEMS"
                                        :name_child_item="NAME_CHILD_ITEM"
                                        :value_child_items="VALUE_CHILD_ITEMS"
                                        :value_child_item="VALUE_CHILD_ITEM" 
                                        :header_items="HEADER_CHILD_ITEMS" 
                                        :header_items2="HEADER_CHILD_ITEMS2" 
                                        :data_items="item.child_data"  
                                        :is_joined="false"  
                                        :table_actions="table_actions"  
                                        @SELECT_ITEM_ACTION="SELECT_CHILD_ITEM_ACTION($event)"
                                        @SELECT_TABLE_ACTION="TABLE_ACTION"
                                    />
                                </td>
                            </template>

                             <!-- //-------------------- FOOTER---------------------- -->
                            <template v-slot:footer  >
                                <div class="mt-2">
                                    <v-divider></v-divider>  
                                </div> 
                                <div  class="b2 lighten-1"  v-if="TOTAL_ITEMS">
                                    <!-- <v-data-table class="font-weight-bold b1"
                                        :headers="HEADERS(VALUE_ITEMS)"
                                        :items="TOTAL_ITEMS"
                                        hide-default-footer
                                        hide-default-header
                                        :search="search" >
                                    </v-data-table>  -->
                                    <v-layout row wrap class="ma-0 pa-1" >
                                        <v-spacer></v-spacer>
                                        <v-flex v-for="(item,index) in TOTAL_ITEMS" :key="index" class="ma-0 pa-0">
                                            <div column  class="px-5 ma-0 text-right"> 
                                                <div class="font-weight-bold  ">{{MBS.actions.TEXT_UP(item.name)}}</div> 
                                                <div>{{item.show_value_?item.show_value_:item.value_}}</div>
                                            </div>    
                                        </v-flex>
                                    </v-layout> 
                                </div> 
                            </template>
                        </v-data-table>    
                    </div>

                    <v-sheet v-else  class=""
                        :height="vsh/2"
                        tile="">
                        <v-layout justify-center="" align-center="" fill-height="">
                            <v-chip  large color="b1" text-color="grey" class="px-5"> 
                                <v-icon color="grey" class="mr-1 mb-1">warning</v-icon>
                                <div>No {{MBS.actions.TEXT_UP1(NAME_ITEM)}} Found</div> 
                            </v-chip>
                        </v-layout>
                    </v-sheet> 
                </v-card-text> 
            </v-card>
        </div>
    </v-card> 

    <!-- //DIALOGS -->
    <div >
        <!-- add -->
        <div v-if="dialog_add_item">
            <v-dialog v-model="dialog_add_item" persistent  max-width="800px" > 
                <v-card flat>
                    <v-card-title class="primary white--text" dark>
                        <span v-if="add_item_title">{{ add_item_title }}</span>
                        <span v-else class="headline">{{MBS.actions.TEXT_UP(add_item_action?add_item_action.action_do:'ADD')}} {{MBS.actions.TEXT_UP(CHILD?NAME_CHILD_ITEM:NAME_ITEM)}}</span>
                        <v-spacer></v-spacer> 
                        <v-btn @click="SELECT_EXCEL()" color="white" outlined="" rounded="" class="ml-2"><v-icon size="" class="pr-1">mdi-file-excel-outline</v-icon> EXCEL</v-btn>
                        <v-btn class="ml-2" dark @click="dialog_add_item=false" icon=""><v-icon>close</v-icon></v-btn>
                    </v-card-title> 

                    <!-- //NOT STEPS -->
                    <v-card-text v-if="!STEP_FIELD_ITEMS" > 
                        <v-container grid-list-lg>
                            <br>
                            <v-card outlined class="px-5 py-4 mb-4" v-if="CHILD">
                                <v-layout >
                                    <strong>{{MBS.actions.TEXT_UP('Selected item name: ')}}</strong>
                                    <div class="pl-2">{{MBS.actions.TEXT_UP11(SELECTED_ITEM.name)}}</div> 
                                </v-layout>
                                <v-layout >
                                    <strong>{{MBS.actions.TEXT_UP('Selected item Code: ')}}</strong>
                                    <div class="pl-2">{{SELECTED_ITEM.code}}</div> 
                                </v-layout> 
                            </v-card>
                            <form id="itemDetailForm" @submit.prevent="SUBMIT_ITEM(CHILD)" >
                                <div class="pa-1"  >   
                                    <!-- FIELD -->  
                                    <mbs-input-fields
                                        v-model="input"
                                        :name_items="NAME_ITEMS"
                                        :name_item="NAME_ITEM"
                                        :value_items="VALUE_ITEMS"
                                        :value_item="VALUE_ITEM"
                                        :field_items="CHILD?FIELD_CHILD_ITEMS:FIELD_ITEMS"
                                        :select_items="SELECT_ITEMS"
                                        :disabled_fields="CHILD?DISABLED_CHILD_FIELDS:DISABLED_FIELDS"
                                        />    
                                </div>
                                <v-card-actions >
                                    <v-spacer></v-spacer >
                                    <v-btn color="primary" text="" @click="dialog_add_item=false">CANCEL</v-btn>
                                    <v-btn color="primary" :loading="inputLoading" dark="" type="submit">Save</v-btn>
                                </v-card-actions>
                            </form> 
                        </v-container>
                        <small>Click Excel button to add {{MBS.actions.TEXT_UP0(CHILD?NAME_CHILD_ITEMS:NAME_ITEMS)}} using excel template</small>
                    </v-card-text> 

                    <!-- //STEPS FORM -->
                    <v-stepper v-model="stepper_input" v-else>
                        <v-stepper-header>
                            <template v-for="(step,index) in STEP_FIELD_ITEMS" >
                                <v-divider v-if="index>0"></v-divider> 
                                <v-stepper-step :key="index"
                                    :complete="stepper_input > (index+1)"
                                    :step="index+1" >
                                    {{step?step.step.name:''}}
                                </v-stepper-step>  
                            </template> 
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content v-for="(step,index) in STEP_FIELD_ITEMS" :key="'i-'+index" :step="index+1"> 
                                <v-card outlined class="px-5 py-4 mb-4" v-if="CHILD">
                                    <v-layout >
                                        <strong>{{MBS.actions.TEXT_UP('Selected item name: ')}}</strong>
                                        <div class="pl-2">{{MBS.actions.TEXT_UP11(SELECTED_ITEM.name)}}</div> 
                                    </v-layout>
                                    <v-layout >
                                        <strong>{{MBS.actions.TEXT_UP('Selected item Code: ')}}</strong>
                                        <div class="pl-2">{{SELECTED_ITEM.code}}</div> 
                                    </v-layout> 
                                </v-card>
                                <v-card > 
                                    <form id="itemDetailForm" @submit.prevent="SUBMIT_ITEM(CHILD,step,index)"  >
                                        <v-card outlined class="pa-3" tile>
                                            
                                            <div class="pa-4"  >   
                                                <!-- FIELD -->  
                                                <mbs-input-fields
                                                    v-model="input"
                                                    :name_items="NAME_ITEMS"
                                                    :name_item="NAME_ITEM"
                                                    :value_items="VALUE_ITEMS"
                                                    :value_item="VALUE_ITEM"
                                                    :field_items="CHILD?FIELD_CHILD_ITEMS:step.fields"
                                                    :select_items="SELECT_ITEMS"
                                                    :disabled_fields="CHILD?DISABLED_CHILD_FIELDS:DISABLED_FIELDS"
                                                    />    
                                            </div >
                                        </v-card>
                                        <br><br>
                                        <v-layout v-if="stepper_input!=MBS.actions.SIZE(STEP_FIELD_ITEMS)" > 
                                            <v-btn v-if="index>0" 
                                                @click="PREVIOUS_STEP(step,index)"
                                                color="secondary"      
                                                dark="" 
                                                type=""  class="mr-1 --text">
                                                <v-icon class="mr-2">mdi-chevron-left</v-icon> 
                                                {{step.nextBtn?step.nextBtn:"Back"}}</v-btn>
                                            <v-btn type="submit" @click="NEXT_STEP(step,index)"
                                                color="primary"  :loading="stepLoading" dark="" 
                                                > {{step.nextBtn?step.nextBtn:"Continue"}}<v-icon class="ml-2">mdi-chevron-right</v-icon></v-btn>
                                            <v-btn  color="primary"  text="" @click="dialog_add_item=false">{{step.cancelBtn?step.cancelBtn:"Cancel"}}</v-btn>
                                        </v-layout>

                                        <v-layout v-else>
                                            <v-spacer></v-spacer >
                                                <v-btn v-if="index>0" 
                                                    @click="PREVIOUS_STEP(step,index)"
                                                color="secondary"      
                                                :loading="stepLoading" dark="" 
                                                class="mr-1 --text">
                                                <v-icon class="mr-2">mdi-chevron-left</v-icon> 
                                                {{step.nextBtn?step.nextBtn:"Back"}}</v-btn>

                                                <v-btn min-width="150" color="primary" :loading="inputLoading" dark="" type="submit">Save <v-icon class="ml-2">mdi-content-save</v-icon></v-btn>
                                                <!-- <v-btn color="primary" text="" @click="dialog_add_item=false">CANCEL</v-btn> -->
                                        </v-layout>
                                    </form>  
                                    <br>
                                    <small>Click Excel button to add {{MBS.actions.TEXT_UP0(CHILD?NAME_CHILD_ITEMS:NAME_ITEMS)}} using excel template</small>
                                </v-card>
                            </v-stepper-content> 
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog> 
        </div>

        <!-- edit -->
        <div v-if="dialog_edit_item">
            <v-dialog v-model="dialog_edit_item" persistent max-width="800px" > 
                <v-card>
                    <v-card-title class="primary white--text" dark>
                        <span v-if="edit_item_title">{{ edit_item_title }}</span>
                        <span v-else class="headline">{{MBS.actions.TEXT_UP(edit_item_action?edit_item_action.action_do:'EDIT')}} {{MBS.actions.TEXT_UP(CHILD?NAME_CHILD_ITEM:NAME_ITEM)}}</span>
                        <v-spacer></v-spacer> 
                        <v-btn @click="dialog_edit_item=false" icon dark><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <!-- //NOT STEPS -->
                    <v-card-text v-if="!STEP_FIELD_ITEMS" > 
                        <v-container grid-list-lg>
                            <br>
                            <v-card outlined class="px-5 py-4 mb-4" v-if="CHILD">
                                <v-layout >
                                    <strong>{{MBS.actions.TEXT_UP('Selected item name: ')}}</strong>
                                    <div class="pl-2">{{MBS.actions.TEXT_UP11(SELECTED_ITEM.name)}}</div> 
                                </v-layout>
                                <v-layout >
                                    <strong>{{MBS.actions.TEXT_UP('Selected item Code: ')}}</strong>
                                    <div class="pl-2">{{SELECTED_ITEM.code}}</div> 
                                </v-layout> 
                            </v-card>
                            <form id="itemDetailForm" @submit.prevent="SUBMIT_EDIT_ITEM(CHILD)" >
                                <div  class="pa-1"  >   
                                    <!-- FIELD -->   
                                    <mbs-input-fields
                                        v-model="input"
                                        :update_item="true"
                                        :name_items="NAME_ITEMS"
                                        :name_item="NAME_ITEM"
                                        :value_items="VALUE_ITEMS"
                                        :value_item="VALUE_ITEM"
                                        :updating_item="EDITING_ITEM"
                                        :field_items="CHILD?FIELD_CHILD_ITEMS:FIELD_ITEMS"
                                        :select_items="SELECT_ITEMS"
                                        :disabled_fields="CHILD?DISABLED_CHILD_FIELDS:DISABLED_FIELDS"
                                        />    
                                </div>
                                <v-card-actions >
                                    <v-spacer></v-spacer >
                                    <v-btn color="primary" text="" @click="dialog_add_item=false">CANCEL</v-btn>
                                    <v-btn color="primary" :loading="inputLoading" dark="" type="submit">Save</v-btn>
                                </v-card-actions>
                            </form> 
                        </v-container>
                        <small>Click Excel button to add {{MBS.actions.TEXT_UP0(CHILD?NAME_CHILD_ITEMS:NAME_ITEMS)}} using excel template</small>
                    </v-card-text> 

                    <!-- //STEPS FORM -->
                    <v-stepper v-model="stepper_input" v-else>
                        <v-stepper-header>
                            <template v-for="(step,index) in STEP_FIELD_ITEMS" >
                                <v-divider v-if="index>0"></v-divider> 
                                <v-stepper-step :key="index"
                                    :complete="stepper_input > (index+1)"
                                    :step="index+1" >
                                    {{step?step.step.name:''}}
                                </v-stepper-step>  
                            </template> 
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content v-for="(step,index) in STEP_FIELD_ITEMS" :key="'i-'+index" :step="index+1"> 
                                <v-card outlined class="px-5 py-4 mb-4" v-if="CHILD">
                                    <v-layout >
                                        <strong>{{MBS.actions.TEXT_UP('Selected item name: ')}}</strong>
                                        <div class="pl-2">{{MBS.actions.TEXT_UP11(SELECTED_ITEM.name)}}</div> 
                                    </v-layout>
                                    <v-layout >
                                        <strong>{{MBS.actions.TEXT_UP('Selected item Code: ')}}</strong>
                                        <div class="pl-2">{{SELECTED_ITEM.code}}</div> 
                                    </v-layout> 
                                </v-card>
                                <v-card > 
                                    <form id="itemDetailForm" @submit.prevent="SUBMIT_EDIT_ITEM(CHILD,step,index)"  >
                                        <v-card outlined class="pa-3" tile> 
                                            <div   class="pa-3"  >  
                                                <!-- FIELD -->  
                                                <mbs-input-fields
                                                    v-model="input"
                                                    :update_item="true"
                                                    :name_items="NAME_ITEMS"
                                                    :name_item="NAME_ITEM"
                                                    :value_items="VALUE_ITEMS"
                                                    :value_item="VALUE_ITEM"
                                                    :updating_item="EDITING_ITEM"
                                                    :field_items="CHILD?FIELD_CHILD_ITEMS:step.fields"
                                                    :select_items="SELECT_ITEMS"
                                                    :disabled_fields="CHILD?DISABLED_CHILD_FIELDS:DISABLED_FIELDS"
                                                    />    
                                            </div>
                                        </v-card>
                                        <br><br>
                                        <v-layout v-if="stepper_input!=MBS.actions.SIZE(STEP_FIELD_ITEMS)" > 
                                            <v-btn v-if="index>0" 
                                                @click="PREVIOUS_STEP(step,index)"
                                                color="secondary"      
                                                :loading="inputLoading" dark="" 
                                                type=""  class="mr-1 --text">
                                                <v-icon class="mr-2">mdi-chevron-left</v-icon> 
                                                {{step.nextBtn?step.nextBtn:"Back"}}</v-btn>
                                            <v-btn type="submit" @click="NEXT_STEP(step,index)"
                                                color="primary"  :loading="inputLoading" dark="" 
                                                > {{step.nextBtn?step.nextBtn:"Continue"}}<v-icon class="ml-2">mdi-chevron-right</v-icon></v-btn>
                                            <v-btn  color="primary"  text="" @click="dialog_add_item=false">{{step.cancelBtn?step.cancelBtn:"Cancel"}}</v-btn>
                                        </v-layout>

                                        <v-layout v-else>
                                            <v-spacer></v-spacer >
                                                <v-btn v-if="index>0" 
                                                    @click="PREVIOUS_STEP(step,index)"
                                                color="secondary"      
                                                :loading="stepLoading" dark="" 
                                                class="mr-1 --text">
                                                <v-icon class="mr-2">mdi-chevron-left</v-icon> 
                                                {{step.nextBtn?step.nextBtn:"Back"}}</v-btn> 
                                                <v-btn min-width="150" color="primary" :loading="inputLoading" dark="" type="submit">Save <v-icon class="ml-2">mdi-content-save</v-icon></v-btn>
                                                <!-- <v-btn color="primary" text="" @click="dialog_add_item=false">CANCEL</v-btn> -->
                                        </v-layout>
                                    </form>  
                                    <br>
                                    <small>..</small>
                                </v-card>
                            </v-stepper-content> 
                        </v-stepper-items>
                    </v-stepper>
                    <v-card-text v-if="false"> 
                        <v-container grid-list-lg>
                            <br>
                            <form id="itemDetailForm" @submit.prevent="SUBMIT_EDIT_ITEM(CHILD)">
                                <div class="pa-1">   
                                    <!-- FIELD -->  
                                    <mbs-input-fields
                                        v-model="input"
                                        :update_item="true"
                                        :name_items="NAME_ITEMS"
                                        :name_item="NAME_ITEM"
                                        :value_items="VALUE_ITEMS"
                                        :value_item="VALUE_ITEM"
                                        :updating_item="EDITING_ITEM"
                                        :field_items="CHILD?FIELD_CHILD_ITEMS:FIELD_ITEMS"
                                        :select_items="SELECT_ITEMS"
                                        :disabled_fields="CHILD?DISABLED_CHILD_FIELDS:DISABLED_FIELDS"
                                        />  
                                </div>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text="" @click="dialog_edit_item=false">CANCEL</v-btn>
                                <v-btn color="primary" :loading="inputLoading" dark="" type="submit">Save</v-btn>
                                </v-card-actions>
                            </form> 
                        </v-container>
                        <small>..</small>
                    </v-card-text> 
                </v-card>
            </v-dialog> 
        </div>

        <!-- select -->
        <div v-if="excelSelector" >
            <mbs-excel-selector 
                :notShow="excelSelector"
                @notNo="excelSelector =!excelSelector"
                @notYes="FILE_SELECTED"/>
        </div>

        <!-- Selected -->
        <v-dialog v-model="dialog_selected_item" persistent max-width="900px" > 
            <v-card>
                <v-card-title class="primary white--text" dark>
                    <span class="headline">UPLOAD {{MBS.actions.TEXT_UP(NAME_ITEMS)}}</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!MBS.actions.CODE_IS_AUTO(EXCEL_DATA_ITEMS)" @click="EXCEL_DATA_ITEMS=MBS.actions.CODE_AUTO_GENERATE(EXCEL_DATA_ITEMS)" rounded="" outlined="" dark>Auto Generate Code</v-btn>

                    <v-btn  @click="dialog_selected_item=false" color="white" icon="" class="ml-2"><v-icon size="" class="pr-1">mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text> 
                    <v-container grid-list-lg>
                        <br>
                        <v-data-table
                            :headers="HEADERS('excel')"
                            :items="JOINED_EXCEL_DATA_ITEMS" >

                            <template v-slot:top>
                                
                            </template> 

                            <template v-slot:item.action="{ item }" > 
                                
                                <v-hover v-slot:default="{hover}" class="ma-1">
                                    <v-avatar tile color="primary" class="pa-1">
                                        <v-icon size="18" dark @click="EDIT_EXCEL_ITEM(item)" :class="hover?'white':''" :color="hover?'primary':'white'">mdi-pencil</v-icon>
                                    </v-avatar>
                                </v-hover>  
                                <v-hover v-slot:default="{hover}" class="ma-1">
                                    <v-avatar tile color="red" class="pa-1" >
                                        <v-icon size="18" dark @click="DELETE_EXCEL_ITEM(item)" :class="hover?'white':''" :color="hover?'red':'white'">mdi-close</v-icon>
                                    </v-avatar>
                                </v-hover> 
                            </template>
                            <template v-slot:no-data>
                                <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                    <v-icon>warning</v-icon>
                                    Empty
                                </v-layout>
                            </template>
                        </v-data-table> 
                    </v-container>
                    <v-divider></v-divider>
                    <br>
                    <v-layout justify-center="">  
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog_selected_item=false" color="primary" text="">CANCEL</v-btn>
                        <v-btn :loading="inputLoading" @click="UPLOAD_REPLACE_EXCEL()" color="primary "><v-icon class="mr-2">mdi-cloud-upload</v-icon> UPLOAD {{MBS.actions.TEXT_UP(NAME_ITEMS)}}</v-btn>
                    </v-layout>
                </v-card-text> 
            </v-card>
        </v-dialog>

        <!-- CHILD ITEMS DIALOG -->
        <v-dialog fullscreen v-model="dialog_child_items" persistent max-width="900px" > 
            <v-card tile height="100%" color="b2">
                <v-layout column fill-height>
                    <template v-if="SELECTED_ITEM">
                        <v-card-title class="primary white--text" dark>
                            <v-btn  @click="dialog_child_items=false" color="white" icon="" class="ml-2 mr-5"><v-icon size="" >mdi-arrow-left</v-icon></v-btn>
                            <span class="headline">{{SELECTED_ITEM?SELECTED_ITEM.code:''}} : {{MBS.actions.TEXT_UP(SELECTED_ITEM?SELECTED_ITEM.name:'')}} : {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} </span>
                            <v-spacer></v-spacer>   
                            <v-btn  @click="dialog_child_items=false" color="white" icon="" class="ml-2"><v-icon size="" class="pr-1">mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text v-if="JOINED_DATA_CHILD_ITEMS_SELECTED"> 
                            <v-container grid-list-lg>
                                <br>
                                <v-card class="" >
                                    <v-card-title class="primary white--text" dark>
                                        
                                        <v-spacer></v-spacer>  
                                        <v-btn @click="ADD_ITEM(true)" color="white" outlined="" rounded="" class="mr-2"><v-icon size="18" class="pr-1">mdi-plus-thick</v-icon> Add {{MBS.actions.TEXT_UP1(NAME_CHILD_ITEM)}}</v-btn>
                                        <v-menu  bottom left 
                                            v-model="menuTableSetChild"
                                            :close-on-content-click="false"
                                            :nudge-width="250">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    dark outlined="" rounded=""
                                                    icon
                                                    v-on="on" >
                                                    <v-icon>mdi-tune</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title primary-title>
                                                    <v-layout >
                                                        CUSTOMIZE TABLE
                                                        <v-spacer></v-spacer>
                                                        <v-btn icon="" @click="menuTableSetChild=false"><v-icon>close</v-icon></v-btn>
                                                    </v-layout>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-virtual-scroll 
                                                        height="400"
                                                        item-height="50"
                                                        :items="HEADER_CHILD_ITEMS">
                                                        <template v-slot ="{item, index}" >
                                                            <v-subheader v-if="item.title">{{item.name}}</v-subheader>
                                                            <v-list-item v-else  @click="MBS.actions.TABLE_HEADER_SELECTOR(HEADER_CHILD_ITEMS,index)" >
                                                                <v-list-item-icon><v-icon>{{item.show?"mdi-check-box-outline":"mdi-checkbox-blank-outline"}}</v-icon></v-list-item-icon>
                                                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                                            </v-list-item> 
                                                        </template> 
                                                    </v-virtual-scroll>  
                                                </v-card-text> 
                                                <v-divider></v-divider>
                                                <v-layout class="pt-2 pr-2 pb-2" >
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="menuTableSetChild = false" color="primary">OK</v-btn>
                                                </v-layout>
                                            </v-card> 
                                        </v-menu> 
                                    </v-card-title>
                                    <v-card-title>
                                        {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} 
                                        <v-spacer></v-spacer> 
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-data-table
                                        :headers="HEADERS(VALUE_CHILD_ITEMS)"
                                        :items="JOINED_DATA_CHILD_ITEMS_SELECTED"
                                        :search="search" >

                                        <template v-slot:top> 
                                        </template>  
                                        <template v-slot:item.action="{item}" >  
                                            <v-menu bottom left >
                                                <template v-slot:activator="{ on }">
                                                <v-btn  
                                                    icon
                                                    v-on="on" >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                                </template>
                                                <v-toolbar   color="primary" dark>
                                                    ACTION
                                                    <v-spacer></v-spacer>
                                                    <v-btn icon=""><v-icon>close</v-icon></v-btn>
                                                </v-toolbar>

                                                <v-list>  
                                                    <v-subheader>Item number {{item.no}}</v-subheader>
                                                    <div v-if="MBS.actions.SIZE(HEADER_ACTION_CHILD_ITEM.item)<1">
                                                        <v-list-item  @click="EDIT_ITEM(item,true)" >
                                                            <v-list-item-action><v-icon>mdi-pencil</v-icon></v-list-item-action>
                                                            <v-list-item-title>Edit</v-list-item-title>
                                                        </v-list-item> 
                                                        <v-list-item  @click="DELETE_ITEM(item,true)" >
                                                            <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action>
                                                            <v-list-item-title>Delete</v-list-item-title>
                                                        </v-list-item>  
                                                    </div>
                                                    <div v-else>
                                                        <v-list-item v-for="(header, index) in HEADER_ACTION_CHILD_ITEM?HEADER_ACTION_CHILD_ITEM.item:null" 
                                                            :key="index"  
                                                            @click="SELECT_ITEM_ACTION(header.action, item,true)" >
                                                            <v-list-item-action><v-icon>{{header.icon}}</v-icon></v-list-item-action>
                                                            <v-list-item-title>{{header.name}}</v-list-item-title>
                                                        </v-list-item>  
                                                    </div>
                                                </v-list>
                                            </v-menu> 
                                        </template>
                                        <template v-slot:no-data>
                                            <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                                <v-icon>warning</v-icon>
                                                Empty
                                            </v-layout>
                                        </template>
                                    </v-data-table>   
                                </v-card>
                            </v-container>
                            
                            <br>
                            
                        </v-card-text>  
                    </template>
                    <template v-else>
                        <v-card>
                            <v-layout justify-center align-center class="pa-5">
                                No selected item
                            </v-layout>
                        </v-card>
                    </template>
                    <v-spacer></v-spacer>
                    <div >
                        <v-divider></v-divider>
                        <v-layout justify-center="" class="px-5 py-5">  
                            <v-spacer></v-spacer>
                            <v-btn outlined @click="dialog_child_items=false" color="primary" >CLOSE</v-btn> 
                        </v-layout>   
                    </div>
                    
                </v-layout>
            </v-card>
        </v-dialog>

        <!-- export excel -->
        <mbs-excel-export 
            :file_name="TABLE_TAB?TABLE_TAB.name:NAME_ITEMS"
            :sheet_name="VALUE_ITEMS"
            :data_items="JOINED_DATA_ITEMS()"
            :header_items="HEADER_ITEMS"
        /> 
    </div>

    <!-- DOWNLOAD -->
    <a v-show="false" 
        ref="download_items" 
        :href="DOWNLOAD_URL" 
        :download="DOWNLOAD_FILE_NAME">
    </a>
  </div> 
</template>

<script>
    import DATA, { ITEMS } from '../../plugins/DATA';
    import DB from '../../plugins/DB';
    import {mapState} from "vuex"
    let PAGE_NAME  = "ITEM_MANAGER" 
    export default {
        name:PAGE_NAME,
        props:[
            'show','value','height','fixed_header','auto_code','table_no_header','table_title','table_title_icon','top_label','name_items','name_item','value_items',
            'value_item','field_items','disabled_fields','header_items','header_items2','data_items','item_data_include','is_joined',
            'path_item','padding','add_vertical_lines',

            'show_expand','single_expand','item_key',
            
            'name_child_items','name_child_item','value_child_items','value_child_item',
            'field_child_items','disabled_child_fields','header_child_items','header_child_items2','data_child_items',
            
            'table_tabs','select_items','table_actions','total_item_values',
            '_show_select','_single_select','filters','filter_date','filter_date_only_display','filter_date_value','filter_default_days',
            'add_item_title','edit_item_title','add_item_serve_text','edit_item_serve_text',
            'add_item_serve_icon','edit_item_serve_icon','add_item_action','edit_item_action','row_click','row_click_action'
        ],
        data() {
            return { 
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                search:'', 
                search_input:null,   
                stepper_input:1, 
                next_step:false,  
                stepLoading:false,  
                inputLoading:false,  
                input:this.value ?this.value :{},
                show_select:this.value ?this.value.show_select :false, 
                single_select:this.value ?this.value.single_select :false, 
                input_selected_items:[], 
                input_filters:{},
                input_filter_Date:{},

                tableOptions:null,   
                tableHistoryOptions:null,  
                currentTableTab:0,  
                showTableTab:true,  
                filterDateDifference:null, 
                currentTableItems:null, 
                CURRENT_TOTAL_ITEMS:null, 
 
                NAME_ITEM_IMAGE:'Image',
                VALUE_ITEM_IMAGE:'Image', 
                CHILD:false,
                SELECTED_ITEM:null,
                EDITING_ITEM:null,

                HEADER_ITEMS_REQUIRED_:[],
                HEADER_ITEMS_:[],
                HEADER_ITEMS2_:[],
                HEADER_CHILD_ITEMS_:[],
                HEADER_CHILD_ITEMS2_:[],

                DOWNLOAD_URL: null,
                DOWNLOAD_FILE_NAME: null,

                menuFilters:false,
                menuTableSet:false,
                menuTableSetChild:false,
                dialog_add_item:false,
                dialog_edit_item:false, 
                dialog_selected_item:false,
                dialog_child_items:false,
                excelSelector:false,
                EXCEL_DATA_ITEMS:[],
                generatedCode:null,  
                
                menu_date_picker:{},
                menu_time_picker:{},
                dialog_time_picker:{},
                dialogInput:{},
                
                
                SELECT_TABLE_ACTION_MODEL:null,  
                showOffline:true
                
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.MBS.events.$on('ITEM_ACTION', this.ITEM_ACTION);   
                this.MBS.events.$on('CANCEL_SELECTED_ITEMS', this.CANCEL_SELECTED_ITEMS);   
                this.INITIATE_PAGE_HISTORIES()   
                this.SHOW_OFFLINE()
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },

            ACCESS_ITEM(){
                return permission=>{
                    return  true
                    if (permission === "read") {
                        return this.MBS.actions.ACCESS_READ(this.VALUE_ITEMS) 
                    }else if (permission === "write") {
                        return this.MBS.actions.ACCESS_WRITE(this.VALUE_ITEMS) 
                    }else if (permission === "edit") {
                        return this.MBS.actions.ACCESS_EDIT(this.VALUE_ITEMS) 
                    }else if (permission === "delete") {
                        return this.MBS.actions.ACCESS_DELETE(this.VALUE_ITEMS) 
                    }else{
                        return false
                    }
                }
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                PageHistories: state=> state.items[(DATA.ITEMS.PAGE_HISTORIES.values).toUpperCase()], 
            }), 

            PageHistory(){
                let histories = this.PageHistories
                let uid = this.us?this.us.uid:null
                if(!histories||!uid){return null}
                let filtered_item = histories.find(history=>history.key==this.NAME_ITEMS+"="+uid)
                return filtered_item 
            }, 
            moment(){  
                return this.MBS.date.moment
            },  

            CALCULATED_TABLE_TABS(){ 
                let table_tabs = this.table_tabs
                if(!table_tabs){return null}
                let tabs =  []
                table_tabs.forEach(tab => {
                    let indicator = tab.indicator
                    let indicator_value = indicator?indicator.value:null
                    if ( typeof indicator_value == "function") {
                        indicator_value = indicator_value(this,this.MBS.actions,this.JOINED_DATA_ITEMS(tab))
                        tabs.push({
                            ...tab,
                            indicator:{
                                ...indicator,
                                value:indicator_value
                            }
                        })  
                    }else{
                        tabs.push(tab)  
                    }
                });
                return tabs
            },
            TABLE_TAB(){
                let page = this.currentTableTab
                let table_tabs = this.table_tabs
                if(!table_tabs){return null}
                let tab = table_tabs[page]
                return tab
            },
            TABLE_ACTION(){
                return action=>{
                    let table_actions = this.table_actions
                    let actions = []
                    if(!table_actions){return null}

                    if (action == 'btn') {
                        actions = table_actions.filter(item=>{
                            return item.type == "btn" || item.btn 
                        })
                    }else if (action == 'action') {
                        actions = table_actions.filter(item=>{
                            return item.type == "action"  
                        })
                    }else if (action == 'title') {
                        actions = table_actions.filter(item=>{
                            return item.type == "title"  
                        })
                    } else {
                         actions = table_actions.filter(item=>{
                            return !item.btn  
                        })
                    } 
                    return actions 
                }
            },
            NAME_ITEMS(){
                let item = this.name_items
                return item?item:'items'
            },
            NAME_ITEM(){
                let item = this.name_item
                return item?item:'item'
            },
            VALUE_ITEMS(){
                let item = this.value_items
                return item?item:'item'
            },
            VALUE_ITEM(){
                let item = this.value_item
                return item?item:'item'
            },
            FIELD_ITEMS(){
                const isEven = number => number % 2 === 0;
                const isOdd = number => Math.abs(number % 2) == 1;
                let data_source = this.SELECT_ITEMS
                let fields = this.field_items
                console.log(fields,'f..........i.');
                if(!fields){return null}
                let return_fields = []
                fields.forEach(field => {
                    if(field.data_field){
                        let data = data_source?data_source[field.data_name]:null
                        let data_size = this.MBS.actions.SIZE(data)
                        if (!data_size) {
                            console.log(data_source,'no data-----------i-');
                            console.log(field.data_name,'field.data_name-----------i-');
                           return 
                        }
                        data.forEach((t_data,index) => {
                            let t_field=field.field
                            let md=t_field.md
                            let number = index
                            let required = t_field.required
                            if ( typeof required == "function") {
                                required = required(this,t_field,index)
                            } 
                            let label_top = t_field.label_top
                            if ( typeof label_top == "function") {
                                label_top = label_top(this,t_data,index)
                            } 
                            let label = t_field.label
                            if ( typeof label == "function") {
                                label = label(this,t_data,index)
                            } 
                            let value = t_field.value
                            if ( typeof value == "function") {
                                value = value(this,t_data,index)
                            } 


                            if (field.isOdd && data_size==index+1 && isOdd(number)) {
                                md=12
                            }
                            
                            if (field.isEven && data_size==index+1 && isEven(number)) {
                                md=12
                            }

                            // console.log(field,'field...');
                            // console.log(md,'md...');
                            // console.log(number,'number...');
                            // console.log(isOdd(number),'isOdd......');
                            // console.log(isEven(number),'isOdd......');
                            return_fields.push({
                                ...t_field,
                                required:required,
                                value:value,
                                label:label,
                                label_top:label_top,
                                md:md
                            })
                            
                        });
                    
                    }else{
                        return_fields.push(field)
                    }
                    
                }); 
                return return_fields
            },
            STEP_FIELD_ITEMS(){
                let fields = this.FIELD_ITEMS
                if(!fields){return null}
                let steps = fields.find(item=>item.steps)
                steps=steps?steps.steps:null 
                if(!steps){return null}
                let field_steps = []
                steps.forEach(step => {
                    let fd = fields.filter(item=>this.MBS.actions.TEXT_UP(item.step_name) == this.MBS.actions.TEXT_UP(step.name))
                    if (fd) {
                        field_steps.push({
                            step,
                            fields:fd
                        })
                    }
                });  
                console.log(field_steps,'f.......');
                return field_steps
            },
            DISABLED_FIELDS(){
                let item = this.disabled_fields 
                return item
            },
            HEADER_ACTION_ITEM(){
                let fields = this.HEADER_ITEMS
                if(!fields){return null}
                let action = fields.find(f=>{
                    return this.MBS.actions.TEXT_UP(f.name) == this.MBS.actions.TEXT_UP("action")
                })
                return action
            },
            HEADER_ACTION_CHILD_ITEM(){
                let fields = this.HEADER_CHILD_ITEMS
                if(!fields){return null}
                let action = fields.find(f=>{
                    return this.MBS.actions.TEXT_UP(f.name) == this.MBS.actions.TEXT_UP("action")
                })
                return action
            },
            ACTION_ITEM(){
                let action = this.HEADER_ACTION_ITEM 
                return action?action.item:null
            },
            HEADER_ITEMS(){
                this.HEADER_ITEMS_ = []
                let item = this.header_items
                let add_vertical_lines = this.add_vertical_lines
                if (item) {

                    let first = true
                    let first_show = false
                    item.forEach((element,index) => {
                        let header = {...element}
                        if (header.show && first) {
                            first_show = true
                            first = false
                        }
                        if (add_vertical_lines) {
                            if (first_show) {
                                header = {
                                    ...element,
                                    cellClass:element.cellClass+' with-divider-lr',
                                }  
                                first_show=false
                            } else {
                                header = {
                                    ...element,
                                    cellClass:element.cellClass+' with-divider',
                                } 
                            }
                        } 
                        this.HEADER_ITEMS_.push(header) 
                    });
                }   
                return this.HEADER_ITEMS_
            },
            HEADER_ITEMS2(){
                let item = this.header_items2
                if (item) {
                    item.forEach(element => {
                       this.HEADER_ITEMS2_.push(element) 
                    });
                } 
                return this.HEADER_ITEMS2_
            }, 
            PATH_ITEM(){ 
                if (this.path_item == true) {
                    let SERVER_NAME = DATA.APP.SERVER
                    return '/'+SERVER_NAME+'/SETTINGS/'+this.MBS.actions.TEXT_UP(this.VALUE_ITEMS)+'/'
                }else if (this.path_item) { 
                   return this.path_item 
                }
                this.MBS.actions.dialog({
                    show:true,
                    title:"ERROR",
                    Text:"No path "+this.VALUE_ITEMS,
                    btnYes:"OKAY"
                }) 
                return null 
            }, 
            DATA_ITEMS(){
                let items = this.data_items
                let filters = this.filters
                let input_filters = this.input_filters
                let data_filtered = input_filters?input_filters.data_filtered:null
                let input_filtered = input_filters?input_filters.input_fields:null

                if(filters){
                    items = input_filtered?data_filtered:items
                    //return input_filtered?data_filtered:items
                }
                
                if(!items){return null}
                
                let filtered_item = items
                if (this.filter_date && !this.filter_date_only_display) {
                    let input_filter_Date = this.input_filter_Date
                    let filter_date_value = this.filter_date_value
                    let from_date = input_filter_Date?input_filter_Date.from_date:null
                    let to_date = input_filter_Date?input_filter_Date.to_date:null  
                    filtered_item = items.filter(item => {
                        let mDate = null
                        let date = null
                        if (filter_date_value) { 
                            date = this.moment(item[filter_date_value]).format("yyyy-MM-D") 
                        }else{
                            date = this.moment(item.created_at).format("yyyy-MM-D")
                        }
                        mDate = this.moment(date) 
                        let between = mDate.isBetween(from_date,to_date,'','[]')  
                        return between
                    });  
                } 
                return filtered_item
            },  
            NAME_CHILD_ITEMS(){
                let item = this.name_child_items
                return item?item:'items'
            },
            NAME_CHILD_ITEM(){
                let item = this.name_child_item
                return item?item:'item'
            },
            VALUE_CHILD_ITEMS(){
                let item = this.value_child_items
                return item?item:'item'
            },
            VALUE_CHILD_ITEM(){
                let item = this.value_child_item
                return item?item:'item'
            },
            FIELD_CHILD_ITEMS(){
                let item = this.field_child_items
                let field = [
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},  
                    // {type:"select",name:"description",value:"description",label:"description",textarea:true,required:true,sm:12,md:12},
                ]
                return item?item:'item'
            },
            DISABLED_CHILD_FIELDS(){
                let item = this.disabled_child_fields 
                return item
            },
            HEADERS(){
                return (type,original)=>{
                    try {
                        
                        let headers = null
                        if (type === this.VALUE_ITEMS) {
                            headers = this.HEADER_ITEMS
                        }else if (type === 'excel'){
                            headers = this.HEADER_ITEMS2
                        } else if (type === this.VALUE_CHILD_ITEMS){
                            headers = this.HEADER_CHILD_ITEMS
                        } 
                        let tables = this.MBS.actions.CREATE_TABLE_HEADER(headers)    
                        return original?headers:tables
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'HEADERS',
                            page:PAGE_NAME, 
                        }) 
                    }  
                }
            }, 
            HEADER(){
                return (value,type)=>{
                    if (!type) {
                        type = this.VALUE_ITEMS
                    }
                    let headers = this.HEADERS(type,true)
                    if(!headers){return null}
                    if(!value){return null}
                    let item = headers.find(h=>{
                        return h.value == value
                    })  
                    return item
                }
            },
            HEADER_CLASS(){
                return header=>{ 
                    return " "+header.cellClass+" "+header.align+" "

                }
            },
            HEADER_STYLE(){
                return header=>{ 
                    return " text-align: "+header.align+"; "

                }
            },
            
            HEADER_ACTION_CHILD_ITEM(){
                let fields = this.HEADER_CHILD_ITEMS
                if(!fields){return null}
                let action = fields.find(f=>{
                    return this.MBS.actions.TEXT_UP(f.name) == this.MBS.actions.TEXT_UP("action")
                })
                return action
            }, 
            HEADER_CHILD_ITEMS(){
                let item = this.header_child_items
                if (item) {
                    item.forEach(element => {
                       this.HEADER_CHILD_ITEMS_.push(element) 
                    });
                } 
                return this.HEADER_CHILD_ITEMS_
            },
            HEADER_CHILD_ITEMS2(){
                let item = this.header_child_items2
                if (item) {
                    item.forEach(element => {
                       this.HEADER_CHILD_ITEMS2_.push(element) 
                    });
                } 
                return this.HEADER_CHILD_ITEMS2_
            }, 
            PATH_CHILD_ITEM(){
                let SERVER_NAME = DATA.APP.SERVER
                return '/'+SERVER_NAME+'/SETTINGS/'+this.MBS.actions.TEXT_UP(this.VALUE_CHILD_ITEMS)+'/'
            }, 
            DATA_CHILD_ITEMS(){
                let item = this.data_child_items 
                return item
            },
            DATA_CHILD_ITEMS_SELECTED(){
                let item = this.DATA_CHILD_ITEMS
                let SELECTED_ITEM = this.SELECTED_ITEM 
                if(!item){return null}
                if(!SELECTED_ITEM){return null}

                let filtered_item = item.filter(child=>{
                    return child[this.MBS.actions.TEXT_UP0(this.VALUE_ITEM)+'_code'] == SELECTED_ITEM.code
                }) 
                return filtered_item
            },    
            SELECT_ITEMS(){ 
                let item = this.select_items 
                return item?item:null
            },  
            P_DATA_ITEM(){
                if (!this.DATA_ITEMS) { return null}
                let list = [...this.DATA_ITEMS]
                list.sort(this.MBS.actions.dynamicSort("i",false)) 
                return this.MBS.actions.pagination(list,this.PAGE_CURRENT,this.PAGE_SIZE)
            },
            JOINED_DATA_ITEMS(){
                return (tab = this.TABLE_TAB)=>{ 
                    let is_joined = this.is_joined
                   
                    let show_if= tab?tab.show_if:null
                    let data = this.DATA_ITEMS
                    let join = null
                    if (is_joined) {
                        join = data
                    }else{
                        join = this.MBS.actions.JOIN({
                            items:data,
                            name:this.VALUE_ITEMS
                        })  
                    }
    
                    //filter if tabs
                    if (show_if && join) {
                        let filtered = join.filter(item=>{
                            return show_if(this,item)
                        }) 
                        return filtered
                    } 
                    return join
                }
            }, 
            TOTAL_ITEMS(){
                let items = this.JOINED_DATA_ITEMS()

                // let items = this.currentTableItems
                let VALUE_ITEMS = this.VALUE_ITEMS
                // let headers = this.HEADERS(VALUE_ITEMS)
                // let search = this.search
                let searched_items = null
                let values_for_totals = this.total_item_values 
                if(!values_for_totals){return null}
                if (false) {
                    searched_items = items.filter(item=>{
                        let search_text = ""
                        headers.forEach(header => {
                            if (header.value=='action'||header.value=='action') {
                                
                            } else {
                                let text = item[header.value]
                                if (text || text == 0) {
                                    search_text = search_text+" "+text
                                }
                            }
                            
                        });
                        let index = this.MBS.actions.TEXT_UP(search_text).indexOf(this.MBS.actions.TEXT_UP(search))
                        return index === -1?false:true
                    })
                    
                }else{
                    searched_items = items
                } 
                let TOTAL_ARRAY=[] 
                if (searched_items) {
                    values_for_totals.forEach(element => {
                        let TOTAL={}
                        TOTAL[element.value]=0
                        if (element.money) {
                            TOTAL[element.show_value]=this.MBS.actions.money(TOTAL[element.value] ) 
                        }else if (element.name) {
                            TOTAL['name']=element.name
                        }  
                        searched_items.forEach(item => {
                            TOTAL[element.value]= TOTAL[element.value]+this.toNumber(item[element.value])
                            if (element.money) {
                                TOTAL[element.show_value]=this.MBS.actions.money(TOTAL[element.value] )  
                            }if (element.name) {
                                TOTAL['name']=element.name
                            }  
                        });
                        TOTAL['value'] = element.value
                        TOTAL['show_value'] = element.show_value
                        TOTAL['value_'] = TOTAL[element.value] 
                        TOTAL['show_value_'] = TOTAL[element.show_value]
                        TOTAL_ARRAY.push(TOTAL)
                    }); 
                } 
                this.input = {
                    ...this.input,
                    calculated_total_item_value : TOTAL_ARRAY 
                }  
                return TOTAL_ARRAY
            },
            JOINED_EXCEL_DATA_ITEMS(){
                let DATA_ITEMS = this.EXCEL_DATA_ITEMS
                return this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_ITEMS
                })  
            },
            
            JOINED_DATA_CHILD_ITEMS(){
                let DATA_ITEMS = this.DATA_CHILD_ITEMS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_CHILD_ITEMS
                })  
                return join
            },
            JOINED_DATA_CHILD_ITEMS_SELECTED(){
                let DATA_ITEMS = this.DATA_CHILD_ITEMS_SELECTED
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_CHILD_ITEMS
                })  
                return join
            },
            JOINED_EXCEL_DATA_CHILD_ITEMS(){
                let DATA_ITEMS = this.EXCEL_DATA_CHILD_ITEMS
                return this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_ITEMS
                })  
            },
            ItemActionFunction(){
                return (item,action)=>{
                    if(!action){return null}
                    if ( typeof action == "function") {
                        return action(this,item)
                    }else{
                        return action
                    } 
                }
            }

        },
        methods: {  
            onRowClick(item){
                try {   
                    let on_row_click_action = this.on_row_click_action
                    this.$emit("on_row_clicked",item)
                    this.SELECT_ITEM_ACTION("on_row_clicked",item,this.child)
                    if(on_row_click_action){
                        console.log(item,'on_row_clicked.........*******.');

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'onRowClick',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SHOW_OFFLINE(){ 
                this.showOffline = true 
                setTimeout(() => {
                    this.showOffline = false 
                    setTimeout(() => { 
                        this.SHOW_OFFLINE()
                    }, 500);
                }, 3000);  
            },
            EXPORT_EXCEL(){
                try {   
                    this.MBS.events.$emit("EXPORT_EXCEL")
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            TABLE_UPDATED(options){
                try {  
                    this.tableHistoryOptions = options
                    this.UPDATE_PAGE_HISTORIES()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_UPDATED',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CANCEL_SELECTED_ITEMS(){
                try {  
                    this.show_select = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CANCEL_SELECTED_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CURRENT_TABLE_ITEMS(items){
                try {  
                    setTimeout(() => { 
                        this.currentTableItems = [...items]
                    }, 1000);
                    // let values_for_totals = this.total_item_values 
                    // if(!values_for_totals){return null}
                    
                    // let TOTAL_ARRAY=[] 
                    // if (items) {
                    //     values_for_totals.forEach(element => {
                    //         let TOTAL={}
                    //         TOTAL[element.value]=0
                    //         if (element.money) {
                    //             TOTAL[element.show_value]=this.MBS.actions.money(TOTAL[element.value] ) 
                    //         }else if (element.name) {
                    //             TOTAL['name']=element.name
                    //         }  
                    //         items.forEach(item => {
                    //             TOTAL[element.value]= TOTAL[element.value]+this.toNumber(item[element.value])
                    //             if (element.money) {
                    //                 TOTAL[element.show_value]=this.MBS.actions.money(TOTAL[element.value] )  
                    //             }if (element.name) {
                    //                 TOTAL['name']=element.name
                    //             }  
                    //         });
                    //         TOTAL['value'] = element.value
                    //         TOTAL['show_value'] = element.show_value
                    //         TOTAL['value_'] = TOTAL[element.value] 
                    //         TOTAL['show_value_'] = TOTAL[element.show_value]
                    //         TOTAL_ARRAY.push(TOTAL)
                    //     }); 
                    // } 
                    // this.input = {
                    //     ...this.input,
                    //     calculated_total_item_value : TOTAL_ARRAY 
                    // } 
                    
                    // console.log(items,'items-------------------');
                    // console.log(TOTAL_ARRAY,'TOTAL_ARRAY-------------------');
                    // setTimeout(() => {
                        
                    //     // this.CURRENT_TOTAL_ITEMS = TOTAL_ARRAY
                    // }, 1000);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CURRENT_TABLE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            UPDATE_PAGE_HISTORIES(){
                try {  
                    let us = this.us
                    let currentTableTab = this.currentTableTab
                    let tableHistoryOptions = this.tableHistoryOptions
                    let uid = us?us.uid:null
                    DB.addItems({
                        name:DATA.ITEMS.PAGE_HISTORIES.values,
                        data:{
                            key:this.NAME_ITEMS+"="+uid,
                            uid:uid,
                            PAGE_LOAD:this.PAGE_LOAD,
                            PAGE_CURRENT:this.PAGE_CURRENT,
                            PAGE_SIZE:this.PAGE_SIZE,
                            search:this.search,
                            search_input:this.search_input,
                            filter_date:this.input_filter_Date,
                            table_options:tableHistoryOptions,
                            current_table_tab:currentTableTab,
                        }
                    }).then(res=>{
                        this.MBS.events.$emit("SUBMIT_PAGE_HISTORIES",PAGE_NAME)
                    }).catch(error=>{

                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_PAGE_HISTORIES',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            INITIATE_PAGE_HISTORIES(){ 
                try {   
                    let history = this.PageHistory
                    let PAGE_LOAD = history?history.PAGE_LOAD:null
                    let PAGE_CURRENT = history?history.PAGE_CURRENT:null
                    let PAGE_SIZE = history?history.PAGE_SIZE:null
                    let search = history?history.search:null
                    let search_input = history?history.search_input:null
                    let table_options = history?history.table_options:null
                    let input_filter_Date = history?history.filter_date:null
                    let currentTableTab = history?history.current_table_tab:null

                    
                    if (history) {
                        if (input_filter_Date) {
                            this.input_filter_Date = null  
                        }
                        setTimeout(() => {
                            this.PAGE_CURRENT = PAGE_CURRENT 
                            this.PAGE_SIZE = PAGE_SIZE 
                            this.search = search 
                            this.search_input = search_input 
                            this.tableOptions = table_options 
 
                            this.showTableTab = false
                            setTimeout(() => { 
                                this.currentTableTab = currentTableTab
                                this.showTableTab=true
                            }, 100);
                            
                            if (input_filter_Date) {
                                this.input_filter_Date = input_filter_Date 
                                this.filterDateDifference = input_filter_Date?input_filter_Date.difference?input_filter_Date.difference:'0':null 
                            }
                        }, 100);
                    }else{ 
                        this.input_filter_Date = {}
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIATE_PAGE_HISTORIES',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            SELECT_ITEM_ACTION(action,item,child=false){
                try { 
                    this.$emit('SELECT_ITEM_ACTION',action,item)
                    if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('add_item_child')) {
                        this.SELECTED_ITEM = item 
                        this.ADD_ITEM(true)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('edit_item')) {
                        this.EDIT_ITEM(item,child)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('select_item')) {
                        this.SELECT_ITEM(item,child)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('delete_item')) {
                        this.DELETE_ITEM(item,child)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('archive_item')) {
                        this.ARCHIVE_ITEM(item,child)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('unarchive_item')) {
                        this.UNARCHIVE_ITEM(item,child)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('view_item_child')) {
                        this.SHOW_CHILD_ITEM(item)
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('cancel_item')) {
                        this.UPDATE_ITEM({
                            key:item.key,
                            canceled:true
                        })
                    }if (this.MBS.actions.TEXT_UP(action)===this.MBS.actions.TEXT_UP('un_cancel_item')) {
                        this.UPDATE_ITEM({
                            key:item.key,
                            canceled:false
                        })
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            SELECT_CHILD_ITEM_ACTION(action,data,child=false,parent){
                try { 
                    console.log(action,'.....a.');
                    console.log(data,'.....data.');
                    console.log(child,'.....child.');
                    console.log(parent,'.....parent.');
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_CHILD_ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            SELECT_TABLE_ACTION(item){ 
                try { 
                    this.$emit('SELECT_TABLE_ACTION',item.action,item) 
                    if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('add_item')) { 
                        this.ADD_ITEM()
                    }else if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('cancel_selected_items')) { 
                        this.show_select = false
                        this.input_selected_items = []
                    }else if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('archive_selected_items')) { 
                        this.ARCHIVE_MULTIPLE_ITEM(this.input_selected_items)
                    }else if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('delete_selected_items')) { 
                        this.DELETE_MULTIPLE_ITEM(this.input_selected_items)
                    }else if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('delete_all_items')) { 
                        this.DELETE_ALL_ITEMS()
                    }else if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('export_items')) { 
                        this.EXPORT_ITEMS()
                    }else if (this.MBS.actions.TEXT_UP(item.action)===this.MBS.actions.TEXT_UP('export_excel')) { 
                        this.EXPORT_EXCEL()
                    }    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            SHOW_CHILD_ITEM(item){
                try {  
                    this.SELECTED_ITEM = item 
                    this.dialog_child_items = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHOW_CHILD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            ADD_ITEM(child){
                try {  
                    // IF NOT CHILD DESELECT
                    if (!child) {
                        this.SELECTED_ITEM = null
                    }
                    let p = this.ACCESS_ITEM("add")
                    if (!p) { 
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:this.MBS.text.add_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).title,
                           text:this.MBS.text.add_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).text,
                           btnYes:this.MBS.text.add_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).btnYes 
                       }) 
                    } else {  
                        this.input = {} 
                        this.stepper_input = 1
                        this.next_step = false
                        this.inputLoading = false
                        this.CHILD = child?true:false
                        this.dialog_add_item = true  
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            CREATE_FIELDS_ARRAY_DATA(child,input,notify,update_data){
                try { 
                    let code = this.input.code
                    //CREATE ITEM ARRAY
                    let fields_array_data = {}
                    let required = false
                    let field_items = child?this.FIELD_CHILD_ITEMS:this.FIELD_ITEMS
                    if (input.key) {
                        fields_array_data["key"]=input.key
                    } 
                    field_items.forEach(field => {
                        if (field.value==='code') {
                            fields_array_data.code = code 
                        } else {
                            let value = input[field.value]||input[field.value]==false?input[field.value]:null 
                            let no_data = false
                            if ( typeof value == "object") {
                                no_data = this.MBS.actions.SIZE(value)>0?false:true
                            }
                            if (no_data || value == null || value == undefined) {
                                if (field.required) {
                                    if (notify) {
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:false,
                                            title:this.MBS.text.field_required(field.label).title,
                                            text:this.MBS.text.field_required(field.label).text,
                                            btnYes:this.MBS.text.field_required(field.label).btnYes
                                        }) 
                                    }
                                    required = true
                                }

                                // delete if update
                                if (update_data) {
                                    if (no_data || value == null) {
                                        fields_array_data[field.value] = null 
                                    } 
                                }
                            }else{
                                fields_array_data[field.value] = value 
                            }
                        } 
                    }); 
                    if (this.item_data_include) {
                        fields_array_data = {
                            ...fields_array_data,
                            ...this.item_data_include
                        }
                    } 
                    return {
                        fields_array_data:fields_array_data,
                        required:required
                    }
                         
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_FIELDS_ARRAY_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            
            NEXT_STEP(step,index){
                try { 
                    if (step) {
                        let size = this.MBS.actions.SIZE(this.STEP_FIELD_ITEMS)
                        if (size != this.stepper_input) {
                            this.next_step = true
                            // this.inputLoading = true
                            // setTimeout(() => {
                            //     this.stepper_input++
                            //     this.inputLoading = false
                            // }, 500);
                            // return
                        }  
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            PREVIOUS_STEP(step,index){
                try { 
                    if (step) {
                        this.stepper_input = this.stepper_input - 1
                        // let size = this.MBS.actions.SIZE(this.STEP_FIELD_ITEMS)
                        // if (size != this.stepper_input) {
                        //     this.inputLoading = true
                        //     setTimeout(() => {
                        //         this.stepper_input++
                        //         this.inputLoading = false
                        //     }, 500);
                        //     return
                        // }  
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NEXT_STEP',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            SUBMIT_ITEM(child,step,index){
                try { 
                    if (step) {
                        let size = this.MBS.actions.SIZE(this.STEP_FIELD_ITEMS)
                        if (size != this.stepper_input) {
                            this.stepLoading = true
                            if (this.next_step) { 
                                this.next_step = false
                                setTimeout(() => {
                                    this.stepper_input++
                                    this.stepLoading = false
                                }, 500);
                                return 
                            }else{
                                this.stepLoading = false
                                return
                            }
                        }  
                    }   
                    // errors
                    let errors = this.input.errors
                    let errors_size = this.MBS.actions.SIZE(errors) 
                    let has_errors = errors_size>0?true:false
                    if (has_errors) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"FIELD ERROR(S)",
                            text:"Please, make sure you fixe all highlighted field error(s) before you can continue.",
                            btnYes:"OKAY",  
                        }) 
                        return
                    } 
                    delete this.input.errors 

                    //convert code to int
                    let code = this.input.code
                    let array = child?this.DATA_CHILD_ITEMS:this.DATA_ITEMS

                    //check code
                    if ((child?this.FIELD_CHILD_ITEMS[0].value:this.FIELD_ITEMS[0].value)==='code') { 
                        if (code === 0) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.check_code_error_0.title,
                                text:this.MBS.text.check_code_error_0.text,
                                btnYes:this.MBS.text.check_code_error_0.btnYes,
                                btnNo:this.MBS.text.check_code_error_0.btnNo,
                                action:{
                                    code:PAGE_NAME+"=AUTO-SET-CODE",
                                    data:array
                                }
                            }) 
                            return
                        } 
                    }

                    //CREATE ITEM ARRAY
                    let fields_data = this.CREATE_FIELDS_ARRAY_DATA(child,this.input,true)
                    let required = fields_data?fields_data.required:null
                    let fields_array_data = fields_data?fields_data.fields_array_data:null
                    if (required) {return}
                    if (!fields_array_data) {return}

                    if (child) {
                        if (!this.SELECTED_ITEM) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.error_0.title,
                                text:this.MBS.text.error_0.text,
                                btnYes:this.MBS.text.error_0.btnYes, 
                            }) 
                            return
                        }
                        fields_array_data[this.MBS.actions.TEXT_UP0(this.VALUE_ITEM)+"_code"] = 
                        this.SELECTED_ITEM.code 
                    }    
                    if(fields_array_data['add_opening_stocks']){
                        let add_opening_stocks = fields_array_data['add_opening_stocks']
                        if (this.MBS.actions.SIZE(add_opening_stocks)>0) {
                            let opening_stocks = 0
                            let outlets = 0
                            fields_array_data.outlets=[]
                            add_opening_stocks.forEach(stock => {
                                let stock_value = this.toNumber(stock.stock) 
                                fields_array_data.outlets[stock.outlet_code]={
                                    opening_stocks:stock_value
                                } 
                                opening_stocks = opening_stocks+stock_value
                            });
                            fields_array_data["opening_stocks"]=opening_stocks
                        }
                        delete fields_array_data['add_opening_stocks'] 
                    }                 
                    // 
                    let mbs_text = this.MBS.text.add_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM)
                    let action_do = this.add_item_action?this.add_item_action.action_do:null
                    if (action_do) {
                        mbs_text = this.MBS.text.item_action(child?this.NAME_CHILD_ITEM:this.NAME_ITEM,action_do)
                    }
                    this.input.code = code 
                    this.input.check = true
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=ADD-ITEM",
                            child:child?true:false,
                            data:{
                                ...fields_array_data
                            }
                        }
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            EDIT_ITEM(item,child){
                try {
                    let p = this.ACCESS_ITEM("edit")
                    if (!p) {
                       this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:this.MBS.text.edit_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).title,
                           text:this.MBS.text.edit_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).text,
                           btnYes:this.MBS.text.edit_item_permission_denied(child?this.NAME_CHILD_ITEMS:this.NAME_ITEMS).btnYes 
                       }) 
                    } else { 
                        this.EDITING_ITEM = item
                        this.input = {}
                        this.stepper_input = 1
                        this.next_step = false
                        this.inputLoading = false
                        let field_items = child?this.FIELD_CHILD_ITEMS:this.FIELD_ITEMS
                        field_items.forEach(field => { 
                            this.input[field.value] = item[field.value] 
                        }); 
                        this.input.key = item.key 
                        this.CHILD = child?true:false
                        this.dialog_edit_item = true
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            SELECT_ITEM(item,child){
                try { 
                    this.show_select = true 
                    this.input_selected_items.push(item) 
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            SUBMIT_EDIT_ITEM(child,step,index){
                try { 
                    if (step) {
                        let size = this.MBS.actions.SIZE(this.STEP_FIELD_ITEMS)
                        if (size != this.stepper_input) {
                            this.inputLoading = true
                            if (this.next_step) { 
                                this.next_step = false
                                setTimeout(() => {
                                    this.stepper_input++
                                    this.inputLoading = false
                                }, 500);
                                return 
                            }else{
                                this.inputLoading = false
                                return
                            }
                        }  
                    }  
                    // errors
                    let errors = this.input.errors
                    let errors_size = this.MBS.actions.SIZE(errors) 
                    let has_errors = errors_size>0?true:false
                    if (has_errors) {
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"FIELD ERROR(S)",
                            text:"Please, make sure you fixe all highlighted field error(s) before you can continue.",
                            btnYes:"OKAY",  
                        }) 
                        return
                    } 
                    delete this.input.errors 

                    ///
                    this.CHILD = false
                    this.input.check = true
                    //CREATE ITEM ARRAY
                    // console.log(this.input,'this.input...........');
                    let fields_data = this.CREATE_FIELDS_ARRAY_DATA(child,this.input,true,true)
                    let required = fields_data?fields_data.required:null
                    let fields_array_data = fields_data?fields_data.fields_array_data:null
                    if (required) {return}
                    if (!fields_array_data) {return}

                    let mbs_text = this.MBS.text.update_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM)
                    let action_do = this.edit_item_action?this.edit_item_action.action_do:null
                    if (action_do) {
                        mbs_text = this.MBS.text.item_action(child?this.NAME_CHILD_ITEM:this.NAME_ITEM,action_do)
                    }

                    // console.log(fields_array_data,'fields_array_data.......');
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=UPDATE-ITEM",
                            child:child?true:false,
                            data:fields_array_data
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_EDIT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            UPDATE_ITEM(data,child){
                try { 
                    if (!data) {
                        let mbs_text = this.MBS.text.edit_item_fail(child?this.NAME_CHILD_ITEM:this.NAME_ITEM)
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{}
                        })  
                        this.dialog_edit_item = false
                        this.input.loading = false
                        this.inputLoading = false
                       return 
                    }
                    this.CHILD = false
                    this.input.check = true
                    let mbs_text = this.MBS.text.update_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM)
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=UPDATE-ITEM",
                            child:child?true:false,
                            data:data
                        }
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            DELETE_ITEM(item,child){
                try {
                    console.log(item,'--------------======');
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                            text:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                       }) 
                    } else {
                        this.CHILD=child?true:false,
                        this.input.check = true 
                        if (item.image) {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                                text:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                                btnNo:this.MBS.text.delete_image_first(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnNo,
                                action:{
                                    code:PAGE_NAME+"=DELETE-IMAGE",
                                    child:child?true:false,
                                    data:item,
                                    image:item.image,
                                    key:item.key
                                }
                            })  
                        } else {
                            this.MBS.actions.dialogConfirmPassword({
                                show:true,
                                fixed:true,
                                icon:"delete",
                                title:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                                btnNo:this.MBS.text.delete_item(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnNo,
                                action:{
                                    code:PAGE_NAME+"=DELETE-ITEM", 
                                    phone_number_start:true,
                                    all_users:true,
                                    get_access:{
                                        permission_type:"action", 
                                        permission_to:"delete", 
                                        action_name:DATA.ITEMS.ITEMS.names
                                    },
                                    child:child?true:false,
                                    data:item,
                                }
                            })  
                        }
                    }     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ITEM',
                        page:PAGE_NAME, 
                    })  
                }
            }, 
            DELETE_ALL_ITEMS(){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(this.NAME_ITEMS).title,
                            text:this.MBS.text.delete_item_permission_denied(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(this.NAME_ITEMS).btnYes,
                       }) 
                    } else {
                        this.input.check = true
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"delete",
                            title:this.MBS.text.delete_item_all(this.NAME_ITEMS).title,
                            text:this.MBS.text.delete_item_all(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.delete_item_all(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.delete_item_all(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-ALL-ITEMS", 
                                phone_number_start:true,
                                all_users:true,
                                get_access:{
                                    permission_type:"action", 
                                    permission_to:"delete", 
                                    action_name:DATA.ITEMS.ITEMS.names
                                }
                            }
                        })  
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ALL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            ARCHIVE_ITEM(item,child){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                            text:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                       }) 
                    }else if (item.key) {
                        let mbs_text = this.MBS.text.item_action(child?this.NAME_CHILD_ITEM:this.NAME_ITEM,"Archive")
                        this.CHILD=child?true:false
                        this.input.check = true
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"delete",
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=UPDATE-ITEM",
                                child:child?true:false,
                                data:{
                                    key:item.key,
                                    archive:true
                                },
                                phone_number_start:true,
                                all_users:true,
                                get_access:{
                                    permission_type:"action", 
                                    permission_to:"delete", 
                                    action_name:DATA.ITEMS.ITEMS.names
                                }
                            }
                        })   
                    } else {
                        this.MBS.actions.error({
                            error:{
                                error:'no item key',
                                item:item,
                            },
                            from:'DELETE_ITEM',
                            page:PAGE_NAME, 
                        }) 
                    }     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ITEM',
                        page:PAGE_NAME, 
                    })  
                }
            }, 
            ARCHIVE_MULTIPLE_ITEM(items,child){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                            text:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                       }) 
                    }else if (items) {
                        let data = []
                        items.forEach(item => {
                            data.push({
                                key:item.key,
                                archive:true,
                                path:child?this.PATH_CHILD_ITEM+item.key:this.PATH_ITEM+item.key
                            })
                        });
                        this.input.check = true
                        let mbs_text = this.MBS.text.item_action("Selected Items","Archive") 
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"delete",
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+'=UPDATE-MULTIPLE-ITEMS',
                                child:child?true:false,
                                data:data,
                                phone_number_start:true,
                                all_users:true,
                                get_access:{
                                    permission_type:"action", 
                                    permission_to:"delete", 
                                    action_name:DATA.ITEMS.ITEMS.names
                                }
                            }
                        })   
                    } else {
                        this.MBS.actions.error({
                            error:{
                                error:'no item key',
                                item:item,
                            },
                            from:'DELETE_ITEM',
                            page:PAGE_NAME, 
                        }) 
                    }     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ARCHIVE_MULTIPLE_ITEM',
                        page:PAGE_NAME, 
                    })  
                }
            }, 
            DELETE_MULTIPLE_ITEM(items,child){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                            text:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                       }) 
                    }else if (items) {
                        let data = []
                        items.forEach(item => {
                            data.push({
                                key:item.key, 
                                path:child?this.PATH_CHILD_ITEM+item.key:this.PATH_ITEM+item.key
                            })
                        });
                        this.input.check = true
                        let mbs_text = this.MBS.text.item_action("Selected Items","Delete") 
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"delete",
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+'=DELETE-MULTIPLE-ITEMS',
                                child:child?true:false,
                                data:data,
                                phone_number_start:true,
                                all_users:true,
                                get_access:{
                                    permission_type:"action", 
                                    permission_to:"delete", 
                                    action_name:DATA.ITEMS.ITEMS.names
                                }
                            }
                        })   
                    } else {
                        this.MBS.actions.error({
                            error:{
                                error:'no item key',
                                item:item,
                            },
                            from:'DELETE_ITEM',
                            page:PAGE_NAME, 
                        }) 
                    }     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ARCHIVE_MULTIPLE_ITEM',
                        page:PAGE_NAME, 
                    })  
                }
            }, 
            UNARCHIVE_ITEM(item,child){
                try {
                    let p = this.ACCESS_ITEM("delete")
                    if (!p) {
                       this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).title,
                            text:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).text,
                            btnYes:this.MBS.text.delete_item_permission_denied(child?this.NAME_CHILD_ITEM:this.NAME_ITEM).btnYes,
                       }) 
                    }else if (item.key) {
                        let mbs_text = this.MBS.text.item_action(child?this.NAME_CHILD_ITEM:this.NAME_ITEM,"Unarchive")
                        this.CHILD=child?true:false
                        this.input.check = true
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CONFIRM-UNARCHIVE-ITEM",
                                child:child?true:false,
                                data:{
                                    key:item.key,
                                    archive:false
                                }
                            }
                        })   
                    } else {
                        this.MBS.actions.error({
                            error:{
                                error:'no item key',
                                item:item,
                            },
                            from:'DELETE_ITEM',
                            page:PAGE_NAME, 
                        }) 
                    }     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ITEM',
                        page:PAGE_NAME, 
                    })  
                }
            },
            ICON_FUNCTION(icon,item){
                try {  
                    if ( typeof icon == "function") {
                        return icon(item)
                    } else {
                        return icon
                    }
                       
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ICON_FUNCTION',
                        page:PAGE_NAME, 
                    })  
                }
            },   

            //xl
            EDIT_EXCEL_ITEM(item){
                try {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true, 
                        text:"This option is currently not available. Please, edit from the file.",
                        btnYes:"OK"
                    })  
                    this.MBS.actions.dialog(true,false,null,"","OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_EXCEL_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            DELETE_EXCEL_ITEM(item){
                try {
                    this.input.check = true
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.remove_item(this.NAME_ITEM).title,
                        text:this.MBS.text.remove_item(this.NAME_ITEM).text,
                        btnYes:this.MBS.text.remove_item(this.NAME_ITEM).btnYes,
                        btnNo:this.MBS.text.remove_item(this.NAME_ITEM).btnNo,
                        action:{
                            code:PAGE_NAME+"=REMOVE-EXCEL-ITEM",
                            data:item
                        }
                    })   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_EXCEL_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            uploadXl(){
                try {
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.upload_item(this.NAME_ITEMS).title,
                        text:this.MBS.text.upload_item(this.NAME_ITEMS).text,
                        btnYes:this.MBS.text.upload_item(this.NAME_ITEMS).btnYes,
                        btnNo:this.MBS.text.upload_item(this.NAME_ITEMS).btnNo,
                        action:{
                            code:PAGE_NAME+"=XL-UPLOAD-OLD",
                            data:this.this.EXCEL_DATA_ITEMS
                        }
                    })    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'uploadXl',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            UPLOAD_REPLACE_EXCEL(){
                try {
                    let isOk = this.MBS.actions.CODE_IS_OK(this.EXCEL_DATA_ITEMS) 
                    if (!isOk) { 
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.check_code_error_unique_code(this.NAME_ITEMS).title,
                            text:this.MBS.text.check_code_error_unique_code(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.check_code_error_unique_code(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.check_code_error_unique_code(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=AUTO-GENERATE-CODE",
                                data:this.EXCEL_DATA_ITEMS
                            }
                        })    
                        return 
                    }
                     
                    this.input.check = true 
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.text.upload_and_replace_item(this.NAME_ITEMS).title,
                        text:this.MBS.text.upload_and_replace_item(this.NAME_ITEMS).text,
                        btnYes:this.MBS.text.upload_and_replace_item(this.NAME_ITEMS).btnYes,
                        btnNo:this.MBS.text.upload_and_replace_item(this.NAME_ITEMS).btnNo,
                        action:{
                            code:PAGE_NAME+"=UPLOAD-REPLACE-EXCEL", 
                        }
                    })    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPLOAD_REPLACE_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
            },

            AT_INPUT_CHANGE(change){
                try {  
                    this.input_filters = {
                        ...this.input_filters
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            FILTER_DATA(data,input_filters){
                try { 
                    let filters = this.filters 
                    if(!data){return null}
                    

                    //1. DATE FILTER
                        let date_filter = item=>{
                            if (!this.filter_date || this.filter_date_only_display) {
                                return true
                            }
                            let filter_date = this.input_filter_Date
                            let filter_date_value = this.filter_date_value
                            let from_date = filter_date?filter_date.from_date:null
                            let to_date = filter_date?filter_date.to_date:null  

                            let mDate = null
                            let date = null
                            if (filter_date_value) { 
                                date = this.moment(item[filter_date_value]).format("yyyy-MM-D") 
                            }else{
                                date = this.moment(item.created_at).format("yyyy-MM-D")
                            }
                            mDate = this.moment(date) 
                            let between = mDate.isBetween(from_date,to_date,'','[]')  
                            return between 
                        }

                    //2. FILTERS
                        let custom_filters = item=>{
                            if (!input_filters||!filters) {
                                return data
                            }
                            let is = true
                            filters.forEach(filter => {
                                let item_value = item[filter.value]
                                let input_value = input_filters[filter.value]
                                if (item_value && this.MBS.actions.SIZE(input_value)) {
                                    if (filter.type == "auto"||filter.type == "select") {
                                        let index = input_value.indexOf(item_value)
                                        if (index==-1) {
                                            is = false
                                        } else {
                                            
                                        }
                                    } else { 
                                        if (input_value!=item_value) {
                                            is = false
                                        } else {
                                            
                                        }
                                    } 
                                }
                            });
                          return is   
                        }
                    let filtered = data.filter(item=>{
                        let on_date = date_filter(item)
                        let on_custom = custom_filters(item)
                        return on_date && on_custom
                    })
                    return filtered
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            //-----------------[SELECT FILE]-----------
            SELECT_EXCEL(){  
                try {
                    let p = this.ACCESS_ITEM("write")
                    if (!p) {
                        this.MBS.actions.dialog({
                           show:true,
                           fixed:true,
                           title:this.MBS.text.add_item_permission_denied(this.NAME_ITEMS).title,
                           text:this.MBS.text.add_item_permission_denied(this.NAME_ITEMS).text,
                           btnYes:this.MBS.text.add_item_permission_denied(this.NAME_ITEMS).btnYes 
                       })  
                    } else {
                        this.dialog_add_item = false
                        this.excelSelector = !this.excelSelector
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            FILE_SELECTED(wb){
                try {
                    this.WB = wb
                    this.excelSelector = false
                    this.EXCEL_DATA_ITEMS=[]  
                    let worksheet_item = wb.Sheets[this.MBS.actions.TEXT_UP0(this.VALUE_ITEMS)]  
                    if (!worksheet_item) {
                        this.input.check = true
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).title,
                            text:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=SELECT-EXCEL" 
                            }
                        })  
                    } else {
                        let json_item = this.XLSX.utils.sheet_to_json(worksheet_item)  
                        let data_item = []
                        let number = 0
                        json_item.forEach(excel_item => {
                            let dt = new Date
                            let date = dt.toISOString()

                            //CREATE ARRAY
                            let item_array = {}
                            this.FIELD_ITEMS.forEach(element => {
                                if (excel_item[element.value] && element.value) {
                                    item_array[element.value] = excel_item[element.value] 
                                }
                            }); 
                            //ADD
                            data_item.push({
                                ...item_array,
                                created_at:date, 
                                uid:this.us.uid,
                                no:number
                            })
                        }); 
                        this.EXCEL_DATA_ITEMS = data_item
                        this.dialog_selected_item = true 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FILE_SELECTED',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 

            //---------------[EXPORT ITEMS]------------
            async EXPORT_ITEMS(){
                try {
                    this.MBS.actions.progressDialog({
                        show:true,
                        fixed:true,
                        title:this.MBS.actions.TEXT_UP('Downloading '+this.NAME_ITEM)
                    }) 
                    let self = this
                    let items = this.DATA_ITEMS
                    console.log(items);
                    // let items = this.JOINED_DATA_ITEMS()  
                    setTimeout(() => {
                        let convert =  this.MBS.actions.CONVERT_OBJECT_TO_JSON(items)
                        convert.then(res=>{ 
                            let item_json = res.data
                            const bytes = new TextEncoder().encode(item_json);
                            let file = new Blob([bytes], {type: "text"} ) 
                            let fileURL = URL.createObjectURL(file)   
                            let anchor = document.createElement("a")
                            anchor.href = fileURL
                            anchor.download = self.MBS.actions.TEXT_UP(self.NAME_ITEM)+'.json' 
                            setTimeout(() => {
                                console.log(fileURL);
                                document.body.appendChild(anchor)
                                anchor.click()
                                document.body.removeChild(anchor)
                                URL.revokeObjectURL(fileURL)
                                self.MBS.actions.progressDialog()
                            }, 200) 
                        }).catch(error=>{
                            self.MBS.actions.progressDialog()
                            console.log(error,'...........');
                        })  
                    }, 500);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            
            //---------------[MAIN FUNCTIONS]------------
            SAVE_DIALOG_TIME_PICKER(value){
                try {
                   this.input[value] = this.dialogInput[value] 
                   this.dialog_time_picker[value]= false
                   this.UPDATE_MODEL()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_MODEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            UPDATE_MODEL(){
                try {
                   this.$emit('input', {...this.input})
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_MODEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            NAV(){
                try { 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NAV',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DIALOG_YES(action){ 
                try { 
                    if (this.input.check) {
                        this.input.check = false
                        if (action.code) { 
                            if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                                if (action.YES) {
                                    setTimeout(() => {  
                                        let key = action.data?action.data.key:null
                                        let upload_action = key?'fi_set':'fi_add'
                                        let path = action.child?this.PATH_CHILD_ITEM:this.PATH_ITEM
                                        let f_detail = {
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:key?path+key+"/":path,
                                            data:action.data,
                                            us:this.us
                                        } 
                                        this.input.loading = true 
                                        this.inputLoading = true 
                                        this.$store.dispatch(upload_action,f_detail)
                                        .then(res=>{  
                                            let mbs_text = this.MBS.text.add_item_success(this.NAME_ITEM)
                                            let action_do = this.add_item_action?this.add_item_action.action_do:null
                                            if (action_do) {
                                                mbs_text = this.MBS.text.item_action_success(this.NAME_ITEM,action_do)
                                            }
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes,  
                                            }) 
                                            this.input.loading = false
                                            this.inputLoading = false
                                            this.dialog_add_item = false 
                                            this.generatedCode = null 
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses() 
                                            this.$emit("ADDED",res)
                                        })
                                        .catch(error=>{ 
                                            let mbs_text = this.MBS.text.add_item_fail(this.NAME_ITEM)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes, 
                                            })  
                                            this.input.loading = false
                                            this.inputLoading= false
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses()

                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);     
                                } else {
                                    
                                }
                            }  
                            if (action.code ===PAGE_NAME+'=UPDATE-ITEM') {
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.loading = true  
                                        this.inputLoading= true  
                                        this.$store.dispatch("fi_update",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/",
                                            data:action.data,
                                            us:this.us
                                        }).then(res=>{
                                            this.$emit("EDITED",res)
                                            this.$emit("UPDATED",res)
                                            this.inputLoading= false
                                            this.dialog_edit_item = false 
                                        }).catch(error=>{
                                            this.inputLoading= false
                                            console.log(error,'e.............');
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);   
                                } else { 
                                } 
                            } 
                            if (action.code === PAGE_NAME+'=UPDATE-MULTIPLE-ITEMS') { 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.loading = true  
                                        this.inputLoading= true  
                                        this.$store.dispatch("fi_update",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM:this.PATH_ITEM,
                                            multiple:true,
                                            data:action.data,
                                            us:this.us
                                        }).then(res=>{
                                            this.$emit("EDITED",res)
                                            this.$emit("UPDATED",res)
                                            this.inputLoading= false 
                                        }).catch(error=>{
                                            this.inputLoading= false
                                            console.log(error,'err.........');
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);   
                                } else { 
                                } 
                            }  
                            if (action.code === PAGE_NAME+'=DELETE-MULTIPLE-ITEMS') { 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.loading = true  
                                        this.inputLoading= true  
                                        this.$store.dispatch("fi_delete",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM:this.PATH_ITEM,
                                            multiple:true,
                                            data:action.data,
                                            us:this.us,
                                            multiple:true
                                        }).then(res=>{
                                            this.$emit("DELETED",res)
                                            this.$emit("UPDATED",res)
                                            this.inputLoading= false 
                                        }).catch(error=>{
                                            this.inputLoading= false
                                            console.log(error,'err.........');
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);   
                                } else { 
                                } 
                            }   
                            if (action.code ===PAGE_NAME+'=UPDATE-ITEM-WITH-PASS') {
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = false
                                        let password = action.password
                                        password = password?this.MBS.crypt.encrypt(password):null 
                                        if (!password) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        }else if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                            this.input.loading = true 
                                            this.inputLoading= true 
                                            this.$store.dispatch("fi_update",{
                                                action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                                path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/",
                                                data:action.data,
                                                us:this.us
                                            }).then(res=>{
                                                this.inputLoading= false
                                            }).catch(error=>{
                                                this.inputLoading= false
                                            })
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }   
                            }    
    
                             
                            
                            if (action.code ===PAGE_NAME+'=CONFIRM-ARCHIVE-ITEM') {
                                if (action.YES) {
                                    this.input.check = true 
                                    setTimeout(() => {  
                                        this.input.check
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm archiving this "+(action.child?this.NAME_CHILD_ITEM:this.NAME_ITEM),btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=UPDATE-ITEM-WITH-PASS",
                                            }
                                        })   
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                } else {
                                    
                                } 
                            }   
                            if (action.code ===PAGE_NAME+'=CONFIRM-UNARCHIVE-ITEM') {
                                if (action.YES) {
                                    setTimeout(() => {  
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm un-archiving this "+(action.child?this.NAME_CHILD_ITEM:this.NAME_ITEM),btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=UPDATE-ITEM-WITH-PASS",
                                            }
                                        })   
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                } else {
                                    
                                } 
                            }   
                            if (action.code ===PAGE_NAME+'=DELETE-ITEM') {
                                if (action.YES) {
                                    setTimeout(() => {  
                                        this.input.check = false
                                        // let password = action.password
                                        // if (!password) { 
                                        //     this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        // }else if (password!==this.ud.p_uid) { 
                                        //     this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        // } else {
                                        //     console.log(action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/",'---------------------------+++');
                                        // } 
                                        this.$store.dispatch("fi_delete",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/"
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }   
                            }    
                            if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEMS') {
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.check = false
                                        let password = action.password
                                        // password = password?this.MBS.crypt.encrypt(password):null 
                                        if (!password) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        }else if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                            this.$store.dispatch("fi_delete",{
                                                action:this.VALUE_ITEMS,
                                                path:this.PATH_ITEM 
                                            })
                                        }  
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                } 
                            } 
                            if (action.code===PAGE_NAME+'=DELETE-IMAGE') { 
                                if (action.YES) { 
                                    setTimeout(() => {
                                        this.$store.dispatch("fi_deleteImage",{
                                            path:this.PATH_ITEM+action.key,
                                            link:action.image.link,
                                            action:this.VALUE_ITEM_IMAGE,
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else { 
                                }
                            }
                            
    
                            if (action.code ===PAGE_NAME+'=SELECT-EXCEL') {
                                if (action.YES) {
                                    this.SELECT_EXCEL()   
                                } else { 
                                }
                            } 
                            if (action.code ===PAGE_NAME+'=REMOVE-EXCEL-ITEM') {
                                if (action.YES) {
                                    let item =action.item 
                                    let items = this.EXCEL_DATA_ITEMS
                                    this.EXCEL_DATA_ITEMS = items.filter(cs=>{
                                        return cs.code != item.code
                                    })  
                                } else { 
                                }
                            } 
    
                            if (action.code ===PAGE_NAME+'=UPLOAD-REPLACE-EXCEL') {  
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.inputLoading= true
                                        this.$store.dispatch("fi_set",{
                                            action:this.VALUE_ITEMS,
                                            path:this.PATH_ITEM,
                                            path_invalid:true,
                                            data:this.EXCEL_DATA_ITEMS,
                                            us:this.us,
                                            array:true
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        }  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
            ITEM_ACTION(action,item){ 
                try {   
                    if (action) {   
                        if (action.NAME_ITEM === this.NAME_ITEM) { 
                            if (action.action === 'add_item') {
                                this.dialog_add_item = false
                                setTimeout(() => {
                                    this.ADD_ITEM()
                                    if (action.input) {
                                       this.input = action.input 
                                    }  
                                }, 100);
                            }else if (action.action === 'edit_item') { 
                                this.EDIT_ITEM(action.item) 
                            }else if (action.action === 'archive_item') { 
                                this.ARCHIVE_ITEM(action.item) 
                            }else if (action.action === 'delete_item') { 
                                this.DELETE_ITEM(action.item) 
                            }else{
                                console.log('no action sent');
                            }
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        this.PAGE_LOAD =true

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                            let addingItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_ITEM
                            })
                            let addingChildItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_CHILD_ITEM
                            })
                            let updatingItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_ITEM
                            })  
                            let updatingChildItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_CHILD_ITEM
                            })  
                            let deletingItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEM
                            })
                            let deletingChildItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_CHILD_ITEM
                            })
                            let deletingAllItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEMS
                            })
                            let deletingAllChildItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_CHILD_ITEMS
                            })
                             

                            if (gettingItems) { 
                                if (this.MBS.actions.SIZE(this.DATA_ITEMS)>0) {
                                    this.PAGE_LOAD =true 
                                    this.MBS.actions.progressToolbar({
                                        show:true
                                    })
                                }else{
                                    this.PAGE_LOAD =true 
                                    this.MBS.actions.progressToolbar({
                                        show:true
                                    })
                                }
                            }else{
                                this.PAGE_LOAD =true
                                this.MBS.actions.progressToolbar()
                            } 

                            if (addingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                })  
                            }else{ 
                            }  
                            if (addingChildItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                })  
                            }else{ 
                            }  

                            if (updatingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                })  
                            }else{ 
                            }  
                            if (updatingChildItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                })  
                            }else{ 
                            }  

                            if (deletingItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  
                            if (deletingChildItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  

                            if (deletingAllItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }
                            if (deletingAllChildItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            } 
                        }else{
                            this.PAGE_LOAD =true
                            this.MBS.actions.progressToolbar()
                        }  
                        
                        //ADD ITEM RESPONSE
                        // if (res.res==='adding/'+this.VALUE_ITEM+'/successful') { 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:this.MBS.text.add_item_success(this.NAME_ITEM).title,
                        //         text:this.MBS.text.add_item_success(this.NAME_ITEM).text,
                        //         btnYes:this.MBS.text.add_item_success(this.NAME_ITEM).btnYes,  
                        //     }) 
                        //     this.input.loading = false
                        //     this.dialog_add_item = false 
                        //     this.generatedCode = null 
                        //     this.MBS.actions.progressDialog() 
                        //     this.MBS.actions.restartResponses()  
                            
                        // }else if (res.res==='adding/'+this.VALUE_ITEM+'/error'){ 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:this.MBS.text.add_item_fail(this.NAME_ITEM).title,
                        //         text:this.MBS.text.add_item_fail(this.NAME_ITEM).text,
                        //         btnYes:this.MBS.text.add_item_fail(this.NAME_ITEM).btnYes, 
                        //     })  
                        //     this.input.loading = false
                        //     this.MBS.actions.progressDialog() 
                        //     this.MBS.actions.restartResponses()
                        // }
                        // if (res.res==='adding/'+this.VALUE_CHILD_ITEM+'/successful') { 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).title,
                        //         text:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).text,
                        //         btnYes:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).btnYes,  
                        //     }) 
                        //     this.input.loading = false
                        //     this.dialog_add_item = false
                        //     this.MBS.actions.progressDialog() 
                        //     this.MBS.actions.restartResponses() 
                            
                        // }else if (res.res==='adding/'+this.VALUE_CHILD_ITEM+'/error'){ 
                        //     this.MBS.actions.dialog({
                        //         show:true,
                        //         fixed:true,
                        //         title:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).title,
                        //         text:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).text,
                        //         btnYes:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                        //     })  
                        //     this.input.loading = false
                        //     this.MBS.actions.progressDialog() 
                        //     this.MBS.actions.restartResponses()
                        // }

                         
                        
                        if (res.res==='updating/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.inputLoading= false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='updating/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.inputLoading= false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        
                        
                        if (res.res==='setting/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.inputLoading= false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='setting/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='setting/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.inputLoading= false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='setting/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                        
                        if (res.res==='deleting/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='deleting/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                        if (res.res==='deleting/'+this.VALUE_ITEMS+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEMS).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEMS).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_ITEMS+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEMS).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEMS).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 


                        if (res.res==='deleting/image/'+this.VALUE_ITEM_IMAGE+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/image/'+this.VALUE_ITEM_IMAGE+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }


                        if (res.res==='setting/'+this.VALUE_ITEMS+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEMS).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEMS).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()  
                            this.dialog_selected_item = false
                        }else if (res.res==='setting/'+this.VALUE_ITEMS+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEMS).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEMS).btnYes, 
                            })  
                            this.input.loading = false
                            this.inputLoading= false
                            this.dialog_selected_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
            this.MBS.events.$off('ITEM_ACTION', this.ITEM_ACTION); 
            this.UPDATE_PAGE_HISTORIES()  
        },
        watch: { 
            us(v){ 
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            SELECT_TABLE_ACTION_MODEL(value){
                if (value) { 
                    this.SELECT_TABLE_ACTION({action:'model',value:value})  
                }
            },
            input(currentValue) { 
                this.$emit('input', {
                    ...currentValue,
                    filter_date:this.input_filter_Date,
                    current_table_tab:this.currentTableTab, 
                })
            },
            input_selected_items(value) { 
                this.$emit('ON_ITEM_SELECTED', value)
                this.input={
                    ...this.input,
                    item_selected:value
                }
            },
            show_select(value) { 
                this.$emit('ON_SHOW_SELECT', value)
                this.input={
                    ...this.input,
                    show_select:value
                }
            },
            single_select(value) { 
                this.$emit('ON_SINGLE_SELECT', value)
                this.input={
                    ...this.input,
                    single_select:value
                }
            },
            dialog_edit_item(currentValue) {
                this.input={
                    ...this.input,
                    item_editing:currentValue
                }
            },
            input_filters(value) {
                console.log(value,'v..............,,');
                this.input={
                    ...this.input,
                    input_filters:value,
                    filter_date:this.input_filter_Date,
                    current_table_tab:this.currentTableTab,
                } 
            },
            input_filter_Date(currentValue) {
                this.input={
                    ...this.input,
                    filter_date:currentValue,
                    current_table_tab:this.currentTableTab,
                    input_filters:this.input_filters,
                } 
            },
            currentTableTab(currentValue) { 
                this.input={
                    ...this.input,
                    filter_date:this.input_filter_Date,
                    current_table_tab:currentValue,
                    input_filters:this.input_filters,
                } 
            },
            HEADER_ITEMS(value){
                // console.log('cccccccc');
                // this.PAGE_LOAD = false
                // setTimeout(() => {
                //    this.PAGE_LOAD = true 
                // }, 500);
            },  
        },
    }
</script>