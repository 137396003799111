<template>
    <div>   
        <v-divider></v-divider>
        <mbs-page-tab 
            :items="MY_LINKS"/> 
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider class="mb-2"></v-divider>
             
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <v-card > 
                        <mbs-item-manager
                            :table_title="'My History'"
                            table_title_icon="mdi-clipboard-text-clock"
                            :add_vertical_lines="true"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :filters="FILTERS"
                            :row_click="true"
                            :show_select="true"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS" 
                            :data_items="SortedBothSaleOrders"   
                            :select_items="DataSource"   
                            :is_joined="true"  
                            :path_item="SALES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"  

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.SALE_ORDERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.SALES.names,
                NAME_ITEM:DATA.ITEMS.SALES.name,
                VALUE_ITEMS:DATA.ITEMS.SALE_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.SALE_ORDERS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                table_actions:[  
                    {type:"btn",action:"sales_return",color:"secondary", outlined:false,  text:"Sales Return"},  
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'total_quantity'},
                    // {name:'Total Cost Price',value:'total_cost_price',show_value:'total_cost_price_',money:true},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ], 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
             
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sale",1,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],  
                DayShiftsSales: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS_SALES.values).toUpperCase()], 
                JoinedDayShiftsSales: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS_SALES.values).toUpperCase()], 
            
                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                Departments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                JoinedDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                
            
            }), 
            PaymentMethodsData(){ 
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods = this.PaymentMethods
                let JoinedPaymentMethods = this.JoinedPaymentMethods
                let items = JoinedPaymentMethods?JoinedPaymentMethods:PaymentMethods 
                if(!items){return DefaultPaymentMethod}
                let list = [...DefaultPaymentMethod,...items]  
                return list
            },  
            DepartmentsData(){ 
                let Departments = this.Departments
                let JoinedDepartments = this.JoinedDepartments
                let items = JoinedDepartments?JoinedDepartments:Departments 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            },  
            SalesOrdersData(){ 
                let SalesOrders = this.SalesOrders
                let JoinedSalesOrders = this.JoinedSalesOrders
                let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list
            }, 
            OutletsData(){ 
                let Outlets = this.Outlets
                let JoinedOutlets = this.JoinedOutlets
                let items = JoinedOutlets?JoinedOutlets:Outlets 
                return items
            }, 
            DayShiftsData(){ 
                let DayShifts = this.DayShifts
                let JoinedDayShifts = this.JoinedDayShifts
                let items = JoinedDayShifts?JoinedDayShifts:DayShifts  
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },  
            CashupsData(){ 
                let Cashups = this.Cashups
                let JoinedCashups = this.JoinedCashups
                let items = JoinedCashups?JoinedCashups:Cashups 
                return items
            }, 
            DayShiftsCashups(){
                let days = this.DayShiftsData
                if(!days){return null}
                let cashups = []
                days.forEach(element => {
                    if (element.joined_cashups) {
                        cashups = [...cashups,...element.joined_cashups]
                    }
                }); 
                if(!cashups){return null} 
                let list = [...cashups]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))    
                return list 
            },
            Users(){
                let items  =this.$store.getters.getCompanyUsers
                let list = items?[...items]:null
                if(!list){return null}
                list.sort(this.MBS.actions.dynamicSort('created_at',false))
                return list
            }, 
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            DataSource(){ 
                let Customers = this.Customers
                let PaymentMethodsData = this.PaymentMethodsData
                let Departments = this.DepartmentsData
                let Outlets = this.OutletsData
                let DayShifts = this.DayShiftsData
                let Cashups = this.DayShiftsCashups
                let SalesOrders = this.SalesOrdersData
                let Tellers = this.JoinedUsers 
                return { 
                    Customers:Customers,
                    PaymentMethods:PaymentMethodsData,
                    Departments:Departments,
                    Outlets:Outlets,
                    DayShifts:DayShifts,
                    Cashups:Cashups,
                    SalesOrders:SalesOrders,
                    Tellers:Tellers,
                }
            },
            FILTERS(){ 
                return [  
                    {
                        type:"autocomplete",value:"payment_method_name",prepend_inner_icon:'mdi-account-cash',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Payment",
                        select:{
                            name:'PaymentMethods',
                            value:'name',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"receipt_number",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Receipt Number",
                        select:{
                            name:'SalesOrders',
                            value:'receipt_number',
                            text:'receipt_number'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"created_uid",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Teller Name",
                        select:{
                            name:'Tellers',
                            value:'uid',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"customer_key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Customer",
                        select:{
                            name:'Customers',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"cashup_key",barcode:true,prepend_inner_icon:'mdi-account-cash-outline',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Cashup",
                        select:{
                            name:'Cashups',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-account-cash-outline',
                                tile:true
                            },
                            item_title:['till_name'],
                            item_subtitle:['teller_name','key','local_created_at_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"day_shift_key",barcode:true,prepend_inner_icon:'mdi-network-pos',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Day Shift",
                        select:{
                            name:'DayShifts',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-network-pos',
                                tile:true
                            },
                            item_title:['outlet_name'],
                            item_subtitle:['teller_name','operating_date_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"outlet_code",barcode:true,prepend_inner_icon:'mdi-sitemap',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Outlet",
                        select:{
                            name:'Outlets',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    },    
                ]
            }, 
            HEADER_ITEMS(){
                return [ 
                {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    {id:0,name:"Payment",value:"payment_method_name",show:true},     
                    {id:0,name:"S. Key",value:'day_shift_key',show:false},   
                    {id:0,name:"C. Key",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number_",align:"right",divider:true,show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:false},         
                    {id:0,name:"Till",value:"till_name",show:true},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },  
            DataItems(){ 
                let us = this.us
                let uid = us?us.uid:null 
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders 
                if(!items){return null}
                let mys = items.filter(sale=>{
                    return sale.created_uid == uid
                }) 
                let list = [...mys]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
            DayShiftsSalesData(){
                let DayShiftsSales = this.DayShiftsSales
                let JoinedDayShiftsSales = this.JoinedDayShiftsSales
                let items = JoinedDayShiftsSales?JoinedDayShiftsSales:DayShiftsSales
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list 
            },
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup  
                return localCashup
            },
            JoinedLocalCashup(){   
                let localCashup = this.LocalCashup
                let joined = this.MBS.actions.JOIN_CASHUP(localCashup)  
                return joined
            },
            SalesOrders(){
                let DayShiftsData = this.DayShiftsData
                let DayShiftsSalesData = this.DayShiftsSalesData

                let orders = []
                if (DayShiftsData) {
                    for (const day of DayShiftsData) {
                        let sales = day.sale  
                    }
                }
                if (DayShiftsSalesData) {
                    for (const sales of DayShiftsSalesData) {
                        if (sales) {
                            orders = [...orders,sales]
                        } 
                    }
                }
                console.log(orders,'orders');
                return orders 
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataItems
                let localOrders = this.AllLocalSaleOrdersData 
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                }); 
                return both
            },
            SortedBothSaleOrders(){
                let items = this.BothSaleOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false)) 
                console.log(list,'.....................****');
                return list
            }

        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") { 
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    console.log(item,'...');
                    if (action == "view_order") {
                        let account_type = item?item.account_type:null
                        let receipt_key = item?item.key:null
                        if (account_type === "on_account") {
                            this.MBS.actions.go("/sales-invoice/"+receipt_key) 
                        } else {
                            this.MBS.actions.go("/sales-receipt/"+receipt_key) 
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
