import Vue from 'vue';  
import router from './../router';
import { store } from '../store';
import {Store, mapState} from "vuex" 
import {EventBus} from '../plugins/eventBus'; 
import {TextBus} from '../plugins/textBus'; 
import {DateBus} from '../plugins/dateBus'; 
import DATA from '../plugins/DATA'     
import formatNumber  from '../plugins/formatNumber'     
const toNumber=formatNumber.toNumber
const NAME = "ACTION-BUS"

export const ActionBus = new Vue({
    data(){
        return{
            imgLazy:require(`@/assets/system/no-image.png`), 
            scrolled:0, 
            forceLogout:false, 
            INPUT_TEXT : '',
            INPUT_NEW_TEXT : true,
            PROMISE_ON_QR_SCAN : null,
            localCashup:null,
            allLocalCashups:null,
            fullscreen:false,
        }
    },
    computed:{ 
        app(){
            return store.getters.getApp
        }, 

        // ...mapState({
        //     loading:state=>state.load.loading,
        //     processes:state=>state.load.processes,
        //     responses:state=>state.load.responses,
        // }),
        // ...mapState({
        //     us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
        //     ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
        //     SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
        // }),
        // ...mapState({ 
            // LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()], 
            // LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            // JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
        // }), 

        us(){
            let returnUser = store.getters.getUser
            return returnUser
        },  
        ud(){
            let returnUser = store.getters.getUserDetails
            return returnUser
        }, 
        loading(){ 
            const loading = store.getters.getLoading
            return loading
        },
        processes(){ 
            const process = store.getters.getProcesses
            return process
        }, 
        responses(){ 
            const re = store.getters.getResponses
            return re
        }, 
        permissions(){ 
            const re = store.getters.getPermissions
            return re
        }, 
        publicPermissions(){ 
            const permissions = this.permissions
            if(!permissions){return null}
            let filtered = permissions.filter(permission=>
                this.TEXT_UP0(this.TEXT_SPACE0(permission.access_type)) == this.TEXT_SPACE0('public')||
                this.TEXT_UP0(this.TEXT_SPACE0(permission.access_type)) == this.TEXT_SPACE0('public user') 
            )
            return filtered
        }, 
        billingPlans(){ 
            const re = store.state.items[(DATA.ITEMS.BILLING_PLANS.values).toUpperCase()]
            return re
        }, 
        billingPlanFeatures(){ 
            const re = store.state.items[(DATA.ITEMS.BILLING_PLAN_FEATURES.values).toUpperCase()]
            return re
        }, 
        currentBillingPlan(){ 
            const re = store.state.items[(DATA.ITEMS.CURRENT_BILLING_PLANS.values).toUpperCase()]
            return re
        }, 
        currentPlanDetails(){ 
            const features = this.billingPlanFeatures
            const plans = this.billingPlans
            const current = this.currentBillingPlan
            let details = current?current.plan_details:null
            let key = details?details.key:null
            let code = details?details.code:null
            let data = null
            if (key&&plans) {
                data = plans.find(plan=>plan.key==key)
            }else if (code&&plans) {
                data = plans.find(plan=>plan.code==code)
            }
 
            if (data) {
                return this.JOIN_BILLING_PLAN({...details,...data})
            } 
            return this.JOIN_BILLING_PLAN(details) 
        }, 
        currentPlanFeatures(){ 
            const all_features = this.billingPlanFeatures
            const plan = this.currentPlanDetails
            const features = plan?plan.billing_plan_features:null
            const added = plan?plan.added_plan_feature:null
            let allFeatures = [] 
            if (features) {
                features.forEach((p,index) => {
                    let permission = store.getters.getBillingPlanFeatureByCode(p.billing_plan_feature_code)   
                    if (permission) {
                      allFeatures.push(permission)   
                    }
                }); 
            }
            if (added) {
                added.forEach((p,index) => {
                    let permission = store.getters.getBillingPlanFeatureByCode(p.billing_plan_feature_code)   
                    if (permission) {
                      allFeatures.push(permission)   
                    }
                }); 
            } 
            return this.SIZE(allFeatures)?allFeatures:null
        }, 
        currentPlanPermissions(){ 
            const features = this.currentPlanFeatures  
            const public_permissions = this.publicPermissions  
            let allPermissions = [] 
            if (features) { 
                features.forEach((feature,index) => {
                    const permissions = feature?feature.permissions:null
                    permissions.forEach(p => {
                        let permission = store.getters.getUserPermissionByCode(p.permission_code)   
                        if (permission) {   
                            let index = allPermissions.findIndex(pm=>pm.code == permission.code)
                            if (index==-1) {
                                allPermissions.push(permission)    
                            }
                        } 
                    });
                }); 
            }
            if (public_permissions) {
                allPermissions.push(...public_permissions)
            }
            return this.SIZE(allPermissions)?allPermissions:null
        },  
        CurrentTill(){ 
            const cashup = this.LocalCashup  
            const till_key = cashup?.till_key
            const till = store.getters.getTillByKey(till_key) 
            return till
        },  
        CurrentOutlet(){ 
            const till = this.CurrentTill
            const outlet_code = till?.outlet_code
            const outlet = store.getters.getStockOutletByCode(outlet_code) 
            return outlet
        },  

        Fullscreen(){  
            return this.fullscreen
        },  
        CompanySettings(){ 
            const re = store.state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
            return re
        },
        CompanyUsers(){ 
            const re = store.state.items[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()]
            return re
        },  
        busTickets(){ 
            const re = store.getters.getBusTickets
            return re
        }, 
        deliveringSteps(){ 
            const re = store.getters.getDeliveringSteps
            return re
        },  
        selectedCompany(){ 
            const re = store.getters.getSelectedCompany
            return re
        },  
        WEB_LINKS(){
            let selectedCompany = this.selectedCompany
            let company_key = selectedCompany?selectedCompany.key:null
            let links = store.getters.getWebLinks
            if(!links){return null}
            let accessLink = links.filter(link=>{ 
                if (!company_key&&link.company_link) {
                    return false
                }
                let access = this.GET_ACCESS({
                    page_link:link.company_link?"/:id"+link.link:link.link
                }) 
                return access
            })
            return accessLink
        }, 
        LINK_DETAILS(){
            let web_links = this.WEB_LINKS
            if(!web_links){return null}
            let positions = [] 
            web_links.forEach(element => { 
                let position = element.important
                let pIndex = positions.indexOf(position)
                if (pIndex == -1) {
                    if (position>=1) {
                        positions.push(position) 
                    }
                } 
            });  
            positions.sort(function(a, b){return a - b})
            let first_position = positions[0]
            let first_links = web_links.filter(link=>{
                return link.important == first_position
            })
            return {
                size:this.SIZE(web_links),
                positions:positions,
                first_position:first_position,
                first_links:first_links
            }
        },
        LINKS(){
            let selectedCompany = this.selectedCompany
            let company_key = selectedCompany?selectedCompany.key:null
            let LINK_DETAILS = this.LINK_DETAILS
            let size = LINK_DETAILS?LINK_DETAILS.size:null
            let positions = LINK_DETAILS?LINK_DETAILS.positions:null
            let first_position = LINK_DETAILS?LINK_DETAILS.first_position:null
            let first_links = LINK_DETAILS?LINK_DETAILS.first_links:null
            if(!first_links){return null}

            let subLinks = (item)=>{
                if(!item){return null}
                let position = item.important
                let link = item.link
                let route = link?link.split("/")[position]:null
                let sub_links = this.FIND_SUB_LINKS(route,position)
                return sub_links
            }
            let addSubLinks = (link)=>{
                if(!link){return null} 
                let sub_links = subLinks(link)
                let sub_links_size = this.SIZE(sub_links) 
                if (sub_links_size>0) {
                    let new_sub_links=[]
                    sub_links.forEach(sub_link => {
                        let new_sub_link = addSubLinks(sub_link)  
                        if (new_sub_link) {
                            new_sub_links.push(new_sub_link) 
                        }else{
                            // console.log('err n sub_link.:',new_sub_link);
                        }
                    });
                    link.sub_links = new_sub_links
                    link.sub_links_size = sub_links_size 
                } 
                
                return {
                    ...link,
                    link:company_key?
                        link.company_link?"/"+company_key+link.link :link.link
                            :link.link,
                    link2:link.link2?company_key?
                        link.company_link?"/"+company_key+link.link2 :link.link2
                            :link.link2:null
                }
            }

            let newLinks = []
            first_links.forEach(link => {
                let newLink = addSubLinks(link)
                if (newLink) {
                    newLinks.push(newLink) 
                }else{ 
                }
            });  
            return newLinks 
        }, 
        FIND_SUB_LINKS(){
          return (route,position,company_link)=>{ 
            let selectedCompany = this.selectedCompany
            let company_key = selectedCompany?selectedCompany.key:null
            let web_links = this.WEB_LINKS
            if(!web_links){return null}
             
            let sub_links = web_links.filter(sub_link=>{
              let link = sub_link.link 
              if (link) {
                let path_items = link.split("/")
                let path_items_size = path_items.length 
                if (path_items_size == (position+2)) {
                  return path_items[position] == route
                }
              }
              return false 
            }) 
            if (company_link && sub_links && company_key) {
                let newLinks = []
                sub_links.forEach(element => {
                    newLinks.push({
                        ...element,
                        link:element.company_link?"/"+company_key+element.link:element.link,
                        link2:element.link2?element.company_link?"/"+company_key+element.link2:element.link2:null
                    })
                });
                return newLinks
            }
            return sub_links
          }
        },
        COMPANY_LINK(){
            return (link,isCompany,company_key)=>{ 
                if (!company_key) {
                    let selectedCompany = this.selectedCompany
                    company_key = selectedCompany?selectedCompany.key:null 
                }
                let index1 = link?link.includes("xxxx"):null
                let index2 = link?link.includes("XXXX"):null
                if(!link){return null}
                if (!isCompany) {return link}
                if (!company_key) {return null} 

                if (index1) {
                    const new_link = link.replace("xxxx",company_key)
                    return new_link  
                }else if (index2) {
                    const new_link = link.replace("XXXX",company_key)
                    return new_link  
                }else{
                    const new_link = "/"+company_key+link 
                    return new_link 
                }
            }
        },  

        //
        items_state(){
            return store.getters.getItemsState
        },
        joined_items_state(){
            return store.getters.getJoinedItemsState
        },
        LocalSaleOrders(){
            return store.getters.getLocalSaleOrders
        },
        JoinedLocalSaleOrders(){
            return store.getters.getJoinedLocalSAleOrders
        },
        AllLocalSaleOrdersData(){  
            const LocalSaleOrders = this.LocalSaleOrders
            const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
            let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
            if(!items){return null} 
            let list = [...items]
            list.sort(this.dynamicSort("local_created_at",true)) 
            return list
        }, 
        UnUploadedLocalSoldItems(){  
            const orders = this.AllLocalSaleOrdersData
            let items = []
            if(!orders){return null}
            orders.forEach(order => {
                let order_items = order.items
                if (order_items && !order.uploaded) {
                    order_items.forEach(item => {
                        let index = items.findIndex(it=>{
                            return it.item_key  == item.item_key
                        }) 
                        let sold_quantity = this.toNumber(item.quantity)
                        if (index == -1) { 
                            items.push({
                                ...item, 
                                sold_quantity:sold_quantity, 
                            })
                        } else {
                            items[index].sold_quantity = items[index].sold_quantity+sold_quantity 
                        }   
                    }); 
                } 
            }); 
            return items
        }, 
        getSoldItem(){  
            const sold_items = this.UnUploadedLocalSoldItems  
            return (key)=>{ 
                if(!sold_items){return null}
                return sold_items.find(item=>item.item_key == key) 
            }
        }, 
        
        //CASHUP
        LocalCashups(){
            return store.getters.getLocalCashups
        },
        LocalCashup(){  
            let cashups = this.LocalCashups
            let local_cashup = cashups?cashups.find(item=>{
                return !item.closed
            }):null 
            return local_cashup
        },  
        //ALL EXPENSES
        Expenses(){ 
            const expenses = store.getters.getExpenses
            return expenses
        },
        ExpenseAccounts(){ 
            const expenses = store.getters.getExpenseAccounts
            return expenses
        },
        ArchivedExpenses(){ 
            const expense = this.Expenses
            if(!expense){return null}
            let filtered_items = expense.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedExpenses(){ 
            const expense = this.Expenses
            if(!expense){return null}
            let filtered_items = expense.filter(item=>{
                return !item.archive 
            })
            return filtered_items
        }, 
        ExpensesByAccountCode(){
            let expenses = this.Expenses 
            let archivedExpenses = this.ArchivedExpenses 
            let unArchivedExpenses = this.UnArchivedExpenses 
            return payload=>{ 
                let items = null
                let code = payload?payload.code:null 
                let archive = payload?payload.archive:null 
                let all = payload?payload.all:null 
                if (all) {
                    items = expenses
                }else if (archive) {
                    items = archivedExpenses
                } else {
                    items = unArchivedExpenses
                }
                if (!items || !code) {return null}
                let filtered_items = items.filter(item=>{
                    return item.expense_account_code == code
                })
                return filtered_items
            }
        },
        ExpensesByCategoryCode(){
            let accounts = this.ExpenseAccounts
            let expenses = this.Expenses 
            let archivedExpenses = this.ArchivedExpenses 
            let unArchivedExpenses = this.UnArchivedExpenses 
            return payload=>{ 
                let items = null
                let code = payload?payload.code:null 
                let archive = payload?payload.archive:null 
                let all = payload?payload.all:null 
                if (all) {
                    items = expenses
                }else if (archive) {
                    items = archivedExpenses
                } else {
                    items = unArchivedExpenses
                }
                if (!accounts ||!items || !code ) {return null} 

                let category_accounts = accounts.filter(item=>{
                    return item.expense_category_code == code
                })
                
                if(!category_accounts){return null}
                let category_expenses = []
                category_accounts.forEach(element => {
                    let filtered_items = items.filter(item=>{
                        return item.expense_account_code == element.code
                    })
                    category_expenses.push(...filtered_items)
                });
                return category_expenses
            }
        },
        
        //ALL PAYMENTS
        Payments(){ 
            const payments = store.getters.getPayments
            return payments
        },
        PaymentsByTransactionKey(){
            let payments = this.Payments 
            return transaction_key=>{ 
                if (!payments) {return null}
                let filtered_items = payments.filter(item=>{
                    return item.transaction_key == transaction_key
                })
                return filtered_items
            }
        },
        
        //ALL STOCK ORDERS
        StockOrders(){ 
            const orders = store.getters.getStockOrders
            return orders
        },
        ArchivedStockOrders(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedStockOrders(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        StockOrderItems(){ 
            let items = null
            let orders = this.StockOrders
            let archived_orders = this.ArchivedStockOrders
            let un_archived_orders = this.UnArchivedStockOrders
            return (payload)=>{
                if (!payload) {
                    items = orders
                    // console.log(items,payload,'....1111');
                }else if (payload.all) {
                    // console.log(items,payload,'....222');
                    items = orders
                }else if (payload.order) {
                    // console.log(items,payload,'....333');
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders
                    // console.log(items,payload,'....444');
                }else{
                    items = un_archived_orders
                    // console.log(items,payload,'....5555');
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            })
                            
                        });
                    }
                });
                return order_items
            }
        }, 
        StockOrderItemsByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.StockOrderItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })
                return filtered_items
            }
        }, 
        
        //RECEIVED STOCK
        ReceivedStocks(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.order_type == DATA.ITEMS.STOCK_RECEIVES.value
            })
            return filtered_items
        },
        ArchivedReceivedStocks(){ 
            const orders = this.ReceivedStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedReceivedStocks(){ 
            const orders = this.ReceivedStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        ReceivedStocksItems(){ 
            let items = null
            let orders = this.ReceivedStocks
            let archived_orders = this.ArchivedReceivedStocks
            let un_archived_orders = this.UnArchivedReceivedStocks
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            })
                            
                        });
                    }
                });
                return order_items
            }
        }, 
        ReceivedStockItemsByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.ReceivedStocksItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })
                return filtered_items
            }
        },  
        
        //RETURNED STOCK
        ReturnedStocks(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.order_type == DATA.ITEMS.STOCKS_RETURN.value
            })
            return filtered_items
        },
        ArchivedReturnedStocks(){ 
            const orders = this.ReturnedStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedReturnedStocks(){ 
            const orders = this.ReturnedStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        ReturnedStocksItems(){ 
            let items = null
            let orders = this.ReturnedStocks
            let archived_orders = this.ArchivedReturnedStocks
            let un_archived_orders = this.UnArchivedReturnedStocks
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            }) 
                        });
                    }
                });
                return order_items
            }
        }, 
        ReturnedStockItemsByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.ReturnedStocksItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })
                return filtered_items
            }
        },  
        
        //Adjust STOCK
        AdjustStocks(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.order_type == DATA.ITEMS.STOCK_ADJUSTMENTS.value
            })
            return filtered_items
        },
        ArchivedAdjustStocks(){ 
            const orders = this.AdjustStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedAdjustStocks(){ 
            const orders = this.AdjustStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        AdjustStocksItems(){ 
            let items = null
            let orders = this.AdjustStocks
            let archived_orders = this.ArchivedAdjustStocks
            let un_archived_orders = this.UnArchivedAdjustStocks
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            })
                            
                        });
                    }
                });
                return order_items
            }
        }, 
        AdjustStockItemsByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.AdjustStocksItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })
                return filtered_items
            }
        }, 
        
        //Opening STOCK
        OpeningStocks(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.order_type == DATA.ITEMS.OPENING_STOCK.value
            })
            return filtered_items
        },
        ArchivedOpeningStocks(){ 
            const orders = this.OpeningStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedOpeningStocks(){ 
            const orders = this.OpeningStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        OpeningStocksItems(){ 
            let items = null
            let orders = this.OpeningStocks
            let archived_orders = this.ArchivedOpeningStocks
            let un_archived_orders = this.UnArchivedOpeningStocks
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            })
                            
                        });
                    }
                });
                return order_items
            }
        }, 
        OpeningStockItemsByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.OpeningStocksItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })
                return filtered_items
            }
        }, 
        
        //Return STOCK
        ReturnSoldStocks(){ 
            const orders = this.StockOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.order_type == DATA.ITEMS.SALES_RETURN.value
            })
            return filtered_items
        },
        ArchivedReturnSoldStocks(){ 
            const orders = this.ReturnSoldStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedReturnSoldStocks(){ 
            const orders = this.ReturnSoldStocks
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        ReturnSoldStocksItems(){ 
            let items = null
            let orders = this.ReturnSoldStocks
            let archived_orders = this.ArchivedReturnSoldStocks
            let un_archived_orders = this.UnArchivedReturnSoldStocks
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            })
                            
                        });
                    }
                });
                return order_items
            }
        }, 
        ReturnSoldStockItemsByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.ReturnSoldStocksItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })
                return filtered_items
            }
        },  

        //SALES
        sale_orders(){ 
            const orders = store.getters.getSaleOrders  
            return orders
        },
        ArchivedSaleOrders(){ 
            const orders = this.sale_orders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnArchivedSaleOrders(){ 
            const orders = this.sale_orders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        SaleOrderItems(){ 
            let items = null
            let orders = this.sale_orders
            let archived_orders = this.ArchivedSaleOrders
            let un_archived_orders = this.UnArchivedSaleOrders
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key
                            })
                            
                        });
                    }
                });  
                return order_items
            }
        }, 
        SaleOrderItemByItemKey(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.SaleOrderItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.item_key == payload.item_key
                })  
                return filtered_items
            }
        },

        //RECEIVABLES SALES
        ReceivableSaleOrders(){ 
            const orders = this.sale_orders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.account_type == "on_account"
            })
            return filtered_items
        }, 
        ArchivedReceivableSaleOrders(){ 
            const orders = this.ReceivableSaleOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return item.archive == true
            })
            return filtered_items
        }, 
        UnReceivableSaleOrders(){ 
            const orders = this.ReceivableSaleOrders
            if(!orders){return null}
            let filtered_items = orders.filter(item=>{
                return !item.archive
            })
            return filtered_items
        }, 
        ReceivableSaleOrderItems(){ 
            let items = null
            let orders = this.ReceivableSaleOrders
            let archived_orders = this.ArchivedReceivableSaleOrders
            let un_archived_orders = this.UnReceivableSaleOrders
            return (payload)=>{
                if (!payload) {
                    items = orders 
                }else if (payload.all) { 
                    items = orders
                }else if (payload.order) { 
                    items = payload.order
                }else if (payload.archive) {
                    items = archived_orders 
                }else{
                    items = un_archived_orders 
                }
                if(!items){return null} 
                let order_items = []
                items.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            order_items.push({
                                ...item,
                                order_key:element.key,
                                receivable_code:element.receivable_code
                            })
                            
                        });
                    }
                }); 
                return order_items
            }
        }, 
        ReceivableSaleOrderItemsByReceivableCode(){ 
            return (payload)=>{
                if(!payload){return null}
                let items = this.ReceivableSaleOrderItems(payload) 
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return item.receivable_code == payload.receivable_code
                }) 
                return filtered_items
            }
        },
        
    },
    created(){
        EventBus.$on('DIALOG_YES',this.DIALOG_YES) 
        this.SCREEN_FULL_MODE()
        setTimeout(() => {
             
        }, 1500);
    },
    mounted(){ 
 
    },
    methods:{ 
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())  
            } catch (error) {
                this.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        error(error){
            try {
                if(!error){return}
                let err = error.error?error.error:'...'
                let from = error.from?error.from:'...'
                let page = error.page?error.page:'...'
                store.dispatch('ERROR',{
                    error:err,
                    from:from,
                    page:page
                }) 
            } catch (error) {
                this.error({
                    error:error,
                    from:'error',
                    page:NAME, 
                })
            }
        }, 

        SIZE(item){
            try {
                if (Array.isArray(item)) {
                    return item.length;
                } else if (typeof item === 'object' && item !== null) {
                    return Object.keys(item).length;
                } else if (typeof item === 'string' || typeof item === 'number') {
                    return item.length;
                } else {
                    // Handle other types if needed, or throw an error
                    return 0; // Or handle it differently
                }
            } catch (error) { 
                this.error({
                    error:error,
                    from:'SIZE',
                    page:NAME, 
                })
                return 0
            }
        }, 
        OBJECT_ITEMS(obj){
            try {
                if(!obj) {return null}
                if (typeof obj === 'object' && obj !== null) {
                    let newData = []
                    let i = 1
                    for (const key in obj) {
                        let item = obj[key]
                        newData.push({
                            i:i,
                            key: key,
                            ...item
                        })
                        i++
                    }
                   return newData
                }
                return null 
            } catch (error) {
                this.error({
                    error:error,
                    from:'OBJECT_ITEMS',
                    page:NAME, 
                }) 
                return null 
            }
        },
        DEFINED_ONLY(obj){
            try {
                if(!obj) {return null}
                if (typeof obj === 'object' && obj !== null) {
                    let newObj = [] 
                    for (const key in obj) {
                        let item = obj[key]
                        if (item) { 
                            newObj[key]=item 
                        }
                    }
                   return newObj
                }
                return null 
            } catch (error) {
                this.error({
                    error:error,
                    from:'DEFINED_ONLY',
                    page:NAME, 
                }) 
                return null 
            }
        }, 
        
        //CONVERT 
        CREATE_ARRAY_DATA (payload) {
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"CREATE_ARRAY_DATA",
                    page:NAME,
                    payload:payload
                } 
                try { 
                    let data = payload?payload.data:null
                    let headers = payload?payload.headers:null
                    let attach_items = payload?payload.attach_items:null
                    let attach_value = payload?payload.attach_value:null

                    let add_uid = payload?payload.add_uid:true
                    let add_created_date = payload?payload.add_created_date:true
                    let add_number = payload?payload.add_number:true 

                    add_uid = add_uid==undefined||add_uid==null?true:add_uid
                    add_number = add_number==undefined||add_number==null?true:add_number
                    add_created_date = add_created_date==undefined||add_created_date==null?true:add_created_date 


                    if(!data){
                        reject({success:false,error:'no data',...response})
                    } 
                    setTimeout(() => {
                        let data_item = []
                        let only_attached_data_item = []
                        let not_found_data_item = []
                        let number = 0 
                        let findAttachment = value=>attach_items?attach_items.find(item=>{
                            return  this.NUMBER_SAME_LENGTH(item[attach_value]) == this.NUMBER_SAME_LENGTH(value)
                        }):null

                        for (const item of data) {
                            let dt = new Date
                            let date = dt.toISOString()
                            number++
                            let item_array = {}
                            if (add_uid) {
                                item_array.uid = this.us.uid
                            }if (add_number) {
                                item_array.no = number
                            }if (add_created_date) {
                                item_array.created_at = date
                            } 
    
                            if (!headers) {
                                let new_item = {
                                    ...item,
                                    ...item_array, 
                                }
                                data_item.push(new_item) 
                                let find_value = new_item[attach_value]
                                let attachment = findAttachment(find_value)
                                if (attachment) {
                                    only_attached_data_item.push({
                                        ...attachment,
                                        ...new_item
                                    }) 
                                }else{
                                    not_found_data_item.push(new_item)
                                }
                            }else{
                                //CREATE OBJECT KEYS
                                let keys = Object.keys(item)
                                let caps = this.TEXT_UP
                                let findKey = (value)=>keys?keys.find(key=>caps(key)==caps(value)):null
                                //CREATE HEADER DATA 
                                let header_data = {}
                                headers.forEach(header => {
                                    let key = findKey(header.value)
                                    if (key&&header.show) {
                                        let key_data = item[key]
                                        if (header.is_number) {
                                            header_data[header.rename_value?header.rename_value:header.value] = toNumber(key_data?key_data:null )
                                        }else{
                                            header_data[header.rename_value?header.rename_value:header.value] = key_data?key_data:null 
                                        }
                                    } 
                                }); 
                                //ADD
                                let new_item = {
                                    ...header_data,
                                    ...item_array, 
                                }
                                data_item.push(new_item) 
                                let find_value = new_item[attach_value]
                                let attachment = findAttachment(find_value)
                                if (attachment) {
                                    only_attached_data_item.push({
                                        ...attachment,
                                        ...new_item
                                    }) 
                                }else{
                                    not_found_data_item.push(new_item)
                                }
                            } 
                        } 
                        resolve({
                            success:true,
                            data:data_item,
                            only_attached_data:only_attached_data_item,
                            not_found_data:not_found_data_item,

                        }) 
                    }, 1);
                } catch (error) {
                    reject({
                        error:error,
                        success:false
                    })
                } 

            })
        },
        CONVERT_ARRAY_TO_OBJECT(items){
            try {
                if(!items) {return null} 
                if (typeof items === 'object' && items !== null) {
                    let newData = [] 
                    // items.forEach(element => {
                    //     newData[element.key] = element

                    // });
                    const res = items.reduce((acc,curr)=> (acc[curr.key]=curr,acc),{});
                    return res
                }
                return null 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CONVERT_ARRAY_TO_OBJECT',
                    page:NAME, 
                }) 
                return null 
            }
        }, 
        CONVERT_OBJECT_TO_JSON(item_object){
            try {
                return new Promise((resolve, reject)=>{
                    setTimeout(() => {
                        if(!item_object){
                            reject({
                                error:'No Items',
                                from:'CONVERT_OBJECT_TO_JSON',
                                page:NAME, 
                                response:'not success'
                            })
                        }
                        let item_type = typeof item_object
                        if(item_type != 'object'){
                            reject({
                                error:'wrong type',
                                from:'CONVERT_OBJECT_TO_JSON',
                                page:NAME, 
                                response:'not success'
                            })
                        } 
                        let item_json = JSON.stringify(item_object)
                        resolve({
                            response:'success',
                            data:item_json
                        }) 
                    }, 0);
                }) 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CONVERT_OBJECT_TO_JSON',
                    page:NAME, 
                })
                return new Promise((resolve,reject)=>{
                    reject({
                        error:error,
                        from:'CONVERT_OBJECT_TO_JSON',
                        page:NAME, 
                        response:'not success'
                    })  
                })
            }
        }, 
        toNumber(stNumber){
            try { 
                return toNumber(stNumber)
            } catch (error) { 
                this.error({
                    error:{
                        error:error,
                        stNumber:stNumber
                    },
                    from:'toNumber',
                    page:NAME, 
                })
            }
        },
        money(amount, decimalCount = 2, decimal = ".", thousands = ","){
            try {
                amount = this.toNumber(amount) 
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (error) {
                this.error({
                    error:error,
                    from:'money',
                    page:NAME, 
                })
            }
        },
        show4String(text,thousands = " "){
            try {
                let i = this.TEXT_SPACE0(text); 
                let j = (i.length > 4) ? i.length % 4 : 0;
                return  (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{4})(?=\d)/g, "$1" + thousands);
            } catch (error) {
                this.error({
                    error:error,
                    from:'show4String',
                    page:NAME, 
                })
            }
        },
        showCard(key){
            try {
                let i = this.NUMBER_SAME_LENGTH(this.TEXT_SPACE0(key),16);  
                return  this.show4String(i)
            } catch (error) {
                this.error({
                    error:error,
                    from:'showCard',
                    page:NAME, 
                })
            }
        },

        currency(){
            try {
                var number = 123456.789; 
                // request a currency format
                console.log(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number));
                // → 123.456,79 € 
                // the Japanese yen doesn't use a minor unit
                console.log(new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(number));
                // → ￥123,457 
                // limit to three significant digits
                console.log(new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number));
                // → 1,23,000 
            } catch (error) {
                this.error({
                    error:error,
                    from:'currency',
                    page:NAME, 
                }) 
            }
        },
        convertDate(s_date,numbers){ 
            try {
                if (!s_date) {
                return "00/00/0000"
                } 
                const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
                ];
                const montShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];
                var dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                var dayShortNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                
                let date =  new Date(s_date);
                if (numbers) {
                date = new Date(0,0,s_date)
                }
                let newDate = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' +((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' +  date.getFullYear()
    
                let newTime=(date.getHours())+":"+(date.getMinutes())
                return newDate+" "+newTime 
            } catch (error) {
                this.error({
                    error:error,
                    from:'convertDate',
                    page:NAME, 
                })
            }
        },
        convertTime(time){ 
            try {
                if (!time) {
                return "00:00"
                }  
                let date =  new Date(time); 
                let newTime=(date.getHours())+":"+(date.getMinutes())
                return  newTime 
            } catch (error) {
                this.error({
                    error:error,
                    from:'convertTime',
                    page:NAME, 
                }) 
            }
        }, 
        toDateTime(date_string){ 
            try {
                let moment = DateBus.moment
                let date = moment(date_string).format('yyyy/MM/DD HH:mm') 
                return  date 
            } catch (error) {
                this.error({
                    error:error,
                    from:'toDateTime',
                    page:NAME, 
                }) 
            }
        },
        toDate(date_string){ 
            try {
                let moment = DateBus.moment
                let date = moment(date_string).format('yyyy/MM/DD') 
                return  date 
            } catch (error) {
                this.error({
                    error:error,
                    from:'toDate',
                    page:NAME, 
                }) 
            }
        },
        toTime(date_string){ 
            try {
                let moment = DateBus.moment
                let time = moment(date_string).format('HH:mm') 
                return  time 
            } catch (error) {
                this.error({
                    error:error,
                    from:'toTime',
                    page:NAME, 
                }) 
            }
        },
        icon(text){
            try {
                if(!text){return ""}
                let split_text = text.split(/(?=[A-Z])/) 
                let newText = ""
                for (let index = 0; index < split_text.length; index++) {
                    let element = split_text[index]
                    element = this.TEXT_UP0(element)
                    if (index == 0) {
                        newText = element
                    }else{
                        newText = newText+"-"+element
                    }
                } 
                return newText 
            } catch (error) {
                this.error({
                    error:error,
                    from:'icon',
                    page:NAME, 
                }) 
            }
        }, 
        isTimestamp(input) {
             // Coerce to string to handle both string and integer inputs
             const str = input.toString(); 
             // Validate Unix timestamp format (13 digits)
             const unixTimestampRegex = /^\d{13}$/; 
             // Use Date constructor for additional validation and timestamp range check
             const date = new Date(this.toNumber(str)); 
             return unixTimestampRegex.test(str) && !isNaN(date.getTime()) && date.getTime() >= 0;;
        },
        toFixedDecimal(n,fixed) {
            return ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
        },
          
        
        //text
        DECRYPT_ID_QR(code){
            try {
                let SCANNED_NATION_ID = null
                if (code.includes("~~")) {
                    SCANNED_NATION_ID = {
                        id_type:"Nation ID"
                    } 
                    let cardInfo = code.split("~~")
                    let nameData = cardInfo[0]
                    let dateData = cardInfo[1]

                    let nameDataList = nameData.split("~")
                    let dateDataList = dateData.split("~")
                    if (this.SIZE(nameDataList)>6) {
                        SCANNED_NATION_ID.id_number = nameDataList[5]
                        SCANNED_NATION_ID.id_surname = nameDataList[4]
                        SCANNED_NATION_ID.id_names = nameDataList[6]
                    }
                    if (this.SIZE(dateDataList)>2) {
                        SCANNED_NATION_ID.id_gender = dateDataList[0]
                        let moment = DateBus.moment
                        let date_of_birth = moment(dateDataList[1]).format('yyyy-MM-DD')
                        let issued_date = moment(dateDataList[2]).format('yyyy-MM-DD')
                        
                        SCANNED_NATION_ID.id_date_of_birth = date_of_birth
                        SCANNED_NATION_ID.id_issued_date = issued_date
                    }   
                }else if (code.includes("DriverCardBarcode")) { 
                    let JsonOb = JSON.parse(code) 
                    SCANNED_NATION_ID = {
                        id_type:"Driving License"
                    }  
                    SCANNED_NATION_ID.id_number = JsonOb.basicAccessProtection
                } else if (code.includes("EntityCardBarcode")) { 
                    let JsonOb = JSON.parse(code) 
                    SCANNED_NATION_ID = {
                        id_type:"Traffic Register Card"
                    }  
                    SCANNED_NATION_ID.id_number = JsonOb.basicAccessProtection
                }  
                if (SCANNED_NATION_ID) {
                    SCANNED_NATION_ID.id_qr_code = code
                }
                return SCANNED_NATION_ID
            } catch (error) {
                this.error({
                    error:error,
                    from:'DECRYPT_ID_QR',
                    page:NAME, 
                })
            }
        },
        
        //text
        TEXT_UP(text){
            try {
                if (!text) {return null} 
                let type = typeof text 
                if (type != "string"){return null} 
                let tx = text.toUpperCase()
                return tx 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_UP',
                    page:NAME, 
                })
            }
        },
        TEXT_UP1(text){
            try {
                if (!text) {return null} 
                let type = typeof text 
                if (type != "string"){return null} 
                let tx = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
                return tx 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_UP1',
                    page:NAME, 
                })
            }
        },
        TEXT_UP11(text){
            try {
                if (!text) {return null} 
                let type = typeof text 
                if (type != "string"){return null} 
                var splitStr = text.toLowerCase().split(' ');
                for (var i = 0; i < splitStr.length; i++) { 
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                } 
                return splitStr.join(' ');  
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_UP11',
                    page:NAME, 
                })
            }
        },
        TEXT_UP0(text){
            try {
                if (!text) {return null} 
                let type = typeof text 
                if (type != "string"){return null} 
                return text.toLowerCase();   

            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_UP0',
                    page:NAME, 
                })
            }
        },
        TEXT_EQ(text,text2){
            try {
                if (this.TEXT_UP(text)===this.TEXT_UP(text2)) {
                    return true
                } 
                return false  
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_EQ',
                    page:NAME, 
                })
            }
        },
        TEXT_SPLIT_N(text){
            try {
                if(!text){return null}
                return text.split('\n') 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_SPLIT_N',
                    page:NAME, 
                })
            }
        },
        TEXT_SPACE0(text){
            try {
                if(!text){return ''}
                return text.replace(/\s/g,'') 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_SPACE0',
                    page:NAME, 
                }) 
                return ''
            }
        },
        TEXT_SPACE_(text){
            try {
                if(!text){return ''}
                return text.replace(/\s/g,'_') 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TEXT_SPACE0',
                    page:NAME, 
                }) 
                return ''
            }
        },
        //number
        NUMBER_SHOW(number){
            try {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
            } catch (error) {
                this.error({
                    error:error,
                    from:'NUMBER_SHOW',
                    page:NAME, 
                })
            }
        },
        NUMBER_SAME_LENGTH(number,size){ 
            try {
                if (!number) {
                    number = '0'
                }
                return number.toString().padStart(size?size:7, "0")   
            } catch (error) {
                this.error({
                    error:error,
                    from:'NUMBER_SAME_LENGTH',
                    page:NAME, 
                })
            }
        },
        //
        
        //array  
        go(to,params){ 
            try {
                const name = router.app.$route.name
                const path = router.app.$route.path
                const links = router.options.routes
                const query = router.app.$route.query
                if (typeof to  == 'number') {
                    router.go(to)
                }else{
                    if (path===to) {
                        // if (to!='/') {
                        //     this.RELOAD_PAGE()
                        // } 
                    }else{
                        if (params) { 
                            router.push({
                                path:to,
                                query: params
                            })
                        } else {
                            router.push(to)  
                        }
                    } 
                }
 

            } catch (error) {
                this.error({
                    error:error,
                    from:'go',
                    page:NAME, 
                }) 
            }
        },
        goHistory(to){ 
            try {
                router.go(to) 
            } catch (error) {
                this.error({
                    error:error,
                    from:'goHistory',
                    page:NAME, 
                })
            }
        }, 
        pagination(list,page,size){
            try {
                let total = list.length
                let pages = total/size
                let faction = page/pages
                let last = faction*total
                let start = last-size
                //console.log("t:"+total+" f:"+faction+" p:"+pages+" p:"+page+" l:"+last+" s:"+start); 
                return {
                    list:list.slice(start,last),
                    total:total,
                    pages:Math.ceil(pages),
                    start:start,
                    last:last
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'pagination',
                    page:NAME, 
                })
            }
        },
        dynamicSort(property,asc){
            try {
                var sortOrder = 1;
                if(property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return function (a,b) {
                    /* next line works with strings and numbers, 
                    * and you may want to customize it to your needs
                    */
                   let aa = a[property] 
                   let bb = b[property]
                   if (typeof aa === 'string') {
                       aa = aa?aa.replace(/ /g,''):aa 
                   }
                   if (typeof bb === 'string') {
                       bb = bb?bb.replace(/ /g,''):bb 
                   }
    
                    var result = (aa <bb) ? -1 : (aa > bb) ? 1 : 0;
                    if (asc) {
                      return result * sortOrder;
                    }else{
                      return -1 * (result * sortOrder);
                    } 
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'dynamicSort',
                    page:NAME, 
                })
            }
        }, 
        
        restartResponses(){
            try {
                store.dispatch("restartResponses") 
            } catch (error) {
                this.error({
                    error:error,
                    from:'restartResponses',
                    page:NAME, 
                })
            }
        },
        logout(force){
            try {
                let localCashup = this.LocalCashup
                let local_cashup_company_key = localCashup?localCashup.company_key:null
                let company = this.selectedCompany
                let company_key = company?company.company_key:null

                if (!localCashup) {
                    if (force) {
                        this.forceLogout=force
                        store.dispatch('fi_signUserOut')
                        this.go('/')
                    } else {
                        this.dialog({
                            show:true,
                            fixed:true,
                            text:"Are you sure you want to logout",
                            title:"LOGOUT",
                            btnYes:"LOGOUT",
                            btnNo:"CANCEL",
                            action:{
                                code:NAME+":LOGOUT"
                            } 
                        }) 
                    }   
                } else {
                    let mbs_text = TextBus.close_register_before_logout(company)
                    this.dialog({
                        show:true,
                        fixed:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:NAME+":CLOSE-REGISTER"
                        },
                    })
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'logout',
                    page:NAME, 
                })
            }
        },
        //
        header(payload={}){
            try {
                let show = payload.show 
                let color = payload.color
                let height = payload.height
                let type = payload.type
                let images = payload.images
                let time = payload.time
                let action = payload.action
                store.dispatch('setMBS_header',{
                    show : show, 
                    color : color,
                    height: height,
                    type : type,
                    images : images,
                    time : time,
                    action : action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'header',
                    page:NAME, 
                })
            }
            
        },
        nav(payload={}){
            try {
                let show = payload.show 
                let color = payload.color 
                let type = payload.type
                let min = payload.min 
                let action = payload.action
                store.dispatch('setMBS_nav',{
                    show : show, 
                    color : color, 
                    type : type,
                    min : min, 
                    action : action
                })   
            } catch (error) {
                this.error({
                    error:error,
                    from:'nav',
                    page:NAME, 
                })
            }
        },
        toolbar(payload={}){
            try {
                let show = payload.show
                let fixed = payload.fixed
                let flat = payload.flat
                let color = payload.color
                let textColor = payload.textColor
                let height = payload.height
                let title = payload.title 
                let action = payload.action
                store.dispatch('setMBS_toolbar',{
                    show : show,
                    fixed : fixed,
                    flat :flat,
                    color : color,
                    textColor : textColor,
                    height: height,
                    title : title, 
                    action : action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'toolbar',
                    page:NAME, 
                })
            }
            
        },
        progressToolbar(payload={}){
            try {
                let show = payload.show
                let no_subtoolbar = payload.no_subtoolbar
                let color = payload.color
                let background_color = payload.background_color
                let height = payload.height
                let top = payload.top 
                let action = payload.action
                store.dispatch('setMBS_progress_toolbar',{
                    show : show,
                    no_subtoolbar : no_subtoolbar,
                    color : color,
                    background_color : background_color,
                    height :height,
                    top : top, 
                    action : action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'progressToolbar',
                    page:NAME, 
                })
            } 
        },
        subToolbar(payload={}){
            try {
                let show = payload.show
                let fixed = payload.fixed
                let flat = payload.flat
                let color = payload.color
                let textColor = payload.textColor
                let height = payload.height
                let title = payload.title 
                let side = payload.side 
                let action = payload.action
                store.dispatch('setMBS_sub_toolbar',{
                    show : show,
                    fixed : fixed,
                    flat :flat,
                    color : color,
                    textColor : textColor,
                    height: height,
                    title : title, 
                    side : side, 
                    action : action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'subToolbar',
                    page:NAME, 
                })
            } 
        },
        cardSwipeAction(payload,show,fixed,page,text,action){
            try { 
                if (payload) {
                    show = payload.show,
                    fixed = payload.fixed, 
                    page = payload.page, 
                    action = payload.action 
                } 
                store.dispatch('setMBS_card_swipe',{
                    show : show,
                    fixed : fixed, 
                    page : page, 
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'dialog',
                    page:NAME, 
                })
            }
        },
        dialog(payload,show,fixed,title,text,icon,btnYes,btnNo,action){
            try { 
                if (payload) {
                    show = payload.show,
                    fixed = payload.fixed, 
                    title = payload.title,
                    text = payload.text,
                    icon = payload.icon,
                    btnYes = payload.btnYes,
                    btnNo = payload.btnNo,
                    action = payload.action 
                } 
                store.dispatch('setMBS_dialog',{
                    show : show,
                    fixed : fixed, 
                    title : title,
                    text : text,
                    icon : icon,
                    btnYes : btnYes,
                    btnNo:btnNo,
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'dialog',
                    page:NAME, 
                })
            }
        },
        dialogConfirmPassword(payload,show,fixed,title,text,icon,btnYes,btnNo,
            btnYesIcon,btnNoIcon,btnYesColor,btnNoColor,action){
            try { 
                if (payload) {
                    show = payload.show,
                    fixed = payload.fixed, 
                    title = payload.title,
                    text = payload.text,
                    icon = payload.icon,
                    btnYes = payload.btnYes,
                    btnNo = payload.btnNo,
                    btnYesIcon = payload.btnYesIcon,
                    btnNoIcon = payload.btnNoIcon,
                    btnYesColor = payload.btnYesColor,
                    btnNoColor = payload.btnNoColor,
                    action = payload.action 
                } 
                store.dispatch('setMBS_dialog_confirm_password',{
                    show : show,
                    fixed : fixed, 
                    title : title,
                    text : text,
                    icon : icon,
                    btnYes : btnYes,
                    btnNo:btnNo,
                    btnYesIcon : btnYesIcon,
                    btnNoIcon:btnNoIcon,
                    btnYesColor : btnYesColor,
                    btnNoColor:btnNoColor,
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'dialogConfirmPassword',
                    page:NAME, 
                })
            }
        },
        dialogInput(payload,show,fixed,title,text,field,text2,btnYes,btnNo,action){
            try {
                if (payload) {
                    show = payload.show,
                    fixed = payload.fixed, 
                    title = payload.title,
                    text = payload.text,
                    field = payload.field,
                    text2 = payload.text2,
                    btnYes = payload.btnYes,
                    btnNo = payload.btnNo,
                    action = payload.action 
                } 
                store.dispatch('setMBS_dialog_input',{
                    show : show,
                    fixed : fixed, 
                    title : title,
                    text : text,
                    field : field,
                    text2 : text2,
                    btnYes : btnYes,
                    btnNo:btnNo,
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'dialogInput',
                    page:NAME, 
                })
            }
        },
        progressDialog(payload,show,fixed,title,text,color,action){
            try {
                if (payload) {
                    show = payload.show,
                    fixed = payload.fixed, 
                    title = payload.title,
                    text = payload.text,
                    color = payload.color, 
                    action = payload.action 
                } 
                store.dispatch('setMBS_progress_dialog',{
                    show : show,
                    fixed : fixed, 
                    title : title,
                    text : text,
                    color : color, 
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'progressDialog',
                    page:NAME, 
                })
            }
        },
        alertDialog(payload,show,min,type,text,btnYes,btnNo,color,icon,items,action){
            try {
                if (payload) {
                    show = payload.show,
                    min = payload.min, 
                    type = payload.type,
                    text = payload.text,
                    btnYes = payload.btnYes,
                    btnNo = payload.btnNo,
                    color = payload.color, 
                    icon = payload.icon, 
                    items = payload.items, 
                    action = payload.action 
                } 
                store.dispatch('setMBS_alert_toolbar',{
                    show : show,
                    min : min, 
                    type : type,
                    text : text,
                    btnYes : btnYes,
                    btnNo : btnNo,
                    color : color, 
                    icon : icon, 
                    items : items, 
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'alertDialog',
                    page:NAME, 
                })
            }
        },
        addAlertItem(payload){
            try { 
                store.dispatch('addAlertItem',payload)  
            } catch (error) {
                this.error({
                    error:error,
                    from:'addAlertItem',
                    page:NAME, 
                })
            }
        },
        addIfNoAlertItem(payload){
            try { 
                store.dispatch('addIfNoAlertItem',payload)  
            } catch (error) {
                this.error({
                    error:error,
                    from:'addIfNoAlertItem',
                    page:NAME, 
                })
            }
        },
        deleteAlertItem(payload){
            try { 
                store.dispatch('deleteAlertItem',payload)  
            } catch (error) {
                this.error({
                    error:error,
                    from:'deleteAlertItem',
                    page:NAME, 
                })
            }
        },
        deleteAllAlertItems(payload){
            try { 
                store.dispatch('deleteAllAlertItems',payload)  
            } catch (error) {
                this.error({
                    error:error,
                    from:'deleteAllAlertItems',
                    page:NAME, 
                })
            }
        },
        setPAGE_LOAD(payload){
            try { 
                store.dispatch('setPAGE_LOAD',payload)  
            } catch (error) {
                this.error({
                    error:error,
                    from:'setPAGE_LOAD',
                    page:NAME, 
                })
            }
        },

        snackbar(payload,show,time,app,text,color,textColor,lines,vertical,x,y,center,action){
            try {
                if (payload) {
                    show = payload.show,
                    time = payload.time, 
                    app = payload.app,
                    text = payload.text,
                    color = payload.color, 
                    textColor = payload.textColor, 
                    lines = payload.lines, 
                    vertical = payload.vertical, 
                    x = payload.x, 
                    y = payload.y, 
                    center = payload.center, 
                    action = payload.action 
                } 
                store.dispatch('setMBS_snackbar',{
                    show : show,
                    time : time, 
                    app : app,
                    text : text,
                    color : color,
                    textColor : textColor, 
                    lines : lines, 
                    vertical : vertical, 
                    x : x, 
                    y : y, 
                    center : center, 
                    action:action
                })  
            } catch (error) {
                this.error({
                    error:error,
                    from:'snackbar',
                    page:NAME, 
                })
            }
        }, 

        copyText(textToCopy,alert) {   
            navigator.clipboard.writeText(textToCopy).then(() => { 
                if (alert) {
                    this.snackbar({
                        show:true,
                        time:2000,
                        color:'secondary',
                        y:'bottom',
                        text:"Text copied to clipboard: " + textToCopy, 
                    }) 
                }
            }).catch((error) => {
                console.error("Failed to copy text: ", error);
            });
        },

        //ALGORITHM
        //Password
        PASSWORD_CHECKER(password,password_confirm,notify,task_id=0){
            try {
                let checked = false
                let title = ''
                let text = ''
                let btnNo = ''
                let btnYes = ''
                if (!password) {
                    title = TextBus.password_no.title
                    text = TextBus.password_no.text
                    btnNo = TextBus.password_no.btnNo
                    btnYes = TextBus.password_no.btnYes 
                }else if (password != password_confirm) {
                    title = TextBus.password_mismatch.title
                    text = TextBus.password_mismatch.text
                    btnNo = TextBus.password_mismatch.btnNo
                    btnYes = TextBus.password_mismatch.btnYes  
                }else if (this.SIZE(password)<8) {
                    title = TextBus.password_short.title
                    text = TextBus.password_short.text
                    btnNo = TextBus.password_short.btnNo
                    btnYes = TextBus.password_short.btnYes 
                }else{
                    checked = true
                }
                if (!checked) {
                    if (notify) {
                        this.dialog({
                            show:true,
                            fixed:true,
                            title:title,
                            text:text,
                            btnNo:btnNo,
                            btnYes:btnYes,
                            action:{
                                code:"PASSWORD_CHECKER="+task_id
                            }
                        }) 
                    } 
                }
                return {
                    checked:checked,
                    title:title,
                    text:text,
                    btnNo:btnNo,
                    btnYes:btnYes,
                    task_id:task_id,
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'PASSWORD_CHECKER',
                    page:NAME, 
                })
            }
        },

        //TABLE HEADERS
        CREATE_TABLE_HEADER(headers,type){
            try {
                if(!headers){return null}
                if (!(headers instanceof Array)) {  return false } 
    
                let newHeaders = []
                headers.forEach(element => {
                    if(!element){return null}
                    if(element === undefined){return null}
                    if (element.show) {
                        let new_header = {}
                        if (!type) {
                            new_header={
                                ...element,
                                text:this.TEXT_UP(element.text?element.text:element.name),
                                value:this.TEXT_UP0(element.value?element.value:element.name),
                                align:element.align?element.align:'left',
                                sortable:element.sortable===false?false:true
                            }
                        }else if (type===1) {
                            new_header={
                                text:this.TEXT_UP1(element.text?element.text:element.name),
                                value:this.TEXT_UP0(element.value?element.value:element.name),
                                align:element.align?element.align:'left',
                                sortable:element.sortable===false?false:true
                            }
                        }else if (type===11) {
                            new_header={
                                text:this.TEXT_UP11(element.text?element.text:element.name),
                                value:this.TEXT_UP0(element.value?element.value:element.name),
                                align:element.align?element.align:'left',
                                sortable:element.sortable===false?false:true
                            }
                        }else if (type===0) {
                            new_header={
                                text:this.TEXT_UP0(element.text?element.text:element.name),
                                value:this.TEXT_UP0(element.value?element.text:element.name),
                                align:element.align?element.align:'left',
                                sortable:element.sortable===false?false:true
                            }
                        } 
                        newHeaders.push({
                            ...new_header,
                            class:' mbs-one-line font-weight-bold '+element.class,
                            cellClass:' '+element.cellClass
                        })
                    }
                    
                });
                return newHeaders 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CREATE_TABLE_HEADER',
                    page:NAME, 
                })
            }
        },
        TABLE_HEADERS(customeHeader){ 
            try {
                let headers = []
                  //general
                if (this.TABLE_HEADER_SHOW(customeHeader,'Number')) {
                    headers.push({ text: 'NO.',align: 'left', value: 'no',sortable: true})
                } 
                if (this.TABLE_HEADER_SHOW(customeHeader,'icon')) {
                    headers.push({ text: 'DEVICE',align: 'left', value: 'device_icon',sortable: true})
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,'Image')) {
                    headers.push({ text: '',align: 'left', value: 'image',sortable: false})
                }
                
                if (this.TABLE_HEADER_SHOW(customeHeader,'os')) {
                    headers.push({ text: 'OS',align: 'left', value: 'os',sortable: true})
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,'Name')) {
                    headers.push({ text: 'BZ',align: 'left', value: 'name',sortable: true})
                }
    
                if (this.TABLE_HEADER_SHOW(customeHeader,'Revisit')) {
                    headers.push({ text: 'REVISIT',align: 'left', value: 'total_revisit',sortable: true})
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,'First Visit')) {
                    headers.push({ text: 'FIRST V',align: 'left', value: 'first_visit',sortable: true})
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,'Last Visit')) {
                    headers.push({ text: 'LAST V',align: 'left', value: 'last_visit',sortable: true})
                }
    
                if (this.TABLE_HEADER_SHOW(customeHeader,'country')) {
                    headers.push({ text: 'COUNTRY',align: 'left', value: 'location_country',sortable: true})
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,'city')) {
                    headers.push({ text: 'CITY',align: 'left', value: 'location_city',sortable: true})
                }
     
                //USER 
                if (this.TABLE_HEADER_SHOW(customeHeader,"Surname")) {
                    headers.push({ text: 'SURNAME',  value: 'surname',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Names")) {
                    headers.push({ text: 'NAMES',  value: 'names',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Email")) {
                    headers.push({ text: 'EMAIL',  value: 'email',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Phone Number")) {
                    headers.push({ text: 'PHONE',  value: 'phone_number',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Password")) {
                    headers.push({ text: 'PASSWORD',  value: 'password',sortable: true},)
                }
                 
    
                //added / created 
                if (this.TABLE_HEADER_SHOW(customeHeader,"Added By")) {
                    headers.push({ text: 'ADDED BY',  value: 'user_name',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Added At")) {
                    headers.push({ text: 'ADDED AT',  value: 'created_at',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Updated At")) {
                    headers.push({ text: 'UPDATED AT',  value: 'updated_at',sortable: true},)
                }
    
                 
    
                
                
                 
    
                //SMS 
                if (this.TABLE_HEADER_SHOW(customeHeader,"Time")) {
                    headers.push({ text: 'TIME', value: 'created_at',sortable: true},)
                } 
                if (this.TABLE_HEADER_SHOW(customeHeader,"From")) {
                    headers.push({ text: 'FROM', value: 'from',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Message")) {
                    headers.push({ text: 'SMS', value: 'message',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Device")) {
                    headers.push({ text: 'DEVICE', value: 'device_id',sortable: true},)
                }
    
                
    
                if (this.TABLE_HEADER_SHOW(customeHeader,"Code")) {
                    headers.push({ text: 'CODE', value: 'code',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Start Date")) {
                    headers.push({ text: 'START_DATE', value: 'start_date',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"End Date")) {
                    headers.push({ text: 'END_DATE', value: 'end_date',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Start Time")) {
                    headers.push({ text: 'START_TIME', value: 'start_time',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"End Time")) {
                    headers.push({ text: 'END_TIME', value: 'end_time',sortable: true},)
                } 
                if (this.TABLE_HEADER_SHOW(customeHeader,"Error")) {
                    headers.push({ text: 'ERROR', value: 'error',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Candidates Results Code")) {
                    headers.push({ text: 'CANDIDATES RESULTS', value: 'candidates_results',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"Image Link")) {
                    headers.push({ text: 'IMAGE LINK', value: 'image_link',sortable: true},)
                }
                if (this.TABLE_HEADER_SHOW(customeHeader,"ACTION")) {
                    headers.push({ text: 'ACTION',align: 'center', value: 'action',sortable: false })
                }
                return headers 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TABLE_HEADERS',
                    page:NAME, 
                })
            }
        },
        TABLE_HEADER_SHOW(headers,name){  
            try {
                if (!headers) {return false}
                if (!(headers instanceof Array)) { 
                return false
                } 
                
                let hd = headers.find(item=>{
                    return this.TEXT_UP(item.name) == this.TEXT_UP(name) 
                })
                if (!hd) {return false} 
                return hd.show 
            } catch (error) {
                this.error({
                    error:error,
                    from:'TABLE_HEADER_SHOW',
                    page:NAME, 
                })
            }
        },
        TABLE_HEADER_SELECTOR(headers,index){    
            try {
                if (!headers) {return}
                if (!index) {return} 
                let hd = headers.hasOwnProperty(index)
                if (hd) {
                    headers[index].show = !headers[index].show
                }  
            } catch (error) {
                this.error({
                    error:error,
                    from:'TABLE_HEADER_SELECTOR',
                    page:NAME, 
                })
            }
        },

        //PAYMENT CARDS
        GET_COMPANY_CARD_BY_CODE(card_code,company_key){
            return new Promise((resolve,reject)=>{
                setTimeout( async() => {
                    try {
                        let prams = {
                            card_code:card_code,
                            company_key:company_key,
                            key:DATA.APP.key.apiKey,
                            uid:this.us?this.us.uid:null
                        }
                        let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/getCompanyCardByCode',prams)
                        let body = res?res.body:null
                        let amount = body?body.amount:null
                        resolve({
                            response:"success",
                            amount:amount,
                            body:body
                        })
                    } catch (error) {
                        this.error({
                            error:error,
                            from:'GET_COMPANY_CARD_BY_CODE',
                            page:NAME, 
                        })
                        reject({
                            error:error,
                            response:'failed'
                        })
                    } 
                }, 1);
            })
        },
        GET_COMPANY_CARD_BY_KEY(card_key,company_key){
            return new Promise((resolve,reject)=>{
                setTimeout( async() => {
                    try {
                        let prams = {
                            card_key:card_key,
                            company_key:company_key,
                            key:DATA.APP.key.apiKey,
                            uid:this.us?this.us.uid:null
                        }
                        let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/getCompanyCardByKey',prams)
                        let body = res?res.body:null
                        let amount = body?body.amount:null
                        resolve({
                            response:"success",
                            amount:amount,
                            body:body
                        })
                    } catch (error) {
                        this.error({
                            error:error,
                            from:'GET_COMPANY_CARD_BY_KEY',
                            page:NAME, 
                        })
                        reject({
                            error:error,
                            response:'failed'
                        })
                    } 
                }, 1);
            })
        },
        COMPANY_CARD_ADD_AMOUNT(card_key,card_amount,company_key){
            return new Promise((resolve,reject)=>{
                setTimeout( async() => {
                    try {
                        let prams = {
                            card_key:card_key,
                            card_amount:card_amount,
                            company_key:company_key,
                            key:DATA.APP.key.apiKey,
                            uid:this.us?this.us.uid:null
                        }
                        let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/companyCardAddAmount',prams)
                        let body = res?res.body:null
                        let amount = body?body.amount:null
                        resolve({
                            response:"success",
                            amount:amount,
                            body:body
                        })
                    } catch (error) {
                        this.error({
                            error:error,
                            from:'COMPANY_CARD_ADD_AMOUNT',
                            page:NAME, 
                        })
                        reject({
                            error:error,
                            response:'failed'
                        })
                    } 
                }, 1);
            })
        },
        COMPANY_CARD_REMOVE_AMOUNT(card_key,card_amount,company_key){
            return new Promise((resolve,reject)=>{
                setTimeout( async() => {
                    try {
                        let prams = {
                            card_key:card_key,
                            card_amount:card_amount,
                            company_key:company_key,
                            key:DATA.APP.key.apiKey,
                            uid:this.us?this.us.uid:null
                        }
                        let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/companyCardRemoveAmount',prams)
                        let body = res?res.body:null
                        let amount = body?body.amount:null
                        resolve({
                            response:"success",
                            amount:amount,
                            body:body
                        })
                    } catch (error) {
                        this.error({
                            error:error,
                            from:'COMPANY_CARD_REMOVE_AMOUNT',
                            page:NAME, 
                        })
                        reject({
                            error:error,
                            response:'failed'
                        })
                    } 
                }, 1);
            })
        },



        POST_REQUEST(payload){
            let response = {
                from:'POST_REQUEST',
                payload:payload, 
                page:NAME, 
            } 
            return new Promise((resolve,reject)=>{
                setTimeout( async() => {
                    try {
                        let data = payload?payload.data:null
                        let path = payload?payload.path:null
                        if (!data) {
                            reject({...response,error:"no data"})
                            return
                        }
                        let prams = {
                            key:DATA.APP.key.apiKey,
                            uid:this.us?this.us.uid:null,
                            ...data
                        }
                        let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/'+path,prams)
                        let body = res?res.body:null
                        let code = body?body.code:null
                        resolve({
                            response:"success",
                            code:code,
                            body:body
                        })
                    } catch (error) {
                        response = {
                            ...response,
                            error:error,
                            response:'failed'
                        }
                        this.error(response)
                        reject(response)
                    } 
                }, 1);
            })
        },
        //CODE
        CODE_GENERATE(value_items){
            return new Promise((resolve,reject)=>{
                setTimeout( async() => {
                    try {
                        let prams = {
                            value_items:value_items,
                            key:DATA.APP.key.apiKey,
                            uid:this.us?this.us.uid:null
                        }
                        let res = await this.$http.post(DATA.APP.SERVER_LINK+'app/code',prams)
                        let body = res?res.body:null
                        let code = body?body.code:null
                        resolve({
                            response:"success",
                            code:code,
                            body:body
                        })
                    } catch (error) {
                        this.error({
                            error:error,
                            from:'CODE_GENERATE',
                            page:NAME, 
                        })
                        reject({
                            error:error,
                            response:'failed'
                        })
                    } 
                }, 1);
            })
        },
        CODE_IS_OK(array){
            try {
                if(!array){return false}
                let isOk = true
                array.forEach(element => {
                    let code = element.code
                    if (!code) {
                    isOk = false
                    }else if (code ==='') {
                    isOk = false
                    }else if (code ==='0') {
                    isOk = false
                    }else if (code ===0) {
                    isOk = false
                    }
                });
                return isOk 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CODE_IS_OK',
                    page:NAME, 
                })
            }
        },
        CODE_IS_AUTO(array){
            try {
                if(!array){return false}
                let isAuto = true
                let autoArray  = this.CODE_AUTO_GENERATE(array)
                
                for (const index in array) {
                  let e_array = array[index]
                  let e_autoArray = autoArray[index]
                  let code = e_array?e_array.code:null
                  let autoCode = e_autoArray?e_autoArray.code:null
    
                  if (code !== autoCode) {
                    isAuto = false
                  } 
                } 
                return isAuto 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CODE_IS_AUTO',
                    page:NAME, 
                })
            }
        },
        CODE_AUTO_GENERATE(array){
            try {
                if(!array){return null} 
                let newArray = []
                let code = 0
                array.forEach(element => {
                  code = code+1
                  newArray.push({
                    ...element,
                    code:code
                  })
                });
                return newArray 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CODE_AUTO_GENERATE',
                    page:NAME, 
                })
            }
        },
        CODE_GET_FIRST(array){
            try {
                if(!array){return 0}
                let first = 0
                let number = 0
                array.forEach(element => {
                  number = number+1
                  let code = element.code*1
                  if (number === 1 ) {
                    first = code
                  }
                  
                  if (code < first) {
                    first = code
                  }
                }); 
                return first 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CODE_GET_FIRST',
                    page:NAME, 
                })
            }
        },
        CODE_GET_LAST(array){
            try {
                if(!array){return 0}
                let last = 0 
                array.forEach(element => { 
                  let code = element.code*1
                  if (code > last) {
                    last = code
                  }
                }); 
                return last 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CODE_GET_LAST',
                    page:NAME, 
                })
            }
        },
        CODE_CHECK(array,code){
            try {
                if(!array){return false}
                if(!code){return false}
                
                let check = false 
                
                array.forEach(element => { 
                  if (element.code ===code) {
                    check = true
                  }
                });  
                return check 
            } catch (error) {
                this.error({
                    error:error,
                    from:'CODE_CHECK',
                    page:NAME, 
                })
            }
        }, 

        //KEY_NAME
        KEY_NAME_FIND(key_name,items,item_key){
            try { 
                let found = false
                if(!key_name){return found}
                if(!items){return found}
                items.forEach(element => {
                    if (element.key_name==key_name) {
                        if (item_key) {
                            if (item_key!=element.key) {
                                found = true 
                            }
                        } else {
                            found = true 
                        }
                    }
                });
                return found
                
            } catch (error) {
                this.error({
                    error:error,
                    from:'KEY_NAME_FIND',
                    page:NAME, 
                })
                return null
            } 
        },
        KEY_NAME_VERIFY(key_name,name,items,item_key){
            try {  
                if (!key_name) {
                    return {
                        error:true,
                        text:"Key Name is required"
                    }
                }
                if (!name) {
                    return {
                        error:false,
                        text:""
                    }
                }
                if (!items) {
                    return {
                        error:false,
                        text:""
                    }
                } 

                let found = this.KEY_NAME_FIND(key_name,items,item_key)

                if (found) {
                    return {
                        error:true,
                        text:"Key name is already been used"
                    }
                }
                return {
                    error:false,
                    text:""
                } 
                
            } catch (error) {
                this.error({
                    error:error,
                    from:'KEY_NAME_VERIFY',
                    page:NAME, 
                })
                return null
            } 
        },
        KEY_NAME_ADD(name,items,item_key){
            try { 
                if (!name) {return null } 
                let key = name.replace(/ /g, '-') 
                let i = 0 
                for (i=1;this.KEY_NAME_FIND(key,items,item_key) === true;i++){ 
                    key = name.replace(/ /g, '-')+"-"+i
                } 
                return this.TEXT_UP0(key) 
            } catch (error) {
                this.error({
                    error:error,
                    from:'KEY_NAME_VERIFY',
                    page:NAME, 
                })
                return null
            } 
        },
        KEY_NAME(name,path){
            return new Promise(async(resolve,reject) =>{
                let response = {
                    from:'KEY_NAME',
                    page:NAME, 
                }
                try { 
                    if (name) {
                        let key_name = name.replace(/ /g, '-')
                        key_name = this.TEXT_UP0(key_name) 
                        if (path) {
                            let fi_check_key = async(key,path)=>{
                                let data = await store.dispatch("fi_check_key",{
                                    key:key,
                                    path:path
                                })
                                return data
                            }

                            let i = 0
                            let available = false
                            let data = null
                            let calculated_key_name = ""+key_name

                            while (!available) {
                                if (i>0) { 
                                    calculated_key_name = key_name+""+i
                                }
                                data = await fi_check_key(calculated_key_name,path) 
                                available = data?data.available:false
                                i++;
                            }  
                            response = {
                                key_name:calculated_key_name,
                                data:data
                            }
                        } else {
                            response ={
                                key_name:key_name 
                            }
                        } 
                        resolve(response)
                    } else {
                        response = {
                            error:"no name",
                            payload:{
                                name:name,
                                path:path
                            }
                        }
                    } 
                } catch (error) {
                    response = {
                        error:error,
                        from:'KEY_NAME',
                        page:NAME, 
                        payload:{
                            name:name,
                            path:path
                        }
                    }
                    this.error(response) 
                } 
                reject(response) 
            })
        },
        
        //ACTIONS
        //
        SELECT_COMPANY(company){
            return new Promise(async(resolve,reject) =>{
                let response = {
                    from:'SELECT_COMPANY',
                    page:NAME, 
                    payload:{
                        company:company, 
                    }
                }
                try { 
                    let localCashup = this.LocalCashup
                    let local_cashup_company_key = localCashup?localCashup.company_key:null
                    let company_key = company?company.company_key:null
                    if (localCashup) {
                        if (local_cashup_company_key != company_key) {
                            let mbs_text = TextBus.close_register_before_switch_company(company.company_name)
                            this.dialog({
                                show:true,
                                fixed:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                                btnNo:mbs_text.btnNo,
                                action:{
                                    code:NAME+":CLOSE-REGISTER"
                                },
                            })
                            return
                        }
                    } 
                    let data = await store.dispatch("select_company",{ 
                        company:company
                    })
                    resolve(data) 
                } catch (error) {
                    response.error = error
                    this.error(response) 
                } 
                reject(response) 
            })
        }, 
        //JOIN
        JOIN(item){  
            try { 
                let name = item.name
                let items = item.items 
                if (this.TEXT_UP(name) === this.TEXT_UP('Users')) {
                    return this.JOIN_USERS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP('Visiters')) {
                    return this.JOIN_VISITER(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP('Districts')) {
                    return this.JOIN_DISTRICTS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP('Parcels')) {
                    return this.JOIN_PARCELS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP('permissions')) { 
                    return this.JOIN_PERMISSIONS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP('user_permissions')) { 
                    return this.JOIN_USER_PERMISSIONS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.COMPANY_USER_GROUPS.values)) { 
                    return this.JOIN_COMPANY_USER_GROUPS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.BILLING_PLANS.values)) { 
                    return this.JOIN_BILLING_PLANS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.BILLING_PLAN_FEATURES.values)) { 
                    return this.JOIN_BILLING_PLAN_FEATURES(items)
                }
                
                else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.ERRORS.values)) { 
                    return this.JOIN_ERRORS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.ORDERS.values)) { 
                    return this.JOIN_BANK_ORDERS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.STOCK_OUTLETS.values)) { 
                    return this.JOIN_STOCK_OUTLETS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.ITEMS.values)) { 
                    return this.JOIN_ITEMS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.STOCK_ORDERS.values)) { 
                    return this.JOIN_STOCK_ORDERS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.STOCK_ORDER_ITEMS.values)) { 
                    return this.JOIN_STOCK_ORDER_ITEMS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.SALE_ORDERS.values)) { 
                    return this.JOIN_SALE_ORDERS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.SALE_ORDER_ITEMS.values)) { 
                    return this.JOIN_SALE_ORDER_ITEMS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.COMPANIES.values)) { 
                    return this.JOIN_COMPANIES(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values)) { 
                    return this.JOIN_QUOTATIONS_AND_INVOICES(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.TILLS.values)) { 
                    return this.JOIN_TILLS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.PRINTERS.values)) { 
                    return this.JOIN_PRINTERS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.DAYS_SHIFTS.values)) { 
                    return this.JOIN_DAY_SHIFTS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.CASHUPS.values)) { 
                    return this.JOIN_CASHUPS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.ASSETS.values)) { 
                    return this.JOIN_ASSETS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.RECEIVABLES.values)) { 
                    return this.JOIN_RECEIVABLES(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.EXPENSE_ACCOUNTS.values)) { 
                    return this.JOIN_EXPENSE_ACCOUNTS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.EXPENSE_CATEGORIES.values)) { 
                    return this.JOIN_EXPENSE_CATEGORIES(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.BILLS.values)) { 
                    return this.JOIN_BILLS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.INVOICES.values)) { 
                    return this.JOIN_INVOICES(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.EXPENSES.values)) { 
                    return this.JOIN_EXPENSES(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.SUPPLIERS.values)) { 
                    return this.JOIN_SUPPLIERS(items)
                }else if (this.TEXT_UP(name) === this.TEXT_UP(DATA.ITEMS.CUSTOMERS.values)) { 
                    return this.JOIN_CUSTOMERS(items)
                } else {
                    let JOIN = []
                    let NUMBER = 0
                    if(!items){return null}
                    items.forEach(element => {
                        NUMBER = NUMBER+1  
                         
                        let newItem = {
                            ...element,
                            no:NUMBER,  
                            name:this.TEXT_UP11(element.name),
                            created_at:this.convertDate(element.created_at), 
                            created_at_server:this.convertDate(element.created_at_server),
                            updated_at:this.convertDate(element.updated_at), 
                            updated_at_server:this.convertDate(element.updated_at_server), 
                        }
                        JOIN.push(newItem) 
                    }); 
                    return JOIN 
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN',
                    page:NAME, 
                }) 
                return null
            }
        }, 
        JOIN_USERS(users){
            try {
                if(!users){return null}
                let JOIN = []
                let NUMBER = 0
    
                users.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_USER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_USERS',
                    page:NAME, 
                }) 
            }
        },
        JOIN_USER(element,NUMBER = 1){
            try {
                if(!element){return null} 
                
                //
                let uid = element.uid?element.uid:element.key
                let email = element.email
                if (!email) {
                    let user = store.getters.getUserByUid(uid)
                    if (user) {
                        element = {
                            ...user,
                            ...element,
                        } 
                    }
                }

                //card
                let card_key = element.card_key 
                let card_code = element.card_code 
                let card = null
                if (card_key) {
                    card = store.getters.getCardByKey(card_key)
                    card_code = card?.code 
                }if (card_code) {
                    card = store.getters.getCardByCode(card_code)
                    card_key = card?.key 
                }

                //find Designation
                let designation_code = element.designation_code 
                let designation = store.getters.getDesignationByCode(designation_code)
                let designation_name = designation?designation.name:'...'
                
                //User group
                let user_group_code = element.user_group_code 
                let user_group = store.getters.getUserGroupByCode(user_group_code)
                let user_group_name = user_group?user_group.name:'...'
                let active_status = element.disabled?'Disabled':'Active'
                active_status = element.reset_password?'Rest Password':active_status
                let user_status = store.getters.getUserStatusByKey(uid)

                let selectedCompany = this.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                let companies = element.companies
                let company  = companies && company_key?companies[company_key]:null
                let company_user_group_code = company?company.company_user_group_code:null
                let company_user_group = store.getters.getCompanyUserGroupByCode(company_user_group_code)
                let company_user_group_name = company_user_group?company_user_group.name:'...'
  

                let newData = {
                    ...element,
                    no:NUMBER,  
                    card:card,
                    card_code:card_code,
                    card_key:card_key,
                    designation:designation,
                    designation_name:designation_name,
                    user_group:user_group,
                    user_group_name:user_group_name, 
                    reg_status:element.registered?'Registered':element.signup_status,
                    active_status:active_status,
                    user_status:user_status,
                    company:company,
                    company_key:company_key,
                    company_user_group:company_user_group,
                    company_user_group_name:company_user_group_name,
                    name:this.TEXT_UP11(element.surname+' '+element.names),
                    last_changed:user_status?user_status.last_changed:null,
                    logged:user_status?user_status.logged:null,
                    online:user_status?user_status.online:null,
                    last_changed_:user_status? this.toDateTime(user_status.last_changed):'', 
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 

                } 
                return newData 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_USER',
                    page:NAME, 
                }) 
            }
        },
        JOIN_USERS_AND_ROLES(users){
            try {
                if(!users){return null}
                let JOIN = []
                let NUMBER = 0
    
                users.forEach(element => {
                    NUMBER = NUMBER+1 
                    
                    //find districts
                    let uid = element.user_id
                    let user = store.getters.getUsersByUid(uid)
                    let user_name = user?user.surname+' '+user.names:'...'
                    let phone_number = user?user.phone_number:null  
    
                    let newData = {
                        ...element,
                        no:NUMBER,   
                        user:user, 
                        phone_number:phone_number,
                        user_name:this.TEXT_UP11(user_name), 
                        user_role:this.TEXT_UP11(element.user_role), 
                    }
                    JOIN.push(newData) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_USERS_AND_ROLES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_COMPANY_USER_GROUPS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_COMPANY_USER_GROUP(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_COMPANY_USER_GROUPS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_COMPANY_USER_GROUP(element,NUMBER = 1){
            try {
                if(!element){return null}  
               
                //Company 
                let key = element.key
                let is_default = element.is_default
                let default_name = is_default?"Default":"Created"
                let permissions = element.permissions 
                let permissions_size = this.SIZE(permissions)
                let permissions_ = this.OBJECT_ITEMS(permissions)
                let joined_permissions = permissions?this.JOIN_PERMISSIONS(permissions_):null
                
                //Company 
                let company_key = element.company_key
                let company = store.getters.getCompanyByKey(company_key)
                let company_name = company?company.company_name:'--' 
                
                
                //Users
                let created_uid = element.created_uid
                let created_user = store.getters.getUserByUid(created_uid) 
                let created_user_name = created_user?created_user.surname+' '+created_user.names:'--'
                
                //Users
                let updated_uid = element.updated_uid
                let updated_user = store.getters.getUserByUid(updated_uid) 
                let updated_user_name = updated_user?updated_user.surname+' '+updated_user.names:'--'

                

                let newItem = {
                    ...element,
                    no:NUMBER,  
 
                    company:company,    
                    company_name:company_name,    
                    default_name:default_name,    
                    
                 
                    created_user:created_user, 
                    created_user_name:created_user_name, 
                    
                    updated_user:updated_user, 
                    updated_user_name:updated_user_name,  

                    permissions_:permissions_,    
                    permissions_size:permissions_size,    
                    joined_permissions:joined_permissions,    
 
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at), 
                    created_at_time:this.toTime(element.created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server), 
                    created_at_server_date:this.toDate(element.created_at_server), 
                    created_at_server_time:this.toTime(element.created_at_server),  
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_COMPANY_USER_GROUP',
                    page:NAME, 
                })
            }
        }, 


        JOIN_VISITER(visiter){
            try {
                if(!visiter){return null}
                let JOIN = []
                let NUMBER = 0
    
                visiter.forEach(element => {
                    NUMBER = NUMBER+1
                    let revisitC = ()=>{
                        let obj = element.revisit
                        let newData = []
                        let i = 1
                        for (const key in obj) {
                            let sms = obj[key]
                            newData.push({
                                i:i,
                                key: key,
                                ...sms
                            })
                            i++
                        }
                        return newData
                    }
                    let revisit = revisitC()
                    let last =revisit?revisit[revisit.length -1]:null 
                    let newVisiter = {
                        ...element,
                        no:NUMBER,
                        device:element.mobile?"Phone":"Desktop",
                        device_icon:element.mobile?"mdi-cellphone-iphone ":"mdi-monitor ",
                        location_country:element.location?element.location.country.name:'',
                        location_city:element.location?element.location.city:'',
                        total_revisit:revisit?revisit.length:'0',
                        last_visit:last?this.convertDate(last.date):this.convertDate(element.date),
                        first_visit:this.convertDate(element.date),
                        revisit:revisit
                    }
                    JOIN.push(newVisiter) 
                });
    
                return JOIN
                
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_VISITER',
                    page:NAME, 
                })
            }
        }, 
        JOIN_DISTRICTS(districts){
            try {
                if(!districts){return null}
                let JOIN = []
                let NUMBER = 0 
                districts.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_DISTRICT(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_DISTRICTS',
                    page:NAME, 
                })
            }
        },  
        JOIN_DISTRICT(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let region_code = element.region_code
                let region = store.getters.getRegionByCode(region_code)
                let region_name = region?region.name:'--'
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    region_name:region_name, 
                    region:region,
                    name:this.TEXT_UP11(element.name),
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_DISTRICT',
                    page:NAME, 
                })
            }
        }, 
        JOIN_PARCELS(parcels){
            try {
                if(!parcels){return null}
                let JOIN = []
                let NUMBER = 0 
                parcels.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_PARCEL(element)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PARCELS',
                    page:NAME, 
                })
            }
        },  
        JOIN_PARCEL(element,NUMBER = 1){
            try {
                if(!element){return null} 
                //created by
                let created_uid = element.created_uid
                let created_by_user = store.getters.getUserByUid(created_uid)
                let created_by_surname = created_by_user?created_by_user.surname:null
                let created_by_names = created_by_user?created_by_user.names:null
                let created_by_name = created_by_surname+" "+created_by_names
                let created_by_phone_number = created_by_user?created_by_user.phone_number:null
                let created_by_date_of_birth = created_by_user?created_by_user.date_of_birth:null
                
                // Sender
                let sender_uid = element.sender_uid
                let sender_user = store.getters.getUserByUid(sender_uid)
                let sender_surname = sender_user?sender_user.surname:null
                let sender_names = sender_user?sender_user.names:null
                let sender_name = sender_surname+" "+sender_names
                let sender_phone_number = sender_user?sender_user.phone_number:null
                let sender_date_of_birth = sender_user?sender_user.date_of_birth:null
                
                // Sender
                let receiver_uid = element.sender_uid
                let receiver_user = store.getters.getUserByUid(receiver_uid)
                let receiver_surname = receiver_user?receiver_user.surname:null
                let receiver_names = receiver_user?receiver_user.names:null
                let receiver_name = receiver_surname+" "+receiver_names
                let receiver_phone_number = receiver_user?receiver_user.phone_number:null
                let receiver_date_of_birth = receiver_user?receiver_user.date_of_birth:null
                
                //find PICKUP BUS STAGE
                let pickup_bus_stage_code = element.pickup_bus_stage_code
                let pickup_bus_stage = store.getters.getBusStageByCode(pickup_bus_stage_code)
                let pickup_bus_stage_name = pickup_bus_stage?pickup_bus_stage.name:null 
                
                //find DELIVER BUS STAGE
                let deliver_bus_stage_code = element.deliver_bus_stage_code
                let deliver_bus_stage = store.getters.getBusStageByCode(deliver_bus_stage_code)
                let deliver_bus_stage_name = deliver_bus_stage?deliver_bus_stage.name:null 
                
                //JOIN DELIVERY 
                let join_stapes = this.JOIN_DELIVERING_STEPS(element)
                let joined_parcel_delivering_steps = join_stapes?join_stapes.steps:null
                let current_parcel_delivering_step = join_stapes?join_stapes.current_step:null
                let bus_trip = join_stapes?join_stapes.bus_trip:null
                let bus_trip_code = join_stapes?join_stapes.bus_trip_code:null
    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    tracking_number:this.NUMBER_SAME_LENGTH(element.code),

                    pickup_bus_stage:pickup_bus_stage, 
                    pickup_bus_stage_name:this.TEXT_UP11(pickup_bus_stage_name),
                    
                    deliver_bus_stage:deliver_bus_stage, 
                    deliver_bus_stage_name:this.TEXT_UP11(deliver_bus_stage_name),
                        
                    
                    created_by_user:created_by_user,
                    created_by_surname:this.TEXT_UP11(created_by_surname),
                    created_by_names:this.TEXT_UP11(created_by_names),
                    created_by_name:this.TEXT_UP11(created_by_name),
                    created_by_phone_number:created_by_phone_number,
                    created_by_date_of_birth:created_by_date_of_birth,
                    created_by_date_of_birth_:this.convertDate(created_by_date_of_birth),
                    
                    sender_user:sender_user,
                    sender_surname:this.TEXT_UP11(sender_surname),
                    sender_names:this.TEXT_UP11(sender_names),
                    sender_name:this.TEXT_UP11(sender_name),
                    sender_phone_number:sender_phone_number,
                    sender_date_of_birth:sender_date_of_birth,
                    sender_date_of_birth_:this.convertDate(sender_date_of_birth),
                    
                    receiver_user:sender_user,
                    receiver_surname:this.TEXT_UP11(sender_surname),
                    receiver_names:this.TEXT_UP11(sender_names),
                    receiver_name:this.TEXT_UP11(sender_name),
                    receiver_phone_number:sender_phone_number,
                    receiver_date_of_birth:sender_date_of_birth,
                    receiver_date_of_birth_:this.convertDate(sender_date_of_birth),

                    //
                    joined_parcel_delivering_steps:joined_parcel_delivering_steps,
                    current_parcel_delivering_step:current_parcel_delivering_step,
                    bus_trip:bus_trip,
                    bus_trip_code:bus_trip_code,
                    //
                
                
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }   
                return newItem 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PARCEL',
                    page:NAME, 
                })
            }
        },  
        JOIN_DELIVERING_STEPS(parcel){
            try {
                let user_uid=parcel?parcel.created_uid:null
                let user=store.getters.getUserByUid(user_uid)
                let user_surname=user?user.surname:null
                let user_names=user?user.names:null
                if(!user_uid){return null} 
                let deliveringSteps = this.deliveringSteps
    
                let JOIN = []
                let CURRENT_STEP = null 
                let BUS_TRIP = null 
                let BUS_TRIP_CODE = null 
                let parcel_delivering_steps = this.OBJECT_ITEMS(parcel.delivering_steps) 
                if(!deliveringSteps){return null}  
                let findParcelStep = step_code =>{
                    if(!step_code){return null}
                    if(!parcel_delivering_steps){return null}
                    let find_item =  parcel_delivering_steps.find(step=>{
                        return step.step_code == step_code
                    })
                    
                    return find_item
                }
                deliveringSteps.forEach(element => { 
                    let newItem = null
                    if ((element.code*1) == 1) {
                        newItem = {
                            ...element,
                            created_at:parcel.created_at, 
                            date:parcel.created_at, 
                            user_uid:user_uid, 
                            user:user, 
                            user_surname:user_surname, 
                            user_names:user_names, 
                        }
                        CURRENT_STEP = newItem 
                    }else{
                        let user = null
                        let date = null
                        let bus_trip_code = null
                        let bus_trip = null
                        let parcelStep = findParcelStep(element.code)   
                        if (parcelStep) { 
                            let uid = parcelStep?parcelStep.updated_uid:null
                            user = store.getters.getUserByUid(uid)
                            date = parcelStep.updated_at  
                            bus_trip_code = parcelStep?parcelStep.bus_trip_code:null
                            bus_trip = store.getters.getBusTripByCode(bus_trip_code) 
                            bus_trip = this.JOIN_BUS_TRIP(bus_trip)?this.JOIN_BUS_TRIP(bus_trip):bus_trip 
    
                            if (bus_trip_code) { 
                                BUS_TRIP_CODE = bus_trip_code
                                BUS_TRIP = bus_trip 
                            }  
                            newItem = {
                                ...element,
                                user:user,
                                user_surname:user?user.surname:null,
                                user_names:user?user.names:null,
                                user_name:user?user.surname+' '+user.names:null,
                                user_phone_number:user?user.phone_number:null,
                                user_date_of_birth:user?user.date_of_birth:null,
                                date:date,
                                bus_trip:bus_trip,
                                bus_trip_code:bus_trip_code,
                            }
                            CURRENT_STEP = newItem 
                        }else{
                            newItem = {
                                ...element
                            }
                        }
                    }   
                    JOIN.push(newItem) 
                });  
                return {
                    steps:JOIN,
                    current_step:CURRENT_STEP,
                    bus_trip:BUS_TRIP,
                    bus_trip_code:BUS_TRIP_CODE
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_DELIVERING_STEPS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_PERMISSIONS(permissions){
            try {
                let allPermissions = this.permissions  
                if(!allPermissions){return null}
                let JOIN = []
                let NUMBER = 0
                let findMyPermission = (code)=>{
                    if(!permissions){return null}
                    if(!code){return null}
                    return permissions.find(pm=>{
                        return pm.permission_code == code
                    })
                } 
                 let findMyPermissionByKey = (key)=>{
                    if(!permissions){return null}
                    if(!key){return null}
                    return permissions.find(pm=>{
                        return pm.permission_key == key
                    })
                } 
                allPermissions.forEach(element => {
                    NUMBER = NUMBER+1  
                    let code = element.code 
                    let key = element.key 
                    let my_permission = code? findMyPermission(code):findMyPermissionByKey(key) 
                     
                    let newItem = {
                        ...element,
                        no:NUMBER,   
                        my_permission:my_permission,  
                        name:this.TEXT_UP11(element.name), 
                        created_at:this.convertDate(element.created_at), 
                        created_at_server:this.convertDate(element.created_at_server),
                        updated_at:this.convertDate(element.updated_at), 
                        updated_at_server:this.convertDate(element.updated_at_server), 
                    }
                    JOIN.push(newItem) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PERMISSIONS',
                    page:NAME, 
                }) 
            }
        }, 
        JOIN_USER_PERMISSIONS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_USER_PERMISSION(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_USER_PERMISSIONS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_USER_PERMISSION(element,NUMBER = 1){ 
            try {
                if(!element){return null}  
                let permission_key = element.permission_key 
                let permission_code = element.permission_code
                if (permission_key) { 
                    let item = store.getters.getUserPermissionByKey(permission_key)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }else if (permission_code) { 
                    let item = store.getters.getUserPermissionByCode(permission_code)
                    if (item) { 
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }
                // let permission = store.getters.getPermissionByCode(permission_code)
                let permission_name = element?element.name:'--' 
                
                
                let newItem = {
                    ...element,
                    no:NUMBER,   
                    // permission:permission,  
                    permission_name:this.TEXT_UP11(permission_name), 
                    created_at:this.convertDate(element.created_at), 
                    created_at_server:this.convertDate(element.created_at_server),
                    updated_at:this.convertDate(element.updated_at), 
                    updated_at_server:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PERMISSION',
                    page:NAME, 
                })
            }
        }, 
        JOIN_PERMISSIONS_PATHS(items){
            try {
                if(!items){return null}
                let JOIN = []
                let NUMBER = 0 
                items.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_PERMISSION_PATH(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PERMISSIONS_PATHS',
                    page:NAME, 
                })
            }
        },  
        JOIN_PERMISSION_PATH(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let name = element?.name
                let code = element?.code
                let permission_type = element?.permission_type
                let pages = router.options.routes
                let findPath = name=>pages.find(page=>page.name == name)

                let path = element?.path_link
                if (permission_type != "action") {
                    path = findPath(name)?findPath(name).path:null 
                }
                if (permission_type == "action") {
                    code = path
                }
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    path_link:path,   
                    code:code,   
                    code_:code,   
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PERMISSION_PATH',
                    page:NAME, 
                })
            }
        }, 
        JOIN_BILLING_PLANS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_BILLING_PLAN(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BILLING_PLANS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_BILLING_PLAN(element,NUMBER = 1){ 
            try {
                if(!element){return null}  
                let billing_plan_key = element.billing_plan_key 
                let billing_plan_code = element.billing_plan_code
                if (billing_plan_key) { 
                    let item = store.getters.getBillingPlanByKey(billing_plan_key)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }else if (billing_plan_code) { 
                    let item = store.getters.getBillingPlanByCode(billing_plan_code)
                    if (item) { 
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }
                //billing_plan_features 
                let billing_plan_features = element.billing_plan_features
                let join_billing_plan_features = [] 
                let billing_plan_features_size = 0
                if (billing_plan_features) {
                    billing_plan_features_size = billing_plan_features.length
                    billing_plan_features.forEach((p,index) => {
                    let permission = store.getters.getBillingPlanFeatureByCode(p.billing_plan_feature_code)   
                    if (permission) {
                        join_billing_plan_features.push(permission)   
                    }
                    }); 
                }
                
                let uid = element.created_uid
                let user = store.getters.getUserByUid(uid) 
                let user_name = user?user.surname+' '+user.names:'--' 
                
                
                let newItem = {
                    ...element,
                    no:NUMBER,   
                    join_billing_plan_features:join_billing_plan_features,  
                    billing_plan_features_size:billing_plan_features_size,  
                    user:user,  
                    user_name:user_name,  
                    monthly_price_:this.money(element.monthly_price),  
                    created_at:this.convertDate(element.created_at), 
                    created_at_server:this.convertDate(element.created_at_server),
                    updated_at:this.convertDate(element.updated_at), 
                    updated_at_server:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BILLING_PLAN',
                    page:NAME, 
                })
            }
        }, 
        JOIN_BILLING_PLAN_FEATURES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_BILLING_PLAN_FEATURE(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BILLING_PLAN_FEATURES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_BILLING_PLAN_FEATURE(element,NUMBER = 1){ 
            try {
                if(!element){return null}  
                let billing_plan_feature_key = element.billing_plan_feature_key 
                let billing_plan_feature_code = element.billing_plan_feature_code
                if (billing_plan_feature_key) { 
                    let item = store.getters.getBillingPlanFeatureByKey(billing_plan_feature_key)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }else if (billing_plan_feature_code) { 
                    let item = store.getters.getBillingPlanFeatureByCode(billing_plan_feature_code)    
                    if (item) { 
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }
                //permissions 
                let billing_plan_features = element.billing_plan_features
                let join_billing_plan_features = [] 
                let billing_plan_features_size = 0
                if (billing_plan_features) {
                    billing_plan_features_size = billing_plan_features.length
                    billing_plan_features.forEach((p,index) => {
                    let permission = store.getters.getBillingPlanFeatureByCode(p.billing_plan_feature_code)   
                    if (permission) {
                        join_billing_plan_features.push(permission)   
                    }
                    }); 
                }
                
                let uid = element.created_uid
                let user = store.getters.getUserByUid(uid) 
                let user_name = user?user.surname+' '+user.names:'--' 
                
                
                let newItem = {
                    ...element,
                    no:NUMBER,   
                    join_billing_plan_features:join_billing_plan_features,  
                    billing_plan_features_size:billing_plan_features_size,   
                    user:user,  
                    user_name:user_name,  
                    monthly_price_:this.money(element.monthly_price),  
                    created_at:this.convertDate(element.created_at), 
                    created_at_server:this.convertDate(element.created_at_server),
                    updated_at:this.convertDate(element.updated_at), 
                    updated_at_server:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BILLING_PLAN_FEATURE',
                    page:NAME, 
                })
            }
        }, 

        JOIN_ERRORS(errors){
            try {
                if(!errors){return null}
                let JOIN = []
                let NUMBER = 0  
                errors.forEach(element => {
                    NUMBER = NUMBER+1   
                    JOIN.push(this.JOIN_ERROR(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ERRORS',
                    page:NAME, 
                }) 
            }
        }, 
        JOIN_ERROR(element,NUMBER = 1){
            try {
                if(!element){return null}  
             
                let uid = element.uid!=undefined?element.uid?element.uid:element.user:element.user

                let user = store.getters.getUserByUid(uid)
                let user_name = user?user.surname+' '+user.names:'--' 

                let mobile = element.browser?element.browser.mobile:null
                let icon = mobile?'smartphone':'computer'
                // let moment = DateBus.moment
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    user:user, 
                    icon:icon, 
                    from:element.from!=undefined?element.from?element.from:element.form:element.form, 
                    user_name:user_name,  
                    // date_:this.convertDate(element.date),  
                    date_:DateBus.convert(element.date,null,5),  
                }
                return newItem  
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ERROR',
                    page:NAME, 
                }) 
            }
        }, 
        JOIN_BANK_ORDERS(orders){
            try {
                if(!orders){return null}
                let JOIN = []
                let NUMBER = 0  
                orders.forEach(element => {
                    NUMBER = NUMBER+1   
                    JOIN.push(this.JOIN_BANK_ORDER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BANK_ORDERS',
                    page:NAME, 
                }) 
            }
        }, 
        JOIN_BANK_ORDER(element,NUMBER = 1){
            try {
                if(!element){return null}  
             
                let uid = element.uid?element.uid:null
                let bus_trip_key = element.item_details?element.item_details.bus_trip_key:null

                let user = store.getters.getUserByUid(uid)
                let user_surname = user?user.surname:null
                let user_names = user?user.names:null
                let user_name = user?user_surname+' '+user_names:'--'  

                //find BUS TRIPS 
                let bus_trip = store.getters.getBusTripByKey(bus_trip_key)
                let bus_trip_name = bus_trip?bus_trip.name:null
                let bus_route_code = bus_trip?bus_trip.bus_route_code:null
                let bus_code = bus_trip?bus_trip.bus_code:null

                let bus = store.getters.getBusByCode(bus_code)
                bus = this.JOIN_BUS(bus)
    
                //find Bus Route 
                let bus_route = store.getters.getBusRouteByCode(bus_route_code)
                bus_route = this.JOIN_BUS_ROUTE(bus_route)
                let bus_route_name = bus_route?bus_route.name:null 
                
                let paymentMethod = element.paymentMethod
                let paymentMethodName = paymentMethod?paymentMethod.name:null

                //ICON
                let card_icon = ''
                if (paymentMethodName == "VISA") {
                    card_icon = "$visa"
                }else if (paymentMethodName == "MASTERCARD") {
                    card_icon = "$master-card2"
                } else { 
                } 
                let payment_method = paymentMethod?{
                    ...paymentMethod,
                    card_holder_name:paymentMethod.cardholderName,
                    pan_:paymentMethod.pan.slice(-6) 
                }:null

                let captured_payment = element._embedded?element._embedded.payment:null
                let captured_amount=captured_payment?captured_payment[0].amount:null
                let amount = element.amount
                let order_value = amount?amount.value?(amount.value/100):0:0
                let captured_value = captured_amount?captured_amount.value?(captured_amount.value/100):0:0
             

                amount = amount?{
                    ...amount,
                    order_value:order_value,
                    order_value_:this.money(order_value),
                    captured_value:captured_value,
                    captured_value_:this.money(captured_value),
                    balance:order_value-captured_value
                }:null

                //
                let bus_seats = element.item_details?element.item_details.bus_seats:null
                let bus_seats_names = ''
                let total_seats_price = 0
                if (bus_seats) {
                    bus_seats.forEach(seat => {
                        let price = seat.price * 1
                        total_seats_price = total_seats_price+price
                        bus_seats_names = bus_seats_names+seat.name+","
                    });
                }
                
                let newItem = {
                    ...element,
                    payment_method:payment_method,
                    amount:amount,
                    no:NUMBER, 
                    user:user, 
                    user_surname:user_surname,  
                    user_names:user_names,  
                    user_name:user_name,  
                    bus_trip_key:bus_trip_key,
                    bus_trip:bus_trip,  
                    bus_trip_name:bus_trip_name,  
                    bus:bus,  
                    bus_seats_names:bus_seats_names,
                    total_seats_price:total_seats_price,
                    icon:card_icon,
                    bus_route:bus_route,  
                    bus_route_name:bus_route_name,   
 
                    created_at_server_:this.convertDate(element.created_at_server), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem  
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BANK_ORDER',
                    page:NAME, 
                }) 
            }
        },  


        JOIN_STOCK_OUTLETS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_STOCK_OUTLET(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_STOCK_OUTLETS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_STOCK_OUTLET(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let outlet_key = element.outlet_key
                let outlet_code = element.outlet_code
                if (outlet_key) { 
                    let item = store.getters.getStockOutletByKey(outlet_key)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }else if (outlet_code) { 
                    let item = store.getters.getStockOutletByCode(outlet_code)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }
                 

                
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ITEM',
                    page:NAME, 
                })
            }
        },  
        JOIN_ITEMS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_ITEM(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ITEMS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_ITEM(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let item_key = element.item_key
                let item_code = element.item_code
                if (item_key) { 
                    let item = store.getters.getItemByKey(item_key)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }else if (item_code) { 
                    let item = store.getters.getItemByCode(item_code)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }
                //category
                // fixing old version 0.0.28
                if (!element.category_code && element.item_category_code) {
                    element.category_code = element.item_category_code
                }
                let category_code = element.category_code 
                let item_category = store.getters.getItemCategoryByCode(category_code)
                let category_name = item_category?item_category.name:'--'

                //department
                let item_department_code = element.item_department_code 
                let item_department = store.getters.getItemDepartmentByCode(item_department_code)
                let item_department_name = item_department?item_department.name:'--'
                
                //brand
                let brand_code = element.brand_code
                let brand = store.getters.getBrandByCode(brand_code)
                let brand_name = brand?brand.name:'--'
                //manufacturers
                let manufacturer_code = element.manufacturer_code
                let manufacturer = store.getters.getManufacturerByCode(manufacturer_code)
                let manufacturer_name = manufacturer?manufacturer.name:'--'
                
                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 

                let key = element.key

                //calculate order items
                let sale_order_items = this.SaleOrderItemByItemKey({
                    item_key:key
                })   
                let sale_order_items_size = this.SIZE(sale_order_items)
                let total_sold_price = 0
                let total_sold_quantity = 0
                
                if (sale_order_items) {
                    sale_order_items.forEach(item => {
                        let sold_price =toNumber(item.sold_price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let sold = quantity*(sold_price-discount)
                        total_sold_price = total_sold_price+sold
                        total_sold_quantity = total_sold_quantity+quantity 
                    }); 
                } 

                //Calculate Opening Stock Order items
                let opening_stock_order_items = this.OpeningStockItemsByItemKey({
                    item_key:key
                })  
                let opening_stock_order_items_size = this.SIZE(opening_stock_order_items)
                let total_opening_cost_price = 0
                let total_opening_stock_quantity = 0
                if (opening_stock_order_items) {
                    opening_stock_order_items.forEach(item => {
                        let cost_price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let cost = quantity*(cost_price-discount)
                        total_opening_cost_price = total_opening_cost_price+cost
                        total_opening_stock_quantity = total_opening_stock_quantity+quantity 
                    }); 
                }
                
                //Calculate Received Stock Order items
                let received_stock_order_items = this.ReceivedStockItemsByItemKey({
                    item_key:key
                })  
                let received_stock_order_items_size = this.SIZE(received_stock_order_items)
                let total_received_cost_price = 0
                let total_received_stock_quantity = 0
                if (received_stock_order_items) {
                    received_stock_order_items.forEach(item => {
                        let cost_price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let cost = quantity*(cost_price-discount)
                        total_received_cost_price = total_received_cost_price+cost
                        total_received_stock_quantity = total_received_stock_quantity+quantity 
                    }); 
                }
                
                //Calculate Returned Stock Order items
                let returned_stock_order_items = this.ReturnedStockItemsByItemKey({
                    item_key:key
                })  
                let returned_stock_order_items_size = this.SIZE(returned_stock_order_items)
                let total_returned_cost_price = 0
                let total_returned_stock_quantity = 0
                if (returned_stock_order_items) {
                    returned_stock_order_items.forEach(item => {
                        let cost_price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let cost = quantity*(cost_price-discount)
                        total_returned_cost_price = total_returned_cost_price+cost
                        total_returned_stock_quantity = total_returned_stock_quantity+quantity 
                    }); 
                }
                
                //Calculate Adjust Stock Order items
                let adjust_stock_order_items = this.AdjustStockItemsByItemKey({
                    item_key:key
                })  
                let adjust_stock_order_items_size = this.SIZE(adjust_stock_order_items)
                let total_adjust_cost_price = 0
                let total_adjust_stock_quantity = 0
                if (adjust_stock_order_items) {
                    adjust_stock_order_items.forEach(item => {
                        let cost_price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let cost = quantity*(cost_price-discount)
                        total_adjust_cost_price = total_adjust_cost_price+cost
                        total_adjust_stock_quantity = total_adjust_stock_quantity+quantity 
                    }); 
                }
                
                //Calculate Return Sold Stock Order items
                let return_sold_stock_order_items = this.ReturnSoldStockItemsByItemKey({
                    item_key:key
                })  
                let return_sold_stock_order_items_size = this.SIZE(return_sold_stock_order_items)
                let total_return_sold_cost_price = 0
                let total_return_sold_stock_quantity = 0
                if (return_sold_stock_order_items) {
                    return_sold_stock_order_items.forEach(item => {
                        let sold_price = toNumber(item.sold_price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let cost = quantity*(sold_price-discount)
                        total_return_sold_cost_price = total_return_sold_cost_price+cost
                        total_return_sold_stock_quantity = total_return_sold_stock_quantity+quantity 
                    }); 
                }

                //calculate average 
                let stock_order_items_size =  
                    received_stock_order_items_size
                    -returned_stock_order_items_size 
                    +adjust_stock_order_items_size 
                    +opening_stock_order_items_size  
                let total_cost_price = 
                    total_received_cost_price  
                    -total_returned_cost_price 
                    +total_adjust_cost_price  
                    +total_opening_cost_price
                let total_stock_quantity = 
                    total_received_stock_quantity  
                    -total_returned_stock_quantity 
                    +total_adjust_stock_quantity  
                    +total_opening_stock_quantity
                
                let average_cost_price = total_cost_price/total_stock_quantity


                //calculate VAT
                let purchase_vat = 0
                let sold_vat = 0
                let payable_vat = 0
                let tax = element.tax
                if (tax) {
                    purchase_vat = total_cost_price*(16.5)/100
                    sold_vat = total_sold_price*(16.5)/100
                    payable_vat = sold_vat-purchase_vat
                }

                
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    tax_:element.tax?"Yes":"No", 
                    item_category:item_category,
                    category_name:category_name, 
                    item_department:item_department, 
                    item_department_name:item_department_name, 
                    department_code:item_department_code,

                    brand:brand,
                    brand_name:brand_name, 
                    manufacturer:manufacturer,
                    manufacturer_name:manufacturer_name, 
                    supplier:supplier, 

                    purchase_vat:purchase_vat,
                    purchase_vat_:this.money(purchase_vat),
                    sold_vat:sold_vat,
                    sold_vat_:this.money(sold_vat),
                    payable_vat:payable_vat,
                    payable_vat_:this.money(payable_vat),
                    
                    //Sold
                    sale_order_items:sale_order_items, 
                    sale_order_items_size:sale_order_items_size, 
                    total_sold_price:total_sold_price, 
                    total_sold_price_:this.money(total_sold_price), 
                    total_sold_quantity:total_sold_quantity, 
                    
                    //Opening Sock
                    opening_stock_order_items:opening_stock_order_items, 
                    opening_stock_order_items_size:opening_stock_order_items_size, 
                    total_opening_cost_price:total_opening_cost_price, 
                    total_opening_cost_price_:this.money(total_opening_cost_price), 
                    total_opening_stock_quantity:total_opening_stock_quantity, 
                    
                    //Received Sock
                    received_stock_order_items:received_stock_order_items, 
                    received_stock_order_items_size:received_stock_order_items_size, 
                    total_received_cost_price:total_received_cost_price, 
                    total_received_cost_price_:this.money(total_received_cost_price), 
                    total_received_stock_quantity:total_received_stock_quantity, 
                    
                    //Returned Sock
                    returned_stock_order_items:returned_stock_order_items, 
                    returned_stock_order_items_size:returned_stock_order_items_size, 
                    total_returned_cost_price:total_returned_cost_price, 
                    total_returned_cost_price_:this.money(total_returned_cost_price), 
                    total_returned_stock_quantity:total_returned_stock_quantity, 
                    
                    //Adjust Sock
                    adjust_stock_order_items:adjust_stock_order_items, 
                    adjust_stock_order_items_size:adjust_stock_order_items_size, 
                    total_adjust_cost_price:total_adjust_cost_price, 
                    total_adjust_cost_price_:this.money(total_adjust_cost_price), 
                    total_adjust_stock_quantity:total_adjust_stock_quantity, 
    
                    //Return sold Sock
                    return_sold_stock_order_items:return_sold_stock_order_items, 
                    return_sold_stock_order_items_size:return_sold_stock_order_items_size, 
                    total_return_sold_cost_price:total_return_sold_cost_price, 
                    total_return_sold_cost_price_:this.money(total_adjust_cost_price), 
                    total_return_sold_stock_quantity:total_return_sold_stock_quantity, 
 
                    stock_order_items_size:stock_order_items_size, 
                    total_cost_price:total_cost_price, 
                    total_cost_price_:this.money(total_cost_price), 
                    total_stock_quantity:total_stock_quantity,  
                    total_cost_quantity_:total_stock_quantity+"/"+stock_order_items_size,  
                    average_cost_price:average_cost_price,  
                    
                    total_quantity:
                        total_stock_quantity
                        -total_sold_quantity
                        +total_return_sold_stock_quantity,  
                    
                    retail_price_:this.money(element?.retail_price), 
                    wholesale_price_:this.money(element.wholesale_price), 
                    average_cost_price_:this.money(average_cost_price),  
                    code_:this.NUMBER_SAME_LENGTH(element.code),
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ITEM',
                    page:NAME, 
                })
            }
        },  
        JOIN_SUPPLIERS(districts){
            try {
                if(!districts){return null}
                let JOIN = []
                let NUMBER = 0 
                districts.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_SUPPLIER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_SUPPLIERS',
                    page:NAME, 
                })
            }
        },  
        JOIN_SUPPLIER(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let supplier_category_code = element.supplier_category_code
                let supplier_category = store.getters.getSupplierCategoryByCode(supplier_category_code)
                let supplier_category_name = supplier_category?supplier_category.name:'--'
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    supplier_category:supplier_category,
                    supplier_category_name:supplier_category_name, 
                    name:this.TEXT_UP11(element.name),
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_SUPPLIER',
                    page:NAME, 
                })
            }
        }, 
        JOIN_CUSTOMERS(districts){
            try {
                if(!districts){return null}
                let JOIN = []
                let NUMBER = 0 
                districts.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_CUSTOMER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CUSTOMERS',
                    page:NAME, 
                })
            }
        },  
        JOIN_CUSTOMER(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let customer_group_code = element.customer_group_code
                let customer_group = store.getters.getCustomerGroupByCode(customer_group_code)
                let customer_group_name = customer_group?customer_group.name:'--'
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    customer_group:customer_group,
                    customer_group_name:customer_group_name, 
                    name:this.TEXT_UP11(element.name),
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                } 
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CUSTOMER',
                    page:NAME, 
                })
            }
        }, 
        JOIN_DAY_SHIFTS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_DAY_SHIFT(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_DAY_SHIFTS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_DAY_SHIFT(element,NUMBER = 1){
            try {
                if(!element){return null}  
               
                //Company 
                let key = element.key
                let cashups = element.cashups

                let closed = element.total_closing_cash!=null&&element.total_closing_cash!=undefined?true:false
                if (element.local_close_at) {
                    closed = true
                }

                let joined_cashups = cashups?this.JOIN_CASHUPS(this.OBJECT_ITEMS(cashups)):null
                
                //Company 
                let company_key = element.company_key
                let company = store.getters.getCompanyByKey(company_key)
                let company_name = company?company.company_name:'--' 
                
                //Outlet 
                let outlet_code = element.outlet_code
                let outlet = store.getters.getStockOutletByCode(outlet_code)
                let outlet_name = outlet?outlet.name:'--' 
                
                //Users
                let created_uid = element.created_uid
                let user = store.getters.getUserByUid(created_uid) 
                let user_name = user?user.surname+' '+user.names:'--'
                
                //Users
                let updated_uid = element.updated_uid
                let close_user = store.getters.getUserByUid(updated_uid) 
                let close_user_name = close_user?close_user.surname+' '+close_user.names:'--'

                //tills  
                let joined_tills =[]
                if ( element.tills ) {
                    let array_tills = Object.keys(element.tills).map((key) => {return{...element.tills[key],key:key}}); 
                    array_tills .forEach(item => {
                        let till_key = item.till_key
                        let till = store.getters.getTillByKey(till_key)
                        if (till) {
                            joined_tills.push({
                                ...till,
                                ...item,
                                key:till_key,
                                day_shift_and_till_key:element.key+"/"+till_key,
                                day_shift_key:element.key,
                                outlet:outlet,
                                outlet_name:outlet_name,
                                operating_date:element.operating_date,  
                                operating_date_:this.toDate(element.operating_date), 
                            })
                        }
                    });
                } 

                if (!element.local_created_at) {
                    let moment = DateBus.moment
                    element.local_created_at = moment(element.created_at).valueOf()
                } 

                let newItem = {
                    ...element,
                    no:NUMBER,  

                    closed:closed,    
                    status:closed?"Closed":"Running...", 

                    company:company,    
                    company_name:company_name,    
                    outlet_name:outlet_name,
                    outlet:outlet,   

                    joined_tills:joined_tills, 
                    user:user, 
                    user_name:user_name, 
                    
                    close_user:close_user, 
                    close_user_name:close_user_name,  

                    cashups:cashups,    
                    joined_cashups:joined_cashups,    

                    total_closing_cash_:this.money(element.total_closing_cash), 
                    total_floating_cash_:this.money(element.total_floating_cash), 
                    operating_date_:this.toDate(element.operating_date), 
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at), 
                    created_at_time:this.toTime(element.created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server), 
                    created_at_server_date:this.toDate(element.created_at_server), 
                    created_at_server_time:this.toTime(element.created_at_server), 
                    local_created_at_:this.toDateTime(element.local_created_at), 
                    local_created_at_date:this.toDate(element.local_created_at), 
                    local_created_at_time:this.toTime(element.local_created_at), 
                    local_updated_at_:this.toDateTime(element.local_updated_at), 
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_DAY_SHIFT',
                    page:NAME, 
                })
            }
        }, 
        JOIN_CASHUPS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_CASHUP(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CASHUPS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_CASHUP(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let till_key = element?element.till_key:null 
                
                //Day Shift 
                let day_shift_key = element?element.day_shift_key:null 
                let day_shift = store.getters.getDayShiftByKey(day_shift_key) 
                let operating_date = day_shift?day_shift.operating_date:"..."
                
                //Till 
                let till = store.getters.getTillByKey(till_key)
                let till_name = till?till.name:'--' 
                let outlet_code = till?till.outlet_code:null
                
                //category 
                let outlet = store.getters.getStockOutletByCode(outlet_code)
                let outlet_name = outlet?outlet.name:'--' 
                
                //teller
                let teller_uid = element.uid
                let teller = store.getters.getUserByUid(teller_uid) 
                let teller_name = teller?teller.surname+' '+teller.names:'--'

                //INPUT PAYMENTS
                const input_payments = element.input_payments?element.input_payments:element.InputPayments 
                let input_cash_payments = 0
                let input_other_payments = 0
                if (input_payments) {
                    input_payments.forEach(payment => {
                        if (payment.key == "cash") {
                            input_cash_payments = input_cash_payments+this.toNumber(payment.given_amount)
                        } else {
                            input_other_payments = input_other_payments+this.toNumber(payment.given_amount)
                        } 
                    });
                }

                let expected_cash = this.toNumber(element.expected_cash)
                if (element.InputPayments) {
                    expected_cash=expected_cash+input_cash_payments
                }

                
                let closing_cash = this.toNumber(element.closing_cash)
                let sales_retail = this.toNumber(element.sales_retail)
                let sales_wholesales = this.toNumber(element.sales_wholesales)
                let sales_cash_payments = this.toNumber(element.sales_cash_payments)
                let sales_total_other_option_payments = this.toNumber(element.sales_total_other_option_payments)
                let invoice_cash_payments = this.toNumber(element.invoice_cash_payments)
                let invoice_total_other_option_payments = this.toNumber(element.invoice_total_other_option_payments)
                let total_sales = sales_retail+sales_wholesales
                let total_cash_payments = sales_cash_payments+invoice_cash_payments
                let total_other_option_payments = sales_total_other_option_payments+invoice_total_other_option_payments
                let shortage = closing_cash-expected_cash
                let isShortage = shortage<0?true:false

                let moment = DateBus.moment 
                
                let newItem = {
                    ...element,
                  no:NUMBER,  
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name, 

                  day_shift:day_shift, 
                  operating_date:operating_date, 
                  operating_date_:this.toDate(operating_date),  
                  till:till, 
                  till_name:till_name, 
                  teller:teller, 
                  teller_name:teller_name,    
                  date_:moment(element.local_created_at).format('yyyy/MM/D'),
                  open_time_:moment(element.local_created_at).format('hh:mm:ss a'),
                  close_time_:element.closed?moment(element.local_updated_at).format('hh:mm:ss a'):'Running...',
                  close_date_time_:element.closed?this.toDateTime(element.local_updated_at):'Running...', 
                  floating_cash_:this.money(element.floating_cash), 
                  opening_cash_:this.money(element.opening_cash), 
                  closing_cash_:this.money(element.closing_cash), 
                  expected_cash:expected_cash, 
                  expected_cash_:this.money(expected_cash), 

                  input_cash_payments:input_cash_payments,
                  input_other_payments:input_other_payments,

                  sales_retail_:this.money(sales_retail), 
                  sales_wholesales_:this.money(sales_wholesales),
                  shortage_:isShortage?'('+ this.money(shortage*-1)+')':this.money(shortage),
                  shortage:shortage,
                  isShortage:isShortage, 
                  total_sales_:this.money(total_sales), 
                  total_cash_payments_:this.money(total_cash_payments), 
                  total_other_option_payments_:this.money(total_other_option_payments), 

                  created_at_:this.toDateTime(element.created_at), 
                  local_created_at_:this.toDateTime(element.local_created_at), 
                  local_created_at_date:this.toDate(element.local_created_at), 
                  local_created_at_time:this.toTime(element.local_created_at), 
                  local_updated_at_:this.toDateTime(element.local_updated_at), 
                  created_at_server_:this.toDateTime(element.created_at_server),
                  updated_at_:this.toDateTime(element.updated_at), 
                  updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CASHUP',
                    page:NAME, 
                })
            }
        }, 
        JOIN_STOCK_ORDERS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_STOCK_ORDER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_STOCK_ORDERS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_STOCK_ORDER(element,NUMBER = 1){
            try {
                if(!element){return null} 
                
                //category
                let deliver_to_outlet_code = element.deliver_to_outlet_code
                let deliver_to_outlet = store.getters.getStockOutletByCode(deliver_to_outlet_code)
                let deliver_to_outlet_name = deliver_to_outlet?deliver_to_outlet.name:'--' 
                
                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 
                let supplier_name = supplier?supplier.name:'--' 

                //payment_method
                let payment_method_code = element.payment_method_code
                let payment_method_old = element.payment_method
                let payment_method = null
                let payment_method_name = null 

                if (this.TEXT_UP0(payment_method_old) == "bill"||this.TEXT_UP0(payment_method_code) == "bill"||payment_method_code == -1) {
                    payment_method =  {name:"Bill",code:'bill'}
                    payment_method_name = "Bill"
                }else if (this.TEXT_UP0(payment_method_code) == "petty_cash" || payment_method_code == '-2') {
                    payment_method =  {name:"Petty Cash",code:'petty_cash'}
                    payment_method_name = "Petty Cash"
                }else if (!payment_method_code || this.TEXT_UP0(payment_method_code) == "cash"|| payment_method_code == '0') {
                    payment_method =  {name:"Cash",code:'cash'}
                    payment_method_name = "Cash"
                }else {
                    payment_method = store.getters.getPaymentMethodByCode(payment_method_code) 
                    payment_method_name = payment_method?payment_method.name:"Unknown" 
                }

                if (!element.payment_method_code && payment_method) {
                    element.payment_method_code=payment_method.code
                }

                //calculate order items
                let key = element.key
                let joined_items = null
                if (!element.items) {
                    joined_items = store.getters.getStockOrderItemsByOrderKey(key)  
                }else{
                    joined_items = this.JOIN_ITEMS(element.items)
                }
                let items_size = this.SIZE(element.items)
                let total_price = 0
                let total_quantity = 0
                if (joined_items) {
                    joined_items.forEach(item => {
                        let price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let cost = quantity*(price-discount)
                        total_price = total_price+cost
                        total_quantity = total_quantity+quantity

                    }); 
                }

                let icon_name = "mdi-cart-outline"
                let type_name = ''
                let deliver_from = "--"
                let deliver_to = "--"
                if (element.order_type == DATA.ITEMS.STOCK_ORDERS.value) {
                    icon_name = "mdi-cart"
                    type_name = DATA.ITEMS.STOCK_ORDERS.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                    icon_name = "mdi-cart-check"
                    type_name = DATA.ITEMS.STOCK_RECEIVES.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }else if (element.order_type == DATA.ITEMS.OPENING_STOCK.value) {
                    icon_name = "mdi-cart-check"
                    type_name = DATA.ITEMS.OPENING_STOCK.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }else if (element.order_type == DATA.ITEMS.STOCKS_RETURN.value) {
                    icon_name = "mdi-cart-remove"
                    type_name = DATA.ITEMS.STOCKS_RETURN.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }else if (element.order_type == DATA.ITEMS.STOCK_TRANSFERS.value) {
                    icon_name = "mdi-cart-arrow-right"
                    type_name = DATA.ITEMS.STOCK_TRANSFERS.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }else if (element.order_type == DATA.ITEMS.SALES_RETURN.value) {
                    icon_name = "mdi-cart-arrow-right"
                    type_name = DATA.ITEMS.SALES_RETURN.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }else if (element.order_type == DATA.ITEMS.STOCK_ADJUSTMENTS.value) {
                    icon_name = "mdi-cart-arrow-right"
                    type_name = DATA.ITEMS.STOCK_ADJUSTMENTS.name
                    deliver_from = supplier_name
                    deliver_to = deliver_to_outlet_name
                }
 
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    supplier:supplier,
                    supplier_name:supplier_name, 
                    deliver_to_outlet:deliver_to_outlet, 
                    deliver_to_outlet_name:deliver_to_outlet_name,
                    deliver_from:deliver_from, 
                    deliver_to:deliver_to, 
                    icon:icon_name, 
                    type_name:type_name, 
                    joined_items:joined_items, 
                    items_size:items_size, 
                    total_price:total_price, 
                    payment_method:payment_method, 
                    payment_method_name:payment_method_name, 
                    total_price_:this.money(total_price), 
                    total_quantity:total_quantity,  
                    total_quantity_:total_quantity+"/"+items_size,  
                    code_:this.NUMBER_SAME_LENGTH(element.code),
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at),
                    created_at_time:this.toTime(element.created_at),
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_STOCK_ORDER',
                    page:NAME, 
                })
            }
        }, 
        JOIN_STOCK_ORDER_ITEMS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_STOCK_ORDER_ITEM(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_STOCK_ORDER_ITEMS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_STOCK_ORDER_ITEM(element,NUMBER = 1){
            try {
                if(!element){return null} 

                //order
                let order_key = element.order_key
                let order = store.getters.getStockOrderByKey(order_key)
                let order_name = order?order.name:'--' 
                
                //Item
                let item_code = element.item_code
                let item = store.getters.getSupplierByCode(item_code) 
                let item_name = item?item.name:'--' 

                
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    order:order,
                    order_name:order_name, 
                    item:item,
                    item_name:item_name,  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_STOCK_ORDER_ITEM',
                    page:NAME, 
                })
            }
        },  
        JOIN_SALE_ORDERS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_SALE_ORDER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_SALE_ORDERS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_SALE_ORDER(element,NUMBER = 1){
            try {
                if(!element){return null} 
                //table
                let table_key = element.table_key
                let table = store.getters.getItemOrderOptionByKey(table_key) 
                let table_name = table?table.name:'--'  
                
                //waiter
                let waiter_key = element.waiter_key
                let waiter = store.getters.getItemOrderOptionByKey(waiter_key) 
                let waiter_name = waiter?waiter.name:'--' 
                let user_uid = waiter?waiter.uid:null
                
                let user = store.getters.getUserByUid(user_uid) 
                let user_name = user?user.surname+' '+user.names:'--'  

                //cashup
                let day_shift_key = element.day_shift_key
                let day_shift = store.getters.getDayShiftByKey(day_shift_key)

                //cashup
                let cashup_key = element.cashup_key
                let cashups = day_shift?day_shift.cashups:null
                let cashup = cashups?cashups[cashup_key]:null
                let till_key = cashup?cashup.till_key:null
                let teller_key = cashup?cashup.uid:null
                
                //Till 
                let company = store.getters.getCompanyByKey(element.company_key)
                let company_name = company?company.company_name:'--'  
                
                //Till 
                let till = store.getters.getTillByKey(till_key)
                let till_name = till?till.name:'--' 
                let outlet_code = till?till.outlet_code:null
                
                //category 
                let outlet = store.getters.getStockOutletByCode(outlet_code)
                let outlet_name = outlet?outlet.name:'--' 
                
                //teller
                let teller_uid = element.created_uid
                let teller = store.getters.getUserByUid(teller_uid) 
                let teller_name = teller?teller.surname+' '+teller.names:'--' 
                
                //customer
                let customer_key = element.customer_key
                let customer = store.getters.getCustomerByKey(customer_key) 
                let customer_name = customer?customer.name:null 
                let customer_names = customer?customer.names:'..' 
                let customer_surname = customer?customer.surname:'..' 
                customer_name = customer_name?customer_name:customer_names+" "+customer_surname
                
                

                //receivable
                let receivable_code = element.receivable_code
                let receivable_key = element.receivable_key
                let receivable = null
                let receivable_name ='Unknown' 

                if (receivable_key) {
                    receivable = store.getters.getReceivableByKey(receivable_key) 
                    receivable_name = receivable?receivable.name:'Unknown'  
                } else if(receivable_code){
                    receivable = store.getters.getReceivableByCode(receivable_code) 
                    receivable_name = receivable?receivable.name:'Unknown'  
                    
                }

                // icon
                let icon_image = {
                    icon:'mdi-receipt' 
                }
                if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                    
                }else if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                    
                }

                //payment_method
                let input_payments = element.input_payments 
                let joined_input_payments = []
                let payment_method_code = element.payment_method_code?element.payment_method_code:element.payment_option_code
                let payment_method_key = element.payment_option_key
                let payment_method = null
                let payment_method_name = ''
                if (element.account_type == "multiple" && input_payments) { 
                    input_payments.forEach((element,index) => {
                    const given_amount = toNumber(element.given_amount)
                    if (given_amount) {
                        if (element.key=='cash') {
                        const name = index?", Cash":"Cash"
                        payment_method_name= payment_method_name+name
                        joined_input_payments.push({
                            ...element,
                            name:"cash",
                            payment_method_name:"Cash"
                        }) 
                        }else{
                        const m = store.getters.getPaymentMethodByCode(element.code) 
                        const m_name = m?m.name:"Unknown" 
                        const name = index?", "+m_name:m_name
                        payment_method_name = payment_method_name+name
                        joined_input_payments.push({
                            ...m,
                            ...element,
                            payment_method_name:m_name
                        }) 
                        } 
                    }
                    
                    });
                    payment_method =  {
                    name:payment_method_name,
                    code:'multiple',
                    input_payments:input_payments,
                    joined_input_payments:joined_input_payments
                    } 
                    icon_image.icon = 'mdi-account-cash' 
                    
                } else if (element.account_type == DATA.ITEMS.ON_ACCOUNTS.value) {
                    payment_method =  {name:DATA.ITEMS.ON_ACCOUNTS.name,code:DATA.ITEMS.ON_ACCOUNTS.value}
                    payment_method_name = DATA.ITEMS.ON_ACCOUNTS.name+' ('+receivable_name+')'
                    icon_image.icon = 'mdi-cash-clock'
                } else if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                    payment_method =  {name:DATA.ITEMS.CARD_PAYMENTS.name,code:DATA.ITEMS.CARD_PAYMENTS.value}
                    payment_method_name = DATA.ITEMS.CARD_PAYMENTS.name
                    icon_image.icon = 'mdi-credit-card'
                }else if (payment_method_key) {
                    payment_method =  store.getters.getPaymentMethodByKey(payment_method_key) 
                    payment_method_name = payment_method?payment_method.name:"Unknown Option" 
                    icon_image.icon = 'mdi-account-cash'
                } else if (!payment_method_code || payment_method_code == '0') {
                    payment_method =  {name:"Cash",code:'0'}
                    payment_method_name = "Cash" 
                    icon_image.icon = 'mdi-account-cash ' 
                } else if (payment_method_code == -1||payment_method_code == '-3') {
                    payment_method =  {name:"Bill",code:'-3'}
                    payment_method_name = "Bill" 
                    icon_image.icon = 'mdi-account-cash ' 
                } else {
                    payment_method = store.getters.getPaymentMethodByCode(payment_method_code) 
                    payment_method_name = payment_method?payment_method.name:"Unknown" 
                }

                //calculate order items
                let key = element.key
                let sale_order_items =element.items?element.items:store.getters.getSaleOrderItemsByOrderKey(key) 
                let sale_order_size = this.SIZE(sale_order_items)
                let total_cost_price = 0
                let total_sold_price = 0
                let total_quantity = 0
                let total_price = toNumber(element.total_price)
                let total_discount = toNumber(element.total_discount)
                if (sale_order_items) {
                    sale_order_items.forEach(item => {
                        let average_cost_price = toNumber(item.average_cost_price)
                        let sold_price = toNumber(item.sold_price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let sold = quantity*(sold_price-discount)
                        let cost = average_cost_price*quantity
                        total_sold_price = total_sold_price+sold
                        total_cost_price = total_cost_price+cost
                        total_quantity = total_quantity+quantity 
                    }); 
                    total_sold_price = total_sold_price-total_discount
                } 
                let joined_items = this.JOIN_SALE_ORDER_ITEMS(sale_order_items)
                let mix_sale_order_items = joined_items?joined_items:sale_order_items
                let joined_items_departments = []
                if (mix_sale_order_items) {
                    mix_sale_order_items.forEach(item => {
                      //-------ITEM DEPARTMENT
                      const tip_department = {
                        name:"Tip",
                        key:'tip',
                        code:'tip'
                      }
                      let item_department = item.item_department?item.item_department:{code:'unknown',name:"Unknown"}
                      if (item.key == 'tip') {
                        item_department = tip_department
                      }
                      const d_index = joined_items_departments.findIndex(depart=>depart.code == item_department.code)
                      if (d_index == -1) { 
                        joined_items_departments.push({
                            ...item_department,
                            joined_items:[item]
                        })
                      } else {
                        joined_items_departments[d_index].joined_items.push(item)  
                      } 
                       
                    });  
                } 

                //receipt_number
                let receipt_number = element.order_key?element.order_key:element.key
                receipt_number = element.receiptNumber?element.receiptNumber:receipt_number
                receipt_number = element.ReceiptNumber?element.ReceiptNumber:receipt_number
                receipt_number = element.receipt_number?element.receipt_number:receipt_number
                
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    table:table,
                    table_name:table_name,
                    waiter:waiter,
                    waiter_key:waiter_key,
                    waiter_name:waiter_name,
                    user:user,
                    user_name:user_name,
                    icon_image:icon_image,
                    day_shift:day_shift,
                    cashup:cashup,
                    outlet_name:outlet_name,
                    outlet:outlet,  
                    to:outlet_name, 
                    receipt_number:receipt_number, 
                    receipt_number_:this.NUMBER_SAME_LENGTH(receipt_number,5),

                    payment_method:payment_method,  
                    payment_method_name:payment_method_name,  

                    company:company,  
                    company_name:company_name,  

                    receivable:receivable, 
                    receivable_name:receivable_name, 
                    till:till, 
                    till_name:till_name, 
                    teller:teller, 
                    teller_name:teller_name, 
                    customer:customer, 
                    customer_name:customer_name, 
                    customer_names:customer_names, 
                    customer_surname:customer_surname, 
                    joined_items_departments:joined_items_departments, 
                    joined_items:joined_items, 
                    items_size:sale_order_size, 
                    total_cost_price:total_cost_price, 
                    total_cost_price_:this.money(total_cost_price), 
                    total_sold_price:total_sold_price, 
                    total_sold_price_:this.money(total_sold_price), 
                    total_quantity:total_quantity,  
                    total_quantity_:total_quantity+"/"+sale_order_size,  
                    code_:this.NUMBER_SAME_LENGTH(element.code),
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at), 
                    created_at_time:this.toTime(element.created_at), 
                    local_created_at_:this.toDateTime(element.local_created_at), 
                    local_created_at_date:this.toDate(element.local_created_at), 
                    local_created_at_time:this.toTime(element.local_created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server),
                    created_at_server_date:this.toDate(element.created_at_server),
                    created_at_server_time:this.toTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_SALE_ORDER',
                    page:NAME, 
                })
            }
        }, 
        JOIN_SALE_ORDER_ITEMS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_SALE_ORDER_ITEM(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_SALE_ORDER_ITEMS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_SALE_ORDER_ITEM(element,NUMBER = 1){
            try {
                if(!element){return null} 

                //order
                let order_key = element.order_key
                let order = store.getters.getStockOrderByKey(order_key)
                let order_name = order?order.name:'--' 
                
                //Item
                let item_key = element.item_key
                let item = store.getters.getItemByKey(item_key) 
                let item_name = item?item.name:'--' 

                let category_code = item?.category_code 
                let item_category = store.getters.getItemCategoryByCode(category_code)
                let category_name = item_category?item_category.name:'--'

                //department
                let item_department_code = (item?.item_department_code) ?(item?.item_department_code) : (item_category?.item_department_code)
                let item_department = store.getters.getItemDepartmentByCode(item_department_code)
                let item_department_name = item_department?item_department.name:'--'

                // let join_item = null
                // if (item) {
                //     join_item = this.JOIN_ITEM(item)
                // } 
                    
                let newItem = {
                    ...element,
                    no:NUMBER, 
                    order:order,
                    order_name:order_name, 
                    item:item,
                    // join_item:join_item,
                    item_name:item_name,  
                    item_category:item_category,  
                    category_name:category_name,  
                    item_department:item_department,  
                    item_department_name:item_department_name,  
                    item_department_code:item_department_code,  
                    sold_price_:this.money(element.sold_price), 
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_SALE_ORDER_ITEM',
                    page:NAME, 
                })
            }
        }, 
        JOIN_COMPANIES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_COMPANY(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_COMPANIES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_COMPANY(element,NUMBER = 1){
            try {
                if(!element){return null} 

                //check company
                let key = element.key
                let company_name = element.company_name
                if (!company_name) {
                    let company = store.getters.getCompanyByKey(key)
                    if (company) {
                        element = {
                            ...company,
                            ...element
                        } 
                    }  
                }    
                let uid = element.created_uid
                let user = store.getters.getUserByUid(uid)
                let user_name = user?user.surname+' '+user.names:'--'
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    user:user,
                    user_name:user_name,
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_COMPANY',
                    page:NAME, 
                })
            }
        }, 
        JOIN_QUOTATIONS_AND_INVOICES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_QUOTATION_AND_INVOICE(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_QUOTATIONS_AND_INVOICES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_QUOTATION_AND_INVOICE(element,NUMBER = 1){
            try {
                if(!element){return null} 
                //category
                let outlet_code = element.outlet_code
                let outlet = store.getters.getStockOutletByCode(outlet_code)
                let outlet_name = outlet?outlet.name:'--' 
                
                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 
                let supplier_name = supplier?supplier.name:'--' 

                //calculate order items 
                let items = element.items
                let items_size = this.SIZE(items)
                let total_price = 0
                let total_quantity = 0
                if (items) {
                    items.forEach(item => {
                        let price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let sold = quantity*(price-discount)
                        total_price = total_price+sold
                        total_quantity = total_quantity+quantity 
                    }); 
                } 
                let joined_items = this.JOIN_ITEMS(items) 

                let icon_name = "mdi-file-clock"
                let type_name = ''
                if (element.order_type == DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value) {
                    icon_name = "mdi-file-clock-outline"
                    type_name = DATA.ITEMS.REQUEST_FOR_QUOTATIONS.name
                }else if (element.order_type == DATA.ITEMS.QUOTATIONS.value) {
                    icon_name = "mdi-file-check-outline"
                    type_name = DATA.ITEMS.QUOTATIONS.name
                }else if (element.order_type == DATA.ITEMS.INVOICES.value) {
                    icon_name = "mdi-file-certificate-outline "
                    type_name = DATA.ITEMS.INVOICES.name
                }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                    icon_name = "mdi-file-certificate-outline " 
                    if (this.TEXT_UP0(element.payment_method)=="bill") {
                        type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Invoice)' 
                    }else if (this.TEXT_UP0(element.payment_method)=="cash") {
                        type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Cash)' 
                    }else if (this.TEXT_UP0(element.payment_method)=="check") {
                        type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Check)' 
                    }else{
                        type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Credit Card)' 
                    } 
                }
                let moment = DateBus.moment
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    outlet_name:outlet_name,
                    outlet:outlet,  
                    to:outlet_name, 
                    supplier:supplier, 
                    supplier_name:supplier_name, 
                    from:supplier_name,
                    joined_items:joined_items, 
                    items_size:items_size, 
                    total_price:total_price, 
                    total_price_:this.money(total_price), 
                    total_quantity:total_quantity,  
                    total_quantity_:total_quantity+"/"+items_size,   
                    icon:icon_name,
                    type_name:type_name,
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at),
                    created_at_time_:this.toTime(element.created_at),
                    local_created_at_:this.convertDate(element.local_created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_QUOTATION_AND_INVOICE',
                    page:NAME, 
                })
            }
        }, 
        JOIN_TILLS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_TILL(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_TILLS',
                    page:NAME, 
                })
            }
        },     
        JOIN_TILL(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let outlet_code = element.outlet_code
                let outlet = store.getters.getStockOutletByCode(outlet_code)   
                let outlet_name = outlet?outlet.name:'...'
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    outlet:outlet,  
                    outlet_name:outlet_name,  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_TILL',
                    page:NAME, 
                })
            }
        }, 
        JOIN_PRINTERS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_PRINTER(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_PRINTERS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_PRINTER(element,NUMBER = 1){
            try {
                if(!element){return null} 
                let printer_key = element.printer_key
                let printer_code = element.printer_code
                if (printer_key) { 
                    let item = store.getters.getDeviceByKey(printer_key)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                }else if (printer_code) { 
                    let item = store.getters.getDeviceByCode(printer_code)
                    if (item) {
                        element={
                            ...item,
                            ...element,
                        }
                    }
                } 
                let printer_type_code = element.printer_type_code
                let printer_type = store.getters.getPrinterTypeByCode(printer_type_code)   
                let printer_type_name = printer_type?printer_type.name:'...'
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    printer_type:printer_type,  
                    printer_type_name:printer_type_name,  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_TILL',
                    page:NAME, 
                })
            }
        }, 
        JOIN_ASSETS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_ASSET(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ASSETS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_ASSET(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let asset_type_code = element.asset_type_code
                let asset_type = store.getters.getAssetTypeByCode(asset_type_code)   
                let asset_type_name = asset_type?asset_type.name:'...'
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    asset_type:asset_type,  
                    asset_type_name:asset_type_name,  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_ASSET',
                    page:NAME, 
                })
            }
        }, 
        JOIN_RECEIVABLES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_RECEIVABLE(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_RECEIVABLES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_RECEIVABLE(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code
                //calculate order items
                let receivable_order_items = this.ReceivableSaleOrderItemsByReceivableCode({
                    receivable_code:code
                })   
                let receivable_order_items_size = this.SIZE(receivable_order_items)
                let total_sold_price = 0
                let total_sold_quantity = 0
                if (receivable_order_items) {
                    receivable_order_items.forEach(item => {
                        let sold_price = toNumber(item.sold_price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let sold = quantity*(sold_price-discount)
                        total_sold_price = total_sold_price+sold
                        total_sold_quantity = total_sold_quantity+quantity 
                    }); 
                } 
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    receivable_order_items:receivable_order_items,  
                    receivable_order_items_size:receivable_order_items_size,  
                    total_sold_price:total_sold_price,  
                    total_sold_price_:this.money(total_sold_price),  
                    total_sold_quantity:total_sold_quantity,  
                    transactions_amount:this.money(0),  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_RECEIVABLE',
                    page:NAME, 
                })
            }
        }, 
        JOIN_EXPENSE_ACCOUNTS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_EXPENSE_ACCOUNT(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_EXPENSE_ACCOUNTS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_EXPENSE_ACCOUNT(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code
                //Expense Account
                let expense_category_code = element.expense_category_code
                let expense_category = null
                if (toNumber(expense_category_code)<0) {
                    expense_category =  store.getters.getDefaultExpenseCategoryByCode(expense_category_code) 
                } else {
                    expense_category =  store.getters.getExpenseCategoryByCode(expense_category_code) 
                } 
                let expense_category_name = expense_category?expense_category.name:"Unknown" 

                //calculate order items 
                let expenses = this.ExpensesByAccountCode({
                    code:code
                })   
                let expenses_size = this.SIZE(expenses)
                let total_amount = 0 
                let join_expenses = null 
                if (expenses) {
                    expenses.forEach(item => {
                        let amount = toNumber(item.amount)
                        total_amount = total_amount+amount 
                    }); 
                    join_expenses = this.JOIN_EXPENSES(expenses)
                } 
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    expense_category:expense_category,  
                    expense_category_name:expense_category_name,   
                    expenses:expenses,  
                    join_expenses:join_expenses,  
                    expenses_size:expenses_size,    
                    total_amount:total_amount,  
                    total_amount_:this.money(total_amount),  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_EXPENSE_ACCOUNT',
                    page:NAME, 
                })
            }
        },
        JOIN_EXPENSE_CATEGORIES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_EXPENSE_CATEGORY(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_EXPENSE_CATEGORIES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_EXPENSE_CATEGORY(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code 

                //calculate order items 
                let expenses = this.ExpensesByCategoryCode({
                    code:code
                })   
                let expenses_size = this.SIZE(expenses)
                let total_amount = 0 
                let join_expenses = null 
                if (expenses) {
                    expenses.forEach(item => {
                        let amount = toNumber(item.amount)
                        total_amount = total_amount+amount 
                    }); 
                    join_expenses = this.JOIN_EXPENSES(expenses)
                } 
                     
                let newItem = {
                    ...element,
                    no:NUMBER,   
                    expenses:expenses,  
                    join_expenses:join_expenses,  
                    expenses_size:expenses_size,    
                    total_amount:total_amount,  
                    total_amount_:this.money(total_amount),  
                    created_at_:this.convertDate(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_EXPENSE_CATEGORY',
                    page:NAME, 
                })
            }
        }, 
        JOIN_CASHBOOKS(items){
            try {
                if(!items){return null}
                items.sort(this.dynamicSort("local_created_at",true)) 
                let JOIN = []
                let NUMBER = 0 
                let TOTAL_BALANCE = 0
                items.forEach(element => {
                    NUMBER = NUMBER+1  
                    let cashbook = this.JOIN_CASHBOOK(element,NUMBER)
                    if (cashbook) {
                        const total_deposit = toNumber(cashbook.total_deposit)
                        const total_paid = toNumber(cashbook.total_paid)
                        const total_balance = TOTAL_BALANCE + (total_deposit-total_paid)
                        TOTAL_BALANCE = total_balance
                        const total_balance_ = this.money(total_balance)
                        JOIN.push({
                            ...cashbook,
                            total_balance:total_balance,
                            total_balance_:total_balance_,
                        })  
                    }
                }); 
                JOIN.sort(this.dynamicSort("local_created_at",false)) 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CASHBOOKS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_CASHBOOK(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code

                let invoice = null 
                let total_paid = 0 
                let total_deposit = 0  

                //payee
                let payee = null
                let payee_name = null
                let order_type_name = null

                if (!element.created_at) {
                    element.created_at = element.local_created_at?
                        element.local_created_at:element.updated_at
                }
                
                //customer
                let customer_key = element.customer_key
                let customer = store.getters.getCustomerByKey(customer_key) 
                let customer_name = customer?customer.name:'Unknown' 

                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 
                let supplier_name = supplier?supplier.name:'--' 

                //Find Invoice
               
              

                if (element.order_type == DATA.ITEMS.DAYS_SHIFTS_SALES.values) {
                    payee = null
                    payee_name = DATA.ITEMS.DAYS_SHIFTS_SALES.names
                    order_type_name = "Sales ("+element.operating_date+")"
                    total_deposit = element.amount
                }else if (element.order_type == DATA.ITEMS.PAYMENTS.values) {
                    let transaction_key = element.transaction_key
                    let transaction_type = element.transaction_type
                    let transaction = element.transaction 
                    if (transaction_type == DATA.ITEMS.INVOICES.value) {
                        if (!transaction) {
                            transaction = store.getters
                        }
                        total_deposit = element.amount
                        order_type_name =  DATA.ITEMS.INVOICES.name+" "+DATA.ITEMS.PAYMENTS.name 
                    } else {
                        if (!transaction) {
                            transaction = store.getters
                        }
                        total_paid = element.amount
                        order_type_name =  DATA.ITEMS.BILLS.name+" "+DATA.ITEMS.PAYMENTS.name 
                    }
                    payee = supplier
                    payee_name = supplier_name
                    
                }else if (element.order_type == DATA.ITEMS.EXPENSES.values) {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name =  DATA.ITEMS.EXPENSES.name
                    total_paid = element.amount
                }else {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = "Unknown"
                }
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  

                    customer:customer,  
                    customer_name:customer_name,  
                    
                    supplier:supplier,  
                    supplier_name:supplier_name,  
                    supplier_code:supplier_code,  
                    
                    payee:payee,  
                    payee_name:payee_name,  

                    order_type_name:order_type_name,  
  
                    total_paid:toNumber(total_paid),  
                    total_paid_:this.money(total_paid),  
                    total_deposit:total_deposit,  
                    total_deposit_:this.money(total_deposit),   
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at), 
                    created_at_time:this.toTime(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CASHBOOK',
                    page:NAME, 
                })
            }
        }, 
        backup_JOIN_CASHBOOK(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code

                let invoice = null 
                let total_paid = 0 
                let total_deposit = 0  

                //payee
                let payee = null
                let payee_name = null
                let order_type_name = null

                if (!element.created_at) {
                    element.created_at = element.local_created_at?
                        element.local_created_at:element.updated_at
                }
                
                //customer
                let customer_key = element.customer_key
                let customer = store.getters.getCustomerByKey(customer_key) 
                let customer_name = customer?customer.name:'Unknown' 

                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 
                let supplier_name = supplier?supplier.name:'--' 

                //Find Invoice
               
                if (element.transaction_type == DATA.ITEMS.INVOICES.value) {
                    invoice = store.getters.getQuotationAndInvoiceByKey(element.transaction_key)
                    element.order_type = DATA.ITEMS.INVOICES.value
                    total_paid = toNumber(element.amount)
                }else if (element.transaction_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                    let order = store.getters.getStockOrderByKey(element.transaction_key)
                    let join = this.JOIN_STOCK_ORDER(order)
                    if (join) { 
                        element = {
                            ...element,
                            ...join,
                        }
                    } 
                    element.order_type = DATA.ITEMS.STOCK_RECEIVES.value+"_"+DATA.ITEMS.PAYMENTS.value
                    total_paid = toNumber(element.amount)
                }else if (element.order_type == DATA.ITEMS.INVOICES.value) {
                    let join = this.JOIN_QUOTATION_AND_INVOICE(element) 
                    if (join) { 
                        element = {
                            ...element,
                            ...join,
                        }
                    } 
                    element.order_type = DATA.ITEMS.INVOICES.value+'_'+DATA.ITEMS.ORDERS.value
                }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                    let join = this.JOIN_STOCK_ORDER(element) 
                    if (join) { 
                        element = {
                            ...element,
                            ...join,
                        }
                    } 
                }else if (element.transaction_type == DATA.ITEMS.SALE_ORDERS.value) { 
                    let sale_order = store.getters.getSaleOrderByKey(element.transaction_key)
                    let join = this.JOIN_SALE_ORDER(sale_order)
                    if (join) {
                        payee =  join.customer
                        payee_name =  join.customer_name 
                        element = {
                            ...join,
                            ...element,
                        }
                    } else {
                        element = {
                            ...sale_order,
                            ...element,
                        }
                    } 
                    element.order_type = DATA.ITEMS.PAYMENTS.value
                    total_deposit = toNumber(element.amount)
                }else if (element.transaction_type == DATA.ITEMS.EXPENSES.value) { 
                    let expense = store.getters.getExpenseByKey(element.transaction_key)
                    let join = this.JOIN_EXPENSE(expense)
                    if (join) {
                        payee =  join.supplier
                        payee_name =  join.supplier_name 
                        element = {
                            ...join,
                            ...element,
                        }
                    } else {
                        element = {
                            ...expense,
                            ...element,
                        }
                    } 
                    element.order_type = DATA.ITEMS.COMPANY_EXPENSES.value
                    // total_deposit = toNumber(element.amount)
                }else if (element.transaction_type == DATA.ITEMS.OPENING_BALANCE.value) {  
                    element.order_type = DATA.ITEMS.OPENING_BALANCE.value 
                    
                }else if (element.order_type == DATA.ITEMS.SALE_ORDERS.value) {  
                    let join = this.JOIN_SALE_ORDER(element)
                    if (join) {
                        payee =  join.customer
                        payee_name =  join.customer_name 
                        element = {
                            ...element,
                            ...join,
                        }
                    } else {
                        element = {
                            ...element,
                            ...sale_order,
                        }
                    }  
                }

                let joined_invoice = this.JOIN_QUOTATION_AND_INVOICE(invoice)
                if (joined_invoice) {
                    supplier = joined_invoice.supplier
                    supplier_code = joined_invoice.supplier_code
                    supplier_name = joined_invoice.supplier_name
                }

                 

                if (element.order_type == DATA.ITEMS.SALE_ORDERS.value) {
                    payee = element.customer
                    payee_name = element.customer_name
                    order_type_name = DATA.ITEMS.SALES.name
                    total_deposit = element.total_sold_price
                }else if (element.order_type == DATA.ITEMS.PAYMENTS.value) { 
                    payee = element.customer
                    payee_name = element.customer_name+' ( by '+element.receivable_name+')'
                    order_type_name = DATA.ITEMS.INVOICES.name
                    // total_deposit = element.total_sold_price
                }else if (element.order_type == DATA.ITEMS.INVOICES.value) { 
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = DATA.ITEMS.BILLS.name 
                }else if (element.order_type == DATA.ITEMS.INVOICES.value+'_'+DATA.ITEMS.ORDERS.value) {  
                    payee = this.selectedCompany
                    payee_name = this.selectedCompany?this.selectedCompany.company_name+" ("+element.payment_method+" to "+element.supplier_name+")":'--'
                    order_type_name = DATA.ITEMS.INVOICES.name 
                    total_paid = toNumber(element.total_price)
                }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value+"_"+DATA.ITEMS.PAYMENTS.value) {  
                    payee = this.selectedCompany
                    payee_name = this.selectedCompany?this.selectedCompany.company_name+
                        " ("+element.payment_method+" to "+element.supplier_name+")":'--'
                    order_type_name = DATA.ITEMS.STOCK_RECEIVES.name 
                }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {  
                    payee = this.selectedCompany
                    payee_name = this.selectedCompany?this.selectedCompany.company_name+" ("+element.payment_method+" to "+element.supplier_name+")":'--'
                    order_type_name = DATA.ITEMS.STOCK_RECEIVES.name 
                    total_paid = toNumber(element.total_price)
                }else if (element.order_type == DATA.ITEMS.EXPENSES.value) {  
                    payee = this.selectedCompany
                    payee_name = this.selectedCompany?this.selectedCompany.company_name+" ("+element.payment_method+" to "+supplier_name+")":'--'
                    order_type_name = DATA.ITEMS.EXPENSES.name 
                    total_paid = toNumber(element.amount)
                }else if (element.order_type == DATA.ITEMS.COMPANY_EXPENSES.value) {  
                    payee = this.selectedCompany
                    payee_name = this.selectedCompany?this.selectedCompany.company_name+" ("+element.payment_method+" to "+element.supplier_name+")":'--'
                    order_type_name = DATA.ITEMS.EXPENSES.name
                    total_paid = toNumber(element.amount)
                }else if (element.order_type == DATA.ITEMS.OPENING_BALANCE.value) {  
                    payee = this.selectedCompany
                    payee_name = this.selectedCompany?this.selectedCompany.company_name:'--'
                    order_type_name = DATA.ITEMS.OPENING_BALANCE.name
                    total_deposit = toNumber(element.amount)
                }else {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = "Unknown"
                }
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  

                    customer:customer,  
                    customer_name:customer_name,  
                    
                    supplier:supplier,  
                    supplier_name:supplier_name,  
                    supplier_code:supplier_code,  
                    
                    payee:payee,  
                    payee_name:payee_name,  

                    order_type_name:order_type_name,  
  
                    total_paid:toNumber(total_paid),  
                    total_paid_:this.money(total_paid),  
                    total_deposit:total_deposit,  
                    total_deposit_:this.money(total_deposit),   
                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at), 
                    created_at_time:this.toTime(element.created_at), 
                    created_at_server_:this.convertDate(element.created_at_server),
                    updated_at_:this.convertDate(element.updated_at), 
                    updated_at_server_:this.convertDate(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_CASHBOOK',
                    page:NAME, 
                })
            }
        }, 
        JOIN_BILLS(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_BILL(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BILLS',
                    page:NAME, 
                })
            }
        }, 
        JOIN_BILL(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code
                let key = element.key
                
                //customer
                let customer_key = element.customer_key
                let customer = store.getters.getCustomerByKey(customer_key) 
                let customer_name = customer?customer.name:'Unknown' 

                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 
                let supplier_name = supplier?supplier.name:'--' 

                //calculate order items
                let getOrderItem = ()=>{
                    const res =  this.ORDERS_ITEMS({
                        orders:[element]
                    })
                    let data = res?res.data:null
                    let success = res?res.success:null
                    return  success?data:null   
                }
                let order_items =  getOrderItem() 
                let order_items_size = this.SIZE(order_items)
                let total_price = 0 
                let total_quantity = 0
                if (order_items) {
                    order_items.forEach(item => {
                        let price = toNumber(item.price)
                        let discount = toNumber(item.discount)
                        let quantity = toNumber(item.quantity)
                        let items_price = quantity*(price-discount)
                        total_price = total_price+items_price
                        total_quantity = total_quantity+quantity 
                    }); 
                } 

                //calculate payments
                let payments = this.PaymentsByTransactionKey(key)
                
                let payments_size = this.SIZE(order_items)
                let total_paid = 0  
                if (payments) {
                    payments.forEach(item => {
                        const paid = toNumber(item.amount) 
                        total_paid = total_paid+paid
                    }); 
                }  


                //payee
                let payee = null
                let payee_name = null
                let order_type_name = null
                let amount = 0
                let balance = 0 
                if (element.order_type == DATA.ITEMS.INVOICES.value) {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = DATA.ITEMS.INVOICES.name
                    amount = total_price
                    balance = total_price-total_paid
                }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = DATA.ITEMS.STOCK_RECEIVES.name
                    amount = total_price
                    balance = total_price-total_paid
                }else if (element.order_type == DATA.ITEMS.EXPENSES.value) {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = DATA.ITEMS.EXPENSES.name
                    amount = toNumber(element.amount)
                    balance = toNumber(element.amount)-total_paid
                }else {
                    payee = supplier
                    payee_name = supplier_name
                    order_type_name = DATA.ITEMS.BILLS.name
                    balance = total_price-total_paid
                }
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  

                    customer:customer,  
                    customer_name:customer_name,  
                    
                    supplier:supplier,  
                    supplier_name:supplier_name,  
                    
                    payee:payee,  
                    payee_name:payee_name,  
                    
                    order_type_name:order_type_name,  

                    order_items:order_items,  
                    order_items_size:order_items_size,  
                    total_price:total_price,  
                    total_price_:this.money(total_price),  
                    total_quantity:total_quantity, 
                    total_quantity_:total_quantity+'/'+order_items_size, 

                    amount:amount,   
                    balance:balance,   
                    payments:payments,   
                    payments_size:payments_size,   
                    total_paid:total_paid,   
                    amount_:this.money(amount),   
                    balance_:this.money(balance),  
                    total_paid_:this.money(total_paid),   

                    bill_date_:this.toDate(element.bill_date),
                    bill_due_date_:this.toDate(element.bill_due_date),

                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at),
                    created_at_time:this.toTime(element.created_at),
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_BILL',
                    page:NAME, 
                })
            }
        }, 
        JOIN_INVOICES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_INVOICE(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_INVOICES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_INVOICE(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code
                let key = element.key
                //totals
                let total_price = 0
                let total_quantity = 0
                let total_paid = 0  
                //payee
                let payee = null
                let payee_name = null
                let order_items = null
                let order_type_name = null
                let order_items_size = 0
                let amount = 0
                let balance = 0 

                //customer
                let customer_key = null
                let customer = null
                let customer_name = 'Unknown'  
                //supplier
                let supplier_code = null
                let supplier = null
                let supplier_name = 'Unknown'
                //supplier
                let receivable_code = null
                let receivable = null
                let receivable_name = 'Unknown'

                //check if sales
                if (element.order_type == DATA.ITEMS.SALE_ORDERS.value) {
                    code = key
                    let join = this.JOIN_SALE_ORDER(element)
                    if (join) {
                        element = {
                            ...element,
                            join
                        }
                        customer = join.customer
                        customer_name = join.customer_name
                        receivable = join.receivable
                        receivable_name = join.receivable_name
                        total_price = join.total_sold_price
                        total_quantity = join.total_quantity 
                    } 
                } else {
                    //customer
                    customer_key = element.customer_key
                    customer = store.getters.getCustomerByKey(customer_key) 
                    customer_name = customer?customer.name:customer_name
    
                    //supplier
                    supplier_code = element.supplier_code
                    supplier = store.getters.getSupplierByCode(supplier_code) 
                    supplier_name = supplier?supplier.name:supplier_name
    
                    //calculate order items
                    let getOrderItem = ()=>{
                        const res =  this.ORDERS_ITEMS({
                            orders:[element]
                        })
                        let data = res?res.data:null
                        let success = res?res.success:null
                        return  success?data:null   
                    }
                    order_items =  getOrderItem() 
                    order_items_size = this.SIZE(order_items)
                    if (order_items) {
                        order_items.forEach(item => {
                            let price = toNumber(item.price)
                            let discount = toNumber(item.discount)
                            let quantity = toNumber(item.quantity)
                            let items_price = quantity*(price-discount)
                            total_price = total_price+items_price
                            total_quantity = total_quantity+quantity 
                        }); 
                    }  
                }
                

                //calculate payments
                let payments = this.PaymentsByTransactionKey(key) 
                let payments_size = this.SIZE(payments)
                
                if (payments) {
                    payments.forEach(item => {
                        const paid = toNumber(item.amount) 
                        total_paid = total_paid+paid
                    }); 
                }  

 
                if (element.order_type == DATA.ITEMS.SALE_ORDERS.value) {
                    payee = receivable
                    payee_name = receivable_name
                    order_type_name = DATA.ITEMS.SALES.name
                    amount = total_price
                    balance = total_price-total_paid
                }else {
                    payee = receivable
                    payee_name = receivable_name
                    order_type_name = "Unknown"
                    balance = total_price-total_paid
                }
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    code:code,  

                    customer:customer,  
                    customer_name:customer_name,  
                    
                    supplier:supplier,  
                    supplier_name:supplier_name,  
                    
                    payee:payee,  
                    payee_name:payee_name,  

                    receivable:receivable,  
                    receivable_name:receivable_name,  
                    
                    order_type_name:order_type_name,  

                    order_items:order_items,  
                    order_items_size:order_items_size,  
                    total_price:total_price,  
                    total_price_:this.money(total_price),  
                    total_quantity:total_quantity, 
                    total_quantity_:total_quantity+'/'+order_items_size, 

                    amount:amount,   
                    balance:balance,   
                    payments:payments,   
                    payments_size:payments_size,   
                    total_paid:total_paid,   
                    amount_:this.money(amount),   
                    balance_:this.money(balance),  
                    total_paid_:this.money(total_paid),   

                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at),
                    created_at_time:this.toTime(element.created_at),
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_INVOICE',
                    page:NAME, 
                })
            }
        }, 
        JOIN_EXPENSES(item){
            try {
                if(!item){return null}
                let JOIN = []
                let NUMBER = 0 
                item.forEach(element => {
                    NUMBER = NUMBER+1  
                    JOIN.push(this.JOIN_EXPENSE(element,NUMBER)) 
                }); 
                return JOIN 
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_EXPENSES',
                    page:NAME, 
                })
            }
        }, 
        JOIN_EXPENSE(element,NUMBER = 1){
            try {
                if(!element){return null}  
                let code = element.code
                 

                  
                //supplier
                let supplier_code = element.supplier_code
                let supplier = store.getters.getSupplierByCode(supplier_code) 
                let supplier_name = supplier?supplier.name:"Unknown"
                
                //Expense Account
                let expense_account_code = element.expense_account_code
                let expense_account = store.getters.getExpenseAccountByCode(expense_account_code) 
                let expense_account_name = expense_account?expense_account.name:"Unknown"

                 
                     
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    code:code,  
 
                    
                    supplier:supplier,  
                    supplier_name:supplier_name,  
                    expense_account:expense_account,  
                    expense_account_name:expense_account_name,   
   
                    amount_:this.money(element.amount),    

                    created_at_:this.toDateTime(element.created_at), 
                    created_at_date:this.toDate(element.created_at),
                    created_at_time:this.toTime(element.created_at),
                    created_at_server_:this.toDateTime(element.created_at_server),
                    updated_at_:this.toDateTime(element.updated_at), 
                    updated_at_server_:this.toDateTime(element.updated_at_server), 
                }
                return newItem
            } catch (error) {
                this.error({
                    error:error,
                    from:'JOIN_INVOICE',
                    page:NAME, 
                })
            }
        },  

        //
        FIND_COMPANY_USER_BY_CARD_CODE(payload){ 
            let response= {
                page:NAME,
                from:"FIND_COMPANY_USER_BY_CARD_CODE",
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {
                    let card_code = payload?.card_code
                    let users = this.JOIN_USERS(this.CompanyUsers) 
                    if(!card_code){
                        reject({error:"card_code required"})
                        return
                    }if(!users){
                        reject({error:"no users"}) 
                        return
                    }
                    let user = users.find(user=>user.card_code ==card_code)
                    if (!user) {
                        reject({
                            success:false,
                            error:"not user with the card"
                        })
                        return
                    }
                    resolve({
                        success:true,
                        user:user, 
                    })
                } catch (error) {
                    response.error = error
                    this.error(response)
                    return response
                }  
            })
        }, 

        
        //ORDER FUNCTIONS
        ARCHIVED(payload){ 
            let response= {
                page:NAME,
                from:"ARCHIVED",
                payload:payload
            }
            try {
                if (!payload) { 
                    return {
                        ...response,
                        error:'no payload'
                    }
                }
                let items = payload?payload.items:null
                if (!items) { 
                    return {
                        ...response,
                        error:'payload required'

                    }
                }
                let filtered_items = items.filter(item=>{
                    return item.archive == true
                })
                return {
                    data:filtered_items,
                    success:true, 
                }
            } catch (error) {
                response.error = error
                this.error(response)
                return response
            } 
        }, 
        UN_ARCHIVED(payload){
            let response= {
                page:NAME,
                from:"UN_ARCHIVED",
                payload:payload
            }
            try {
                if (!payload) { 
                    return {
                        ...response,
                        error:'no payload'
                    }
                }
                let items = payload?payload.items:null
                if (!items) { 
                    return {
                        ...response,
                        error:'payload required' 
                    }
                }
                let filtered_items = items.filter(item=>{
                    return !item.archive  
                })
                return {
                    data:filtered_items,
                    success:true, 
                }
            } catch (error) {
                response.error = error
                this.error(response)
                return response
            }  
        }, 
        ORDERS_ITEMS(payload){
            let response= {
                page:NAME,
                from:"ORDERS_ITEMS",
                payload:payload
            }
            try {
                if (!payload) { 
                    return {
                        ...response,
                        error:'no payload'
                    }
                }
                let orders = payload?payload.orders:null
                let values = payload?payload.values:null
                if (!orders) { 
                    return {
                        ...response,
                        error:'payload required' 
                    }
                }
                let order_items = []
                orders.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            if (values) {
                                values.forEach(value => {
                                    item[value.item_value] = element.order_value
                                }); 
                            } 
                            order_items.push(item)   
                        });
                    }
                });
                return {
                    data:order_items,
                    success:true, 
                }
            } catch (error) {
                response.error = error
                this.error(response)
                return response
            }  
        }, 
        ORDER_ITEMS_BY_VALUE(payload){
            let response= {
                page:NAME,
                from:"ORDERS_ITEMS",
                payload:payload
            }
            try {
                if (!payload) { 
                    return {
                        ...response,
                        error:'no payload'
                    }
                }
                let items = payload?payload.items:null
                let value = payload?payload.value:null
                let key = payload?payload.key:null
                let orders = payload?payload.orders:null
                if (!orders||!items||!value||!key) { 
                    return {
                        ...response,
                        error:'payload required' 
                    }
                }
                 
                let filtered_items = items.filter(item=>{
                    return item[value] == key
                })
                return {
                    data:filtered_items,
                    success:true, 
                }
            } catch (error) {
                response.error = error
                this.error(response)
                return response
            }  
        }, 
        ITEMS_BY_VALUE(payload){
            let response= {
                page:NAME,
                from:"ORDERS_ITEMS",
                payload:payload
            }
            try {
                if (!payload) { 
                    return {
                        ...response,
                        error:'no payload'
                    }
                }
                let items = payload?payload.items:null
                let value = payload?payload.value:null
                let key = payload?payload.key:null
                if (!items||!value||!key) { 
                    return {
                        ...response,
                        error:'payload required' 
                    }
                }
                let filtered_items = items.filter(item=>{
                    return item[value] == key
                })
                return {
                    data:filtered_items,
                    success:true, 
                }
            } catch (error) {
                response.error = error
                this.error(response)
                return response
            }  
        }, 
        ITEM_BY_VALUE(payload){
            let response= {
                page:NAME,
                from:"ITEM_BY_VALUE",
                payload:payload
            }
            try {
                if (!payload) { 
                    return {
                        ...response,
                        error:'no payload'
                    }
                }
                let items = payload?payload.items:null
                let value = payload?payload.value:null
                let key = payload?payload.key:null
                if (!items||!value||!key) { 
                    return {
                        ...response,
                        error:'payload required' 
                    }
                }
                let filtered_items = items.find(item=>{
                    return item[value] == key
                })
                return {
                    data:filtered_items,
                    success:true, 
                }
            } catch (error) {
                response.error = error
                this.error(response)
                return response
            }  
        }, 

        //---------------[SALES CALCULATIONS]------------ 
        CHECK_ITEMS_STOCK_SETTINGS(payload){
            let response = {
                stop_selling:false, 
                ordering_remind:false, 
                stock_settings:null,
                stop_sell_items:null,
                ordering_remind_items:null,

                page:NAME,
                from:"CHECK_ITEMS_STOCK_SETTINGS",
                payload:payload
            }  
            try { 


                let items = payload?payload.items:null  
                let outlet_code = payload?payload.outlet_code:null   
                let notify = payload?payload.notify:null   
                if (!items) { 
                    response.error = "no items"
                    return response
                }

                let stock_settings = []
                let stop_sell_items = []
                let ordering_remind_items = []
                items.forEach(item => {
                    let stock_setting = this.CHECK_ITEM_STOCK_SETTINGS({
                        outlet_code:outlet_code,
                        item:item,
                        quantity:item.quantity, 
                    }) 
                    stock_settings.push(stock_setting)
                    if (stock_setting) {
                        if (!stock_setting.allow_sell) {
                           stop_sell_items.push({
                                ...stock_setting,
                                name:item.name
                            }) 
                           response.stop_selling = true
                        }
                        if (stock_setting.ordering_remind) {
                            ordering_remind_items.push({
                                ...stock_setting,
                                name:item.name
                            }) 
                            response.ordering_remind = true
                        }
                    }
                });

                if (notify) {
                    let stop_sell_size = this.SIZE(stop_sell_items)
                    let ordering_remind_size = this.SIZE(ordering_remind_items)
                    let stop_sell_names = ''
                    let ordering_remind_names = ''
                    stop_sell_items.forEach(item => {
                        stop_sell_names = stop_sell_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity
                        +"\n Stop selling level: "+item.stop_selling_level
                    });
                    ordering_remind_items.forEach(item => {
                        ordering_remind_names = ordering_remind_names+"\n \n Item: "+item.name+"\n Quantity: "
                        +item.quantity+"\n Ordering level: "+item.ordering_remind_level
                    });

                    if (stop_sell_size&&ordering_remind_size) {
                        this.dialog({
                            show:true,
                            title:"OUT OF STOCK",
                            text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names
                                +" \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                +".",
                            btnYes:"OKAY"
                        })
                    }else if (stop_sell_size) {
                        this.dialog({
                            show:true,
                            title:"OUT OF STOCK",
                            text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names  
                                +".",
                            btnYes:"OKAY"
                        })
                    }else if (ordering_remind_size) {
                        this.dialog({
                            show:true,
                            title:"LOW ON STOCK LEVEL",
                            text:"Some of the selected item(s) are below order remind level. \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                +".",
                            btnYes:"OKAY"
                        })
                    }  
                } 
                return {
                    ...response, 
                    allow_sell:!response.stop_selling,
                    stock_settings:stock_settings,
                    stop_sell_items:stop_sell_items,
                    ordering_remind_items:ordering_remind_items,
                }
            } catch (error) {
                response = {
                    ...response,
                    error:error
                }
                this.error(response) 
            }
            
        }, 
        CHECK_ITEM_STOCK_SETTINGS(payload){
            let response = {
                allow_sell:false,
                ordering_remind:false,
                quantity:null,
                stop_selling_level:null,
                ordering_remind_level:null,
                active_settings:null,

                page:NAME,
                from:"CHECK_ITEM_STOCK_SETTINGS",
                payload:payload
            }   
            try { 
                let item_selected = payload?payload.item:null 
                let item_selected_name = item_selected?item_selected.name:null 
                let item_kits = item_selected?item_selected.item_kits:null 
                let item_key = item_selected?item_selected.key:null 
                let outlet_code = payload?payload.outlet_code:null  
                let quantity = this.toNumber(payload?payload.quantity:null)
                let notify = payload?payload.notify:null  
                quantity = quantity?quantity:1
                if (!item_selected) { 
                    response.error="no item_selected"
                    return response
                }
                //
                let sold_item = this.getSoldItem(item_selected.key)
                let sold_quantity = this.toNumber(sold_item?sold_item.sold_quantity:null) 
                let settings = this.CompanySettings
                let stock_settings = settings?settings.stock_settings:null
                let outlets_settings = stock_settings?stock_settings.outlets:null
                let outlet_stock_settings = outlets_settings?outlets_settings[outlet_code]:null

                let item_stock_settings = item_selected?item_selected.stock_settings:null 
                let active_settings = item_stock_settings?item_stock_settings:outlet_stock_settings?outlet_stock_settings:stock_settings

                response.active_settings = active_settings  

                if (active_settings && item_selected) {
                    let stop_selling_level = this.toNumber(active_settings.stop_selling_level)
                    let ordering_remind_level = this.toNumber(active_settings.ordering_remind_level)
                    let item_quantity = this.toNumber(item_selected['number_quantity'])
                    if (outlet_code) {
                        item_quantity = this.toNumber(item_selected[outlet_code+'_number_quantity'])
                    }
                    item_quantity = item_quantity-sold_quantity
                    response.sold_quantity = sold_quantity
                    response.quantity = item_quantity
                    response.stop_selling_level = stop_selling_level
                    response.ordering_remind_level = ordering_remind_level
                    
                    if ((item_quantity-quantity) < stop_selling_level) {
                        response.allow_sell = false
                        if (notify && !item_kits) {
                            this.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"NAME: "+item_selected_name 
                                    +"\n ITEM QUANTITY: "+item_quantity
                                    +"\n STOP SELLING LEVEL: "+stop_selling_level
                                    +"\n \n   The selected item is below selling level.",
                                btnYes:"OKAY"
                            })  
                        }
                    }else if ((item_quantity-quantity) < ordering_remind_level) {
                        response.allow_sell = true
                        response.ordering_remind = true
                        if (notify && !item_kits) {
                            this.dialog({
                                show:true,
                                title:"LOW ON STOCK LEVEL",
                                text:"NAME: "+item_selected_name 
                                    +"\n ITEM QUANTITY: "+item_quantity
                                    +"\n ORDER REMIND LEVEL: "+ordering_remind_level
                                    +"\n \n   The selected item is below ordering level.", 
                                btnYes:"OKAY"
                            }) 
                        }
                    }else{
                        response.allow_sell = true
                    }  

                    if (item_kits || item_key=='tip') { 
                        response.allow_sell = true
                    }
                }
                if (!active_settings) {
                    response.allow_sell = true
                }
                return response
            } catch (error) {
                response = {
                    ...response,
                    error:error
                }
                this.error(response) 
            } 
        }, 
        MERGE_DUPLICATED_ITEMS(payload){
            let response = { 
                page:NAME,
                from:"MERGE_DUPLICATED_ITEMS",
                payload:payload
            }   
            try {  
                const items = payload?.items 
                const merge_doc_type = payload?.merge_doc_type 
                let outlet = payload?.outlet 
                if (!outlet) {
                    outlet = this.CurrentOutlet
                }
                const receipt_merge_duplicate_items = outlet?.receipt_merge_duplicate_items 
                const bill_merge_duplicate_items = outlet?.bill_merge_duplicate_items 
                const docket_merge_duplicate_items = outlet?.docket_merge_duplicate_items 
                const order_merge_duplicate_items = outlet?.order_merge_duplicate_items 
                
                const merge = ()=>{
                    let merge_items = []  
                    if (items) {
                        items.forEach(item => {
                            const key = item.key
                            const _key = item._key
                            const code = item.code
                            const _code = item._code
                            let index = null
                            if (_key) {   
                                index = merge_items.findIndex(item=>item._key == _key)
                            }else if(_code) {
                                index = merge_items.findIndex(item=>item._code == _code)
                            }else if(key) {
                                index  = merge_items.findIndex(item=>item.key == key)
                            }else if(code) {
                                index = merge_items.findIndex(item=>item.code == code)
                            } 
    
                            const sold_price = toNumber(item.sold_price)
                            const sold_cost_price = toNumber(item.sold_cost_price)
                            const quantity = toNumber(item.quantity)
                            const discount = toNumber(item.discount)
    
                            if (index == -1) {
                                merge_items.push({
                                    ...item,
                                    sold_price:sold_price,
                                    sold_cost_price:sold_cost_price,
                                    quantity:quantity,
                                    discount:discount,
                                })
                            } else {
                                merge_items[index].quantity =  merge_items[index].quantity+quantity
                                merge_items[index].discount =  merge_items[index].discount+discount 
                            } 
                        });
                    }  
                    return merge_items
                }

                if(!items){return null}
                if (receipt_merge_duplicate_items && (merge_doc_type =="sale_order"||merge_doc_type =="receipt")) {
                    return  merge()
                }else if (bill_merge_duplicate_items && merge_doc_type =="bill") {
                    return  merge()
                }else if (docket_merge_duplicate_items && merge_doc_type =="docket") {
                    return  merge()
                }else if (order_merge_duplicate_items && merge_doc_type =="order") {
                    return  merge()
                }  
                return  items
            } catch (error) {
                response = {
                    ...response,
                    error:error
                }
                this.error(response) 
            } 
        }, 

        // CALCULATE 
        CALCULATE_TOTAL_CASHUPS(cashups,shift){
            try {  
                let total_floating_cash = shift?this.toNumber(shift.total_floating_cash):0
                let cashup_option = shift?shift.cashup_option:null

                let sales_retail = 0
                let sales_wholesales= 0

                let sales_on_account = 0 
                let total_pricing_tiers = []
                let day_shift_total_sales = 0

                let sales_cash_payments = 0
                let invoices_cash_payments= 0

                let sales_total_other_option_payments = 0
                let invoices_total_other_option_payments = 0
                let invoices_other_option_payments = [] 
                let sales_other_option_payments = []
                let all_other_option_payments = []
                
                let total_open_shortage = 0
                let total_open_shortages = 0
                let total_open_overages = 0

                let total_close_shortage = 0
                let total_close_shortages = 0
                let total_close_overages = 0
                

                let closing_cashes = 0
                let total_closing_cash = 0
                let opening_cashes = 0
                let total_opening_cash = 0 

                if (cashups) {
                    for (const cashup of cashups) {
                        day_shift_total_sales = day_shift_total_sales+this.toNumber(cashup.cashup_total_sales)

                        //INPUT PAYMENTS
                        const input_payments = cashup.input_payments?cashup.input_payments:cashup.InputPayments
                        let input_cash_payments = 0
                        let input_other_payments = 0

                        let shortage = this.toNumber(cashup.shortage)
                        let isShortage = cashup.isShortage

                        let opening_cash = this.toNumber(cashup.opening_cash )
                        // let expected_opening_cash = this.toNumber(cashup.expected_opening_cash )
                        let expected_opening_cash = 
                            cashup_option =='submit_cash_after_every_cashup'? 
                            this.toNumber(cashup.floating_cash ):
                            this.toNumber(cashup.expected_opening_cash )
                        let opening_shortage = opening_cash-expected_opening_cash
                        let isOpenShortage = opening_shortage<0?true:false

                        //Input payments
                        if (input_payments) { 
                            input_payments.forEach(payment => {
                                if (payment.key == "cash") {
                                    input_cash_payments = input_cash_payments+toNumber(payment.given_amount)
                                } else {
                                    input_other_payments = input_other_payments+toNumber(payment.given_amount)
                                    //add to sales
                                    let index = sales_other_option_payments.findIndex(option=>{
                                        if(!payment.key){
                                            return option.payment_option_code  == payment.code 
                                        }
                                        return option.payment_option_key  == payment.key
                                    }) 
                                    const pay_option = store.getters.getPaymentMethodByCode(payment.code)
                                    if (index == -1) {  
                                        sales_other_option_payments.push({ 
                                            ...payment, 
                                            payment_method:pay_option,
                                            payment_method_name:pay_option?pay_option.name:'...',
                                            payment_option_code:payment.code,
                                            payment_option_key:payment.key,
                                            amount:payment.given_amount,
                                        })
                                    } else {
                                        sales_other_option_payments[index].amount = sales_other_option_payments[index].amount+this.toNumber(payment.given_amount)
                                    } 

                                    //add to all
                                    let index_all = all_other_option_payments.findIndex(option=>{
                                        return option.payment_option_code  == payment.code
                                    }) 
                                    if (index_all == -1) { 
                                        all_other_option_payments.push({
                                            ...payment, 
                                            payment_method:pay_option,
                                            payment_method_name:pay_option?pay_option.name:'...',
                                            payment_option_code:payment.code,
                                            amount:payment.given_amount,
                                        })
                                    } else {
                                        all_other_option_payments[index_all].amount = all_other_option_payments[index_all].amount+this.toNumber(payment.given_amount)
                                    }  
                                }
                                
                            });
                        }

                        //Pricing 
                        if (cashup.cashup_pricing_tiers) {
                            cashup.cashup_pricing_tiers.forEach(pricing_tier => {
                                let index = total_pricing_tiers.findIndex(item=>item.key==pricing_tier.key)
                                let sum_amount = this.toNumber(pricing_tier.sum_amount)
                                let sold_price = this.toNumber(pricing_tier.sold_price)
                                let quantity = this.toNumber(pricing_tier.quantity)
                                let size = this.toNumber(pricing_tier.size)
                                if (index==-1) {
                                    total_pricing_tiers.push({
                                        ...pricing_tier,
                                        sum_amount:sum_amount,
                                        sold_price:sold_price,
                                        quantity:quantity,
                                        size:size,
                                    })
                                } else {
                                    total_pricing_tiers[index].sum_amount = total_pricing_tiers[index].sum_amount+sum_amount
                                    total_pricing_tiers[index].sold_price = total_pricing_tiers[index].sold_price+sold_price
                                    total_pricing_tiers[index].quantity = total_pricing_tiers[index].quantity+quantity
                                    total_pricing_tiers[index].size = total_pricing_tiers[index].size+size
                                }
                            });
                        }

                        if (cashup.force_closed) {
                            
                        }else if (cashup.closed) {
                            closing_cashes = closing_cashes+this.toNumber(cashup.closing_cash) 
                            opening_cashes = opening_cashes+this.toNumber(cashup.opening_cash) 

                            sales_retail = sales_retail+this.toNumber(cashup.sales_retail) 
                            sales_wholesales = sales_wholesales+this.toNumber(cashup.sales_wholesales) 
                            sales_on_account = sales_on_account+this.toNumber(cashup.sales_on_account) 
                            
                            // sales_cash_payments = sales_cash_payments+this.toNumber(cashup.sales_cash_payments)+input_cash_payments
                            sales_cash_payments = sales_cash_payments+this.toNumber(cashup.sales_cash_payments) 
                            invoices_cash_payments = invoices_cash_payments+this.toNumber(cashup.invoices_cash_payments) 
                            
                            // sales_total_other_option_payments = sales_total_other_option_payments+this.toNumber(cashup.sales_total_other_option_payments) +input_other_payments
                            sales_total_other_option_payments = sales_total_other_option_payments+this.toNumber(cashup.sales_total_other_option_payments)  
                            invoices_total_other_option_payments = invoices_total_other_option_payments+this.toNumber(cashup.invoices_total_other_option_payments) 

                            let other_sales = this.CALCULATE_OTHER_PAYMENT_OPTIONS(cashup.sales_other_option_payments) 
                            let other_invoice = this.CALCULATE_OTHER_PAYMENT_OPTIONS(cashup.invoices_other_option_payments)  
                            if (other_sales) {
                                other_sales.forEach(element => {
                                    //add to sales
                                    let index = sales_other_option_payments.findIndex(option=>{
                                        return option.payment_option_code  == element.payment_option_code
                                    }) 
                                    if (index == -1) { 
                                        sales_other_option_payments.push({
                                            ...element, 
                                        })
                                    } else {
                                        sales_other_option_payments[index].amount = sales_other_option_payments[index].amount+this.toNumber(element.amount)
                                    } 
                                    
                                    //add to all
                                    let index_all = all_other_option_payments.findIndex(option=>{
                                        return option.payment_option_code  == element.payment_option_code
                                    }) 
                                    if (index_all == -1) { 
                                        all_other_option_payments.push({
                                            ...element, 
                                        })
                                    } else {
                                        all_other_option_payments[index_all].amount = all_other_option_payments[index_all].amount+this.toNumber(element.amount)
                                    }  
                                });
                            }
                            
                            if (other_invoice) {
                                other_invoice.forEach(element => {
                                    let index = invoices_other_option_payments.findIndex(option=>{
                                        if (!option.payment_option_key) {
                                            return option.payment_option_code  == element.payment_option_code 
                                        }
                                        return option.payment_option_key  == element.payment_option_key
                                    }) 
                                    if (index == -1) { 
                                        invoices_other_option_payments.push({
                                            ...element, 
                                        })
                                    } else {
                                        invoices_other_option_payments[index].amount = invoices_other_option_payments[index].amount+this.toNumber(element.amount)
                                    }  

                                    //add to all
                                    let index_all = all_other_option_payments.findIndex(option=>{
                                        if (!option.payment_option_key) {
                                            return option.payment_option_code  == element.payment_option_code 
                                        }
                                        return option.payment_option_key  == element.payment_option_key
                                    }) 
                                    if (index_all == -1) { 
                                        all_other_option_payments.push({
                                            ...element, 
                                        })
                                    } else {
                                        all_other_option_payments[index_all].amount = all_other_option_payments[index_all].amount+this.toNumber(element.amount)
                                    }  
                                });
                            }  
                        }else{ 
                        }

                        //open 
                        if (isOpenShortage) {
                            total_open_shortage = total_open_shortage+opening_shortage
                            total_open_shortages = total_open_shortages+opening_shortage
                        } else {
                            total_open_shortage = total_open_shortage+opening_shortage
                            total_open_overages = total_open_overages+opening_shortage
                        }

                        //close calculate shortages 
                        if (isShortage) {
                            total_close_shortage = total_close_shortage+shortage
                            total_close_shortages = total_close_shortages+shortage
                        }else{
                            total_close_shortage = total_close_shortage+shortage
                            total_close_overages = total_close_overages+shortage
                        }
                    }  
                }
                let isCloseShortage = total_close_shortage<0?true:false
                let isOpenShortage = total_open_shortage<0?true:false

                //CALCULATE TOTALS
                let total_sales = sales_retail+sales_wholesales
                let total_cash_sales  =  sales_cash_payments
                let total_cash  =  sales_cash_payments+invoices_cash_payments+total_floating_cash

                let total_shortage = total_open_shortage+total_close_shortage

                return {
                    sales_retail:sales_retail,
                    sales_wholesales:sales_wholesales,
                    sales_on_account:sales_on_account,
                    total_pricing_tiers:total_pricing_tiers,
                    day_shift_total_sales:day_shift_total_sales,
                    day_shift_total_sales_:this.money(day_shift_total_sales),
                    

                    sales_cash_payments:sales_cash_payments,
                    invoices_cash_payments:invoices_cash_payments,
                    
                    sales_total_other_option_payments:sales_total_other_option_payments,
                    invoices_total_other_option_payments:invoices_total_other_option_payments,
                    
                    sales_other_option_payments:sales_other_option_payments,
                    invoices_other_option_payments:invoices_other_option_payments,
                    all_other_option_payments:all_other_option_payments,

                    //totals
                    total_sales:total_sales, 
                    total_sales_:this.money(total_sales), 
                    total_cash_sales : total_cash_sales,
                    total_cash_sales_ : this.money(total_cash_sales),
                    total_cash : total_cash,
                    total_cash_ : this.money(total_cash), 

                    
                    total_open_shortage:total_open_shortage,
                    total_open_shortage_:this.money(total_open_shortage),
                    total_is_open_shortage:isOpenShortage, 
                    total_open_overages:total_open_overages,
                    total_open_overages_:this.money(total_open_overages),
                    total_open_shortages:total_open_shortages,
                    total_open_shortages_:this.money(total_open_shortages),
                    
                    total_close_shortage:total_close_shortage,
                    total_close_shortage_:this.money(total_close_shortage),
                    total_is_close_shortage:isCloseShortage, 
                    total_close_overages:total_close_overages,
                    total_close_overages_:this.money(total_close_overages),
                    total_close_shortages:total_close_shortages,
                    total_close_shortages_:this.money(total_close_shortages),

                    total_shortage : total_shortage,
                    total_shortage_ : this.money(total_shortage),
                    total_is_shortage : total_shortage<0?true:false,

                    total_closing_cash : total_closing_cash,
                    total_closing_cash_ : this.money(total_closing_cash),
                    total_opening_cash : total_opening_cash,
                    total_opening_cash_ : this.money(total_opening_cash),
                    
                    closing_cashes : closing_cashes,
                    closing_cashes_ : this.money(closing_cashes),
                    opening_cashes : opening_cashes,
                    opening_cashes_ : this.money(opening_cashes),

                }
            } catch (error) {
                this.error({
                    error:error,
                    from:'CALCULATE_TOTAL_CASHUPS',
                    page:NAME, 
                }) 
            } 
        }, 
        CALCULATE_OTHER_PAYMENT_OPTIONS(other_option_payments){
            try {  
                let options = []
                if (other_option_payments) {
                    for (const key in other_option_payments) {
                        const other_option = other_option_payments[key]; 
                        let index = options.findIndex(option=>{
                            return option.payment_option_key  == key
                        }) 
                        if (index == -1) {
                            let payment_method = store.getters.getPaymentMethodByKey(key)
                            options.push({
                                ...other_option,
                                payment_method:payment_method,
                                payment_method_name:payment_method?payment_method.name:"---", 
                                money_account_code:payment_method?payment_method.money_account_code:null, 
                            })
                        } else {
                            options[index].amount = options[index].amount+this.toNumber(other_option.amount)
                        }  
                    }
                }  
                return this.SIZE(options)>0?options:null
            } catch (error) {
                this.error({
                    error:error,
                    from:'CALCULATE_OTHER_PAYMENT_OPTIONS',
                    page:NAME, 
                }) 
            } 
        }, 
        CALCULATE_SALES_AGGREGATED_ITEMS(payload){
            let response = { 
                page:NAME,
                from:"CALCULATE_SALES_AGGREGATED_ITEMS",
                payload:payload
            }
            try {
                let moment = DateBus.moment
                let sale_orders = payload?.sale_orders
                let filtered_date = payload?.filtered_date
                let difference = filtered_date?filtered_date.difference:null
                let to_date = filtered_date?filtered_date.to_date:null
                let from_date = filtered_date?filtered_date.from_date:null
                if(!sale_orders){return null}
                let items = []
                sale_orders.forEach(order => {
                    // let date = moment(order.local_created_at).format("yyyy-MM-D")
                    // let momentDate = moment(date) 
                    // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    let isBetween = true
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                let index = items.findIndex(it=>{
                                    return it.item_key  == item.item_key
                                }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount
    
                                if (index == -1) { 
                                    items.push({
                                        ...item, 
                                        sold_quantity:sold_quantity,
                                        sold_cost_price:sold_cost_price,
                                        sold_discount:sold_discount,
                                        sold_price:sold_price,
                                        total_sold_price:total_sold_price,
                                        number_of_transactions:1,
                                        
                                        sold_quantity_:this.money( sold_quantity),
                                        sold_cost_price_:this.money( sold_cost_price),
                                        sold_discount_:this.money( sold_discount),
                                        sold_price_:this.money( sold_price),
                                        total_sold_price_:this.money( total_sold_price),
                                    })
                                } else {
                                    items[index].sold_quantity = items[index].sold_quantity+sold_quantity
                                    items[index].sold_cost_price = items[index].sold_cost_price+sold_cost_price
                                    items[index].sold_discount = items[index].sold_discount+sold_discount
                                    items[index].sold_price = items[index].sold_price+sold_price
                                    items[index].total_sold_price = items[index].total_sold_price+total_sold_price
                                    items[index].number_of_transactions = items[index].number_of_transactions+1
                                    
                                    items[index].sold_quantity_ = this.money( items[index].sold_quantity)
                                    items[index].sold_cost_price_ = this.money( items[index].sold_cost_price)
                                    items[index].sold_discount_ = this.money( items[index].sold_discount)
                                    items[index].sold_price_ = this.money( items[index].sold_price)
                                    items[index].total_sold_price_ = this.money( items[index].total_sold_price)
                                }    
                            });
                            
                        }
                    }     
                });  
                return items 
            } catch (error) {
                response = {
                    ...response,
                    error:error
                }
                this.error(response) 
                return null
                
            } 
        },
        CALCULATE_SALES_ITEMS_PER_TRANSACTION(payload){
            let response = { 
                page:NAME,
                from:"CALCULATE_SALES_ITEMS_PER_TRANSACTION",
                payload:payload
            }
            try {
                let moment = DateBus.moment
                let sale_orders = payload?.sale_orders
                let filtered_date = payload?.filtered_date
                let difference = filtered_date?filtered_date.difference:null
                let to_date = filtered_date?filtered_date.to_date:null
                let from_date = filtered_date?filtered_date.from_date:null
                if(!sale_orders){return null}
                let items = []
                sale_orders.forEach(order => {  
                    // let date = moment(order.local_created_at).format("yyyy-MM-D")
                    // let momentDate = moment(date) 
                    // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    let isBetween = true 
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                // let index = items.findIndex(it=>{
                                //     return it.item_key  == item.item_key
                                // }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount
                                

                                items.push({
                                    ...item, 
                                    sold_quantity:sold_quantity,
                                    sold_cost_price:sold_cost_price,
                                    sold_discount:sold_discount,
                                    sold_price:sold_price,
                                    total_sold_price:total_sold_price,
                                    
                                    sold_quantity_:this.money( sold_quantity),
                                    sold_cost_price_:this.money( sold_cost_price),
                                    sold_discount_:this.money( sold_discount),
                                    sold_price_:this.money( sold_price),
                                    total_sold_price_:this.money( total_sold_price),
                                    
                                    transaction_key:order.key,

                                    local_created_at:order.local_created_at,
                                    local_created_at_:order.local_created_at_,
                                    local_created_at_date:order.local_created_at_date,
                                    local_created_at_time:order.local_created_at_time,
                                })
                                     
                            });
                            
                        }
                    }     
                });   
                return items
            } catch (error) {
                response = {
                    ...response,
                    error:error
                }
                this.error(response) 
                return null
                
            } 
        },

        
        //update
        UPDATE_LOCAL_CASHUP(localCashup,allLocalCashups){
            try {
               this.localCashup=localCashup
               this.allLocalCashups=allLocalCashups
            } catch (error) {
                this.error({
                    error:error,
                    from:'UPDATE_CASHUP',
                    page:NAME, 
                })
            }
        },  
        ITEM_STOCK_SETTINGS(item,outlet_code,selling_quantity=1,notify=true){
            try {
                let response = {
                    allow_sell:false,
                    quantity:null,
                    stop_selling_level:null,
                    ordering_remind_level:null,
                    active_settings:null,
                    item:item
                } 
                // let local_sales = this.AllLocalSaleOrdersData 
                // let till = this.CurrentTill
                // let outlet_code = till?till.outlet_code:null
                let settings = this.CompanySettings
                let stock_settings = settings?settings.stock_settings:null
                let stock_outlets = stock_settings?stock_settings.stock_outlets:null
                let outlet_stock_levels = stock_outlets && outlet_code?stock_outlets[outlet_code]:null
                let item_stock_levels = item?item.stock_settings:null 
                let active_settings = item_stock_levels?item_stock_levels:outlet_stock_levels?outlet_stock_levels:stock_settings
                response.active_settings = active_settings 

                if (active_settings && item) {
                    let stop_selling_level = this.toNumber(active_settings.stop_selling_level)
                    let ordering_remind_level = this.toNumber(active_settings.ordering_remind_level)
                    let quantity = this.toNumber(item[outlet_code+'_number_quantity'])-this.toNumber(selling_quantity)
                    response.quantity = quantity
                    response.stop_selling_level = stop_selling_level
                    response.ordering_remind_level = ordering_remind_level
                    
                    if (quantity < stop_selling_level) {
                        response.allow_sell = false
                        if (notify) {
                            this.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"The selected item is below stop selling level.",
                                btnYes:"OKAY"
                            })  
                        }
                    }else if (quantity < ordering_remind_level) {
                        response.allow_sell = true
                        if (notify) {
                            this.dialog({
                                show:true,
                                title:"LOW ON STOCK LEVEL",
                                text:"The selected item is below ordering level.",
                                btnYes:"OKAY"
                            }) 
                        }
                    }else{
                        response.allow_sell = true
                    }  
                }
                if (!active_settings) {
                    response.allow_sell = true
                }
                return response
            } catch (error) {
                this.error({
                    error:error,
                    from:'ITEM_STOCK_SETTINGS',
                    page:NAME, 
                })
            }
        },   
        //
        GET_ACCESS(payload){
            try { 
                let us = this.us
                let ud = this.ud 
                let selectedCompany = this.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                let companies = ud?ud.companies:null
                let company = companies && company_key?companies[company_key]:null
                let company_user_group_code = company?company.company_user_group_code:null
                let user_group_code = ud?ud.user_group_code:null

                let current_page_name = router.app.$route.name
                let fullPath = router.app.$route.fullPath
                let links = router.options.routes
                // let permissions = this.permissions  
                let permissions = this.currentPlanPermissions  

                let page_name = payload?payload.page_name:null
                let page_link = payload?payload.page_link:null
                let action_name = payload?payload.action_name:null 
                
                if (us) {  
                    if (us.email===DATA.APP.ADMIN_EMAIL) {
                      return true  
                    } 
                }if (ud) {  
                    if (ud.email===DATA.APP.ADMIN_EMAIL) {
                      return true  
                    } 
                } 

                let NAME = null
                if (!payload) {
                    NAME = current_page_name
                }else if (page_name) {
                    NAME = page_name  
                }else if (page_link) {
                    let action_link = links?links.find(link=>{
                        return link.path == page_link
                    }):null
                    let action_link_name = action_link?action_link.name:null
                    if (action_link_name) {
                        NAME = action_link_name  
                    } 
                }  

                if (!NAME) {
                    return false
                }
                NAME = this.TEXT_UP0(this.TEXT_SPACE0(NAME))
                let this_permission = permissions?permissions.find(p=>{
                    return (this.TEXT_UP0(this.TEXT_SPACE0(p.name)) == NAME)
                        &&(this.TEXT_UP0(this.TEXT_SPACE0(p.permission_type)) == 'page')
                }):null
                if(!this_permission){
                    // console.log('no permission 2');
                    return false
                } 

                let user_permission = null
                if (this.TEXT_UP0(this.TEXT_SPACE0(this_permission.access_type)) 
                    == 'public') {
                    return true
                }else if (this.TEXT_UP0(this.TEXT_SPACE0(this_permission.access_type)) 
                    == this.TEXT_SPACE0('public user')) {
                    if (us) {
                        return true
                    } else {
                        return false  
                    } 
                }else if (this.TEXT_UP0(this.TEXT_SPACE0(this_permission.access_type)) 
                    == this.TEXT_SPACE0('company')) {  
                    
                    let company_user_group = store.getters.getCompanyUserGroupByCode(company_user_group_code)
                    let company_user_group_permissions_object = company_user_group?company_user_group.permissions:null
                    let company_user_group_permissions = this.OBJECT_ITEMS(company_user_group_permissions_object)
                    // console.log(company_user_group_code,'code....');
                    // console.log(company_user_group,'11....');
                    // console.log(company_user_group_permissions_object,'22....');
                    // console.log(company_user_group_permissions,'33....');
                    if (company_user_group_permissions) {
                        user_permission = company_user_group_permissions.find(permission=>{
                            return permission.permission_code == this_permission.code
                        })
                    } 
                }else{  
                    let user_group = store.getters.getUserGroupByCode(user_group_code)
                    let user_group_permissions_object = user_group?user_group.permissions:null
                    let user_group_permissions = this.OBJECT_ITEMS(user_group_permissions_object)
                    if (user_group_permissions) {
                        user_permission = user_group_permissions.find(permission=>{
                            return permission.permission_code == this_permission.code
                        })
                    } 
                }   
                
                let view_permission = user_permission?user_permission.view_permission:null
                if (view_permission) {
                    // console.log('yes permission 1');
                    return true
                } else {
                    // console.log(user_group_permissions,'no permission 3');
                    return false
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'GET_ACCESS',
                    page:NAME, 
                })
            }

        },
        GET_USER_ACCESS(payload){
            try { 
                let user = payload?.user 
                let selectedCompany = this.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                let companies = user?.companies 
                let company = companies && company_key?companies[company_key]:null
                let company_user_group_code = company?company.company_user_group_code:null
                let user_group_code = user?user.user_group_code:null

                let current_page_name = router.app.$route.name
                let fullPath = router.app.$route.fullPath
                let links = router.options.routes

                let permissions = this.currentPlanPermissions  
                // let permissions = this.permissions  
 
                let page_name = payload?payload.page_name:null
                let page_link = payload?payload.page_link:null
                let action_name = payload?payload.action_name:null 
                let permission_type = payload?payload.permission_type:'page' 
                
                if (user) {  
                    if (user.email===DATA.APP.ADMIN_EMAIL) {
                      return true  
                    } 
                }  

                let NAME = null
                if (!payload) {
                    NAME = current_page_name
                }else if (action_name) {
                    NAME = action_name  
                }else if (page_name) {
                    NAME = page_name  
                }else if (page_link) {
                    let action_link = links?links.find(link=>{
                        return link.path == page_link
                    }):null
                    let action_link_name = action_link?action_link.name:null
                    if (action_link_name) {
                        NAME = action_link_name  
                    } 
                }  

                if (!NAME||!permissions) {
                    return false
                }
                NAME = this.TEXT_UP0(this.TEXT_SPACE0(NAME))
                let this_permission = permissions?permissions.find(p=>{
                    return (this.TEXT_UP0(this.TEXT_SPACE0(p.name)) == NAME)
                        &&(this.TEXT_UP0(this.TEXT_SPACE0(p.permission_type)) == permission_type)
                }):null
                if(!this_permission){
                    console.log('no permission 2');
                    return false
                } 

                let user_permission = null
                if (this.TEXT_UP0(this.TEXT_SPACE0(this_permission.access_type)) 
                    == 'public') {
                    return true
                }else if (this.TEXT_UP0(this.TEXT_SPACE0(this_permission.access_type)) 
                    == this.TEXT_SPACE0('public user')) {
                    if (us) {
                        return true
                    } else {
                        return false  
                    } 
                }else if (this.TEXT_UP0(this.TEXT_SPACE0(this_permission.access_type)) 
                    == this.TEXT_SPACE0('company')) {  
                    
                    let company_user_group = store.getters.getCompanyUserGroupByCode(company_user_group_code)
                    let company_user_group_permissions_object = company_user_group?company_user_group.permissions:null
                    let company_user_group_permissions = this.OBJECT_ITEMS(company_user_group_permissions_object)
                    // console.log(company_user_group_code,'code....');
                    // console.log(company_user_group,'11....');
                    // console.log(company_user_group_permissions_object,'22....');
                    // console.log(company_user_group_permissions,'33....');
                    if (company_user_group_permissions) {
                        user_permission = company_user_group_permissions.find(permission=>{
                            return permission.permission_code == this_permission.code
                        })
                    } 
                }else{  
                    let user_group = store.getters.getUserGroupByCode(user_group_code)
                    let user_group_permissions_object = user_group?user_group.permissions:null
                    let user_group_permissions = this.OBJECT_ITEMS(user_group_permissions_object)
                    if (user_group_permissions) {
                        user_permission = user_group_permissions.find(permission=>{
                            return permission.permission_code == this_permission.code
                        })
                    } 
                }   
                
                let view_permission = user_permission?user_permission.view_permission:null
                if (view_permission) {
                    // console.log('yes permission 1');
                    return true
                } else {
                    // console.log(user_group_permissions,'no permission 3');
                    return false
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'GET_USER_ACCESS',
                    page:NAME, 
                })
            }

        },

        //LOCAL NET DB
        //-----------------[KIPCOUNT CONNECTER]------------//
        async LND_GET_ALL(payload={}){ 
            let response = {
                from:"LND_GET_ALL",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    } 
                    let printer_name = payload.printer_name?payload.printer_name:'POS80'
                    let title = payload.title?payload.title:""
                    let PrintBarcode = payload.PrintBarcode?payload.PrintBarcode:"1"  
                    let url=  payload.url?payload.url:'http://localhost:5000/api/Receipt/connect'   

                    let data_conf = {
                        "printerName": printer_name, 
                        "title": ""+title, 
                        "printBarcode": PrintBarcode, 
                    }
                    var data = JSON.stringify(data_conf); 
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
                        store.dispatch("add_items",{
                            name:'K_CONNECTOR',
                            data:{
                                ...data,
                                url:url
                            }
                        }) 
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{ 
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) {
                        store.dispatch("add_items",{
                            name:'K_CONNECTOR',
                            data:null
                        }) 
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async LND_ADD_RECEIPT(payload={}){ 
            let response = {
                from:"LND_ADD_RECEIPT",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    }  
                    let order = payload.order?payload.order:null 
                    let key = order?order.key:null
                    let url=  payload.url?payload.url:'http://localhost:5000/api/Mongo/CreateReceipt'   

                   
                    if(!key){
                        reject({error:"required field",payload:payload})
                        return
                    }
                    
                    let data = JSON.stringify(order);
                    
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'http://localhost:5000/api/Mongo/CreateReceipt',
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };
                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        resolve({
                            order:data,
                            success:true 
                        })  
                    })
                    .catch(function (error) { 
                        let error_res = error?error.response:null
                        let error_data = error_res?error_res.data:null
                        let message = error_data?error_data.message:null
                        reject({
                            ...response,
                            error:error,
                            message:message,
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 

        //-----------------[KIPCOUNT CONNECTER]------------//
        async K_CONNECT(payload={}){ 
            let response = {
                from:"K_CONNECT",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    } 
                    let printer_name = payload.printer_name?payload.printer_name:'POS80'
                    let title = payload.title?payload.title:""
                    let PrintBarcode = payload.PrintBarcode?payload.PrintBarcode:"1"  
                    let url=  payload.url?payload.url:'http://localhost:5000/api/Receipt/connect'   

                    let data_conf = {
                        "printerName": printer_name, 
                        "title": ""+title, 
                        "printBarcode": PrintBarcode, 
                    }
                    var data = JSON.stringify(data_conf); 
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
                        store.dispatch("add_items",{
                            name:'K_CONNECTOR',
                            data:{
                                ...data,
                                url:url
                            }
                        }) 
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{ 
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) {
                        store.dispatch("add_items",{
                            name:'K_CONNECTOR',
                            data:null
                        }) 
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async GET_CONNECTED_PRINTERS(payload={}){ 
            let response = {
                from:"GET_CONNECTED_PRINTERS",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    } 
                    let printer_name = payload.printer_name?payload.printer_name:'POS80'
                    let title = payload.title?payload.title:""
                    let PrintBarcode = payload.PrintBarcode?payload.PrintBarcode:"1"  
                    let url=  payload.url?payload.url:'http://localhost:5000/api/Receipt/ConnectedPrinters' 

                    let data_conf = {
                        "printerName": printer_name, 
                        "title": ""+title, 
                        "printBarcode": PrintBarcode, 
                    }
                    var data = JSON.stringify(data_conf); 
                    var config = {
                        method: 'get',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null  
                        resolve({ 
                            ...res,
                            success:true
                        })  
                    })
                    .catch(function (error) { 
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        //EPSON PRINTER 
        async EPSON_OPEN_DRAWER(payload={}){ 
            let response = {
                from:"EPSON_OPEN_DRAWER",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    } 
                    let printer_name = payload.printer_name?payload.printer_name:'POS80'
                    let title = payload.title?payload.title:""
                    let PrintBarcode = payload.PrintBarcode?payload.PrintBarcode:"1"  
                    let url=  payload.url?payload.url:'http://localhost:5000/api/Receipt/openDrawer'   

                    let data_conf = {
                        "printerName": printer_name, 
                        "title": ""+title, 
                        "printBarcode": PrintBarcode, 
                    }
                    var data = JSON.stringify(data_conf); 
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null  
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{ 
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) { 
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async EPSON_PRINT(payload){ 
            let response = {
                from:"EPSON_PRINT",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    }
                    let calculate_items = (items)=>{
                        if(!items){return null}
                        let products = []
                        items.forEach(item => {
                            let product = {
                                productName:item.name?item.name:' -- ',
                                taxGroup:item.tax_group,
                                singlePrice:item.sold_price,
                                quantity:item.quantity,
                                itemTotalPrice:item.total_price,
                            }
                            products.push(product)
                        });
                        return products 
                    }
                    let printer_name = payload.printer_name?payload.printer_name:'POS80'
                    let open_drawer = payload.open_drawer?"open":""
                    let title = payload.title?payload.title:""
                    let shop_name = payload.shop_name?payload.shop_name:""
                    let post_address = payload.post_address?payload.post_address:""
                    let email_address = payload.email_address?payload.email_address:""
                    let location_address = payload.location_address?payload.location_address:""
                    let city = payload.city?payload.city:""
                    let phone_number = payload.phone_number?payload.phone_number:""
                    let tpin = payload.tpin?payload.tpin:''
                    let till_name = payload.till_name?payload.till_name:''
                    let receipt_number = payload.receipt_number?payload.receipt_number:''
                    let buyer_name = payload.buyer_name?payload.buyer_name:''
                    let buyer_tpin = payload.buyer_tpin?payload.buyer_tpin:""
                    let buyer_phone_number = payload.buyer_phone_number?payload.buyer_phone_number:""
                    let barcode = payload.barcode?payload.barcode:""
                    let items = payload.items?payload.items:null
                    
                    let print_vat = payload.print_vat?1:0
                    let taxable_a = payload.taxable_a?payload.taxable_a:"0.0"
                    let vat_a = payload.vat_a?payload.vat_a:"0.0"
                    let taxable_b = payload.taxable_b?payload.taxable_b:"0.0"
                    let vat_b = payload.vat_b?payload.vat_b:"0.0"
                    let total_vat = payload.total_vat?payload.total_vat:"0.0"

                    let total = payload.total?payload.total:"0.0"
                    let cash = payload.cash?payload.cash:""
                    let change = payload.change?payload.change:""
                    let discount = payload.discount?payload.discount:""
                    let sub_total = payload.sub_total?payload.sub_total:""

                    let date_time = payload.date_time?payload.date_time:""
                    let date = payload.date?payload.date:""
                    let time = payload.time?payload.time:""
                     

                    let url= 'http://localhost:5000/api/Receipt/PrintEscReceipt'   
                    let data_conf = {
                        "printerName": printer_name,
                        "openDrawer": open_drawer,
                        "shopName": shop_name,
                        "phoneNumber": phone_number,
                        "emailAddress": email_address,
                        "postAddress": post_address,
                        "locationAddress":location_address,
                        "city": city,
                        "TIN": tpin,
                        "till": ""+till_name,
                        "title": ""+title,
                        "buyerName": buyer_name,
                        "buyerTIN": buyer_tpin,
                        "buyerPhoneNumber": buyer_phone_number,
                        "receiptNumber":""+this.NUMBER_SAME_LENGTH(receipt_number,5),
                        "printVAT": print_vat,
                        "printBarcode": barcode,
                        "products": calculate_items(items),
                        "taxableA": taxable_a,
                        "vatA": vat_a,
                        "taxableB":taxable_b,
                        "vatB": vat_b,
                        "totalVAT": total_vat,
                        "total": total,
                        "cash":cash,
                        "change":change,
                        "date": date_time, 
                        "discount": discount, 
                        "subTotal": sub_total, 
                    }
                    var data = JSON.stringify(data_conf);
                    // console.log(data_conf,'.....');
                    // console.log(data,'.....'); 
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
                        
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) {
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },  
        async EPSON_PRINT_LINES(payload){ 
            let response = {
                from:"EPSON_PRINT_LINES",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    } 
                    let printer_name = payload.printer_name?payload.printer_name:'POS80'
                    let title = payload.title?payload.title:""
                    let PrintBarcode = payload.PrintBarcode?payload.PrintBarcode:"1"  
                    let paper_size = payload.paper_size?payload.paper_size:'42'
                    let lines = payload.lines?payload.lines:[]
                    let open_drawer = payload.open_drawer?"open":"" 

                     

                    let url= 'http://localhost:5000/api/Receipt/EpsonPrintLines'   
                    let data_conf = {
                        "printerName": printer_name,
                        "title": ""+title, 
                        "printBarcode": PrintBarcode, 
                        "openDrawer": open_drawer,
                        "paperSize": paper_size,
                        "lines": lines, 
                    }
                    var data = JSON.stringify(data_conf);
                    console.log(data_conf,'.....');
                    // console.log(data,'.....'); 
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data : data
                    };

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
                        
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) {
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },  
        async EPSON_SALE_ORDER_PRINT_REQUEST(payload){ 
            let response = {
                from:"EPSON_SALE_ORDER_PRINT_REQUEST",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    } 
                    let order = payload.SaleOrder 
                    let printer = payload.printer?payload.printer :{}
                    let printer_name = payload.printer_name?payload.printer_name :{}
                    let paper_size = payload.paper_size?payload.paper_size :{}
                    const default_paper_size = 48
                    const default_printer_name = "POS80" 

                    let print_order = {}
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null
                    
                    //PRINTER INFO
                    printer_name = printer?printer.name?printer.name:printer_name?printer_name:default_printer_name:default_printer_name
                    paper_size = printer?printer.paper_size?printer.paper_size:paper_size?paper_size:default_paper_size:default_paper_size 
                    
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name

                   
                    
                    //COMPANY INFO
                    let company = order.company
                    if (company && receipt_show_company_info) {
                        print_order.shop_name = company.company_name
                        print_order.post_address = "Address: "+company.company_post_address
                        print_order.email_address = "Email: "+company.company_email_address
                        print_order.phone_number = "(Cell): "+company.company_phone_number
                        if (company.company_phone_number2) {
                            print_order.phone_number =print_order.phone_number +" (Tell): "+company.company_phone_number2 
                        }
                        print_order.location_address = company.company_location_address
                        if (receipt_show_company_tpin) { 
                            print_order.tpin = "TPIN: "+company.company_tpin 
                        }
                    }
                    
                    //OUTLET INFO
                    let outlet = order.outlet 
                    if (outlet && receipt_show_company_info) {
                        print_order.outlet_name = outlet.name 
                        print_order.till_name ="Outlet: "+outlet.name
                        
                    }
                    //TELLER INFO
                    let teller = order.teller 
                    if (teller && receipt_show_company_info) {
                        print_order.teller_name = teller.names 
                        print_order.till_name =print_order.till_name+" Teller: "+teller.names 
                        
                    }
                    // TILL INFO
                    let till = order.till 
                    if (till && receipt_show_till && receipt_show_company_info) {
                        print_order.till_name = print_order.till_name+" Till: "+till.name 
                    }
                    // CUSTOMER INFO
                    let customer = order.customer 
                    if (customer && receipt_show_buyer_info && receipt_show_company_info) {
                        print_order.buyer_name = customer.name? this.EPSON_1LINE({
                            first:"BUYER'S NAME:",
                            last:""+customer.name,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                        }):""
                        print_order.buyer_phone_number=customer.phone_number?this.EPSON_1LINE({
                            first:"BUYER'S PHONE:",
                            last:""+customer.phone_number,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                        }):""
                        print_order.buyer_tpin =customer.tpin?this.EPSON_1LINE({
                            first:"BUYER'S TPIN:",
                            last:""+customer.tpin,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                        }):""
                    }

                    //RECEIPT TITLE
                    if (receipt_show_receipt_title) {
                        print_order.title = "SALES RECEIPT" 
                    }

                    // RECEIPT NUMBER & BARCODE
                    let receipt_number = order.receipt_number?order.receipt_number:order.key
                    receipt_number = this.NUMBER_SAME_LENGTH(receipt_number,5)
                    if (receipt_number) {
                        print_order.receipt_number = this.EPSON_1LINE({
                            first:"RECEIPT NUMBER:", 
                            last:""+receipt_number,
                            size:paper_size
                        })
                        if (receipt_show_barcode) {
                            print_order.barcode = ""+receipt_number 
                        }
                    }


                    //ITEM INFO
                    let items = order.joined_items?order.joined_items:order.items
                    if (items) {
                        let products = []
                        let index = 0
                        items.forEach(item => {
                            index=index+1
                            // let selected_item_option = item?.selected_item_option
                            let option_name = item?.selected_item_option_names
                            let item_name = item.name?item.name:item.item_name
                            let name =receipt_show_item_numbering?index+". "+item_name:item_name
                            if (option_name) {
                                name = name+" ("+option_name+")"
                            }


                            let quantity = this.toNumber(item.quantity) 
                            let sold_price = this.toNumber(item.sold_price)
                            let total_sold_price = sold_price*quantity
                            let sold_price_ = this.money(sold_price)
                            let total_sold_price_ = this.money(total_sold_price)

                            let item_tax = item.tax 
                            let item_tax_group_code = item.tax_group_code 
                            let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
    
                            let last = total_sold_price_+" "+usable_tax_group
                            let mid = "QTY:"+quantity+" X "+sold_price_
                            let first = name+"  "+mid
                            let first_size = this.SIZE(first)
                            let mid_size = this.SIZE(mid)
                            let max2 = this.SIZE(last)
                            let max1 = paper_size-max2-3 
                            // console.log(max1,'--',max2);

                            if (first_size>max1) {
                                let number1 = name.slice(0,max1)
                                let number2 = name.slice((max1))
                                products.push({
                                    name:this.EPSON_1LINE({
                                        first:number1,
                                        last:last,
                                        size:paper_size,
                                        max1:max1,
                                        max2:max2
                                    }),
                                    "taxGroup":"A",
                                    "singlePrice":"12,000.00",
                                    "quantity":"1",
                                    "itemTotalPrice":"12,000.00" 
                                }) 

                                let number3 = number2?number2+" "+mid:mid
                                products.push({
                                    name:this.EPSON_1LINE({
                                        first:number3,
                                        last:"",
                                        size:paper_size,
                                        max1:paper_size-1,
                                        max2:1
                                    }),
                                    "taxGroup":"A",
                                    "singlePrice":"12,000.00",
                                    "quantity":"1",
                                    "itemTotalPrice":"12,000.00" 
                                }) 
                                
                            } else {
                                products.push({
                                    name:this.EPSON_1LINE({
                                        first:first,
                                        last:last,
                                        size:paper_size,
                                        max1:max1,
                                        max2:max2
                                    }),
                                    "taxGroup":"A",
                                    "singlePrice":"12,000.00",
                                    "quantity":"1",
                                    "itemTotalPrice":"12,000.00" 
                                }) 
                            }


                        });
                        print_order.items = products
                    }

                    // 
                    let total_sold_price = this.toNumber(order.total_sold_price )
                    let given_amount = this.toNumber(order.given_amount)
                    let sum_amount = this.toNumber(order.sum_amount?order.sum_amount:order.paid_amount)
                    let total_price = this.toNumber(order.total_price)
                    let total_discount = this.toNumber(order.total_discount)
                    let change_amount = given_amount-sum_amount
                    let total_sold_price_ = this.money(total_sold_price)
                    let given_amount_ = this.money(given_amount)
                    let sum_amount_ = this.money(sum_amount)
                    let change_amount_ = this.money(change_amount)

                    let total_price_ = this.money(total_price)
                    let total_discount_ = this.money(total_discount)

                    if (total_discount) {
                        print_order.sub_total = this.EPSON_1LINE({
                            first:"SUB-TOTAL:", 
                            last:""+total_price_,
                            size:paper_size
                        })
                        print_order.discount = this.EPSON_1LINE({
                            first:"DISCOUNT:", 
                            last:""+total_discount_,
                            size:paper_size
                        })
                    }
                    
                    print_order.total = this.EPSON_1LINE({
                        first:"TOTAL:", 
                        last:""+total_sold_price_,
                        size:paper_size/2
                    })

                    if (receipt_show_change_amount) {
                        print_order.cash = this.EPSON_1LINE({
                            first:"CASH:", 
                            last:""+given_amount_,
                            size:paper_size
                        })
                        print_order.change = this.EPSON_1LINE({
                            first:"CHANGE:", 
                            last:""+change_amount_,
                            size:paper_size
                        })
                        
                    }

                    //show VAT
                    if (receipt_show_vat) { 
                        let taxable_a = '0.00'
                        let vat_a = '0.00'
                        let taxable_b = '0.00'
                        let vat_b = '0.00'
                        let total_vat = '0.00'
                        print_order.print_vat = true
                        print_order.taxable_a = this.EPSON_1LINE({
                            first:"TAXABLE A-16.5% ", 
                            last:""+taxable_a,
                            size:paper_size
                        })
                        print_order.vat_a = this.EPSON_1LINE({
                            first:"VAT A-16.5% ", 
                            last:""+vat_a,
                            size:paper_size
                        }) 
                        print_order.taxable_b = this.EPSON_1LINE({
                            first:"TAXABLE B-16% ", 
                            last:""+taxable_b,
                            size:paper_size
                        })
                        print_order.vat_b = this.EPSON_1LINE({
                            first:"VAT B-16% ", 
                            last:""+vat_b,
                            size:paper_size
                        }) 
                        print_order.total_vat = this.EPSON_1LINE({
                            first:"TOTAL VAT", 
                            last:""+total_vat,
                            size:paper_size
                        }) 
                    }
                    
                    
                    //TIME
                    print_order.date = order.local_created_at_date
                    print_order.time = order.local_created_at_time
                    print_order.date_time = this.EPSON_1LINE({
                        first:"DATE:"+print_order.date, 
                        last:"TIME:"+print_order.time,
                        size:paper_size
                    })

                    console.log(order,'order.......');
                    console.log(print_order,'print_order.......');

                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },  
        async EPSON_SALE_ORDER_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"EPSON_SALE_ORDER_REQUEST",
                    page:NAME,
                    payload:payload
                } 
                try {    
                    let merge_doc_type = payload?.merge_doc_type
                    let sale_order = payload?payload.sale_order:null
                    if (sale_order?.merge_doc_type) {
                        merge_doc_type = sale_order?.merge_doc_type
                    }
                    let day_shift = sale_order?sale_order.day_shift:null
                    let order_type = sale_order?sale_order.order_type:null
                    let bill_sale_order = order_type=="bill_sale_order"?true:false 
                    let account_type = sale_order?sale_order.account_type:null
                    if(!sale_order){
                        reject({...response,error:"sale_order required"})
                        return null
                    }
                    let cashup_option = day_shift?day_shift.cashup_option:null
                    let cashups = day_shift?day_shift.joined_cashups:null
                    let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                    let running_cashups_size = this.SIZE(running_cashups)
                    let isRunning = running_cashups_size?true:false
                    let isClosed = day_shift?day_shift.closed:null

                    //USED TILLS
                    let used_floating_cash = 0
                    let used_till_keys = []
                    if (cashups) {
                        cashups.forEach(element => {
                            let index = used_till_keys.indexOf(element.till_key)
                            if (index==-1) {
                                used_floating_cash = used_floating_cash+this.toNumber(element.floating_cash)
                                used_till_keys.push(element.till_key)
                            }
                        });
                    }

                    let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                    let total_cashups = this.CALCULATE_TOTAL_CASHUPS(cashups,day_shift)  
                    let report_type = payload?payload.report_type:null
                    let print_title = payload?payload.print_title:null
                    let printer = payload?payload.printer?payload.printer :{}:{}

                    const default_paper_size = 32
                    const default_printer_name = "POS80"
                    let paper_size = 0
                    let printer_name = ""
                    let space_value = '.'   
                    let print_order = {}

                    //SETTINGS
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null
                    //template
                    let template_settings = settings?settings.template_settings:null
                    let receipt_settings = template_settings?template_settings.receipt:null
                    let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null

                    //PRINTER INFO
                    paper_size = printer.paper_size?printer.paper_size:default_paper_size
                    printer_name = printer.printer_name?printer.printer_name:default_printer_name
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name 
                    print_order.lines = [] 
        
                
                    //TOP HEADER
                    if (receipt_show_receipt_title) {
                        print_title = print_title?print_title:report_type?report_type:"SALES REPORT"
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*** "+print_title+" ***"
                        })
                        // print_order.lines.push({ text:" "})
                    }

                    //COMPANY INFO
                    const company = sale_order?.company
                    const outlet = sale_order?.outlet
                    const c_company_name = company?.company_name
                    const c_phone_number_cell = company?.company_phone_number
                    const c_phone_number_tell = company?.company_phone_number2
                    const c_post_address = company?.company_post_address
                    const c_email_address = company?.company_email_address
                    const c_tpin = company?.company_tpin 
                    const c_location_address = company?.company_location_address 
                    
                    const receipt_company_name = outlet?.receipt_company_name
                    const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                    const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                    const receipt_post_address = outlet?.receipt_post_address
                    const receipt_email_address = outlet?.receipt_email_address
                    const receipt_tpin = outlet?.receipt_tpin
                    const receipt_address = outlet?.receipt_address
                    
                    const company_name = receipt_company_name?receipt_company_name:c_company_name
                    const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                    const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                    const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                    const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                    const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                    const company_address =  receipt_address?receipt_address:c_location_address

                    if (company && receipt_show_company_info) { 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            value:'company_name',
                            text:company_name 
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_post_address',
                            text:"Post Address: "+company_post_address
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_email_address',
                            text:"Email: "+company_email_address
                        }) 
                        let phone_details = "(Cell): "+company_phone_number_cell
                        if (company_phone_number_cell) {
                            phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                            if (paper_size<43) {
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number',
                                    text: "(Cell): "+company_phone_number_cell
                                })
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number',
                                    text:"(Tell): "+company_phone_number_tell
                                })
                            }
                        }
                        if (paper_size>42) { 
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_phone_number',
                                text:phone_details
                            })  
                        }  
                        if (receipt_show_company_tpin) {  
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_tpin',
                                text:"TPIN: "+company_tpin 
                            }) 
                        } 
                        
                        
                        let teller = sale_order.teller 
                        let text_outlet =""
                        if (outlet) {  
                            text_outlet = "Outlet: "+outlet?.name
                        } 
                        if (teller) {  
                            text_outlet = text_outlet+" Teller: "+teller.names 
                        } 

                        if (text_outlet!="") {  
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_tpin',
                                text:text_outlet
                            }) 
                        }

                         
                    }
        
                    //DOWN HEADER
                    if (receipt_show_receipt_title) { 
                        // print_order.lines.push({ text:" "})
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"******"
                        }) 
                        print_order.lines.push({ text:" "}) 
                    }

                    //DAY SHIFT DETAILS
                    let receipt_number = sale_order.order_key?sale_order.order_key:sale_order.order_id
                    let receipt_key = sale_order.key 
                    if (true) { 
                        // if (customer && receipt_show_buyer_info) {
                        if (true) {
                            let receipt_type = null
                            if(order_type=="quotation"){
                                receipt_type = this.TEXT_UP(DATA.ITEMS.QUOTATIONS.value) 
                            }else if(account_type == "on_account"){
                                receipt_type = this.TEXT_UP(DATA.ITEMS.INVOICES.value)  
                            }else if(order_type == "bill_sale_order"){
                                receipt_type = this.TEXT_UP(DATA.ITEMS.ORDERS.value)  
                            }else{
                                receipt_type = this.TEXT_UP(DATA.ITEMS.RECEIPTS.value)  
                            }

                            if (receipt_number) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:receipt_type+" NUMBER:", 
                                        last:""+this.NUMBER_SAME_LENGTH(receipt_number,5),
                                        size:paper_size
                                    }) 
                                })   
                            }
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:receipt_type+" KEY:", 
                                    last:""+this.NUMBER_SAME_LENGTH(receipt_key,5),
                                    size:paper_size
                                }) 
                            })  
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"DATE:", 
                                    last:""+sale_order.local_created_at_date,
                                    size:paper_size
                                }) 
                            })  
                        }
                        let customer = sale_order.customer 
                        let waiter_name = sale_order.waiter_name 
                        let table_name = sale_order.table_name 
                        if (customer && receipt_show_buyer_info) {
                            if (customer.name) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"BUYER'S NAME:",
                                        last:""+customer.name,
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                })  
                            }
                            if (customer.phone_number) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"BUYER'S PHONE:",
                                        last:""+customer.phone_number,
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                })  
                            }
                            if (waiter_name) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"WAITER: ",
                                        last:""+waiter_name,
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                })  
                            }
                            if (table_name) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"TABLE: ",
                                        last:""+table_name,
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                })  
                            }
                            
                        } 
                        print_order.lines.push({ text:" "}) 
                       
                    }
                    
                    //ITEMS
                    let items = this.MERGE_DUPLICATED_ITEMS({
                        merge_doc_type:merge_doc_type,
                        items:sale_order.joined_items?sale_order.joined_items:sale_order.items
                    })
                    let item_discounts = 0
                    if (items) { 
                        let index = 0
                        items.forEach(item => {
                            index=index+1
                            // let selected_item_option = item?.selected_item_option
                            let option_name = item?.selected_item_option_names
                            let item_name = item.name?item.name:item.item_name
                            if(!item_name){
                                item_name = "--"
                            }
                            let name =receipt_show_item_numbering?index+". "+item_name:item_name
                            if (option_name) {
                                name = name+" ("+option_name+")"
                            }


                            let quantity = this.toNumber(item.quantity) 
                            let discount = this.toNumber(item.discount) *quantity
                            item_discounts = item_discounts+discount
                            let sold_price = this.toNumber(item.sold_price)
                            let total_sold_price = sold_price*quantity
                            let sold_price_ = this.money(sold_price)
                            let total_sold_price_ = this.money(total_sold_price)

                            let item_tax = item.tax 
                            let item_tax_group_code = item.tax_group_code 
                            let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
    
                            let last = total_sold_price_+" "+usable_tax_group
                            let mid = "QTY:"+quantity+" X "+sold_price_
                            let first = name+"  "+mid
                            let first_size = this.SIZE(first)
                            let mid_size = this.SIZE(mid)
                            let max2 = this.SIZE(last)
                            let max1 = paper_size-max2-3 

                            if (first_size>max1) {
                                let number1 = name.slice(0,max1)
                                let number2 = name.slice((max1))
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:number1,
                                        last:last,
                                        size:paper_size,
                                        max1:max1,
                                        max2:max2
                                    }) 
                                })  

                                let number3 = number2?number2+" "+mid:mid
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:number3,
                                        last:"",
                                        size:paper_size,
                                        max1:paper_size-1,
                                        max2:1
                                    }) 
                                })  
                            } else {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:first,
                                        last:last,
                                        size:paper_size,
                                        max1:max1,
                                        max2:max2
                                    }) 
                                }) 
                                 
                            }

                            
                        });  
                    }

                    //CARD LOADING
                    let selected_card_option = sale_order?sale_order.selected_card_option:null
                    if (selected_card_option=="load_money") {
                        let selected_card = sale_order.selected_card
                        let card_balance = selected_card?this.money(selected_card.balance_amount):null
                        let card_code = selected_card?selected_card.code:null
                        let card_number = this.showCard(card_code)
                        let card_load_amount = this.money(sale_order.card_load_amount) 
                        let new_balance = this.money(this.toNumber(card_balance)+this.toNumber(card_load_amount))
                        sale_order.total_sold_price = card_load_amount
                        
                        print_order.lines.push({ 
                            text:' '
                        })
                        // print_order.lines.push({
                        //     align:"center",
                        //     bold:false,
                        //     value:"",
                        //     text:'CARD LOADING'
                        // }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CARD NUMBER",
                                last:card_number,
                                size:paper_size,
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"B4 BALANCE:",
                                last:card_balance,
                                size:paper_size,
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"LOADED AMOUNT:",
                                last:card_load_amount,
                                size:paper_size,
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"NEW BALANCE:",
                                last:new_balance,
                                size:paper_size,
                            }) 
                        }) 
                    }
                     
                   
                    //TOTAL  
                    if (true) { 
                        // 
                        let total_sold_price = this.toNumber(sale_order.total_sold_price )
                        let given_amount = this.toNumber(sale_order.given_amount)
                        let sum_amount = this.toNumber(sale_order.sum_amount?sale_order.sum_amount:sale_order.paid_amount)
                        let total_price = this.toNumber(sale_order.total_price)
                        let total_discount = this.toNumber(sale_order.total_discount)
                        let all_discounts = total_discount+item_discounts
                        let all_discounts_ = this.money(all_discounts)
                        let change_amount = given_amount-sum_amount
                        let total_sold_price_ = this.money(total_sold_price)
                        let given_amount_ = this.money(given_amount)
                        let sum_amount_ = this.money(sum_amount)
                        let change_amount_ = this.money(change_amount)

                        let total_price_ = this.money(total_price+item_discounts)
                        let total_discount_ = this.money(total_discount)

                        if (all_discounts) { 
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                double:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"SUB-TOTAL:", 
                                    last:""+total_price_,
                                    size:paper_size
                                })
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                double:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"DISCOUNT:", 
                                    last:""+all_discounts_,
                                    size:paper_size
                                })
                            })  
                            // print_order.sub_total = this.EPSON_1LINE({
                            //     first:"SUB-TOTAL:", 
                            //     last:""+total_price_,
                            //     size:paper_size
                            // })
                            // print_order.discount = this.EPSON_1LINE({
                            //     first:"DISCOUNT:", 
                            //     last:""+all_discounts_,
                            //     size:paper_size
                            // })
                        }

                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"left",
                            bold:true,
                            double:true,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL:", 
                                last:""+total_sold_price_,
                                size:paper_size/2
                            }) 
                        })   
                        
                        if (order_type!="bill_sale_order") {
                            let account_type = sale_order.account_type
                            let payment_option_key = sale_order.payment_option_key
                            if (account_type == "multiple") {
                                let payment_method = sale_order?sale_order.payment_method:null
                                let joined_input_payments = payment_method?payment_method.joined_input_payments:null
                                let payments_size = this.SIZE(joined_input_payments)
                                if (receipt_show_change_amount) {
                                    if (joined_input_payments) {
                                        joined_input_payments.forEach(element => {
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:""+element.payment_method_name, 
                                                    last:"AM:"+this.money(element.given_amount)+"  TR:"+(
                                                        element.description?element.description:
                                                        element.payment_description?element.payment_description:'..'),
                                                    size:paper_size
                                                }) 
                                            })   
                                             
                                        });
                                    }
    
                                    if (payments_size>1) {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:this.EPSON_1LINE({
                                                first:payments_size>1?"T. PAID:":"CASH", 
                                                last:""+given_amount_,
                                                size:paper_size
                                            }) 
                                        })   
                                    } 
                                    
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"CHANGE:", 
                                            last:""+change_amount_,
                                            size:paper_size
                                        }) 
                                    })   
                                } 
                            }else if (account_type == "cash" || payment_option_key == "cash") {
                                if (receipt_show_change_amount) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"CASH:", 
                                            last:""+given_amount_,
                                            size:paper_size
                                        }) 
                                    })   
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"CHANGE:", 
                                            last:""+change_amount_,
                                            size:paper_size
                                        }) 
                                    })   
                                } 
                            } else if(account_type == "on_account") {
                                let receivable_name = sale_order?sale_order.receivable_name:null
                                let transaction_id = sale_order?sale_order.transaction_id:null
                                if (receivable_name) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"PAYMENT:", 
                                            last:"On Account",
                                            size:paper_size
                                        }) 
                                    })   
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"ACCOUNT:", 
                                            last:""+receivable_name,
                                            size:paper_size
                                        }) 
                                    })   
                                }
                            } else {
                                let payment_method_name = sale_order?sale_order.payment_method_name:null
                                let transaction_id = sale_order?sale_order.transaction_id:null
                                if (payment_method_name) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"PAYMENT:", 
                                            last:""+payment_method_name,
                                            size:paper_size
                                        }) 
                                    })   
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"TRANSACTION ID:", 
                                            last:""+transaction_id?transaction_id:'--',
                                            size:paper_size
                                        }) 
                                    })   
                                }
                            } 
                        }
                        
    
                        //show VAT
                        if (receipt_show_vat) { 
                            let taxable_a = '0.00'
                            let vat_a = '0.00'
                            let taxable_b = '0.00'
                            let vat_b = '0.00'
                            let total_vat = '0.00'

                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TAXABLE A-16.5% ", 
                                    last:""+taxable_a,
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"VAT A-16.5% ", 
                                    last:""+vat_a,
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TAXABLE B-16% ", 
                                    last:""+taxable_b,
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"VAT B-16% ", 
                                    last:""+vat_b,
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TOTAL VAT", 
                                    last:""+total_vat,
                                    size:paper_size
                                }) 
                            })      
                        }
                    }  

                    //BARCODE
                    if (receipt_show_barcode && receipt_key) {
                        let prefix = order_type?this.TEXT_UP(order_type.slice(0,2)):""

                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            barcode:true,
                            text:prefix+''+this.NUMBER_SAME_LENGTH(receipt_key,5)
                        })  
                    }
                    
                    //DATE AND TIME
                    if (true) {
                        print_order.date = sale_order.local_created_at_date
                        print_order.time = sale_order.local_created_at_time
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"DATE:"+print_order.date, 
                                last:"TIME:"+print_order.time,
                                size:paper_size
                            }) 
                        })   
                    }

                    //POWERED BY
                    if (!disable_kipcount_watermark) { 
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'***POWERED BY KIPCOUNT***'
                        }) 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'***+265993259202***'
                        }) 
                        print_order.lines.push({ value:"line"}) 
                    } 

                    // return print_order 
                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },
        async EPSON_DOCKET_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"EPSON_DOCKET_REQUEST",
                    page:NAME,
                    payload:payload
                } 
                try {    
                    let merge_doc_type = payload?payload.merge_doc_type:null
                    let sale_order = payload?payload.sale_order:null
                    let docket_version = sale_order?sale_order.docket_version:null
                    let day_shift = sale_order?sale_order.day_shift:null
                    let order_type = sale_order?sale_order.order_type:null
                    let bill_sale_order = order_type=="bill_sale_order"?true:false 
                    let account_type = sale_order?sale_order.account_type:null
                    if(!sale_order){
                        reject({...response,error:"sale_order required"})
                        return null
                    }
                    let cashup_option = day_shift?day_shift.cashup_option:null
                    let cashups = day_shift?day_shift.joined_cashups:null
                    let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                    let running_cashups_size = this.SIZE(running_cashups)
                    let isRunning = running_cashups_size?true:false
                    let isClosed = day_shift?day_shift.closed:null

                    //USED TILLS
                    let used_floating_cash = 0
                    let used_till_keys = []
                    if (cashups) {
                        cashups.forEach(element => {
                            let index = used_till_keys.indexOf(element.till_key)
                            if (index==-1) {
                                used_floating_cash = used_floating_cash+this.toNumber(element.floating_cash)
                                used_till_keys.push(element.till_key)
                            }
                        });
                    }

                    let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                    let total_cashups = this.CALCULATE_TOTAL_CASHUPS(cashups,day_shift)  
                    let report_type = payload?payload.report_type:null
                    let print_title = payload?payload.print_title:null
                    let printer = payload?payload.printer?payload.printer :{}:{}

                    const default_paper_size = 32
                    const default_printer_name = "POS80"
                    let paper_size = 0
                    let printer_name = ""
                    let space_value = '.'  
                    
                    
                    let docket_print_order = []
                    let joined_items_departments = sale_order?.joined_items_departments
                    let joined_items_dockets = sale_order?.joined_items_dockets

                    let docket = null
                    if(docket_version == "docket_all_updates"){

                    }else if(docket_version == "docket_first_update"){
                        docket = joined_items_dockets?joined_items_dockets[0]:null
                    }else if(docket_version == "docket_last_update"){
                        docket = joined_items_dockets?joined_items_dockets[joined_items_dockets.length-1]:null
                    }else{

                    } 
                    let update_key = docket?.update_key 
                    if (joined_items_departments) {
                        joined_items_departments.forEach(department => {
                            // return if tip
                            if (department.key == "tip") {
                                return
                            }
                            let joined_items = this.MERGE_DUPLICATED_ITEMS({
                                items:department.joined_items,
                                merge_doc_type:merge_doc_type
                            })
                            let department_name = department.name
                            let print_order = {}
        
                            //SETTINGS
                            let settings = this.CompanySettings
                            let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                            let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                            let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                            let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                            let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                            let receipt_show_till = settings?settings.receipt_show_till:null
                            let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                            let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                            let receipt_show_vat = settings?settings.receipt_show_vat:null
                            //template
                            let template_settings = settings?settings.template_settings:null
                            let receipt_settings = template_settings?template_settings.receipt:null
                            let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null
        
                            //PRINTER INFO
                            paper_size = printer.paper_size?printer.paper_size:default_paper_size
                            printer_name = printer.printer_name?printer.printer_name:default_printer_name
                            print_order.paper_size = paper_size
                            print_order.printer_name = printer_name 
                            print_order.lines = [] 
                
                        
                            //TOP HEADER
                            if (receipt_show_receipt_title) {
                                // print_title = print_title?print_title:report_type?report_type:"SALES REPORT"
                                print_title = "DOCKET"
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'top_header',
                                    text:"*** "+department_name+" "+print_title+" ***"
                                })
                                // print_order.lines.push({ text:" "})
                            }
         
                            //COMPANY INFO
                            const company = sale_order?.company
                            const outlet = sale_order?.outlet
                            const c_company_name = company?.company_name
                            const c_phone_number_cell = company?.company_phone_number
                            const c_phone_number_tell = company?.company_phone_number2
                            const c_post_address = company?.company_post_address
                            const c_email_address = company?.company_email_address
                            const c_tpin = company?.company_tpin 
                            const c_location_address = company?.company_location_address 
                            
                            const receipt_company_name = outlet?.receipt_company_name
                            const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                            const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                            const receipt_post_address = outlet?.receipt_post_address
                            const receipt_email_address = outlet?.receipt_email_address
                            const receipt_tpin = outlet?.receipt_tpin
                            const receipt_address = outlet?.receipt_address
                            
                            const company_name = receipt_company_name?receipt_company_name:c_company_name
                            const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                            const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                            const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                            const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                            const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                            const company_address =  receipt_address?receipt_address:c_location_address
                            if (company && receipt_show_company_info) { 
                                print_order.lines.push({
                                    align:"center",
                                    bold:true, 
                                    value:'company_name',
                                    text:company_name 
                                })
                                // print_order.lines.push({
                                //     align:"center",
                                //     bold:false,
                                //     value:'company_post_address',
                                //     text:"Address: "+company.company_post_address
                                // })
                                // print_order.lines.push({
                                //     align:"center",
                                //     bold:false,
                                //     value:'company_email_address',
                                //     text:"Email: "+company.company_email_address
                                // }) 
                                // let phone_details = "(Cell): "+company.company_phone_number
                                // if (company.company_phone_number2) {
                                //     phone_details = phone_details +" (Tell): "+company.company_phone_number2 
                                //     if (paper_size<43) {
                                //         print_order.lines.push({
                                //             align:"center",
                                //             bold:false,
                                //             value:'company_phone_number',
                                //             text: "(Cell): "+company.company_phone_number
                                //         })
                                //         print_order.lines.push({
                                //             align:"center",
                                //             bold:false,
                                //             value:'company_phone_number',
                                //             text:"(Tell): "+company.company_phone_number2 
                                //         })
                                //     }
                                // }
                                // if (paper_size>42) { 
                                //     print_order.lines.push({
                                //         align:"center",
                                //         bold:false,
                                //         value:'company_phone_number',
                                //         text:phone_details
                                //     })  
                                // }  
                                // if (receipt_show_company_tpin) {  
                                //     print_order.lines.push({
                                //         align:"center",
                                //         bold:false,
                                //         value:'company_tpin',
                                //         text:"TPIN: "+company.company_tpin 
                                //     }) 
                                // } 
                                
                                let outlet = sale_order.outlet 
                                let teller = sale_order.teller 
                                let text_outlet =""
                                if (outlet) {  
                                    text_outlet = "Outlet: "+outlet.name
                                } 
                                if (teller) {  
                                    text_outlet = text_outlet+" Teller: "+teller.names 
                                } 
        
                                // if (text_outlet!="") {  
                                //     print_order.lines.push({
                                //         align:"center",
                                //         bold:false,
                                //         value:'company_tpin',
                                //         text:text_outlet
                                //     }) 
                                // }
        
                                 
                            }
                
                            //DOWN HEADER
                            if (receipt_show_receipt_title) { 
                                // print_order.lines.push({ text:" "})
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'top_header',
                                    text:"******"
                                }) 
                                print_order.lines.push({ text:" "}) 
                            }
        
                            //DETAILS
                            let receipt_number = sale_order.order_key?sale_order.order_key:sale_order.order_id
                            let receipt_key = sale_order.key 
                            if (true) { 
                                // if (customer && receipt_show_buyer_info) {
                                if (true) {
                                    let receipt_type = "BILL"
                                     
        
                                    if (receipt_number) {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:this.EPSON_1LINE({
                                                first:receipt_type+" NUMBER:", 
                                                last:""+this.NUMBER_SAME_LENGTH(receipt_number,5),
                                                size:paper_size
                                            }) 
                                        })   
                                    }
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:receipt_type+" KEY:", 
                                            last:""+this.NUMBER_SAME_LENGTH(receipt_key,5),
                                            size:paper_size
                                        }) 
                                    })  
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"DATE:", 
                                            last:""+sale_order.local_created_at_,
                                            size:paper_size
                                        }) 
                                    })  
                                }
                                let customer = sale_order.customer 
                                let customer_name = sale_order.customer_name 
                                let table_name = sale_order.table_name 
                                let waiter_name = sale_order.waiter_name 
                                if (receipt_show_buyer_info) {
                                    if (waiter_name) {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:this.EPSON_1LINE({
                                                first:"WAITER: ",
                                                last:""+waiter_name,
                                                size:paper_size,
                                                max1:14,
                                                max2:paper_size-14
                                            }) 
                                        })  
                                        
                                    }if (table_name) {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:this.EPSON_1LINE({
                                                first:"TABLE: ",
                                                last:""+table_name,
                                                size:paper_size,
                                                max1:14,
                                                max2:paper_size-14
                                            }) 
                                        })  
                                        
                                    }if (customer_name) {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:this.EPSON_1LINE({
                                                first:"CUSTOMER: ",
                                                last:""+customer_name,
                                                size:paper_size,
                                                max1:14,
                                                max2:paper_size-14
                                            }) 
                                        })  
                                        
                                    }
                                }
                                print_order.lines.push({ text:" "}) 
                               
                            }
                            
                            //ITEMS
                            let items =joined_items?joined_items: sale_order.joined_items?sale_order.joined_items:sale_order.items
                            let items_size = this.SIZE(items)
                            let item_quantity = 0
                            let item_discounts = 0
                            let index = 0
                            
                            if (items ) { 
                                //---------------ITEM HEADER------------
                                print_order.lines.push({ value:"line"})
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"ITEMS",
                                        last:"QYT",
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                }) 
                                
                                items.forEach(item => {
                                    let item_update_key = item?.update_key
                                    let on_docket = false
                                    if(!update_key){
                                        on_docket = true
                                    }else if(item_update_key==update_key){
                                        on_docket = true
                                    }else {
                                        on_docket = false
                                    }
                                    if (on_docket) {
                                        index=index+1 
                                        // let selected_item_option = item?.selected_item_option
                                        let option_name = item?.selected_item_option_names
                                        let item_name = item.name?item.name:item.item_name
                                        let name = receipt_show_item_numbering?index+". "+item_name:item_name
                                        if (option_name) {
                                            name = name+" ("+option_name+")"
                                        }

                                        let quantity = this.toNumber(item.quantity) 
                                        item_quantity=item_quantity+quantity
                                        let discount = this.toNumber(item.discount) *quantity
                                        item_discounts = item_discounts+discount
                                        let sold_price = this.toNumber(item.sold_price)
                                        let total_sold_price = sold_price*quantity
                                        let sold_price_ = this.money(sold_price)
                                        let total_sold_price_ = this.money(total_sold_price)
            
                                        let item_tax = item.tax 
                                        let item_tax_group_code = item.tax_group_code 
                                        let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
                
                                        // let last = total_sold_price_+" "+usable_tax_group
                                        // let mid = "QTY:"+quantity+" X "+sold_price_
                                        // let first = name+"  "+mid
                                        let last = quantity+" "+usable_tax_group
                                        let mid = ":" 
                                        let first = " "+name 
                                        let first_size = this.SIZE(first)
                                        let mid_size = this.SIZE(mid)
                                        let max2 = this.SIZE(last)
                                        let max1 = paper_size-max2-3 
            
                                        if (first_size>max1) {
                                            let number1 = name.slice(0,max1)
                                            let number2 = name.slice((max1))
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:number1,
                                                    last:last,
                                                    size:paper_size,
                                                    max1:max1,
                                                    max2:max2
                                                }) 
                                            })  
            
                                            let number3 = number2?number2+" "+mid:mid
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:number3,
                                                    last:"",
                                                    size:paper_size,
                                                    max1:paper_size-1,
                                                    max2:1
                                                }) 
                                            })  
                                        } else {
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:first,
                                                    last:last,
                                                    size:paper_size,
                                                    max1:max1,
                                                    max2:max2
                                                }) 
                                            }) 
                                             
                                        }  
                                    }
                                    
                                });  
                            }
        
                            //CARD LOADING
                            let selected_card_option = sale_order?sale_order.selected_card_option:null
                            if (selected_card_option=="load_money") {
                                let selected_card = sale_order.selected_card
                                let card_balance = selected_card?this.money(selected_card.balance_amount):null
                                let card_code = selected_card?selected_card.code:null
                                let card_number = this.showCard(card_code)
                                let card_load_amount = this.money(sale_order.card_load_amount) 
                                let new_balance = this.money(this.toNumber(card_balance)+this.toNumber(card_load_amount))
                                sale_order.total_sold_price = card_load_amount
                                
                                print_order.lines.push({ 
                                    text:' '
                                })
                                // print_order.lines.push({
                                //     align:"center",
                                //     bold:false,
                                //     value:"",
                                //     text:'CARD LOADING'
                                // }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"CARD NUMBER",
                                        last:card_number,
                                        size:paper_size,
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"B4 BALANCE:",
                                        last:card_balance,
                                        size:paper_size,
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"LOADED AMOUNT:",
                                        last:card_load_amount,
                                        size:paper_size,
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"NEW BALANCE:",
                                        last:new_balance,
                                        size:paper_size,
                                    }) 
                                }) 
                            }
                           
                            //TOTAL  
                            if (true) { 
                                //  
                                let total_sold_price = this.toNumber(sale_order.total_sold_price )
                                let given_amount = this.toNumber(sale_order.given_amount)
                                let sum_amount = this.toNumber(sale_order.sum_amount?sale_order.sum_amount:sale_order.paid_amount)
                                let total_price = this.toNumber(sale_order.total_price)
                                let total_discount = this.toNumber(sale_order.total_discount)
                                let all_discounts = total_discount+item_discounts
                                let all_discounts_ = this.money(all_discounts)
                                let change_amount = given_amount-sum_amount
                                let total_sold_price_ = this.money(total_sold_price)
                                let given_amount_ = this.money(given_amount)
                                let sum_amount_ = this.money(sum_amount)
                                let change_amount_ = this.money(change_amount)
        
                                let total_price_ = this.money(total_price+item_discounts)
                                let total_discount_ = this.money(total_discount)
        
                               
                                     
        
                                print_order.lines.push({ value:"line"})
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    double:true,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"TOTAL ITEMS:", 
                                        last:""+items_size,
                                        size:paper_size/2
                                    }) 
                                })  
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    double:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"TOTAL QYT:", 
                                        last:""+item_quantity,
                                        size:paper_size
                                    }) 
                                })   
                                 
                                 
                            }  

                            //POWERED BY
                            if (!disable_kipcount_watermark) { 
                                print_order.lines.push({ value:"line"})
                                print_order.lines.push({
                                    align:"center",
                                    bold:false, 
                                    text:'***POWERED BY KIPCOUNT.COM***'
                                }) 
                                print_order.lines.push({ value:" "}) 
                            } 

                            if (index) {
                                docket_print_order.push(print_order) 
                            }
                        }); 
                    }

                    
                    // return print_order 
                    resolve({
                        success:true,
                        data:docket_print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },
        async EPSON_SALE_PAYMENT_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"EPSON_SALE_PAYMENT_REQUEST",
                    page:NAME,
                    payload:payload
                } 
                try {    
                    let sale_order = payload?payload.sale_order:null
                    let day_shift = sale_order?sale_order.day_shift:null
                    let cashup = sale_order?sale_order.cashup:null
                     
                    let receivable = sale_order?sale_order.receivable:null
                    let customer = sale_order?sale_order.customer:null
                    let till = sale_order?sale_order.till:null
                    let teller = sale_order?sale_order.teller:null
                    let payment_method = sale_order?sale_order.payment_method:null
                    let money_account = sale_order?sale_order.money_account:null
                    let order_type = sale_order?sale_order.order_type:null 
                    let invoices = sale_order?sale_order.joined_paid_invoices:null 
                    if(!sale_order){
                        reject({...response,error:"sale_order required"})
                        return null
                    }
                    
 

                    let print_title = payload?payload.print_title:null
                    let printer = payload?payload.printer?payload.printer :{}:{}

                    const default_paper_size = 32
                    const default_printer_name = "POS80"
                    let paper_size = 0
                    let printer_name = ""
                    let space_value = '.'   
                    let print_order = {}

                    //SETTINGS
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null
                    //template
                    let template_settings = settings?settings.template_settings:null
                    let receipt_settings = template_settings?template_settings.receipt:null
                    let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null

                    //PRINTER INFO
                    paper_size = printer.paper_size?printer.paper_size:default_paper_size
                    printer_name = printer.printer_name?printer.printer_name:default_printer_name
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name 
                    print_order.lines = [] 
        
                
                    //TOP HEADER
                    if (receipt_show_receipt_title) {
                        print_title = print_title?print_title:"PAYMENT"
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*** "+print_title+" ***"
                        })
                        // print_order.lines.push({ text:" "})
                    }

                    //COMPANY INFO
                    const company = sale_order?.company
                    const outlet = sale_order?.outlet
                    const c_company_name = company?.company_name
                    const c_phone_number_cell = company?.company_phone_number
                    const c_phone_number_tell = company?.company_phone_number2
                    const c_post_address = company?.company_post_address
                    const c_email_address = company?.company_email_address
                    const c_tpin = company?.company_tpin 
                    const c_location_address = company?.company_location_address 
                    
                    const receipt_company_name = outlet?.receipt_company_name
                    const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                    const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                    const receipt_post_address = outlet?.receipt_post_address
                    const receipt_email_address = outlet?.receipt_email_address
                    const receipt_tpin = outlet?.receipt_tpin
                    const receipt_address = outlet?.receipt_address
                    
                    const company_name = receipt_company_name?receipt_company_name:c_company_name
                    const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                    const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                    const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                    const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                    const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                    const company_address =  receipt_address?receipt_address:c_location_address
                    if (company && receipt_show_company_info) { 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            value:'company_name',
                            text:company_name 
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_post_address',
                            text:"Address: "+company_post_address
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_email_address',
                            text:"Email: "+company_email_address
                        }) 
                        let phone_details = "(Cell): "+company_phone_number_cell
                        if (company_phone_number_tell) {
                            phone_details = phone_details +" (Tell): "+company_phone_number_tell
                            if (paper_size<43) {
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number',
                                    text: "(Cell): "+company_phone_number_cell
                                })
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number',
                                    text:"(Tell): "+company_phone_number_tell
                                })
                            }
                        }
                        if (paper_size>42) { 
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_phone_number',
                                text:phone_details
                            })  
                        }  
                        if (receipt_show_company_tpin) {  
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_tpin',
                                text:"TPIN: "+company_tpin 
                            }) 
                        } 
                        
                        let outlet = sale_order.outlet 
                        let teller = sale_order.teller 
                        let text_outlet =""
                        if (outlet) {  
                            text_outlet = "Outlet: "+outlet.name
                        } 
                        if (teller) {  
                            text_outlet = text_outlet+" Teller: "+teller.names 
                        } 

                        if (text_outlet!="") {  
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_tpin',
                                text:text_outlet
                            }) 
                        }

                         
                    }
        
                    //DOWN HEADER
                    if (receipt_show_receipt_title) { 
                        // print_order.lines.push({ text:" "})
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"******"
                        }) 
                        print_order.lines.push({ text:" "}) 
                    }

                    //DAY SHIFT DETAILS
                    let receipt_number = sale_order.payment_number?sale_order.payment_number:sale_order.code
                    let receipt_key = sale_order.key 
                    if (true) { 
                        if (true) {
                            let receipt_type = "RECEIPT" 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:receipt_type+" NUMBER:", 
                                    last:""+this.NUMBER_SAME_LENGTH(receipt_number,5),
                                    size:paper_size
                                }) 
                            })  
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"DATE:", 
                                    last:""+sale_order.created_at_server_,
                                    size:paper_size
                                }) 
                            })   
                        }  
                        if (customer && receipt_show_buyer_info) {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"Bill To:",
                                    last:""+sale_order.customer_name                                    ,
                                    size:paper_size,
                                    max1:14,
                                    max2:paper_size-14
                                }) 
                            }) 
                            if (customer.phone_number) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"PHONE:",
                                        last:""+customer.phone_number,
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                }) 
                            }
                            
                        }
                        if (receivable) {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"ACCOUNT:",
                                    last:""+receivable.name,
                                    size:paper_size,
                                    max1:14,
                                    max2:paper_size-14
                                }) 
                            })   
                        }
                        print_order.lines.push({ text:" "}) 
                    }
                    
                    //INVOICES
                    
                    if (invoices) { 
                        print_order.lines.push({
                            align:"left",
                            bold:true,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"INVOICE(S):", 
                                last:"AMOUNT",
                                size:paper_size
                            }) 
                        })   
                        
                        invoices.forEach(invoice => { 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:" No.:"+receipt_number, 
                                    last:""+this.money(invoice.amount),
                                    size:paper_size
                                }) 
                            })   
                        }); 
                        
                        print_order.lines.push({
                            align:"left",
                            bold:true,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"DEPOSIT:", 
                                last:""+sale_order.invoice_paid_amount_                                ,
                                size:paper_size
                            }) 
                        })
                        print_order.lines.push({
                            align:"left",
                            bold:true,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"BALANCE:", 
                                last:""+sale_order.invoice_balance_amount_,
                                size:paper_size
                            }) 
                        })
                    }

               
                     
                   
                    //TOTAL  
                    if (true) { 
                        
                        //    
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"left",
                            bold:true,
                            double:true,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL PAID:", 
                                last:""+sale_order.amount_                                ,
                                size:paper_size/2
                            }) 
                        })   
                        
                         
                        if (payment_method) {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"PAYMENT:", 
                                    last:""+payment_method.name,
                                    size:paper_size
                                }) 
                            })   
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TRANSACTION ID:", 
                                    last:""+payment_method.transaction_id?payment_method.transaction_id:'..',
                                    size:paper_size
                                }) 
                            })   
                        }
                         
                    }  

                    //BARCODE
                    if (receipt_show_barcode && receipt_key) {
                        let prefix = "PA"

                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            barcode:true,
                            text:prefix+''+this.NUMBER_SAME_LENGTH(sale_order.day_shift_key+" "+sale_order.code,5)
                        })  
                    }
                    
                    //DATE AND TIME
                    if (true) {
                        print_order.date = sale_order.created_at_server_date
                        print_order.time = sale_order.created_at_server_time
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"DATE:"+print_order.date, 
                                last:"TIME:"+print_order.time,
                                size:paper_size
                            }) 
                        })   
                    }

                    //POWERED BY
                    if (!disable_kipcount_watermark) { 
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'***POWERED BY KIPCOUNT***'
                        }) 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'***+265993259202***'
                        }) 
                        print_order.lines.push({ value:"line"}) 
                    } 

                    // return print_order 
                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async EPSON_CASHUP_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                try {  
                    let response = {
                        from:"EPSON_CASHUP_REQUEST",
                        // page:NAME,
                        payload:payload
                    } 
                    let cashup = payload?payload.cashup:null 
                    let day_shift = payload?payload.day_shift:null
                    let data = payload?payload.data:null
                    if (data&&!cashup) {
                        cashup = this.JOIN_CASHUP(data)
                    }
                    if (cashup&&!day_shift) {
                        day_shift = this.JOIN_DAY_SHIFT(cashup.day_shift)
                    }
                    
                    if (!cashup||!day_shift) { 
                        return {
                            ...response,
                            success:false,
                            error:'no payload'
                        }
                    } 
                    
                    let print_order = {}
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null
                    
                    //PRINTER INFO 
                    const default_paper_size = 32
                    const default_printer_name = "POS80"
                    let printer = payload.printer?payload.printer :{} 
                    let paper_size = printer.paper_size?printer.paper_size:default_paper_size
                    let printer_name = printer.printer_name?printer.printer_name:default_printer_name
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name 
                    print_order.lines = []  
        
                
                    //TOP HEADER
                    if (true) {
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*** CASHUP ***"
                        })
                    }
                     
                    //COMPANY INFO
                    const company = day_shift?.company
                    const outlet = day_shift?.outlet
                    const c_company_name = company?.company_name
                    const c_phone_number_cell = company?.company_phone_number
                    const c_phone_number_tell = company?.company_phone_number2
                    const c_post_address = company?.company_post_address
                    const c_email_address = company?.company_email_address
                    const c_tpin = company?.company_tpin 
                    const c_location_address = company?.company_location_address 
                    
                    const receipt_company_name = outlet?.receipt_company_name
                    const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                    const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                    const receipt_post_address = outlet?.receipt_post_address
                    const receipt_email_address = outlet?.receipt_email_address
                    const receipt_tpin = outlet?.receipt_tpin
                    const receipt_address = outlet?.receipt_address
                    
                    const company_name = receipt_company_name?receipt_company_name:c_company_name
                    const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                    const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                    const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                    const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                    const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                    const company_address =  receipt_address?receipt_address:c_location_address
                    if (company) { 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            value:'company_name',
                            text:company_name 
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_post_address',
                            text:"Address: "+company_post_address
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_email_address',
                            text:"Email: "+company_email_address
                        }) 
                        let phone_details = "(Cell): "+company_phone_number_cell
                        if (company_phone_number_tell) {
                            phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                            if (paper_size<43) {
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text: "(Cell): "+company_phone_number_cell
                                })
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text:"(Tell): "+company_phone_number_tell 
                                })
                            }
                        }
                        if (paper_size>42) { 
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_phone_number',
                                text:phone_details
                            })  
                        }
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_tpin',
                            text:"TPIN: "+company_tpin 
                        })   
                    }
        
                    //DOWN HEADER
                    if (true) {
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*******"
                        })
                    }
        
                    print_order.lines.push({
                        align:"left",
                        bold:false,
                        value:"cashup_key",
                        text:this.EPSON_1LINE({
                            first:"CASHUP NUMBER:", 
                            last:cashup.key,
                            size:paper_size
                        })
        
                    }) 
                    print_order.lines.push({
                        align:"left",
                        bold:false,
                        value:"",
                        text:this.EPSON_1LINE({
                            first:"OPERATING DATE:", 
                            last:""+cashup.operating_date_,  
                            size:paper_size
                        })
        
                    }) 
                    print_order.lines.push({
                        align:"left",
                        bold:false,
                        value:"cashup_key",
                        text:this.EPSON_1LINE({
                            first:"START:"+cashup.open_time_, 
                            last:"CLOSE:"+cashup.close_time_,  
                            size:paper_size
                        })
        
                    })  
                    if (cashup.teller) {
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"teller_name",
                            text:this.EPSON_1LINE({
                                first:"TELLER NAME:", 
                                last:cashup.teller_name,
                                size:paper_size
                            })
        
                        }) 
                    }
                    if (cashup.till_name) {
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"till_name",
                            text:this.EPSON_1LINE({
                                first:"Till:", 
                                last:cashup.till_name,
                                size:paper_size
                            })
        
                        }) 
                    }
        
                    //summary
                    if (true) {
                        // print_order.lines.push({ 
                        //     value:"line",  
                        // }) 
                        // print_order.lines.push({
                        //     align:"center",
                        //     bold:false,
                        //     value:'',
                        //     text:"SUMMARY"
                        // })
                        
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CASH SALES:", 
                                last:this.money(cashup.sales_cash_payments),
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OTHER SALES:", 
                                last:this.money(cashup.sales_total_other_option_payments),
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CASH INVOICE(S):", 
                                last:this.money(cashup.invoices_cash_payments),
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OTHER INVOICE(S):", 
                                last:this.money(cashup.invoices_total_other_option_payments),
                                size:paper_size
                            })
        
                        }) 
                        // print_order.lines.push({
                        //     align:"left",
                        //     bold:false,
                        //     value:"",
                        //     text:this.EPSON_1LINE({
                        //         first:"CASH CARD LOAD:", 
                        //         last:this.money(cashup.invoices_cash_payments),
                        //         size:paper_size
                        //     })
        
                        // }) 
                        // print_order.lines.push({
                        //     align:"left",
                        //     bold:false,
                        //     value:"",
                        //     text:this.EPSON_1LINE({
                        //         first:"OTHER CARD LOAD:", 
                        //         last:this.money(cashup.invoices_total_other_option_payments),
                        //         size:paper_size
                        //     })
        
                        // }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"ON ACCOUNT:", 
                                last:this.money(cashup.sales_on_account),
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:true, 
                            double:false, 
                            text:this.EPSON_1LINE({
                                first:"TOTAL SALES:", 
                                last:this.money(this.toNumber(cashup.sales_retail)+this.toNumber(cashup.sales_wholesales)),
                                size:paper_size
                            })
        
                        }) 
                    }
                    
                    //OPENING BALANCE
                    let opening_currencies = cashup.opening_currencies?cashup.opening_currencies:null 
                    if (opening_currencies) {
                        print_order.lines.push({ 
                            text:" ",   
                        })
                        let first = this.EPSON_1LINE({
                            first:"NOTES(CASH) ", 
                            last:"QTY(NO.)",
                            size:paper_size/1.8
                        })
                        print_order.lines.push({
                            align:"left",
                            bold:true, 
                            text:this.EPSON_1LINE({
                                first:first, 
                                last:"AMOUNT(MWK)",
                                size:paper_size
                            })
        
                        }) 
                        opening_currencies.forEach(currency => {
                            let total =  this.toNumber(currency.value)*this.toNumber(currency.quantity) 
                            let quantity = this.EPSON_1LINE({
                                first:currency.name+' ', 
                                last:' '+this.toNumber(currency.quantity),
                                size:paper_size/2,
                                space_value:'-'
                            })
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:quantity+' ', 
                                    last:' '+this.money(total),
                                    size:paper_size,
                                    space_value:'-'
                                })
            
                            }) 
                            
                        }); 
                        print_order.lines.push({
                            align:"left",
                            bold:true,  
                            text:this.EPSON_1LINE({
                                first:"OPENING BALANCE",
                                last:cashup.opening_cash_,
                                size:paper_size
                            })
        
                        }) 
                    }
                    //CLOSING CASH
                    let closing_currencies = cashup.closing_currencies?cashup.closing_currencies:null 
                    if (closing_currencies) {
                        print_order.lines.push({ 
                            text:"",  
                        }) 
                        closing_currencies.forEach(currency => {
                            let total =  this.toNumber(currency.value)*this.toNumber(currency.quantity) 
                            let quantity = this.EPSON_1LINE({
                                first:currency.name+' ', 
                                last:' '+this.toNumber(currency.quantity),
                                size:paper_size/2,
                                space_value:'-'
                            })
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:quantity+' ', 
                                    last:' '+this.money(total),
                                    size:paper_size,
                                    space_value:'-'
                                })
            
                            }) 
                            
                        });
                        print_order.lines.push({
                            align:"left",
                            bold:true,  
                            text:this.EPSON_1LINE({
                                first:"CLOSING BALANCE",
                                last:cashup.closing_cash_,
                                size:paper_size
                            })
        
                        }) 
                    }
                    
                    
                    //SHORTAGES
                    if (false) {
                        // print_order.lines.push({ 
                        //     value:"doted_line",  
                        // }) 
                        print_order.lines.push({ 
                            text:" ",   
                        }) 
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'shortages',
                            text:"SHORTAGE(S) / OVERAGE(S)"
                        })
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"shortage",
                            text:this.EPSON_1LINE({
                                first:"Shortage:", 
                                last:'0.00',
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"shortage",
                            text:this.EPSON_1LINE({
                                first:"Overage:", 
                                last:'0.00',
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false, 
                            text:this.EPSON_1LINE({
                                first:"TOTAL:", 
                                last:'0.00',
                                size:paper_size
                            })
        
                        }) 
                    }
                    
                    //totals
                    if (true) {
                        let closed = cashup.closed
                        print_order.lines.push({ 
                            text:" ",  
                        }) 
                        print_order.lines.push({ 
                            value:"line",  
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:true, 
                            double:false, 
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"EXPECTED:", 
                                last:this.money(cashup.expected_cash),
                                size:paper_size
                            })
        
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:true, 
                            double:false, 
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL CASH:", 
                                last:this.money(this.toNumber(cashup.closing_cash)),
                                size:paper_size
                            })
        
                        }) 
                        let shortage = this.toNumber(cashup.closing_cash)-this.toNumber(cashup.expected_cash)
                        let isOverage = shortage>0
                        print_order.lines.push({
                            align:"left",
                            bold:true, 
                            double:false, 
                            text:this.EPSON_1LINE({
                                first:isOverage?"OVERAGE: ":'SHORTAGE: ', 
                                last:!closed?"Running...":isOverage?this.money(shortage):"("+this.money(shortage*-1)+")",
                                size:paper_size
                            })
        
                        }) 
                        let closing_money = this.toNumber(cashup.closing_cash)-this.toNumber(cashup.opening_cash)
                        print_order.lines.push({
                            align:"left",
                            bold:true, 
                            double:false, 
                            text:this.EPSON_1LINE({
                                first:"CLOSING CASH:", 
                                last:!closed?"Running...":this.money(closing_money<0?0:closing_money),
                                size:paper_size
                            })
        
                        })  
                    } 

                    // powered by 
                    if (true) {
                        print_order.lines.push({ 
                            text:" ",  
                        }) 
                        print_order.lines.push({ 
                            value:"line",  
                        }) 
                        print_order.lines.push({ 
                            align:"center",
                            text:"*** POWERED BY KIPCOUNT ***",  
                        }) 
                        print_order.lines.push({ 
                            align:"center",
                            text:"*** +265 993 259 202 ***",  
                        }) 
                        print_order.lines.push({ 
                            value:"line",  
                        })  
                    }
                    // return print_order 
                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },
        async EPSON_DAY_SHIFT_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"EPSON_DAY_SHIFT_REQUEST",
                    page:NAME,
                    payload:payload
                } 
                try {   
                    let day_shift = payload?payload.day_shift:null
                    if(!day_shift){return null}
                    let cashup_option = day_shift?day_shift.cashup_option:null
                    let cashups = day_shift?day_shift.joined_cashups:null
                    let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                    let running_cashups_size = this.SIZE(running_cashups)
                    let isRunning = running_cashups_size?true:false
                    let isClosed = day_shift?day_shift.closed:null

                    //USED TILLS
                    let used_floating_cash = 0
                    let used_till_keys = []
                    if (cashups) {
                        cashups.forEach(element => {
                            let index = used_till_keys.indexOf(element.till_key)
                            if (index==-1) {
                                used_floating_cash = used_floating_cash+this.toNumber(element.floating_cash)
                                used_till_keys.push(element.till_key)
                            }
                        });
                    }

                    let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                    let total_cashups = this.CALCULATE_TOTAL_CASHUPS(cashups,day_shift)  
                    let report_type = payload?payload.report_type:null
                    let printer = payload?payload.printer?payload.printer :{}:{}

                    const default_paper_size = 32
                    const default_printer_name = "POS80"
                    let paper_size = 0
                    let printer_name = ""
                    let space_value = '.'   
                    let print_order = {}

                    //SETTINGS
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null

                    //PRINTER INFO
                    paper_size = printer.paper_size?printer.paper_size:default_paper_size
                    printer_name = printer.printer_name?printer.printer_name:default_printer_name
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name 
                    print_order.lines = [] 
        
                
                    //TOP HEADER
                    if (true) {
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*** DAY SHIFT ***"
                        })
                    }

                     
                    //COMPANY INFO
                    const company = day_shift?.company
                    const outlet = day_shift?.outlet
                    console.log(day_shift,'day_shift.....');
                    console.log(outlet,'outlet.....');
                    console.log(company,'company.....');
                    const c_company_name = company?.company_name
                    const c_phone_number_cell = company?.company_phone_number
                    const c_phone_number_tell = company?.company_phone_number2
                    const c_post_address = company?.company_post_address
                    const c_email_address = company?.company_email_address
                    const c_tpin = company?.company_tpin 
                    const c_location_address = company?.company_location_address 
                    
                    const receipt_company_name = outlet?.receipt_company_name
                    const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                    const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                    const receipt_post_address = outlet?.receipt_post_address
                    const receipt_email_address = outlet?.receipt_email_address
                    const receipt_tpin = outlet?.receipt_tpin
                    const receipt_address = outlet?.receipt_address
                    
                    const company_name = receipt_company_name?receipt_company_name:c_company_name
                    const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                    const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                    const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                    const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                    const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                    const company_address =  receipt_address?receipt_address:c_location_address
                    if (company) { 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            value:'company_name',
                            text:company_name 
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_post_address',
                            text:"Address: "+company_post_address
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_email_address',
                            text:"Email: "+company_email_address
                        }) 
                        let phone_details = "(Cell): "+company_phone_number_cell
                        if (company_phone_number_tell) {
                            phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                            if (paper_size<43) {
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text: "(Cell): "+company_phone_number_cell
                                })
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text:"(Tell): "+company_phone_number_tell 
                                })
                            }
                        }
                        if (paper_size>42) { 
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_phone_number_cell',
                                text:phone_details
                            })  
                        }
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_tpin',
                            text:"TPIN: "+company_tpin 
                        })   
                    }
        
                    //DOWN HEADER
                    if (report_type) {
                        print_order.lines.push({text:" "})
                        print_order.lines.push({value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"**** "+report_type+" ****"
                        })
                        print_order.lines.push({value:"line"})
                        print_order.lines.push({text:" "})
                    }

                    //DAY SHIFT DETAILS
                    if (true) {
                        // print_order.lines.push({ text:' '})  
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"DAY:", 
                                last:""+day_shift.operating_date_,  
                                size:paper_size
                            }) 
                        }) 
                        
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OPEN BY:", 
                                last:""+day_shift.user_name,  
                                size:paper_size
                            })
            
                        })
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OPEN AT:", 
                                last:""+day_shift.local_created_at_,  
                                size:paper_size
                            })
            
                        })

                        if (report_type=="X-READ") {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"PRINTED AT:", 
                                    last:""+day_shift.local_updated_at_,  
                                    size:paper_size
                                })
                
                            }) 
                        }else if (isClosed) {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSE AT:", 
                                    last:""+day_shift.local_updated_at_,  
                                    size:paper_size
                                })
                
                            }) 
                        }else{
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSE AT:", 
                                    last:" [Running...]",  
                                    size:paper_size
                                })
                
                            }) 
                        }
                        if (tills) { 
                            let till_names = '' 
                            tills.forEach((till,index) => {
                                if (index == 0) {
                                    till_names = till_names+till.name 
                                }else{
                                    till_names = till_names+", "+till.name 
                                }
                            });
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TILLS:", 
                                    last:"["+till_names+"]",  
                                    size:paper_size
                                })
                
                            })  
                        }
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL FLOATING CASH:", 
                                last:""+day_shift.total_floating_cash_,  
                                size:paper_size
                            })
            
                        }) 
                    }
                    
                    //CASHUPS
                    if (cashups) {
                        print_order.lines.push({ text:" "})
                        print_order.lines.push({
                            align:"center",
                            bold:false, 
                            text:"CASHUPS(S) / REGISTER(S)"
                        })

                        cashups.forEach(cashup => {
                            let cashup_closed = cashup.closed 
                            let opening_cash = this.toNumber(cashup.opening_cash )
                            let expected_opening_cash = cashup_option =='submit_cash_after_every_cashup'? 
                                this.toNumber(cashup.floating_cash ):
                                this.toNumber(cashup.expected_opening_cash )
                            let isOpenShortage = opening_cash!=expected_opening_cash?true:false
                            let opening_shortage = opening_cash-expected_opening_cash 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TELLER: ", 
                                    last:" "+cashup.teller_name,  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TILL: ", 
                                    last:" "+cashup.till_name,  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"OPEN AT: ", 
                                    last:" "+day_shift.local_created_at_,  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            })  
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSE AT: ", 
                                    last:" "+(cashup_closed?cashup.close_date_time_:'['+cashup.close_date_time_+']'),  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            }) 
                            
                            
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"OPENING BALANCE: ", 
                                    last:" "+cashup.opening_cash_,  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CASH SALES: ", 
                                    last:" "+this.money(cashup.sales_cash_payments),  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            })  
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"OTHER SALES: ", 
                                    last:" "+this.money(cashup.sales_total_other_option_payments),  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"ON ACCOUNT: ", 
                                    last:" "+this.money(cashup.sales_on_account),  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            }) 

                            let total_invoice = this.toNumber(cashup.invoices_cash_payments)
                                +this.toNumber(cashup.invoices_total_other_option_payments)
                             
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"INVOICE PAYMENTS: ", 
                                    last:" "+this.money(total_invoice),  
                                    size:paper_size,
                                    space_value:space_value
                                }) 
                            })
                            
                            if (!cashup.closed) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"CURRENT SALES: ", 
                                        last:" "+cashup.total_sales_,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                })
                            }else {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"TOTAL SALES: ", 
                                        last:" "+cashup.total_sales_,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"CLOSING BALANCE: ", 
                                        last:" "+cashup.closing_cash_,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                })  
                            }
                            if (isOpenShortage) {
                                if (opening_cash>expected_opening_cash) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:true,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"OPEN [OVER..]: ", 
                                            last:" "+this.money(opening_shortage),  
                                            size:paper_size,
                                            space_value:space_value
                                        }) 
                                    }) 
                                } else {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:true,
                                        value:"",
                                        text:this.EPSON_1LINE({
                                            first:"OPEN [SHORT..]: ", 
                                            last:" ("+this.money(opening_shortage*-1)+")",  
                                            size:paper_size,
                                            space_value:space_value
                                        }) 
                                    }) 
                                } 
                            }

                            if (cashup.shortage) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:cashup.isShortage?"CLOSE [SHORT..]: ":"CLOSE [OVER..]: ", 
                                        last:cashup.isShortage?cashup.shortage_:cashup.shortage_,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                })   
                            }
                            print_order.lines.push({ text:" "})
                        });
                        
                        
                    }
                    
                    //PAYMENT OPTIONS
                    if (total_cashups) {
                        print_order.lines.push({ text:" "})
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:false, 
                            text:"PAYMENT(S)"
                        })

                        total_cashups.all_other_option_payments.forEach(payment => { 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:payment.payment_method_name, 
                                    last:this.money(payment.amount),  
                                    size:paper_size
                                }) 
                            }) 
                             
                        });
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL:", 
                                last:this.money(
                                    this.toNumber(total_cashups.sales_total_other_option_payments)+
                                    this.toNumber(total_cashups.invoices_total_other_option_payments)),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({ text:" "})
                        
                        
                    }
                    
                    //SHORTAGES
                    if (total_cashups) {
                        print_order.lines.push({ text:" "})
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:false, 
                            text:"SHORTAGE(S) / OVERAGE(S)"
                        })

                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OPENING SHORTAGES: ", 
                                // last:" ("+this.money(total_cashups.total_open_shortages*-1)+")",  
                                last:" "+this.money(total_cashups.total_open_shortages*-1),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OPENING OVERAGES: ", 
                                last:" "+this.money(total_cashups.total_open_overages),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CLOSING SHORTAGES: ", 
                                // last:" ("+this.money(total_cashups.total_close_shortages*-1)+")",  
                                last:" "+this.money(total_cashups.total_close_shortages*-1),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CLOSING OVERAGES: ", 
                                last:" "+total_cashups.total_close_overages_,  
                                size:paper_size
                            }) 
                        })  
                         
                        print_order.lines.push({
                            align:"left",
                            bold:true,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL: ", 
                                last:" "+(
                                    total_cashups.total_is_shortage?"("+
                                    this.money(total_cashups.total_shortage*-1)+")":
                                    this.money(total_cashups.total_shortage)
                                ),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({ text:" "})
                    }

                    //CASHUPS SUMMARY
                    if (total_cashups) {
                        print_order.lines.push({ text:" "})
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:false, 
                            text:"CASHUPS(S) SUMMARY"
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"RETAIL:", 
                                last:this.money(total_cashups.sales_retail),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"WHOLESALE:", 
                                last:this.money(total_cashups.sales_wholesales),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CASH SALES:", 
                                last:this.money(total_cashups.sales_cash_payments),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OTHER SALES:", 
                                last:this.money(total_cashups.sales_total_other_option_payments),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"ON ACCOUNT:", 
                                last:this.money(total_cashups.sales_on_account),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CASH INVOICE:", 
                                last:this.money(total_cashups.invoices_cash_payments),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OTHER INVOICE:", 
                                last:this.money(total_cashups.invoices_total_other_option_payments),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL SALES:", 
                                last:this.money(total_cashups.total_sales),  
                                size:paper_size
                            }) 
                        })  
                    }
                    
                    //TOTAL SUMMARY
                    if (report_type != "X-READ" && total_cashups) { 
                        print_order.lines.push({ text:" "}) 
                        print_order.lines.push({ value:"line"})
                        let closing_cash = this.toNumber(total_cashups.closing_cashes) 
                               + this.toNumber(total_cashups.total_open_shortage)
                               - this.toNumber(total_cashups.opening_cashes)
                        
                        if (isRunning) {
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CURRENT EXPECTED:", 
                                    last:""+total_cashups.total_cash_,  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CURRENT TOTAL:", 
                                    last:" "+this.money(closing_cash+used_floating_cash),  
                                    size:paper_size
                                }) 
                            }) 
                            
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSING:", 
                                    last:" [Waiting...]",  
                                    size:paper_size
                                }) 
                            }) 
                        }else{
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"EXPECTED:", 
                                    last:""+total_cashups.total_cash_,  
                                    size:paper_size
                                }) 
                            }) 
                            
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TOTAL CASH:", 
                                    last:""+this.money(closing_cash+used_floating_cash),   
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"USED TILL(S) FLOAT:", 
                                    last:""+this.money(used_floating_cash),  
                                    size:paper_size
                                }) 
                            })
                            
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSING:", 
                                    last:" "+this.money(closing_cash),  
                                    size:paper_size
                                }) 
                            })     
                        }
                    } 

                    //POWERED BY
                    if (true) {
                        print_order.lines.push({ text:" "}) 
                        print_order.lines.push({ text:" "}) 
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'POWERED BY KIPCOUNT'
                        }) 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'+265993259202'
                        }) 
                    } 
                    // return print_order 
                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async EPSON_DAY_ITEM_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"EPSON_DAY_ITEM_REQUEST",
                    page:NAME,
                    payload:payload
                } 
                try {   
                    const day_shift = payload?.data?.day_shift  
                    const sale_orders = payload?.data?.sale_orders  
                    let aggregated_items = payload?.data?.aggregated_items 
                    if (!aggregated_items) {
                        aggregated_items = this.CALCULATE_SALES_AGGREGATED_ITEMS({
                            sale_orders:sale_orders
                        })
                        
                    } 
                    if(!day_shift){
                        reject({error:'no dayshift'})
                        return null}
                    let cashup_option = day_shift?day_shift.cashup_option:null
                    let cashups = day_shift?day_shift.joined_cashups:null
                    let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                    let running_cashups_size = this.SIZE(running_cashups)
                    let isRunning = running_cashups_size?true:false
                    let isClosed = day_shift?day_shift.closed:null

                    //USED TILLS
                    let used_floating_cash = 0
                    let used_till_keys = []
                    if (cashups) {
                        cashups.forEach(element => {
                            let index = used_till_keys.indexOf(element.till_key)
                            if (index==-1) {
                                used_floating_cash = used_floating_cash+this.toNumber(element.floating_cash)
                                used_till_keys.push(element.till_key)
                            }
                        });
                    }

                    let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                    let total_cashups = this.CALCULATE_TOTAL_CASHUPS(cashups,day_shift)  
                    let report_type = payload?payload.report_type:null
                    let printer = payload?payload.printer?payload.printer :{}:{}

                    const default_paper_size = 32
                    const default_printer_name = "POS80"
                    let paper_size = 0
                    let printer_name = ""
                    let space_value = '.'   
                    let print_order = {}

                    //SETTINGS
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null

                    //PRINTER INFO
                    paper_size = printer.paper_size?printer.paper_size:default_paper_size
                    printer_name = printer.printer_name?printer.printer_name:default_printer_name
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name 
                    print_order.lines = [] 
        
                
                    //TOP HEADER
                    if (true) {
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*** DAY SOLD ITEMS ***"
                        })
                    }

                     
                    //COMPANY INFO
                    const company = day_shift?.company
                    const outlet = day_shift?.outlet
                    console.log(day_shift,'day_shift.....');
                    console.log(outlet,'outlet.....');
                    console.log(company,'company.....');
                    const c_company_name = company?.company_name
                    const c_phone_number_cell = company?.company_phone_number
                    const c_phone_number_tell = company?.company_phone_number2
                    const c_post_address = company?.company_post_address
                    const c_email_address = company?.company_email_address
                    const c_tpin = company?.company_tpin 
                    const c_location_address = company?.company_location_address 
                    
                    const receipt_company_name = outlet?.receipt_company_name
                    const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                    const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                    const receipt_post_address = outlet?.receipt_post_address
                    const receipt_email_address = outlet?.receipt_email_address
                    const receipt_tpin = outlet?.receipt_tpin
                    const receipt_address = outlet?.receipt_address
                    
                    const company_name = receipt_company_name?receipt_company_name:c_company_name
                    const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                    const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                    const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                    const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                    const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                    const company_address =  receipt_address?receipt_address:c_location_address
                    if (company) { 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            value:'company_name',
                            text:company_name 
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_post_address',
                            text:"Address: "+company_post_address
                        })
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_email_address',
                            text:"Email: "+company_email_address
                        }) 
                        let phone_details = "(Cell): "+company_phone_number_cell
                        if (company_phone_number_tell) {
                            phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                            if (paper_size<43) {
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text: "(Cell): "+company_phone_number_cell
                                })
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text:"(Tell): "+company_phone_number_tell 
                                })
                            }
                        }
                        if (paper_size>42) { 
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_phone_number_cell',
                                text:phone_details
                            })  
                        }
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'company_tpin',
                            text:"TPIN: "+company_tpin 
                        })   
                    }
        
                    //DOWN HEADER
                    if (report_type) {
                        print_order.lines.push({text:" "})
                        print_order.lines.push({value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"**** "+report_type+" ****"
                        })
                        print_order.lines.push({value:"line"})
                        print_order.lines.push({text:" "})
                    }

                    //DAY SHIFT DETAILS
                    if (true) {
                        // print_order.lines.push({ text:' '})  
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"DAY:", 
                                last:""+day_shift.operating_date_,  
                                size:paper_size
                            }) 
                        }) 
                        
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OPEN BY:", 
                                last:""+day_shift.user_name,  
                                size:paper_size
                            })
            
                        })
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OPEN AT:", 
                                last:""+day_shift.local_created_at_,  
                                size:paper_size
                            })
            
                        })

                        if (report_type=="X-READ") {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"PRINTED AT:", 
                                    last:""+day_shift.local_updated_at_,  
                                    size:paper_size
                                })
                
                            }) 
                        }else if (isClosed) {
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSE AT:", 
                                    last:""+day_shift.local_updated_at_,  
                                    size:paper_size
                                })
                
                            }) 
                        }else{
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"CLOSE AT:", 
                                    last:" [Running...]",  
                                    size:paper_size
                                })
                
                            }) 
                        }
                        if (tills) { 
                            let till_names = '' 
                            tills.forEach((till,index) => {
                                if (index == 0) {
                                    till_names = till_names+till.name 
                                }else{
                                    till_names = till_names+", "+till.name 
                                }
                            });
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.EPSON_1LINE({
                                    first:"TILLS:", 
                                    last:"["+till_names+"]",  
                                    size:paper_size
                                })
                
                            })  
                        }
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL FLOATING CASH:", 
                                last:""+day_shift.total_floating_cash_,  
                                size:paper_size
                            })
            
                        }) 
                    }
                    
                    //CASHUPS
                    let items = aggregated_items
                    let items_size = this.SIZE(items)
                    let item_quantity = 0
                    let item_discounts = 0
                    let index = 0
                    if (items) {
                        print_order.lines.push({ text:" "})
                        print_order.lines.push({
                            align:"center",
                            bold:false, 
                            text:"AGGREGATED SOLD ITEM(S)"
                        })
                        print_order.lines.push({ text:" "})

                        
                        items.forEach(item => {
                            index=index+1 
                            let item_name = item?.name?item?.name:item?.item_name
                            let option_name = item?.selected_item_option_names 
                            let name = receipt_show_item_numbering?index+". "+item_name:item_name
                            if (option_name) {
                                name = name+" ("+option_name+")"
                            }

                            let quantity = this.toNumber(item.sold_quantity) 
                            item_quantity=item_quantity+quantity
                            let discount = this.toNumber(item.sold_discount) *quantity
                            item_discounts = item_discounts+discount
                            let sold_price = this.toNumber(item.sold_price)
                            let total_sold_price = sold_price*quantity
                            let sold_price_ = this.money(sold_price)
                            let total_sold_price_ = this.money(total_sold_price)

                            let item_tax = item.tax 
                            let item_tax_group_code = item.tax_group_code 
                            let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
    
                            // let last = total_sold_price_+" "+usable_tax_group
                            // let mid = "QTY:"+quantity+" X "+sold_price_
                            // let first = name+"  "+mid
                            let last = quantity+" "+usable_tax_group
                            let mid = ":" 
                            let first = ""+name 
                            let first_size = this.SIZE(first)
                            let mid_size = this.SIZE(mid)
                            let max2 = this.SIZE(last)
                            let max1 = paper_size-max2-3 

                            if (first_size>max1) {
                                let number1 = name.slice(0,max1)
                                let number2 = name.slice((max1))
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:number1,
                                        last:last,
                                        size:paper_size,
                                        max1:max1,
                                        max2:max2
                                    }) 
                                })  

                                let number3 = number2?number2+" "+mid:mid
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:number3,
                                        last:"",
                                        size:paper_size,
                                        max1:paper_size-1,
                                        max2:1
                                    }) 
                                })  
                            } else {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:first,
                                        last:last,
                                        size:paper_size,
                                        max1:max1,
                                        max2:max2
                                    }) 
                                }) 
                                    
                            }  
                        });  
                    } 

                    //CASHUPS SUMMARY
                    if (true) {
                        print_order.lines.push({ text:" "})
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:false, 
                            text:"SALES SUMMARY"
                        })  
                        
                         
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:" ALL ITEMS QTY:", 
                                last:this.money(item_quantity),  
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"CASH SALES:", 
                                // last:this.money(total_cashups.sales_cash_payments),  
                                last:'---',
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"OTHER SALES:", 
                                // last:this.money(total_cashups.sales_total_other_option_payments),  
                                last:'---',
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"ON ACCOUNT:", 
                                // last:this.money(total_cashups.sales_on_account),  
                                last:'---',
                                size:paper_size
                            }) 
                        }) 
                        print_order.lines.push({
                            align:"left",
                            bold:false,
                            value:"",
                            text:this.EPSON_1LINE({
                                first:"TOTAL SALES:", 
                                // last:this.money(total_cashups.total_sales),  
                                last:'---',
                                size:paper_size
                            }) 
                        })  
                    }
                   
                    //POWERED BY
                    if (true) {
                        print_order.lines.push({ text:" "}) 
                        print_order.lines.push({ text:" "}) 
                        print_order.lines.push({ value:"line"})
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'POWERED BY KIPCOUNT'
                        }) 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            text:'+265993259202'
                        }) 
                    } 
                    // return print_order 
                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async EPSON_ORDER_ITEMS_REQUEST(payload){ 
            return new Promise((resolve,reject)=>{
                let response = {
                    from:"EPSON_ORDER_ITEMS_REQUEST",
                    page:NAME,
                    payload:payload
                } 
                try {    
                    const order = payload?payload.order:null
                    const print_title = payload?payload.print_title:null
                    const aggregated_items = this.CALCULATE_SALES_AGGREGATED_ITEMS({
                        sale_orders:order
                    })
                    
                    let printer = payload?payload.printer?payload.printer :{}:{}

                    const default_paper_size = 48
                    const default_printer_name = "POS80"
                    let paper_size = 0
                    let printer_name = ""
                    let space_value = '.'   
                    let print_order = {}
                     
                    //SETTINGS
                    let settings = this.CompanySettings
                    let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                    let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                    let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                    let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                    let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                    let receipt_show_till = settings?settings.receipt_show_till:null
                    let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                    let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                    let receipt_show_vat = settings?settings.receipt_show_vat:null
                    //template
                    let template_settings = settings?settings.template_settings:null
                    let receipt_settings = template_settings?template_settings.receipt:null
                    let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null

                    //PRINTER INFO
                    paper_size = printer.paper_size?printer.paper_size:default_paper_size
                    printer_name = printer.printer_name?printer.printer_name:default_printer_name
                    print_order.paper_size = paper_size
                    print_order.printer_name = printer_name 
                    print_order.lines = [] 
        
                
                    //TOP HEADER
                    if (receipt_show_receipt_title) { 
                        print_title = print_title?print_title:"SOLD ITEMS"
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"*** "+print_title+" ***"
                        })
                        // print_order.lines.push({ text:" "})
                    }

                    //COMPANY INFO
                    let company = {}
                    if (company && receipt_show_company_info) { 
                        print_order.lines.push({
                            align:"center",
                            bold:true, 
                            value:'company_name',
                            text:company.company_name 
                        })
                         
                    }
        
                    //DOWN HEADER
                    if (receipt_show_receipt_title) { 
                        // print_order.lines.push({ text:" "})
                        print_order.lines.push({
                            align:"center",
                            bold:false,
                            value:'top_header',
                            text:"******"
                        }) 
                        print_order.lines.push({ text:" "}) 
                    }
            


 
                   

                    // if (joined_items_departments) {
                    //     joined_items_departments.forEach(department => {
                    //         let joined_items = department.joined_items
                    //         let department_name = department.name
                              
                            //ITEMS
                            let items = aggregated_items
                            let items_size = this.SIZE(items)
                            let item_quantity = 0
                            let item_discounts = 0
                            let index = 0
                            
                            if (items ) { 
                                //---------------ITEM HEADER------------
                                print_order.lines.push({ value:"line"})
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"ITEMS",
                                        last:"QYT",
                                        size:paper_size,
                                        max1:14,
                                        max2:paper_size-14
                                    }) 
                                }) 
                                
                                items.forEach(item => {
                                    if (true) {
                                        index=index+1 
                                        let option_name = item?.selected_item_option_names
                                        let item_name = item.name?item.name:item.item_name
                                        let name = receipt_show_item_numbering?index+". "+item_name:item_name
                                        if (option_name) {
                                            name = name+" ("+option_name+")"
                                        }

                                        let quantity = this.toNumber(item.sold_quantity) 
                                        item_quantity=item_quantity+quantity
                                        let discount = this.toNumber(item.sold_discount) *quantity
                                        item_discounts = item_discounts+discount
                                        let sold_price = this.toNumber(item.sold_price)
                                        let total_sold_price = sold_price*quantity
                                        let sold_price_ = this.money(sold_price)
                                        let total_sold_price_ = this.money(total_sold_price)
            
                                        let item_tax = item.tax 
                                        let item_tax_group_code = item.tax_group_code 
                                        let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
                
                                        // let last = total_sold_price_+" "+usable_tax_group
                                        // let mid = "QTY:"+quantity+" X "+sold_price_
                                        // let first = name+"  "+mid
                                        let last = quantity+" "+usable_tax_group
                                        let mid = ":" 
                                        let first = ""+name 
                                        let first_size = this.SIZE(first)
                                        let mid_size = this.SIZE(mid)
                                        let max2 = this.SIZE(last)
                                        let max1 = paper_size-max2-3 
            
                                        if (first_size>max1) {
                                            let number1 = name.slice(0,max1)
                                            let number2 = name.slice((max1))
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:number1,
                                                    last:last,
                                                    size:paper_size,
                                                    max1:max1,
                                                    max2:max2
                                                }) 
                                            })  
            
                                            let number3 = number2?number2+" "+mid:mid
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:number3,
                                                    last:"",
                                                    size:paper_size,
                                                    max1:paper_size-1,
                                                    max2:1
                                                }) 
                                            })  
                                        } else {
                                            print_order.lines.push({
                                                align:"left",
                                                bold:false,
                                                value:"",
                                                text:this.EPSON_1LINE({
                                                    first:first,
                                                    last:last,
                                                    size:paper_size,
                                                    max1:max1,
                                                    max2:max2
                                                }) 
                                            }) 
                                             
                                        }  
                                    }
                                    
                                });  
                            }
        
                           
                            //TOTAL  
                            if (true) {  
                                print_order.lines.push({ value:"line"})
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    double:true,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"TOTAL ITEMS:", 
                                        last:""+items_size,
                                        size:paper_size/2
                                    }) 
                                })  
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    double:false,
                                    value:"",
                                    text:this.EPSON_1LINE({
                                        first:"TOTAL QYT:", 
                                        last:""+item_quantity,
                                        size:paper_size
                                    }) 
                                })   
                                 
                                 
                            }  

                            //POWERED BY
                            if (!disable_kipcount_watermark) { 
                                print_order.lines.push({ value:"line"})
                                print_order.lines.push({
                                    align:"center",
                                    bold:false, 
                                    text:'***POWERED BY KIPCOUNT.COM***'
                                }) 
                                print_order.lines.push({ value:" "}) 
                            } 
 
                    //     }); 
                    // }

                     
                    // return print_order 
                    resolve({
                        success:true,
                        data:print_order,
                    }) 
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },
        EPSON_1LINE(payload){ 
            let response = {
                from:"EPSON_1LINE",
                page:NAME,
                payload:payload
            } 
            try {   
                if (!payload) {
                    response =  {
                        ...response,
                        success:false,
                        error:'no payload'
                    }
                    this.error(response)
                    return null
                } 
                let first = payload.first?payload.first:""
                let last = payload.last?payload.last:""
                let size = payload.size?payload.size:48
                let max1 = payload.max1?payload.max1:30
                let max2 = payload.last?payload.max2:15
                let space_value = payload.space_value?payload.space_value:' '

                let size1 = this.SIZE(first)
                let size2 = this.SIZE(last)
                
                
                if (size1>max1) {
                    first = first.slice(0,(max1-2))+".."
                    size1 = max1
                }if (size2>max2) {
                    last = last.slice(0,(max2-2))+".."
                    size2 = max2
                }
                
                let total_size = size1+size2
                let space_size = size-total_size
                let space = ''
                for (let index = 0; index < space_size; index++) { 
                    space = space+space_value 
                }  
                return first+space+last 
            } catch (error) { 
                response = {
                    ...response,
                    error:error,
                    success:false
                } 
                this.error(response)
                reject(response) 
            }  
        },  

        //PROMISE
        ON_QR_SCAN(){
            return new Promise((resolve,reject)=>{
                try { 
                    if (!this.PROMISE_ON_QR_SCAN) {
                        EventTarget()
                        this.PROMISE_ON_QR_SCAN = new Event('qr',document.addEventListener("keydown", function(e) {
                            const textInput = e.key  
                            const timeStamp = e.timeStamp;
                            
                             
                            console.log(textInput,timeStamp,this.INPUT_NEW_TEXT);
                            if (textInput==='Enter') { 
                                
                                this.INPUT_TEXT=''
                                this.INPUT_NEW_TEXT = true 
                            }else if (textInput==='Shift') {
                                    
                            }else{  
                                this.INPUT_TEXT = this.INPUT_TEXT+textInput
                                this.INPUT_NEW_TEXT = false
                            } 
                        }) ) 
                    }
                    resolve(this.PROMISE_ON_QR_SCAN) 
                } catch (error) {
                    reject(error)
                    this.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:NAME, 
                    })  
                }

            }) 
        },
        async FISCAL_PRINT(payload){ 
            let response = {
                from:"FISCAL_PRINT",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    }
                    let action = payload?payload.action:null
                    let com_port = payload?payload.com_port:null
                    let baud_rate = payload.baud_rate?payload.baud_rate:115200
                    let SaleOrder = payload.SaleOrder?payload.SaleOrder:null
                    let duplicate = payload.SaleOrder?payload.duplicate:null
                    let tax_group = payload.SaleOrder?payload.tax_group:null
                    // let url_receipt = 'http://localhost:5000/api/Receipt/PrintReceipt'
                    let url_receipt = 'http://localhost:5000/api/Receipt/FiscalReceipt'
                    let url_duplicate = 'http://localhost:5000/api/Receipt/PrintDuplicate'
                    let url_local = duplicate?url_duplicate:url_receipt 
                    let url = payload.url?payload.url:url_local
                    var config_data = {
                        "comPort": com_port,
                        "baudRate": baud_rate, 
                    }
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        }, 
                    };  

                    if (action == "test_connection") {
                        
                    }else if (duplicate===true) {
                        let receipt_number = SaleOrder?SaleOrder.key:null 
                        var data = JSON.stringify({
                            ...config_data,
                            "receiptNumber": receipt_number
                        });  
                        config = {
                            ...config,
                            data:data
                        }
                    }else if (action === "sales") {
                        let items = SaleOrder?SaleOrder.items:null
                        let joined_items = this.JOIN_ITEMS(items) 
                        var products = []
                        if (items) {
                            joined_items.forEach(element => {
                                let productName = element.name?element.name.slice(0,35):"......."
                                let item_tax = element?element.tax:null
                                let item_tax_group_code = element?element.tax_group_code:null
                                let usable_tax_group = item_tax_group_code?item_tax_group_code:
                                item_tax?"A":"B"
                                products.push({
                                    "productName":productName,
                                    "taxGroup":this.TEXT_UP(tax_group?tax_group:usable_tax_group),
                                    "singlePrice":""+element.sold_price, 
                                    "quantity":""+element.quantity,
                                })
                            });
                        } 
                        var data = JSON.stringify({
                            ...config_data,
                            "products": products
                        });  
                        config = {
                            ...config,
                            data:data
                        }
                    }else{
                        reject({
                            ...response,
                            success:false,
                            error:'no action'
                        })
                    }  
                     
                    
                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
                        
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) {
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        },  
        async VFD_DISPLAY_TEXT(payload){ 
            let response = {
                from:"VFD_DISPLAY_TEXT",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    }
                    let action = payload?payload.action:null
                    let com_port = payload?payload.com_port:null
                    let baud_rate = payload.baud_rate?payload.baud_rate:115200
                    let text = payload.text?payload.text:null 
                    let text2 = payload.text2?payload.text2:null 

                    let url= 'http://localhost:5000/api/Receipt/DisplayVfd' 
                    var config_data = {
                        "comPort": com_port,
                        "baudRate": baud_rate, 
                        "messageLineOne": text,
                        "messageLineTwo": text2, 
                    }
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        }, 
                        data:config_data
                    };  

                     
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
                        
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) {
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        async LED8N_DISPLAY_TEXT(payload){ 
            let response = {
                from:"LED8N_DISPLAY_TEXT",
                page:NAME,
                payload:payload
            }
            return new Promise((resolve,reject)=>{
                try {  
                    if (!payload) {
                        reject({
                            ...response,
                            success:false,
                            error:'no payload'
                        })
                    }
                    let action = payload?payload.action:null
                    let com_port = payload?payload.com_port:null
                    let baud_rate = payload.baud_rate?payload.baud_rate:115200
                    let text = payload.text?payload.text:null 
                    let light_status = payload.light_status?payload.light_status:null 

                    let url= 'http://localhost:5000/api/Receipt/DisplayD1' 
                    var config_data = {
                        "comPort": com_port,
                        "baudRate": baud_rate, 
                        "messageLineOne": text,
                        "lightStatus": light_status, 
                    }
                    var config = {
                        method: 'post',
                        url: url,
                        headers: { 
                            'Content-Type': 'application/json'
                        }, 
                        data:config_data
                    };   
                    this.axios(config)
                    .then(function (res) {
                        let data = res?res.data:null   
                        let error = data?data.isErrorOccured:null 
 
                        if (!error) {
                            resolve({
                                ...response,
                                ...data,
                                success:true
                            }) 
                        }else{
                            reject({
                                ...response,
                                ...data,
                                success:false
                            })
                        }
                    })
                    .catch(function (error) { 
                        reject({
                            ...response,
                            error:error
                        })
                    });     
                } catch (error) {
                    this.show = false
                    this.inputLoading = false 
                    response = {
                        ...response,
                        error:error,
                        success:false
                    } 
                    this.error(response)
                    reject(response) 
                }  
            })
        }, 
        
        //
        SCREEN_FULL_MODE(full){
            try { 
                const element = document.documentElement; // Get the root element (e.g., <html>) 
                if (document.fullscreenEnabled) {
                    const active = document.fullscreenElement
                    if (full) {
                        if (!active) {
                            // Enter full screen mode
                            if (element.requestFullscreen) {
                                element.requestFullscreen();
                            } else if (element.mozRequestFullScreen) {
                                element.mozRequestFullScreen();
                            } else if (element.webkitRequestFullscreen) {
                                element.webkitRequestFullscreen();
                            } else if (element.msRequestFullscreen) {
                                element.msRequestFullscreen();
                            }
                        }   
                    }
                    if (full == false) { 
                        // Exit full screen mode
                        if (active) {
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.mozCancelFullScreen) {
                                document.mozCancelFullScreen();
                            } else if (document.webkitExitFullscreen) {
                                document.webkitExitFullscreen();
                            } else if (document.msExitFullscreen) {
                                document.msExitFullscreen();
                            } 
                        }
                    }
                    this.fullscreen = document.fullscreenElement
                    setTimeout(() => {
                        this.fullscreen = document.fullscreenElement 
                    }, 1000);
                    return {
                        active : this.fullscreen
                    }
                }
            } catch (error) {
                this.error({
                    error:error,
                    from:'SCREEN_FULL_MODE',
                    page:NAME, 
                })
            }
        },  
        SCREEN_RELOAD(){
            try { 
                location.reload() 
            } catch (error) {
                this.error({
                    error:error,
                    from:'SCREEN_RELOAD',
                    page:NAME, 
                })
            }
        },  
        //----------------[MAIN FUNCTIONS]------------------//
        DIALOG_YES(action){
            try {  
                let code = action.code
                if (action) {  
                    if (code===NAME+":LOGOUT") { 
                        if (action.YES) {
                            store.dispatch('fi_signUserOut') 
                        }else{
                            
                        }
                    } 
                    if (code===NAME+":CLOSE-REGISTER") { 
                        if (action.YES) {
                            // let link = this.COMPANY_LINK("/sale",true)
                            // this.go(link)
                            // setTimeout(() => {
                            // }, 1000);
                            EventBus.$emit("ON_CLOSE_REGISTER") 
                        }else{
                            // let link = this.COMPANY_LINK("/",true)
                            this.go("/")
                        }
                    } 
                } 
            } catch (error) {
                this.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:NAME, 
                })
            }
        }, 
        FEEDBACKS(time){
            try {
                if (!time) {
                    time=400
                }
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 
    
    
                    //CHECK USER STATE 
                    if (ps) {
                        let signingIn = ps.find(process=>{
                            return process.name == 'signing/withEmail'
                        })
                        let signingOut = ps.find(process=>{
                            return process.name == 'signing/out'
                        })
                        
    
                        // if (signingIn) { 
                        //     this.progressDialog(null,true,true,"Logging In..")
                        // }else{ 
                        //     // this.progressDialog()
                        // } 
                        
                        // if (signingOut) { 
                        //     this.progressDialog(null,true,true,"Logging Out..")
                        // }else{ 
                        //     this.progressDialog()
                        // } 
                    }else{ 
                    }
    
                    //check response
                    if (res.res==='signing/out/successful') {   
                        if (!this.forceLogout) {
                            this.dialog({
                                show:true,
                                fixed:true,
                                title:"LOGGED OUT",
                                text:"Successfully logged out.",
                                btnYes:"Okay"
                            })  
                        }
                        this.forceLogout = false
                        this.progressDialog()  
                        this.restartResponses()
                    }else if (res.res==='signing/out/error'){ 
                        this.dialog({
                            show:true,
                            fixed:true,
                            title:"ERROR",
                            text:"Something went wrong while trying to logout. Pleas try again.",
                            btnYes:"Okay"
                        })
                        this.dialog(null,true,true,'ERROR','','OK') 
                        this.progressDialog()
                        this.restartResponses()
                    }
                },time) 
            } catch (error) {
                this.error({
                    error:error,
                    from:'FEEDBACKS',
                    page:NAME, 
                })
            }
        }, 
        RELOAD_PAGE(time=100,go){
            try { 
                const name = router.app.$route.name
                const fullPath = router.app.$route.fullPath
                const links = router.options.routes
                router.push('/')  
                setTimeout(()=>{
                    if (go) {
                        router.push(go)     
                    } else {
                        router.push(fullPath)      
                    }
                },time) 
            } catch (error) {
                this.error({
                    error:error,
                    from:'RELOAD_PAGE',
                    page:NAME, 
                })
            }
        }, 
    },
    beforeDestroy(){
        try { 
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        } catch (error) {
            this.error({
                error:error,
                from:'beforeDestroy',
                page:NAME, 
            })
        }  
    },
    watch: {
        us(v){
            this.FEEDBACKS()
        },
        loading(value){
            this.FEEDBACKS(1)
        },
        processes(value){
            this.FEEDBACKS(1)
        },
        responses(value){
            this.FEEDBACKS(1) 
        } 
    },
});
