<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <br> 
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            v-model="input_table"
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_only_display="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :filters="FILTERS"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS" 
                            :data_items="CalculatedItems"   
                            :select_items="DataSource"   
                            :is_joined="true"  
                            :path_item="SALES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div> 
        <div v-if="dialog_doc_show"> 
            <mbs-dialog-doc-show
                v-model="dialog_doc_show"
                :width="'600'"
                :title="input_show_doc.title"
                :data="input_show_doc.data"
                :data_key="input_show_doc.data_key"
                :document_type="input_show_doc.document_type"
                :height="input_show_doc.height"
                :top_header="input_show_doc.top_header"
                :bottom_header="input_show_doc.bottom_header"
            />  
        </div>
        <mbs-auto-print
            v-if="printing"
            @finish_print="FINISH_PRINT"
            :show="true"  
            :auto_print="auto_print"
            :auto_printer_name="auto_printer_name"
            :type="print_type" 
            :title="print_title" 
            :data="print_data" 
            />  
    </div>
    </div> 
</template>

<script>
    import DATA from "../../../../../plugins/DATA"
    let PAGE_NAME = DATA.ITEMS.SALES.values    
    import {mapState,mapGetters } from "vuex"
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:"Sales Items",
                NAME_ITEM:"Sales Item",
                VALUE_ITEMS:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                VALUE_ITEM:DATA.ITEMS.DAYS_SHIFTS_SALES.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                tableTabs:[
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ],
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:"mdi-printer",action:"print",color:"secondary", outlined:false,  text:"Print Sold Items"},     
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                    // {type:"action",btn:true,icon:'mdi-filter-cog-outline',action:"filter_items",color:"", outlined:true, btn_icon:true,class:"ml-3 mr-",  text:""},   
                    // {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Item"},   
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'sold_quantity'},
                    {name:'Total Cost Price',value:'sold_cost_price',show_value:'sold_cost_price_',money:true},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ],
                
                filter_date:null,
                filteredDate:null,
                currentTableTab:null,
                input_table:null, 

                downloading:false,
                dialog_doc_show:false,
                input_show_doc:{
                    // title:"INVOICE..",
                    top_header:true
                }, 

                printing:false,
                print_type:'sales_items',
                print_title:'SALES-REPORT',
                print_data:null,
                auto_print:false,
                auto_printer_name:null,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: { 
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sales",3,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            }, 
            moment(){  
                return this.MBS.date.moment
            }, 
            ////
             ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SalesOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSalesOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 


                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                JoinedItemCategories: state=> state.join[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()], 
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                Departments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                JoinedDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
            }), 
            
            ItemsData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                let items = JoinedItems?JoinedItems:Items
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            },
            OutletsData(){  
                let items = this.JoinedOutlets?this.JoinedOutlets:this.Outlets
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            },
            CategoriesData(){ 
                let Categories = this.ItemCategories
                let JoinedCategories = this.JoinedItemCategories
                let items = JoinedCategories?JoinedCategories:Categories 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            }, 
            DepartmentsData(){ 
                let Departments = this.Departments
                let JoinedDepartments = this.JoinedDepartments
                let items = JoinedDepartments?JoinedDepartments:Departments 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            },  
            SalesOrdersData(){ 
                let SalesOrders = this.SalesOrders
                let JoinedSalesOrders = this.JoinedSalesOrders
                let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },  
            DayShiftsData(){ 
                let items = this.JoinedDayShifts?this.JoinedDayShifts:this.DayShifts
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list
            }, 
            DayShiftsCashups(){
                let days = this.DayShiftsData
                if(!days){return null}
                let cashups = []
                days.forEach(element => {
                    if (element.joined_cashups) {
                        cashups = [...cashups,...element.joined_cashups]
                    }
                }); 
                if(!cashups){return null} 
                let list = [...cashups]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))    
                return list 
            }, 
            DataSource(){
                // let Items = this.ItemsData 
                let Items = this.AggregatedItems 
                let Categories = this.CategoriesData
                let Brands = this.Brands 
                let Manufacturers = this.Manufacturers 
                let Departments = this.DepartmentsData
                let Outlets = this.OutletsData
                let DayShifts = this.DayShiftsData
                let Cashups = this.DayShiftsCashups 
                return {
                    Items:Items,
                    Categories:Categories,
                    Brands:Brands,
                    Manufacturers:Manufacturers,
                    Departments:Departments,
                    Outlets:Outlets,
                    DayShifts:DayShifts,
                    Cashups:Cashups,
                }
            },

           
            // FILTERS(){
            //     let categories = this.CategoriesData
            //     let departments = this.DepartmentsData
            //     let items = this.CalculatedItems 
            //     return [ 
            //         {type:'auto',name:'Department',value:'department_code',item_value:"code",item_text:"name",items:departments}, 
            //         {type:'select',name:'Category',value:'category_code',item_value:"code",item_text:"name",items:categories}, 
            //         {type:'auto',name:'Barcode',value:'code',item_value:"code",item_text:"code",items:items}, 
            //         {type:'auto',name:'Item Name',value:'code',item_value:"code",item_text:"name",items:items}, 
            //     ]
            // }, 
            
            FILTERS(){ 
                return [ 
                    {
                        type:"autocomplete",value:"department_code",prepend_inner_icon:'mdi-format-list-group',clearable:true,filled:false,multiple:true
                        ,chips:true,deletable_chips:true,label:"Department",
                        select:{
                            name:'Departments',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },
                    {
                        type:"autocomplete",value:"category_code",prepend_inner_icon:'mdi-shape-plus',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Category",
                        select:{
                            name:'Categories',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"item_code",search_value:"code",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Barcode",
                        select:{
                            name:'Items',
                            value:'code',
                            text:'code'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"item_key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Item Name",
                        select:{
                            name:'Items',
                            value:'item_key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"cashup_key",barcode:true,prepend_inner_icon:'mdi-account-cash-outline',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Cashup",
                        select:{
                            name:'Cashups',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-account-cash-outline',
                                tile:true
                            },
                            item_title:['till_name'],
                            item_subtitle:['teller_name','operating_date_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"day_shift_key",barcode:true,prepend_inner_icon:'mdi-network-pos',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Day Shift",
                        select:{
                            name:'DayShifts',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-network-pos',
                                tile:true
                            },
                            item_title:['outlet_name'],
                            item_subtitle:['teller_name','operating_date_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"outlet_code",barcode:true,prepend_inner_icon:'mdi-sitemap',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Outlet",
                        select:{
                            name:'Outlets',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    },    
                ]
            }, 
            HEADER_ITEMS1(){
                
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"date",value:'local_created_at_',show:true},           
                    {id:0,name:"Barcode",value:'code_',show:false},   
                    {id:0,name:"transaction",value:"transaction_key",show:false},     
                    {id:0,name:"Item",value:"name",show:true},  

                    {id:0,name:"Department",value:"item_department_name",show:false}, 
                    {id:0,name:"Department Code",value:"item_department_code",show:false},
                    {id:0,name:"Category",value:"category_name",show:true}, 
                    {id:0,name:"Category Code",value:"category_code",show:false}, 
                    {id:0,name:"Brand",value:"brand_name",show:false}, 
                    {id:0,name:"Brand Code",value:"brand_code",show:false}, 

                    {id:0,name:"quantity",value:'sold_quantity',align:'right',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Barcode",value:'code_',show:true},           
                    {id:0,name:"item",value:"name",show:true},     

                    {id:0,name:"Department",value:"item_department_name",show:true}, 
                    {id:0,name:"Department Code",value:"item_department_code",show:false},
                    {id:0,name:"Category",value:"category_name",show:true}, 
                    {id:0,name:"Category Code",value:"category_code",show:false}, 
                    {id:0,name:"Brand",value:"brand_name",show:false}, 
                    {id:0,name:"Brand Code",value:"brand_code",show:false}, 

                    {id:0,name:"Transactions",value:"number_of_transactions",align:'center',show:false},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'center',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.HEADER_ITEMS1
                }else{
                    return this.HEADER_ITEMS2
                }
            }, 
            
            AggregatedItems(){
                let SaleOrders = this.SalesOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null 
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {
                    let date = moment(order.local_created_at).format("yyyy-MM-D")
                    let momentDate = moment(date) 
                    let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                let index = items.findIndex(it=>{
                                    return it.item_key  == item.item_key
                                }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount

                                if (index == -1) { 
                                    items.push({
                                        ...item, 
                                        day_shift:order.day_shift,
                                        day_shift_key:order.day_shift_key,
                                        cashup:order.cashup,
                                        cashup_key:order.cashup_key,
                                        outlet:order.outlet,
                                        outlet_code:order.outlet_code,

                                        sold_quantity:sold_quantity,
                                        sold_cost_price:sold_cost_price,
                                        sold_discount:sold_discount,
                                        sold_price:sold_price,
                                        total_sold_price:total_sold_price,
                                        number_of_transactions:1,
                                        
                                        sold_quantity_:this.MBS.actions.money( sold_quantity),
                                        sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                        sold_discount_:this.MBS.actions.money( sold_discount),
                                        sold_price_:this.MBS.actions.money( sold_price),
                                        total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    })
                                } else {
                                    items[index].sold_quantity = items[index].sold_quantity+sold_quantity
                                    items[index].sold_cost_price = items[index].sold_cost_price+sold_cost_price
                                    items[index].sold_discount = items[index].sold_discount+sold_discount
                                    items[index].sold_price = items[index].sold_price+sold_price
                                    items[index].total_sold_price = items[index].total_sold_price+total_sold_price
                                    items[index].number_of_transactions = items[index].number_of_transactions+1
                                    
                                    items[index].sold_quantity_ = this.MBS.actions.money( items[index].sold_quantity)
                                    items[index].sold_cost_price_ = this.MBS.actions.money( items[index].sold_cost_price)
                                    items[index].sold_discount_ = this.MBS.actions.money( items[index].sold_discount)
                                    items[index].sold_price_ = this.MBS.actions.money( items[index].sold_price)
                                    items[index].total_sold_price_ = this.MBS.actions.money( items[index].total_sold_price)
                                }    
                            });
                            
                        }
                    }     
                });  
                return items
            },
            PerTransactionItems(){ 
                let SaleOrders = this.SalesOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {  
                    let date = moment(order.local_created_at).format("yyyy-MM-D")
                    let momentDate = moment(date) 
                    let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                // let index = items.findIndex(it=>{
                                //     return it.item_key  == item.item_key
                                // }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount
                                

                                items.push({
                                    ...item, 
                                    day_shift:order.day_shift,
                                    day_shift_key:order.day_shift_key,
                                    cashup:order.cashup,
                                    cashup_key:order.cashup_key,
                                    outlet:order.outlet,
                                    outlet_code:order.outlet_code,

                                    sold_quantity:sold_quantity,
                                    sold_cost_price:sold_cost_price,
                                    sold_discount:sold_discount,
                                    sold_price:sold_price,
                                    total_sold_price:total_sold_price,
                                    
                                    sold_quantity_:this.MBS.actions.money( sold_quantity),
                                    sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                    sold_discount_:this.MBS.actions.money( sold_discount),
                                    sold_price_:this.MBS.actions.money( sold_price),
                                    total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    
                                    transaction_key:order.key,

                                    local_created_at:order.local_created_at,
                                    local_created_at_:order.local_created_at_,
                                    local_created_at_date:order.local_created_at_date,
                                    local_created_at_time:order.local_created_at_time,
                                })
                                     
                            });
                            
                        }
                    }     
                });   
                return items
            },
            CalculatedItems(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.PerTransactionItems
                }else{
                    return this.AggregatedItems
                }
            }  
        },
        methods: { 
            FINISH_PRINT(action,payload){
                try {   
                    console.log(action,'action....');
                    console.log(payload,'payload....');
                    // let canceled = payload?payload.canceled:null
                    // if(!action){return}
                    // if (action.code == PAGE_NAME+"=CLOSE-CASHUP") {
                    //     this.$store.dispatch("fi_upload_cashups")
                    //     // this.$store.dispatch("fi_uploadSales")
                    //     this.SUBMIT_CLOSE_CASHUP(action.data) 
                    //     this.REFRESH_DATA()  
                    // }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            TABLE_ACTION(action,item){
                try { 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "print") { 
                        this.PRINT_ITEMS()
                    } else if (action == "received_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "return_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/return-stock",true))
                    }else if (action == "transfer_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/transfer-stock",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async PRINT_ITEMS(){ 
                try { 
                    let tab = this.currentTab
                    let table_tab = this.currentTableTab
                    let day_sales = this.SalesOrdersData
                    let all_items = this.TableData
                    let input_table = this.input_table
                    let filtered_items = input_table?.input_filters?.data_filtered
                    let items = filtered_items?filtered_items:all_items

                    let per_transactions = this.PerTransactionItems
                    let aggregated = this.AggregatedItems
                    let REQUEST = await this.MBS.actions.EPSON_DAY_ITEM_REQUEST({ 
                        data:{
                            day_shift:{},
                            sale_orders:day_sales,
                            aggregated_items:items
                        },
                        report_type:"DDDDDD"
                    })

                    // console.log(REQUEST,'REQUEST....i..');
                    // console.log(items,'items....i..');
                    // console.log(filtered_items,'filtered_items....i..');
                    // console.log(input_table,'input_table....i..');
  
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null
                    
                    let day_shift = this.JoinedDayShift 
                    this.printing = false
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print = true
                        }
                        this.auto_printer_name = printer_name
                        this.print_data = {
                            day_shift:{},
                            sale_orders:day_sales,
                            aggregated_items:items
                        } 
                        this.printing = true   
                    }, 5); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PRINT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{ 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
            input_table(value){ 
                if (value) {
                    this.filteredDate = value.filter_date
                    this.currentTableTab = value.current_table_tab
                } 
            } 

        },
    }
</script>
 
